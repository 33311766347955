import { DATE_BACKEND_FORMAT } from '_common/constants/common';
import { messageWarning } from '_common/constants/message';
import RangeNumber from '_common/dof/Control/RangeNumber';
import RangePicker from '_common/dof/Control/RangePicker';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectInvestor from '_common/dof/Select/SelectInvestor';
import useConfirm from '_common/hooks/useConfirm';
import moment from 'moment';
import {
  CASH_RECEIPT_TYPE,
  EXPENSE_VOUCHER_TYPE,
  PAYMENT_METHOD_TYPE,
} from 'pages/Finance/constants';
import { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormItem, Modal } from 'tera-dls';
import { StatisticCashExpenseByInvestorParamContext } from '.';

interface IProps {
  open: boolean;
  onClose?: () => void;
  onSubmit: (value: any) => void;
}

const SettingModal = (props: IProps) => {
  const { onClose, open, onSubmit } = props;
  const form = useForm({ mode: 'onChange' });
  const confirm = useConfirm();
  const isDirty = form?.formState?.isDirty;
  const investor_id = form?.watch('investor_id');

  const { contextParams } = useContext(
    StatisticCashExpenseByInvestorParamContext,
  );

  useEffect(() => {
    if (contextParams) {
      form.reset({
        ...contextParams,
        ...(contextParams.date?.[0] && {
          date: [
            moment(contextParams.date?.[0]),
            moment(contextParams.date?.[1]),
          ],
        }),
        ...(contextParams.accounting_date?.[0] && {
          accounting_date: [
            moment(contextParams.accounting_date?.[0]),
            moment(contextParams.accounting_date?.[1]),
          ],
        }),
      });
    }
  }, [contextParams]);

  const handleSubmitForm = (value: any): void => {
    const data = {
      ...value,
      ...(!!value.date?.[0] && {
        date: [
          value.date[0]?.format(DATE_BACKEND_FORMAT),
          value.date[1]?.format(DATE_BACKEND_FORMAT),
        ],
      }),
      ...(!!value.accounting_date?.[0] && {
        accounting_date: [
          value.accounting_date[0]?.format(DATE_BACKEND_FORMAT),
          value.accounting_date[1]?.format(DATE_BACKEND_FORMAT),
        ],
      }),
    };
    // setContextParams(data);
    onSubmit(data);
    onClose();
  };

  const handleCloseConfirm = () => {
    if (isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>{messageWarning.WARNING_EXIT_1}</p>
            <p>{messageWarning.WARNING_EXIT_2}</p>
          </>
        ),
        onOk: () => {
          onClose();
        },
      });
    } else onClose();
  };

  const paymentMethod = Object.entries(PAYMENT_METHOD_TYPE).map(
    ([key, value]) => ({
      label: value,
      value: key,
    }),
  );

  const expenseVoucherTypeOptions = Object.entries(EXPENSE_VOUCHER_TYPE).map(
    ([key, value]) => ({
      label: value,
      value: key,
    }),
  );

  const cashReceiptTypeOptions = Object.entries(CASH_RECEIPT_TYPE).map(
    ([key, value]) => ({
      label: value,
      value: key,
    }),
  );

  return (
    <Modal
      title="Tham số báo cáo"
      destroyOnClose
      closeIcon={false}
      width={500}
      cancelText="Hủy"
      okText="Đồng ý"
      onOk={form.handleSubmit(handleSubmitForm)}
      onCancel={handleCloseConfirm}
      open={open}
      centered={true}
    >
      <FormTera form={form} onSubmit={handleSubmitForm}>
        <FormTeraItem label="Nhà đầu tư" name="investor_id">
          <SelectInvestor
            paramsApi={{ include_id: investor_id }}
            placeholder="Tất cả"
          />
        </FormTeraItem>
        <FormTeraItem label="Phương thức thanh toán" name="payment_method">
          <Select
            placeholder="Tất cả"
            mode="multiple"
            options={paymentMethod}
          />
        </FormTeraItem>
        <FormTeraItem label="Ngày hạch toán" name="accounting_date">
          <RangePicker />
        </FormTeraItem>
        <FormTeraItem label="Loại phiếu chi" name="payment_transaction_type">
          <Select
            placeholder="Tất cả"
            options={expenseVoucherTypeOptions}
            mode="multiple"
            allowClear
          />
        </FormTeraItem>
        <FormTeraItem label="Loại phiếu thu" name="receipt_transaction_type">
          <Select
            allowClear
            placeholder="Tất cả"
            options={cashReceiptTypeOptions}
            mode="multiple"
          />
        </FormTeraItem>

        <FormItem label="Tổng giá trị thu" isRequired={false}>
          <RangeNumber
            startFormName="total_receipt_from"
            endFormName="total_receipt_to"
            endInputProps={{
              min: 0,
            }}
            startInputProps={{
              min: 0,
            }}
            startFormItemProps={{
              className: 'w-full',
            }}
            endFormItemProps={{
              className: 'w-full',
            }}
          />
        </FormItem>
        <FormItem
          label="Tổng giá trị chi"
          isRequired={false}
          className="w-full"
        >
          <RangeNumber
            startFormName="total_payment_from"
            endFormName="total_payment_to"
            endInputProps={{
              className: 'w-full',
              min: 0,
            }}
            startInputProps={{
              min: 0,
            }}
            startFormItemProps={{
              className: 'w-full',
            }}
            endFormItemProps={{
              className: 'w-full',
            }}
          />
        </FormItem>

        <FormTeraItem label="Ngày phiếu thu - chi" name="date">
          <RangePicker allowClear={false} />
        </FormTeraItem>
      </FormTera>
    </Modal>
  );
};

export default SettingModal;
