export const ORDER_STATUS = {
  all: 'Tất cả',
  pending: 'Chờ duyệt',
  approved: 'Đã duyệt',
};

export const ORDER_STATUS_COLOR = {
  pending: 'yellow03',
  approved: 'green03',
};

export const ORDER_STATUS_KEY = {
  approved: 'approved',
};
