import { useMutation, useQueryClient } from '@tanstack/react-query';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import {
  DATE_TIME_FORMAT,
  TAB_ITEMS_STATUS,
  TAB_ITEMS_STATUS_COLOR,
} from '_common/constants/common';
import TableTera from '_common/dof/TableTera';
import { ITeraTableProps } from '_common/dof/TableTera/_interfaces';
import useConfirm from '_common/hooks/useConfirm';
import { useState } from 'react';
import {
  ColumnsType,
  DropdownItem,
  formatCurrency,
  formatDate,
  notification,
  Tag,
} from 'tera-dls';
import InvestmentTypeApi from '../../api';
import { INVESTMENT_TYPE } from '../../constants';
import InvestmentTypeDetail from '../Detail';
import InvestmentTypeForm from '../Form';
import { usePermission } from '_common/hooks/usePermission';
import { INVESTMENT_TYPE_PERMISSION_KEY } from '_common/constants/permission';

function InvestmentTypeTable(props: ITeraTableProps) {
  const [openForm, setOpenForm] = useState({ open: false, id: null });
  const [openDetail, setOpenDetail] = useState({ open: false, id: null });

  const { hasPage } = usePermission();
  const confirm = useConfirm();
  const queryClient = useQueryClient();

  const { mutate: mutateChangeStatus, isLoading: loadingChangeStatus } =
    useMutation((params: any) => InvestmentTypeApi.changeStatus({ params }), {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries(['get-investment-type-list']);
          queryClient.invalidateQueries(['get-summary-investment-type-list']);
        }
      },
      onError: (error: any) => {
        notification.error({
          message: error?.message,
        });
      },
    });

  const { mutate: mutateDelete, isLoading: loadingDelete } = useMutation(
    (id: any) => InvestmentTypeApi.delete({ id }),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries(['get-investment-type-list']);
          queryClient.invalidateQueries(['get-summary-investment-type-list']);
        }
      },
      onError: (error: any) => {
        notification.error({
          message: error?.message,
        });
      },
    },
  );

  const handleChangeStatus = (record) => {
    const text = record?.status == 1 ? 'ngưng' : 'mở';
    confirm.warning({
      title: `Xác nhận ${text} hoạt động loại hình đầu tư`,
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn {text} hoạt động loại hình đầu tư</p>
          <p>
            <b>{record?.name}</b> này không?
          </p>
        </div>
      ),
      onOk: () => {
        const params = {
          id: record?.id,
          status: record?.status == 1 ? 0 : 1,
        };
        mutateChangeStatus(params);
      },
    });
  };

  const handleDelete = (record) => {
    confirm.warning({
      title: 'Xác nhận xóa loại hình đầu tư',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn xóa loại hình đầu tư</p>
          <p>
            <b>{record?.name}</b> này không?
          </p>
        </div>
      ),
      onOk: () => {
        mutateDelete(record?.id);
      },
    });
  };

  const renderItemActions = (record) => {
    const items: DropdownItem[] = [];

    if (hasPage(INVESTMENT_TYPE_PERMISSION_KEY.VIEW_DETAIL))
      items.push({
        key: '1',
        label: 'Xem',
        onClick: () => setOpenDetail({ open: true, id: record?.id }),
      });
    if (hasPage(INVESTMENT_TYPE_PERMISSION_KEY.CHANGE_STATUS))
      items.push({
        key: '2',
        label: record?.status == 1 ? 'Ngưng hoạt động' : 'Hoạt động',
        onClick: () => handleChangeStatus(record),
      });
    if (hasPage(INVESTMENT_TYPE_PERMISSION_KEY.UPDATE))
      items.push({
        key: '3',
        label: 'Sửa',
        onClick: () => setOpenForm({ open: true, id: record?.id }),
      });
    if (hasPage(INVESTMENT_TYPE_PERMISSION_KEY.DELETE) && !record?.status)
      items.push({
        key: '4',
        label: <span className="text-red-500">Xóa</span>,
        onClick: () => handleDelete(record),
      });
    return items;
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Mã loại hình đầu tư',
      dataIndex: 'code',
      width: 100,
      render: (text) => <p className="line-clamp-2">{text}</p>,
    },
    {
      title: 'Tên loại hình đầu tư',
      dataIndex: 'name',
      width: 300,
      render: (name, record) => (
        <p
          className="line-clamp-2 break-word text-blue-600 cursor-pointer"
          onClick={() => setOpenDetail({ open: true, id: record?.id })}
        >
          {name}
        </p>
      ),
    },

    {
      title: 'Loại đầu tư',
      dataIndex: 'type',
      width: 100,
      render: (type) => type && INVESTMENT_TYPE?.[type],
    },
    {
      title: 'Giá bán',
      dataIndex: 'price',
      width: 150,
      render: (amount) => amount && formatCurrency(amount),
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'created_at',
      width: 100,
      render: (created_at) =>
        created_at && formatDate(created_at, DATE_TIME_FORMAT),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      width: 100,
      render: (status) => (
        <Tag color={TAB_ITEMS_STATUS_COLOR[status]}>
          {TAB_ITEMS_STATUS[status]}
        </Tag>
      ),
    },
    {
      width: 60,
      fixed: 'right',
      render: (record) => {
        const items = renderItemActions(record);
        return <ActionDropdown dropdownItems={items} trigger="click" />;
      },
    },
  ];

  return (
    <>
      <TableTera
        columns={columns}
        loading={props?.loading || loadingChangeStatus || loadingDelete}
        zebra={false}
        {...props}
      />
      {openForm.open && (
        <InvestmentTypeForm
          open={openForm.open}
          id={openForm.id}
          onClose={() => setOpenForm({ open: false, id: null })}
        />
      )}

      {openDetail.open && (
        <InvestmentTypeDetail
          open={openDetail.open}
          id={openDetail.id}
          onCancel={() => setOpenDetail({ open: false, id: null })}
        />
      )}
    </>
  );
}

export default InvestmentTypeTable;
