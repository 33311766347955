import Filter from '_common/component/Filter';
import { MAXIMUM_CURRENCY } from '_common/constants/common';
import RangeNumber from '_common/dof/Control/RangeNumber';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectCategory from '_common/dof/Select/SelectCategory';
import _ from 'lodash';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

interface ProductFilterProps {
  open: boolean;
  onClose: () => void;
  onFilter: (value) => void;
  initialValue: any;
}

function ProductFilter({
  open,
  onClose,
  onFilter,
  initialValue,
}: ProductFilterProps) {
  const form = useForm({ mode: 'onChange' });

  const handleSubmitForm = (values) => {
    const valuesFilter = {
      ...values,
    };

    onFilter(valuesFilter);
    onClose();
  };

  const handleReset = () => {
    const values = {
      warehouse_catalog_id: null,
      amount_from: null,
      amount_to: null,
      stock_from: null,
      stock_to: null,
    };
    onFilter(values);
    onClose();
  };

  useEffect(() => {
    const values = _.pick(initialValue, [
      'warehouse_catalog_id',
      'amount_from',
      'amount_to',
      'stock_from',
      'stock_to',
    ]);

    form.reset({
      ...values,
    });
  }, [initialValue]);

  return (
    <Filter
      open={open}
      onCancel={onClose}
      onClose={onClose}
      onFilter={() => form.handleSubmit(handleSubmitForm)()}
    >
      <FormTera form={form} onSubmit={form.handleSubmit(handleSubmitForm)}>
        <FormTeraItem label="Danh mục" name="warehouse_catalog_id">
          <SelectCategory
            paramsApi={{ include_id: form.watch('warehouse_catalog_id') }}
          />
        </FormTeraItem>
        <div>
          <p className="mb-2.5">Giá bán</p>

          <RangeNumber
            startFormName="amount_from"
            endFormName="amount_to"
            endInputProps={{
              min: 0,
              max: MAXIMUM_CURRENCY,
            }}
            startInputProps={{
              min: 0,
              max: MAXIMUM_CURRENCY,
            }}
          />
        </div>

        <div>
          <p className="mb-2.5">Giới hạn số lượng đặt</p>
          <RangeNumber
            startFormName="stock_from"
            endFormName="stock_to"
            endInputProps={{
              min: 0,
              max: MAXIMUM_CURRENCY,
            }}
            startInputProps={{
              min: 0,
              max: MAXIMUM_CURRENCY,
            }}
          />
        </div>
        <a
          className="text-red-500 text-sm font-normal cursor-pointer"
          onClick={() => handleReset()}
        >
          Hủy bộ lọc
        </a>
      </FormTera>
    </Filter>
  );
}

export default ProductFilter;
