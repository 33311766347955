import DefaultImage from '_common/component/DefaultImage';
import classNames from 'classnames';
import { ReactNode } from 'react';
import { Spin } from 'tera-dls';

interface CardProps {
  title: string;
  content: ReactNode;
  image: string;
  imageClassName?: string;
  loading?: boolean;
}

function Card({ title, content, image, imageClassName, loading }: CardProps) {
  return (
    <Spin spinning={loading}>
      <div className="p-2.5 rounded border flex gap-2.5 items-start">
        <div className="flex flex-col gap-[15px] justify-between w-full">
          <h4 className="text-[#6B7280] font-medium">{title}</h4>
          {content}
        </div>
        <DefaultImage
          src={image}
          alt={''}
          className={classNames(
            'w-[37px] h-[40px] object-contain',
            imageClassName,
          )}
        />
      </div>
    </Spin>
  );
}

export default Card;
