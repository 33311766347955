import { useQuery } from '@tanstack/react-query';
import HorizontalBarChart from '_common/component/Chart/BarChart/HorizontalBarChart';
import { DATE_BACKEND_FORMAT, DATE_FORMAT } from '_common/constants/common';
import moment from 'moment';
import { useContext, useEffect, useMemo, useState } from 'react';
import { ChartBarProps, formatDate, Spin } from 'tera-dls';
import DashboardApi from '../api';
import Header from '../Header';
import ModalChart from './ModalChart';
import SettingModal from './SettingModal';
import { StatisticsByIncurredExpensesParamContext } from '.';
import NoData from '_common/component/NoData';
export type OpportunityValueStatisticByStage = 'small' | 'large';

interface IProps {
  type?: OpportunityValueStatisticByStage;
  onClose?: () => void;
  chartOptions?: ChartBarProps['options'];
  chartClassName?: string;
  defaultLengthShowTick?: number;
  defaultLengthScroll?: number;
  labelMaxLength?: number;
}

const Content = (props: IProps) => {
  const { type = 'small', chartClassName = '', onClose } = props;
  const [openModal, setOpenModal] = useState<boolean>(false);
  const isSmallType = type === 'small';
  const [maxValue, setMaxValue] = useState(null);
  const [setting, setSetting] = useState({
    open: false,
    params: {
      date_from: moment().subtract(1, 'months').format('YYYY-MM-DD'),
      date_to: moment().format('YYYY-MM-DD'),
    },
  });

  const { contextParams } = useContext(
    StatisticsByIncurredExpensesParamContext,
  );

  const { data, isLoading, isFetching, refetch } = useQuery(
    ['get-incurred-cost-dashboard', setting.params],
    () => DashboardApi.getIncurredCost({ params: setting.params }),
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  const dataSource = useMemo(() => {
    const dataGroup = data?.group_by;
    const dataChart = dataGroup?.map((item) => Number(item?.total_price));
    const dataLabel = dataGroup?.map((item) =>
      formatDate(item?.date, DATE_FORMAT, DATE_BACKEND_FORMAT),
    );
    if (dataChart?.length > 0) {
      const max = Math.max(...dataChart);
      setMaxValue(Math.round(max * 1.05));
    }
    return {
      labels: dataLabel,
      datasets: [
        {
          data: dataChart,
          backgroundColor: ['#F98080'],
          borderColor: ['#F98080'],
          barThickness: 50,
        },
      ],
    };
  }, [data]);

  useEffect(() => {
    contextParams && setSetting((prev) => ({ ...prev, params: contextParams }));
  }, [contextParams]);

  return (
    <Spin spinning={isLoading || isFetching}>
      <Header
        isSmallType={isSmallType}
        title="Thống kê theo chi phí phát sinh"
        onClickSetting={() => setSetting((prev) => ({ ...prev, open: true }))}
        onZoomIn={onClose}
        onZoomOut={() => setOpenModal(true)}
        onClickRefresh={refetch}
      />

      <span className="text-[#6B7280] text-[10px]">{`${formatDate(
        setting?.params?.date_from,
      )} - ${formatDate(setting?.params?.date_to)}`}</span>
      <div>
        {data?.group_by?.length > 0 ? (
          <HorizontalBarChart
            defaultLabelMaxLength={30}
            type={type}
            data={dataSource}
            containerClassName="!h-auto"
            chartClassName={chartClassName}
            maxY={maxValue}
            defaultShowTickLength={15}
            plugins={{
              legend: {
                display: false,
              },
            }}
          />
        ) : (
          <NoData />
        )}
      </div>

      {setting.open && (
        <SettingModal
          open={setting.open}
          defaultValues={setting.params}
          onSubmit={(values) => setSetting({ open: false, params: values })}
          onClose={() => setSetting((prev) => ({ ...prev, open: false }))}
        />
      )}

      {openModal && (
        <ModalChart
          open={openModal}
          onClose={() => setOpenModal(false)}
          type="large"
        />
      )}
    </Spin>
  );
};

export default Content;
