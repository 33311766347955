import CardFormV2 from '_common/component/CardForm/CardFormV2';
import DefaultImage from '_common/component/DefaultImage';
import HoverQuickView from '_common/component/HoverQuickView';
import {
  DATE_TIME_FORMAT,
  SUMI_SHADOW,
  TAB_ITEMS_STATUS,
  TAB_ITEMS_STATUS_COLOR,
} from '_common/constants/common';
import Row from '_common/dof/TableTera/TeraNormalTable/Row';
import classNames from 'classnames';
import { formatCurrency, formatDate, formatNumber, Tag } from 'tera-dls';

function Information({ dataDetails }) {
  const detail = [
    {
      label: 'Ảnh đại diện',
      value: (
        <DefaultImage
          zoom
          src={dataDetails?.file_upload}
          alt={dataDetails?.file_upload}
          className="w-[100px] h-[100px] rounded-[10px] overflow-hidden"
          imageClassName="object-cover"
        />
      ),
      className: 'col-span-3',
    },
    {
      label: 'Tên nhà đầu tư',
      value: dataDetails?.investor_name,
    },
    {
      label: 'SL đã đầu tư',
      value: dataDetails?.vehicle_count,
    },
    {
      label: 'Người tạo',
      value: (
        <HoverQuickView
          avatarUrl={dataDetails?.created_by?.avatar}
          name={dataDetails?.created_by?.full_name}
          code={dataDetails?.created_by?.code}
          email={dataDetails?.created_by?.email}
          phone={dataDetails?.created_by?.phone}
        >
          {dataDetails?.created_by?.full_name}
        </HoverQuickView>
      ),
    },
    {
      label: 'Số điện thoại',
      value: dataDetails?.phone,
    },
    {
      label: 'Tổng SL đơn bán hàng',
      value: formatNumber(dataDetails?.count_sell_order?.count_total),
    },
    {
      label: 'Ngày tạo',
      value:
        dataDetails?.created_at &&
        formatDate(dataDetails?.created_at, DATE_TIME_FORMAT),
    },
    {
      label: 'Ngày sinh',
      value: dataDetails?.birthday && formatDate(dataDetails?.birthday),
    },
    {
      label: 'Tổng số lượng đơn đặt hàng',
      value: formatNumber(dataDetails?.count_order?.count_total),
    },
    {
      label: 'Người cập nhật',
      value: (
        <HoverQuickView
          avatarUrl={dataDetails?.updated_by?.avatar}
          name={dataDetails?.updated_by?.full_name}
          code={dataDetails?.updated_by?.code}
          email={dataDetails?.updated_by?.email}
          phone={dataDetails?.updated_by?.phone}
        >
          {dataDetails?.updated_by?.full_name}
        </HoverQuickView>
      ),
    },
    {
      label: 'Địa chỉ',
      value: dataDetails?.address,
    },
    {
      label: 'Tổng doanh thu',
      value: formatCurrency(dataDetails?.total_revenue?.total_price),
    },
    {
      label: 'Ngày cập nhật',
      value:
        dataDetails?.updated_by &&
        dataDetails?.updated_at &&
        formatDate(dataDetails?.updated_at, DATE_TIME_FORMAT),
    },
    {
      label: 'Email',
      value: dataDetails?.email,
    },
    {
      label: 'Trạng thái',
      value: (
        <Tag color={TAB_ITEMS_STATUS_COLOR[dataDetails?.status]}>
          {TAB_ITEMS_STATUS[dataDetails?.status]}
        </Tag>
      ),
    },
  ];

  return (
    <div
      className={classNames(
        'px-2.5 py-5 rounded-md border border-blue-400',
        SUMI_SHADOW,
      )}
    >
      <CardFormV2 title="Thông tin chung">
        <Row className="grid grid-cols-3 gap-2.5">
          {detail.map((item, index) => (
            <div
              className={classNames('flex items-start', item.className)}
              key={index}
            >
              <div className="detail-key">{item.label}</div>
              <div className="detail-value">{item.value}</div>
            </div>
          ))}
        </Row>
      </CardFormV2>
    </div>
  );
}

export default Information;
