import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const OrderEndpoint = `${endpoint}/sumi/sell/order`;

const OrderApi = {
  getList: async ({ params }) =>
    await api
      .get(`${OrderEndpoint}/list`, params)
      .then((res) => res.data?.data),
  getDetail: async ({ id }) =>
    await api
      .get(`${OrderEndpoint}/detail/${id}`)
      .then((res) => res.data?.data),
  getSummary: async ({ params }) =>
    await api
      .get(`${OrderEndpoint}/summary-list`, params)
      .then((res) => res.data?.data),
  create: async ({ params }) =>
    await api.post(`${OrderEndpoint}/create`, params).then((res) => res.data),
  update: async ({ id, params }) =>
    await api
      .put(`${OrderEndpoint}/update/${id}`, params)
      .then((res) => res.data),
  approval: async ({ id, params }) =>
    await api
      .post(`${OrderEndpoint}/approval/${id}`, params)
      .then((res) => res.data),
  changeStatus: async ({ params }) =>
    await api.post(`${OrderEndpoint}/active`, params).then((res) => res.data),
  delete: async ({ id }) =>
    await api.delete(`${OrderEndpoint}/delete/${id}`).then((res) => res.data),
};

export default OrderApi;
