export const PORTFOLIO = {
  location: {
    name: 'Địa điểm',
    color: '#F17EB8',
  },
  vehicle: {
    name: 'Xe',
    color: '#F8961E',
  },
  kiot: {
    name: 'KIOT',
    color: '#31C48D',
  },
  bakery: {
    name: 'Bakery',
    color: '#6875F5',
  },
};

export const ORDER_TYPE = {
  order: 'Đơn đặt hàng',
  sell: 'Đơn bán hàng',
};
