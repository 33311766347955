import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import CardFormV2 from '_common/component/CardForm/CardFormV2';
import NoData from '_common/component/NoData';
import TabForm from '_common/component/TabForm';
import { IconButton } from '_common/component/TableColumnCustom';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import {
  DATE_TIME_BACKEND_FORMAT,
  DATE_TIME_FORMAT,
  MAXIMUM_CURRENCY,
  MAXIMUM_QUANTITY,
} from '_common/constants/common';
import {
  messageValidate,
  messageValidateLength,
  messageWarning,
} from '_common/constants/message';
import DatePicker from '_common/dof/Control/DatePicker';
import Image from '_common/dof/Control/Image';
import Input from '_common/dof/Control/Input';
import InputNumber from '_common/dof/Control/InputNumber';
import TextArea from '_common/dof/Control/TextArea';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectInvestmentCategory from '_common/dof/Select/SelectInvestmentCategory';
import SelectTicketType from '_common/dof/Select/SelectTicketType';
import moment from 'moment';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ArrowSmallLeftOutlined,
  BookmarkOutlined,
  Breadcrumb,
  Button,
  CubeOutlined,
  formatDate,
  InformationCircleOutlined,
  ItemType,
  notification,
  PhotoOutlined,
  Row,
  Spin,
} from 'tera-dls';
import OrderApi from '../../api';
import { ORDER_URL } from '../../url';
import { usePrompt } from '_common/hooks/usePrompt';

const TABS_FORM = {
  information: {
    title: 'Thông tin chung',
    icon: <InformationCircleOutlined className="w-5 h-5" />,
  },
  information_product: {
    title: 'Thông tin sản phẩm',
    icon: <CubeOutlined className="w-5 h-5" />,
  },
  images: {
    title: 'Hình ảnh',
    icon: <PhotoOutlined className="w-5 h-5" />,
  },
};

interface OrderRequestFormProps {
  isApprove?: boolean;
}

function OrderRequestForm({ isApprove }: OrderRequestFormProps) {
  const form = useForm<any>({
    mode: 'onChange',
    defaultValues: { ticket_type: 'order' },
  });
  const navigate = useNavigate();
  const { id } = useParams();
  const isDirty = form.formState.isDirty;

  const queryClient = useQueryClient();

  const {
    data: dataDetails,
    isLoading,
    isError,
    refetch,
  } = useQuery(['get-order-detail', id], () => OrderApi.getDetail({ id }), {
    enabled: !!id,
    staleTime: 300000,
    cacheTime: 300000,
  });

  const { mutate: mutateOrder, isLoading: isLoadingMutate } = useMutation(
    (params: any) => {
      if (isApprove) return OrderApi.approval({ id, params });
      if (id) return OrderApi.update({ id, params });
      return OrderApi.create({ params });
    },
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries(['get-order-list']);
          queryClient.invalidateQueries(['get-summary-order-list']);
          form.reset();
          setTimeout(() => navigate(-1));
        }
      },
      onError: (error: any) => {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const BreadcrumbItem: ItemType[] = [
    {
      title: (
        <a
          onClick={() => navigate(ORDER_URL.list.path)}
          className="!text-blue-400 hover:!text-blue-600"
        >
          Danh sách đơn đặt hàng
        </a>
      ),
    },
    {
      title: isApprove
        ? 'Duyệt đơn đặt hàng'
        : !id
        ? 'Thêm đơn đặt hàng'
        : 'Sửa đơn đặt hàng',
    },
  ];

  const handleSubmitForm = (values) => {
    if (isLoadingMutate) return;

    const data = {
      type_status: 'sumi',
      invest_id: values?.invest_id,
      ticket_type: values?.ticket_type,
      note: values?.note,
      customer_note: values?.customer_note,
      receipt_date:
        values?.receipt_date &&
        formatDate(values?.receipt_date, DATE_TIME_BACKEND_FORMAT),
      products: [
        {
          amount: values?.amount,
          quantity: values?.quantity,
          product_name: values?.product_name,
          product_image_request: values?.product_image_request,
        },
      ],
    };
    mutateOrder(data);
  };

  useEffect(() => {
    if (dataDetails) {
      const initData = {
        invest_id: dataDetails?.invest_id,
        invest_name: dataDetails?.invest_name,
        note: dataDetails?.note,
        type: dataDetails?.type,
        investor: dataDetails?.investor_name || 'Hệ thống',
        device: dataDetails?.device_name,
        ticket_type: dataDetails?.ticket_type,
        product_name: dataDetails?.order_detail?.[0]?.product_name,
        quantity: dataDetails?.order_detail?.[0]?.quantity_request,
        amount: dataDetails?.order_detail?.[0]?.amount,
        receipt_date:
          dataDetails?.receipt_date && moment(dataDetails?.receipt_date),
        customer_note: dataDetails?.customer_note,
        product_image_request:
          dataDetails?.order_detail?.[0]?.product_image_request,
      };

      form.reset(initData);
    }
  }, [dataDetails]);

  useEffect(() => {
    if (id) refetch();
  }, [id]);

  const disabledDate = (current) => {
    return current && current < moment().startOf('day');
  };

  usePrompt(
    <>
      <p>{messageWarning.WARNING_EXIT_1}</p>
      <p>{messageWarning.WARNING_EXIT_2}</p>
    </>,
    isDirty,
  );

  return (
    <div className="tera-page-form">
      <div className="page-header-sticky">
        <div className="page-header-v2">
          <div className="page-header-v2__breadcrumb">
            <IconButton
              icon={<ArrowSmallLeftOutlined />}
              callBack={() => navigate(-1)}
            />
            <Breadcrumb separator="/" items={BreadcrumbItem} />
          </div>
          <div className="page-header-v2__function">
            <Button
              className="page-header-v2-btn"
              type="success"
              prefix={<BookmarkOutlined />}
              onClick={form.handleSubmit(handleSubmitForm)}
              disabled={isError || (isLoading && !!id) || isLoadingMutate}
            >
              {isApprove ? 'Duyệt' : 'Lưu'}
            </Button>
          </div>
        </div>
      </div>
      <Spin spinning={(isLoading && !!id) || isLoadingMutate}>
        <div className="page-content-v2">
          {isError && !!id ? (
            <NoData />
          ) : (
            <TabForm menu={TABS_FORM}>
              <FormTera form={form}>
                <CardFormV2
                  title="Thông tin chung"
                  id="information"
                  className="form-section"
                >
                  <Row className="grid grid-cols-2 xl:grid-cols-3 gap-x-2.5 gap-y-0">
                    <FormTeraItem
                      label="Đầu tư"
                      name="invest_name"
                      rules={[
                        {
                          required: messageValidate.emptySelect,
                        },
                      ]}
                    >
                      <Input disabled />
                    </FormTeraItem>

                    <FormTeraItem label="Danh mục đầu tư" name="type">
                      <SelectInvestmentCategory disabled />
                    </FormTeraItem>

                    <FormTeraItem label="Nhà đầu tư" name="investor">
                      <Input disabled />
                    </FormTeraItem>
                    <FormTeraItem label="Thiết bị" name="device">
                      <Input disabled />
                    </FormTeraItem>
                    <FormTeraItem label="Loại phiếu" name="ticket_type">
                      <SelectTicketType disabled />
                    </FormTeraItem>
                  </Row>
                </CardFormV2>
                <CardFormV2
                  title="Thông tin sản phẩm"
                  id="information_product"
                  className="form-section"
                >
                  <Row className="grid grid-cols-2 xl:grid-cols-3 gap-x-2.5 gap-y-0">
                    <FormTeraItem
                      label="Tên sản phẩm"
                      name="product_name"
                      rules={[
                        {
                          required: messageValidate.emptyText,
                          maxLength: {
                            value: 100,
                            message: messageValidateLength.text,
                          },
                        },
                      ]}
                    >
                      <Input />
                    </FormTeraItem>
                    <FormTeraItem
                      label="Số lượng"
                      name="quantity"
                      rules={[
                        {
                          required: messageValidate.emptyText,
                        },
                      ]}
                    >
                      <InputNumber min={0} max={MAXIMUM_QUANTITY} />
                    </FormTeraItem>
                    <FormTeraItem
                      label="Giá bán"
                      name="amount"
                      rules={[
                        {
                          required: messageValidate.emptyText,
                        },
                      ]}
                    >
                      <InputNumber min={0} max={MAXIMUM_CURRENCY} />
                    </FormTeraItem>
                    <FormTeraItem label="Thời gian" name="receipt_date">
                      <DatePicker
                        allowClear
                        showTime
                        format={DATE_TIME_FORMAT}
                        disabledDate={disabledDate}
                      />
                    </FormTeraItem>
                    <FormTeraItem
                      label="Ghi chú thông tin khách hàng"
                      name="customer_note"
                      className="col-span-3"
                      rules={[
                        {
                          maxLength: {
                            value: 150,
                            message: 'Không nhập quá 150 kí tự',
                          },
                        },
                      ]}
                    >
                      <TextArea rows={5} />
                    </FormTeraItem>
                    <FormTeraItem
                      label="Ghi chú mô tả"
                      name="note"
                      className="col-span-3"
                      rules={[
                        {
                          maxLength: {
                            value: 150,
                            message: 'Không nhập quá 150 kí tự',
                          },
                        },
                      ]}
                    >
                      <TextArea rows={5} />
                    </FormTeraItem>
                  </Row>
                </CardFormV2>
                <CardFormV2
                  title="Hình ảnh"
                  id="images"
                  className="form-section"
                >
                  <FormTeraItem
                    name="product_image_request"
                    className="col-span-3"
                    rules={[
                      {
                        maxLength: {
                          value: 15,
                          message: 'Không tải quá 15 hình ảnh',
                        },
                      },
                    ]}
                  >
                    <Image
                      multiple
                      object_key="product_image_request"
                      folder="product_image_request"
                      width={100}
                    />
                  </FormTeraItem>
                </CardFormV2>
              </FormTera>
            </TabForm>
          )}
        </div>
      </Spin>
    </div>
  );
}

export default OrderRequestForm;
