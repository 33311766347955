import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const TimeConfigEndpoint = `${endpoint}/portal/config`;

const TimeConfigApi = {
  getList: async () =>
    await api
      .get(`${TimeConfigEndpoint}/list-config`)
      .then((res) => res.data?.data),
  create: async ({ params }) =>
    await api
      .post(`${TimeConfigEndpoint}/create-config`, params)
      .then((res) => res.data),
};

export default TimeConfigApi;
