import { useQuery } from '@tanstack/react-query';
import CardFormV2 from '_common/component/CardForm/CardFormV2';
import { SUMI_SHADOW } from '_common/constants/common';
import classNames from 'classnames';
import SalesExpansesApi from 'pages/Invest/SalesExpenses/api';
import SalesExpensesFormTable from 'pages/Invest/SalesExpenses/containers/Form/SalesExpensesFormTable';
import React, { useEffect } from 'react';
import { Spin } from 'tera-dls';

function SalesExpenses({ id }) {
  const {
    data: dataDetails,
    refetch,
    isLoading,
  } = useQuery(
    ['get-sales-expenses-detail', id],
    () => SalesExpansesApi.getDetail({ id }),
    {
      enabled: !!id,
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    refetch();
  }, []);

  return (
    <Spin spinning={isLoading}>
      <div
        className={classNames(
          'px-2.5 py-5 rounded-md border border-blue-400',
          SUMI_SHADOW,
        )}
      >
        <CardFormV2 title="Danh sách chi phí">
          <SalesExpensesFormTable
            investId={id}
            mode="view"
            objectType="sales-expenses"
            summaryTotal={dataDetails?.sale_cost_items?.total_price}
          />
        </CardFormV2>
      </div>
    </Spin>
  );
}

export default SalesExpenses;
