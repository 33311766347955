import { Row } from 'tera-dls';
import GroupByType from './containers/GroupByType';
import StatisticCashExpenseByInvestor from './containers/StatisticCashExpenseByInvestor';

const Statistic = () => {
  return (
    <div className="p-2.5">
      <div className="font-semibold	text-base uppercase mb-2.5">
        Thống kê thu chi
      </div>
      <Row>
        <GroupByType />
        <StatisticCashExpenseByInvestor />
      </Row>
    </div>
  );
};

export default Statistic;
