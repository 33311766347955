import { Col, Row } from 'tera-dls';
import StatisticsByIncurredExpenses from './StatisticsByIncurredExpenses';
import StatisticsByInvestment from './StatisticsByInvestment';
import TopInvestment from './TopInvestment';
import TopInvestor from './TopInvestor';
import TopBestSeller from './TopBestSeller';

function Dashboard() {
  return (
    <div className="tera-page">
      <Row className="grid-cols-2">
        <Col className="col-span-2 bg-white p-4 rounded shadow">
          <StatisticsByInvestment />
        </Col>
        <Col className="col-span-2 bg-white p-4 rounded shadow">
          <StatisticsByIncurredExpenses />
        </Col>
        <Col className="bg-white p-4 rounded shadow">
          <TopInvestment wrapperClassName="h-[200px]" />
        </Col>
        <Col className="bg-white p-4 rounded shadow">
          <TopInvestor wrapperClassName="h-[200px]" />
        </Col>
        <Col className="bg-white p-4 rounded shadow">
          <TopBestSeller wrapperClassName="h-[200px]" />
        </Col>
      </Row>
    </div>
  );
}

export default Dashboard;
