import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import NoData from '_common/component/NoData';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { MAXIMUM_CURRENCY } from '_common/constants/common';
import {
  messageValidate,
  messageValidateLength,
  messageWarning,
} from '_common/constants/message';
import Input from '_common/dof/Control/Input';
import InputNumber from '_common/dof/Control/InputNumber';
import TextArea from '_common/dof/Control/TextArea';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectIncurredCostCategory from '_common/dof/Select/SelectIncurredCostCategory';
import SelectInvestment from '_common/dof/Select/SelectInvestment';
import SelectInvestmentCategory from '_common/dof/Select/SelectInvestmentCategory';
import UploadFiles from '_common/dof/UploadFiles';
import useConfirm from '_common/hooks/useConfirm';
import InvestmentApi from 'pages/Invest/Invesment/api';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Col, Modal, notification, Row, Spin } from 'tera-dls';
import IncurredCostApi from '../../api';

interface IncurredCostFormProps {
  id: string | number;
  open: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
}

function IncurredCostForm(props: IncurredCostFormProps) {
  const { id, open, onClose, onSuccess } = props;
  const form = useForm({ mode: 'onChange' });
  const confirm = useConfirm();
  const { isDirty } = form.formState;
  const queryClient = useQueryClient();
  const [files, setFiles] = useState([]);

  const invest_id = form.watch('invest_id');
  const incurred_type = form.watch('incurred_type');

  const {
    data: dataDetails,
    refetch,
    isLoading,
    isError,
  } = useQuery(
    ['get-incurred-cost-detail', id],
    () => IncurredCostApi.getDetail({ id }),
    {
      enabled: !!id,
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const { data: dataInvest } = useQuery(
    ['get-investment-detail', invest_id],
    () => InvestmentApi.getDetail({ id: invest_id }),
    {
      enabled: !!invest_id,
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const { mutate: mutateIncurredCost, isLoading: isLoadingMutate } =
    useMutation(
      (params: any) => {
        if (id) return IncurredCostApi.update({ id, params });
        return IncurredCostApi.create({ params });
      },
      {
        onSuccess: (res) => {
          if (res?.code === 200) {
            notification.success({
              message: res?.msg,
            });
            onClose();
            queryClient.invalidateQueries(['get-incurred-cost-list']);
            queryClient.invalidateQueries(['get-summary-incurred-cost-list']);
            onSuccess && onSuccess();
          }
        },
        onError: (error: any) => {
          ErrorToast({ errorProp: error?.data });
        },
      },
    );

  const handleCloseConfirm = () => {
    if (isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>{messageWarning.WARNING_EXIT_1}</p>
            <p>{messageWarning.WARNING_EXIT_2}</p>
          </>
        ),
        onOk: () => {
          onClose();
        },
      });
    } else onClose();
  };

  const handleReceiveFiles = (file) => {
    setFiles((prev) => [...prev, file]);
  };

  const handleRemoveFile = (fileRemove) => {
    const listFile = files.filter((file) => file?.id !== fileRemove?.id);
    setFiles(listFile);
  };

  const handleSubmitForm = (value) => {
    if ((isLoading && !!id) || isLoadingMutate) return;
    const data = {
      ...value,
      file_upload: files,
      type_status: 'sumi',
    };

    mutateIncurredCost(data);
  };

  useEffect(() => {
    if (dataDetails) {
      const initData = {
        type: dataDetails?.type,
        invest_id: dataDetails?.invest_id,
        amount: dataDetails?.amount,
        note: dataDetails?.note,
        incurred_cost_cate_id: dataDetails?.incurred_cost_cate_id,
      };
      setFiles(dataDetails?.file_upload);
      form.reset(initData);
    }
  }, [dataDetails]);

  useEffect(() => {
    if (dataInvest) {
      form.setValue('type', dataInvest?.type);
      form.setValue('device_name', dataInvest?.device?.full_name);
      form.setValue('device_id', dataInvest?.device_id);
      form.setValue('invest_name', dataInvest?.name);
      form.setValue(
        'investor_name',
        dataInvest?.investor?.investor_name || 'Hệ thống',
      );
      form.setValue('investor_id', dataInvest?.investor_id);
    }
  }, [dataInvest, id]);

  useEffect(() => {
    if (id) refetch();
  }, [id]);

  return (
    <Modal
      title={id ? 'Sửa chi phí phát sinh' : 'Thêm chi phí phát sinh'}
      destroyOnClose
      closeIcon={false}
      cancelText="Hủy"
      okText="Đồng ý"
      onOk={() => form.handleSubmit(handleSubmitForm)()}
      onCancel={handleCloseConfirm}
      open={open}
      centered={true}
      className="sm:w-[90%] xl:w-[1200px] 2xl:w-[1500px]"
      confirmLoading={(isLoading && !!id) || isLoadingMutate}
    >
      <Spin spinning={(isLoading && !!id) || isLoadingMutate}>
        {isError && !!id ? (
          <NoData />
        ) : (
          <FormTera form={form} className="grid grid-cols-3 gap-x-4">
            <Row className="col-span-2 grid-cols-2 gap-x-4 gap-y-0">
              <Col>
                <FormTeraItem
                  label="Đầu tư"
                  name="invest_id"
                  rules={[
                    {
                      required: messageValidate.emptySelect,
                    },
                  ]}
                >
                  <SelectInvestment
                    paramsApi={{ include_id: invest_id, isActive: true }}
                    allowClear={false}
                  />
                </FormTeraItem>
                <FormTeraItem label="Nhà đầu tư" name="investor_name">
                  <Input disabled />
                </FormTeraItem>
                <FormTeraItem label="Danh mục đầu tư" name="type">
                  <SelectInvestmentCategory disabled />
                </FormTeraItem>
                <FormTeraItem label="Tên thiết bị" name="device_name">
                  <Input disabled />
                </FormTeraItem>
              </Col>
              <Col>
                <FormTeraItem
                  label="Danh mục chi phí phát sinh"
                  name="incurred_cost_cate_id"
                  rules={[
                    {
                      required: messageValidate.emptySelect,
                    },
                  ]}
                >
                  <SelectIncurredCostCategory
                    paramsApi={{ include_id: incurred_type, status: 1 }}
                  />
                </FormTeraItem>
                <FormTeraItem
                  label="Chi phí phát sinh"
                  name="amount"
                  rules={[
                    {
                      required: messageValidate.emptySelect,
                    },
                  ]}
                >
                  <InputNumber min={0} max={MAXIMUM_CURRENCY} />
                </FormTeraItem>
              </Col>

              <FormTeraItem
                label="Lý do"
                name="note"
                className="col-span-2"
                rules={[
                  {
                    maxLength: {
                      value: 1000,
                      message: messageValidateLength.textArea,
                    },
                  },
                ]}
              >
                <TextArea rows={5} />
              </FormTeraItem>
            </Row>
            <Row>
              <UploadFiles
                isCount={false}
                folder="incurred-cost"
                object_key="incurred-cost"
                fileList={files}
                onReceiveFiles={handleReceiveFiles}
                onRemove={handleRemoveFile}
                max={15}
                maxSize={10}
                accept=".png, .jpeg, .gif, .jpg"
              />
            </Row>
          </FormTera>
        )}
      </Spin>
    </Modal>
  );
}

export default IncurredCostForm;
