import { useMutation, useQueryClient } from '@tanstack/react-query';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { DATE_TIME_FORMAT } from '_common/constants/common';
import { EXPENSE_VOUCHER_PERMISSION_KEY } from '_common/constants/permission';
import TableTera from '_common/dof/TableTera';
import { ITeraTableProps } from '_common/dof/TableTera/_interfaces';
import useConfirm from '_common/hooks/useConfirm';
import { usePermission } from '_common/hooks/usePermission';
import moment from 'moment';
import {
  EXPENSE_VOUCHER_TYPE,
  PAYMENT_METHOD_TYPE_TEXT,
} from 'pages/Finance/constants';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DropdownItem, formatCurrency, notification } from 'tera-dls';
import ExpenseVoucherApi from '../../api';
import { EXPENSE_VOUCHER_URL } from '../../url';
import ExpenseVoucherDetail from '../Detail';

const ExpenseVoucherTable = (props: ITeraTableProps) => {
  const { data = [], ...restProps } = props;

  const { hasPage } = usePermission();
  const navigate = useNavigate();
  const confirm = useConfirm();
  const queryClient = useQueryClient();

  const [detailModel, setDetailModel] = useState<any>({ open: false });

  const { mutate: mutateDelete } = useMutation(
    (variables: any) => ExpenseVoucherApi.delete(variables),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-expense-voucher-list']);
          queryClient.invalidateQueries(['get-expense-voucher-statistic']);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleDelete = (item: any) => {
    confirm.warning({
      title: 'Xóa phiếu chi',
      content: (
        <>
          <p>Bạn có chắc muốn xoá phiếu chi</p>
          <p>
            <span className="font-bold break-word">
              {`${item?.code ?? ''}`}{' '}
            </span>
            này không?
          </p>
        </>
      ),
      onOk: () => {
        mutateDelete({ id: item?.id });
      },
    });
  };

  const generateDropDownItems = (record: any): DropdownItem[] => {
    const dropdownItems: DropdownItem[] = [];

    hasPage(EXPENSE_VOUCHER_PERMISSION_KEY.VIEW_DETAIL) &&
      dropdownItems.push({
        key: 1,
        label: 'Xem',
        onClick: () => {
          setDetailModel({ open: true, value: record.id });
        },
      });

    hasPage(EXPENSE_VOUCHER_PERMISSION_KEY.UPDATE) &&
      dropdownItems.push({
        key: 2,
        label: 'Sửa',
        onClick: () => {
          navigate(`${EXPENSE_VOUCHER_URL.update.path}/${record?.id}`);
        },
      });

    hasPage(EXPENSE_VOUCHER_PERMISSION_KEY.DELETE) &&
      dropdownItems.push({
        key: 3,
        label: <a className="text-red-500">Xóa</a>,
        onClick: () => handleDelete(record),
      });

    return dropdownItems;
  };

  const columns: any = [
    {
      title: 'Mã phiếu chi',
      dataIndex: 'code',
      width: 100,
      render: (val) => (
        <span className="line-clamp-2 leading-[15px]">{val}</span>
      ),
    },
    {
      title: 'Người nộp',
      dataIndex: 'author',
      width: 150,
      render: (val) => (
        <span className="line-clamp-2 leading-[15px]">{val}</span>
      ),
    },
    {
      title: 'Phương thức thanh toán',
      dataIndex: 'methods',
      width: 150,
      render: (value) => (
        <span className="line-clamp-2">{PAYMENT_METHOD_TYPE_TEXT[value]}</span>
      ),
    },
    {
      title: 'Loại phiếu chi',
      dataIndex: 'transaction_type',
      width: 150,
      render: (val) => (
        <span className="line-clamp-2">{EXPENSE_VOUCHER_TYPE[val] ?? ''}</span>
      ),
    },
    {
      title: 'Ngày hạch toán',
      dataIndex: 'accounting_date',
      width: 150,
      render: (val) => (val ? moment(val).format(DATE_TIME_FORMAT) : null),
    },
    {
      title: 'Ngày phiếu chi',
      dataIndex: 'date',
      width: 150,
      render: (val) => (val ? moment(val).format(DATE_TIME_FORMAT) : null),
    },
    {
      title: 'Tổng giá trị',
      dataIndex: 'explain_total_amount',
      width: 150,
      render: (explain_total_amount) =>
        formatCurrency(explain_total_amount?.total_amount),
    },
    {
      title: '',
      dataIndex: 'operation',
      width: 46,
      fixed: 'right',
      render: (_, record) => (
        <ActionDropdown
          dropdownItems={generateDropDownItems(record)}
          trigger="click"
          placement="bottom-end"
        />
      ),
    },
  ];

  return (
    <>
      <TableTera
        rowKey={'id'}
        zebra={false}
        {...restProps}
        data={data}
        columns={columns}
      />
      {detailModel?.open && (
        <ExpenseVoucherDetail
          open={detailModel?.open}
          onClose={() => setDetailModel({ open: false })}
          value={detailModel?.value}
        />
      )}
    </>
  );
};

export default ExpenseVoucherTable;
