import Filter from '_common/component/Filter';
import { DATE_BACKEND_FORMAT, TIME_FORMAT } from '_common/constants/common';
import DatePicker from '_common/dof/Control/DatePicker';
import RangeTime from '_common/dof/Control/RangeTime';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import moment from 'moment';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormItem } from 'tera-dls';

interface TimekeepingFilterProps {
  open: boolean;
  onClose: () => void;
  onFilter: (value) => void;
  initialValue: any;
}

function TimekeepingFilter({
  open,
  onClose,
  onFilter,
  initialValue,
}: TimekeepingFilterProps) {
  const form = useForm({ mode: 'onChange' });

  const handleSubmitForm = (values) => {
    const valuesFilter = {
      date: values?.date && moment(values?.date).format(DATE_BACKEND_FORMAT),
      check_in_time:
        values?.check_in_time &&
        moment(values?.check_in_time).format(TIME_FORMAT),
      check_out_time:
        values?.check_out_time &&
        moment(values?.check_out_time).format(TIME_FORMAT),
    };

    onFilter(valuesFilter);
    onClose();
  };

  const handleReset = () => {
    const values = {
      date: null,
      check_in_time: null,
      check_out_time: null,
    };
    onFilter(values);
    onClose();
  };

  useEffect(() => {
    const data = {
      date:
        initialValue?.date && moment(initialValue?.date, DATE_BACKEND_FORMAT),
      check_in_time:
        initialValue?.check_in_time &&
        moment(initialValue?.check_in_time, TIME_FORMAT),
      check_out_time:
        initialValue?.check_out_time &&
        moment(initialValue?.check_out_time, TIME_FORMAT),
    };

    form.reset(data);
  }, [initialValue]);

  return (
    <Filter
      open={open}
      onCancel={onClose}
      onClose={onClose}
      onFilter={() => form.handleSubmit(handleSubmitForm)()}
    >
      <FormTera form={form} onSubmit={form.handleSubmit(handleSubmitForm)}>
        <FormTeraItem label="Ngày làm việc" name="date">
          <DatePicker />
        </FormTeraItem>

        <FormItem label="Thời gian chấm công" isRequired={false}>
          <RangeTime
            startFormName="check_in_time"
            endFormName="check_out_time"
          />
        </FormItem>

        <a
          className="text-red-500 text-sm font-normal cursor-pointer"
          onClick={() => handleReset()}
        >
          Hủy bộ lọc
        </a>
      </FormTera>
    </Filter>
  );
}

export default TimekeepingFilter;
