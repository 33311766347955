import { useQuery } from '@tanstack/react-query';
import { IPagination } from '_common/interface';
import TimekeepingApi from 'pages/Hr/Timekeeping/api';
import TimekeepingTable from 'pages/Hr/Timekeeping/containers/Table';
import { useState } from 'react';
import { PaginationProps } from 'tera-dls';

interface TimeKeepingProps {
  id: number;
}

function TimeKeeping({ id }: TimeKeepingProps) {
  const [pagination, setPagination] = useState<IPagination>({
    page: 1,
    limit: 10,
  });
  const { data: timekeepingList, isLoading } = useQuery(
    ['get-timekeeping-list', id],
    () => {
      const params = {
        invest_id: id,
        page: pagination?.page || 1,
        limit: pagination?.limit || 10,
      };
      return TimekeepingApi.getList({ params });
    },
    {
      enabled: !!id,
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    const isDiffPageSize = Number(pageSize) !== Number(pagination?.limit || 10);
    setPagination({ page: isDiffPageSize ? 1 : page, limit: pageSize });
  };

  return (
    <TimekeepingTable
      data={timekeepingList?.data || []}
      loading={isLoading}
      pagination={{
        onChange: handleChangePage,
        total: timekeepingList?.total || 0,
        current: timekeepingList?.current_page,
        pageSize: timekeepingList?.per_page || 10,
        to: timekeepingList?.to,
        from: timekeepingList?.from || 10,
      }}
    />
  );
}

export default TimeKeeping;
