import { PRODUCT_PERMISSION_KEY } from '_common/constants/permission';

export const PRODUCT_URL = {
  list: {
    key: PRODUCT_PERMISSION_KEY.VIEW_LIST,
    path: '/sale/product/list',
    shortenUrl: 'product/list',
  },
  detail: {
    key: PRODUCT_PERMISSION_KEY.VIEW_DETAIL,
    path: '/sale/product/detail',
    shortenUrl: 'product/detail/:id',
  },
};
