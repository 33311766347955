import Filter from '_common/component/Filter';
import { MAXIMUM_CURRENCY } from '_common/constants/common';
import RangeNumber from '_common/dof/Control/RangeNumber';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectInvestmentCategory from '_common/dof/Select/SelectInvestmentCategory';
import SelectInvestor from '_common/dof/Select/SelectInvestor';
import SelectTicketType from '_common/dof/Select/SelectTicketType';
import _ from 'lodash';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

interface OrderFilterProps {
  open: boolean;
  onClose: () => void;
  onFilter: (value) => void;
  initialValue: any;
}

function OrderFilter({
  open,
  onClose,
  onFilter,
  initialValue,
}: OrderFilterProps) {
  const form = useForm({ mode: 'onChange' });

  const handleSubmitForm = (values) => {
    const data = {
      ...values,
      investor: values?.investor === 'system' ? 0 : values?.investor,
    };
    onFilter(data);
    onClose();
  };

  const handleReset = () => {
    const values = {
      investor: null,
      invest_type: null,
      ticket_type: null,
      quantity_request_from: null,
      quantity_request_to: null,
      quantity_approved_from: null,
      quantity_approved_to: null,
      total_price_from: null,
      total_price_to: null,
    };
    onFilter(values);
    onClose();
  };

  useEffect(() => {
    const values = _.pick(initialValue, [
      'investor',
      'invest_type',
      'ticket_type',
      'quantity_request_from',
      'quantity_request_to',
      'quantity_approved_from',
      'quantity_approved_to',
      'total_price_from',
      'total_price_to',
    ]);
    const data = {
      ...values,
      investor: values?.investor == 0 ? 'system' : values?.investor,
    };

    form.reset(data);
  }, [initialValue]);

  return (
    <Filter
      open={open}
      onCancel={onClose}
      onClose={onClose}
      onFilter={() => form.handleSubmit(handleSubmitForm)()}
    >
      <FormTera form={form} onSubmit={form.handleSubmit(handleSubmitForm)}>
        <FormTeraItem label="Nhà đầu tư" name="investor">
          <SelectInvestor
            optionCustom={[{ value: 'system', label: 'Hệ thống' }]}
            paramsApi={{
              include_id: form.watch('investor'),
            }}
          />
        </FormTeraItem>
        <FormTeraItem label="Loại phiếu" name="ticket_type">
          <SelectTicketType />
        </FormTeraItem>
        <FormTeraItem label="Danh mục đầu tư" name="invest_type">
          <SelectInvestmentCategory allowClear />
        </FormTeraItem>

        <p className="mb-2.5">Số lượng đặt</p>
        <RangeNumber
          startFormName="quantity_request_from"
          endFormName="quantity_request_to"
          endInputProps={{
            min: 0,
          }}
          startInputProps={{
            min: 0,
          }}
        />
        <p className="mb-2.5">Số lượng duyệt</p>
        <RangeNumber
          startFormName="quantity_approved_from"
          endFormName="quantity_approved_to"
          endInputProps={{
            min: 0,
          }}
          startInputProps={{
            min: 0,
          }}
        />
        <p className="mb-2.5">Tổng thành tiền</p>
        <RangeNumber
          startFormName="total_price_from"
          endFormName="total_price_to"
          endInputProps={{
            min: 0,
            max: MAXIMUM_CURRENCY,
          }}
          startInputProps={{
            min: 0,
            max: MAXIMUM_CURRENCY,
          }}
        />

        <a
          className="text-red-500 text-sm font-normal cursor-pointer"
          onClick={() => handleReset()}
        >
          Hủy bộ lọc
        </a>
      </FormTera>
    </Filter>
  );
}

export default OrderFilter;
