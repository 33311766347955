import Filter from '_common/component/Filter';
import {
  DATE_BACKEND_FORMAT,
  MAXIMUM_CURRENCY,
} from '_common/constants/common';
import DatePicker from '_common/dof/Control/DatePicker';
import RangeNumber from '_common/dof/Control/RangeNumber';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import _ from 'lodash';
import moment from 'moment';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

interface InvestorFilterProps {
  open: boolean;
  onClose: () => void;
  onFilter: (value) => void;
  initialValue: any;
}

function InvestorFilter({
  open,
  onClose,
  onFilter,
  initialValue,
}: InvestorFilterProps) {
  const form = useForm({ mode: 'onChange' });

  const handleSubmitForm = (values) => {
    const valuesFilter = {
      ...values,
      date: values?.date
        ? moment(values?.date).format(DATE_BACKEND_FORMAT)
        : null,
    };

    onFilter(valuesFilter);
    onClose();
  };

  const handleReset = () => {
    const values = {
      date: null,
      price_from: null,
      price_to: null,
    };
    onFilter(values);
    onClose();
  };

  useEffect(() => {
    const values = _.pick(initialValue, ['date', 'price_from', 'price_to']);

    form.reset({
      ...values,
      date: values?.date ? moment(values?.date, DATE_BACKEND_FORMAT) : null,
    });
  }, [initialValue]);

  return (
    <Filter
      open={open}
      onCancel={onClose}
      onClose={onClose}
      onFilter={() => form.handleSubmit(handleSubmitForm)()}
    >
      <FormTera form={form} onSubmit={form.handleSubmit(handleSubmitForm)}>
        <FormTeraItem label="Ngày tạo" name="date">
          <DatePicker placeholder="Vui lòng chọn" format="DD/MM/YYYY" />
        </FormTeraItem>
        <p className="mb-2.5">Giá bán</p>
        <RangeNumber
          startFormName="price_from"
          endFormName="price_to"
          endInputProps={{
            min: 0,
            max: MAXIMUM_CURRENCY,
          }}
          startInputProps={{
            min: 0,
            max: MAXIMUM_CURRENCY,
          }}
        />

        <a
          className="text-red-500 text-sm font-normal cursor-pointer"
          onClick={() => handleReset()}
        >
          Hủy bộ lọc
        </a>
      </FormTera>
    </Filter>
  );
}

export default InvestorFilter;
