import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const TransactionHistoryEndpoint = `${endpoint}/portal/finance/history-transaction`;

const TransactionHistoryApi = {
  getList: async ({ params }) =>
    await api
      .get(`${TransactionHistoryEndpoint}/list`, params)
      .then((res) => res.data?.data),
  getDetail: async ({ id }) =>
    await api
      .get(`${TransactionHistoryEndpoint}/detail/${id}`)
      .then((res) => res.data?.data),
  getSummary: async ({ params }) =>
    await api
      .get(`${TransactionHistoryEndpoint}/summary-list`, params)
      .then((res) => res.data?.data),
  approval: async ({ id, params }) =>
    await api
      .post(`${TransactionHistoryEndpoint}/approval/${id}`, params)
      .then((res) => res.data),
};

export default TransactionHistoryApi;
