import { IRouteProps } from '_common/interface/router';
import TransactionHistory from '.';
import { TRANSACTION_HISTORY_URL } from './url';

export const TransactionHistoryRouter: IRouteProps[] = [
  {
    key: TRANSACTION_HISTORY_URL.list.key,
    path: TRANSACTION_HISTORY_URL.list.shortenUrl,
    component: <TransactionHistory />,
  },
];
