import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  PaginationProps,
  filterField,
  getQueryParams,
  updateQueryParams,
} from 'tera-dls';
import ExpenseVoucherFilter from './containers/Filter';
import Searching from './containers/Searching';
import ExpenseVoucherTable from './containers/Table';

import HeaderViewList from '_common/component/HeaderViewList';
import { ImportExportType } from '_common/component/ImportExport/constants';
import ModalImport from '_common/component/ModalImport';
import ExpenseVoucherApi from './api';
import Statistic from './containers/Statistic';
import { EXPENSE_VOUCHER_URL } from './url';
import { usePermission } from '_common/hooks/usePermission';
import { EXPENSE_VOUCHER_PERMISSION_KEY } from '_common/constants/permission';

const CashReceipt = () => {
  const navigate = useNavigate();
  const { hasPage } = usePermission();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;
  const [openFilterModal, setOpenFilterModal] = useState<boolean>(false);
  const [openImport, setOpenImport] = useState<boolean>(false);
  const [errorImport, setErrorImport] = useState<any | undefined>();
  // const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);

  const {
    data: response,
    isFetching,
    refetch,
  } = useQuery(
    ['get-expense-voucher-list', queryParams],
    () =>
      ExpenseVoucherApi.getList({
        params: filterField({
          ...queryParams,
          page: queryParams?.page ?? 1,
          limit: queryParams?.limit ?? 10,
        }),
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const list = response?.data;

  useEffect(() => {
    refetch();
  }, []);

  const handleUpdateQueryParams = (data) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(location.pathname + paramString);
  };

  const handleImport = () => {
    // mutateImport({
    //   params: {
    //     file: value?.file,
    //     item_code: 'expense_voucher_item',
    //     overwrite_all: Number(value?.overwrite),
    //   },
    // });
  };

  // const dropdownItems = [
  //   // {
  //   //   key: 1,
  //   //   label: <a>Cấu hình cột</a>,
  //   //   onClick: () => openModal(),
  //   // },
  //   // {
  //   //   key: 2,
  //   //   label: <a>Import file excel</a>,
  //   //   onClick: () => {
  //   //     setOpenImport(true);
  //   //   },
  //   // },
  //   {
  //     key: 3,
  //     label: <a>Export file excel</a>,
  //     onClick: () => console.log(),

  //     // mutateExport({
  //     //   params: {
  //     //     item_code: 'expense_voucher_item',
  //     //     selected_ids: selectedRowKeys ?? [],
  //     //   },
  //     // }),
  //   },
  // ];
  const handleSearch = ({ keyword }) => {
    handleUpdateQueryParams({ keyword, page: 1 });
  };

  const handleFilter = (values: any): void => {
    handleUpdateQueryParams({ ...values, page: 1 });
  };

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    handleUpdateQueryParams({ page: page, limit: pageSize });
  };

  return (
    <div className="p-2.5">
      <HeaderViewList
        title={'PHIẾU CHI TIỀN'}
        buttonFilterKey={EXPENSE_VOUCHER_PERMISSION_KEY.SEARCH}
        buttonCreatingKey={EXPENSE_VOUCHER_PERMISSION_KEY.CREATE}
        onClickFilter={() => setOpenFilterModal(true)}
        onClickButtonAdd={() => navigate(EXPENSE_VOUCHER_URL.create.path)}
        actionLeftRender={
          hasPage(EXPENSE_VOUCHER_PERMISSION_KEY.SEARCH) && (
            <Searching onSearch={handleSearch} />
          )
        }
        outsideContent={<Statistic />}
        filterCount={{
          filter: queryParams,
          params: [
            'transaction_type',
            'methods',
            'date',
            'accounting_date',
            'amount_from',
            'amount_to',
          ],
        }}
      >
        <ExpenseVoucherTable
          loading={isFetching}
          data={list ?? []}
          // rowSelection={{
          //   selectedRowKeys,
          //   onChange: setSelectedRowKeys,
          // }}
          pagination={{
            onChange: handleChangePage,
            pageSize: response?.per_page,
            current: response?.current_page,
            total: response?.total,
            from: response?.from,
            to: response?.to,
          }}
        />
      </HeaderViewList>
      {openFilterModal && (
        <ExpenseVoucherFilter
          open={openFilterModal}
          onClose={() => setOpenFilterModal(false)}
          onFilter={handleFilter}
          initialValue={queryParams}
        />
      )}
      {openImport && (
        <ModalImport
          open={openImport}
          onOk={handleImport}
          onCancel={() => {
            setOpenImport(false);
            setErrorImport(undefined);
          }}
          type={ImportExportType.expenseVoucherItem}
          errorValue={errorImport}
        />
      )}
    </div>
  );
};

export default CashReceipt;
