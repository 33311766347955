import { INVESTOR_PERMISSION_KEY } from '_common/constants/permission';

export const INVESTOR_URL = {
  list: {
    key: INVESTOR_PERMISSION_KEY.VIEW_LIST,
    path: '/investor/investor/list',
    shortenUrl: 'investor/list',
  },
  detail: {
    key: INVESTOR_PERMISSION_KEY.VIEW_DETAIL,
    path: '/investor/investor/detail',
    shortenUrl: 'investor/detail/:id',
  },
};
