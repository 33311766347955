import { useQuery } from '@tanstack/react-query';
import { IPagination } from '_common/interface';
import TransactionHistoryApi from 'pages/Finance/TransactionHistory/api';
import TransactionHistoryTable from 'pages/Finance/TransactionHistory/containers/Table';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { PaginationProps } from 'tera-dls';

function TransactionHistory() {
  const { id } = useParams();
  const [pagination, setPagination] = useState<IPagination>({
    page: 1,
    limit: 10,
  });

  const { data: transactionHistoryList, isLoading } = useQuery(
    ['get-transaction-history-list', id],
    () => {
      const params = {
        invoice_id: id,
        page: pagination?.page || 1,
        limit: pagination?.limit || 10,
      };
      return TransactionHistoryApi.getList({ params });
    },
    {
      enabled: !!id,
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    const isDiffPageSize = Number(pageSize) !== Number(pagination?.limit || 10);
    setPagination({ page: isDiffPageSize ? 1 : page, limit: pageSize });
  };

  return (
    <TransactionHistoryTable
      rowKey={'id'}
      data={transactionHistoryList?.data || []}
      loading={isLoading}
      pagination={{
        onChange: handleChangePage,
        total: transactionHistoryList?.total || 0,
        current: transactionHistoryList?.current_page,
        pageSize: transactionHistoryList?.per_page || 10,
        to: transactionHistoryList?.to,
        from: transactionHistoryList?.from || 10,
      }}
    />
  );
}

export default TransactionHistory;
