import { ORDER_PERMISSION_KEY } from '_common/constants/permission';

export const ORDER_URL = {
  list: {
    key: ORDER_PERMISSION_KEY.VIEW_LIST,
    path: '/sale/order/list',
    shortenUrl: 'order/list',
  },
  create: {
    key: ORDER_PERMISSION_KEY.CREATE,
    path: '/sale/order/create',
    shortenUrl: 'order/create',
  },
  update: {
    key: ORDER_PERMISSION_KEY.UPDATE,
    path: '/sale/order/update',
    shortenUrl: 'order/update/:id',
  },
  detail: {
    key: ORDER_PERMISSION_KEY.VIEW_DETAIL,
    path: '/sale/order/detail',
    shortenUrl: 'order/detail/:id',
  },
  approve: {
    key: ORDER_PERMISSION_KEY.CHANGE_STATUS,
    path: '/sale/order/approve',
    shortenUrl: 'order/approve/:id',
  },
  order_request_update: {
    key: ORDER_PERMISSION_KEY.UPDATE,
    path: '/sale/order/order-request-update',
    shortenUrl: 'order/order-request-update/:id',
  },
  order_request_detail: {
    key: ORDER_PERMISSION_KEY.VIEW_DETAIL,
    path: '/sale/order/order-request-detail',
    shortenUrl: 'order/order-request-detail/:id',
  },
};
