import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const CategoryEndpoint = `${endpoint}/sumi/warehouse/catalog`;

const CategoryApi = {
  getList: async ({ params }) =>
    await api
      .get(`${CategoryEndpoint}/list`, params)
      .then((res) => res?.data?.data),
  getDetail: async ({ id }) =>
    await api
      .get(`${CategoryEndpoint}/detail/${id}`)
      .then((res) => res?.data?.data),
  create: async ({ params }) =>
    await api
      .post(`${CategoryEndpoint}/create`, params)
      .then((res) => res?.data),
  update: async ({ id, params }) =>
    await api
      .put(`${CategoryEndpoint}/update/${id}`, params)
      .then((res) => res?.data),
  delete: async ({ id }) =>
    await api
      .delete(`${CategoryEndpoint}/delete/${id}`)
      .then((res) => res?.data),
};

export default CategoryApi;
