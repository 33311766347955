import { DATE_BACKEND_FORMAT } from '_common/constants/common';
import Row from '_common/dof/TableTera/TeraNormalTable/Row';
import moment from 'moment';
import {
  CASH_RECEIPT_TYPE,
  EXPENSE_VOUCHER_TYPE,
  PAYMENT_METHOD_TYPE,
} from 'pages/Finance/constants';
import React, { useRef, useState } from 'react';
import { ArrowPathOutlined, Cog6ToothOutlined, Col, Tooltip } from 'tera-dls';
import SettingModal from './Setting';
import StatisticCashExpense from './StatisticCashExpense';
import { StatisticCashExpenseRefs } from './StatisticCashExpense/containers/Content';
import StatisticCashExpenseByPaymentMethod from './StatisticCashExpenseByPaymentMethod';
import { StatisticCashExpenseByPaymentMethodRefs } from './StatisticCashExpenseByPaymentMethod/containers/Content';
import StatisticCashExpenseByTime from './StatisticCashExpenseByTime';
import { StatisticCashExpenseByTimeRefs } from './StatisticCashExpenseByTime/containers/Content';
export const StatisticCashExpenseContext = React.createContext(null);

function GroupByType() {
  const StatisticCashExpenseByTimeRefs =
    useRef<StatisticCashExpenseByTimeRefs>(null);
  const StatisticCashExpenseByPaymentMethodRefs =
    useRef<StatisticCashExpenseByPaymentMethodRefs>(null);
  const StatisticCashExpenseRefs = useRef<StatisticCashExpenseRefs>(null);
  const currentWeek = [
    moment().startOf('month').format(DATE_BACKEND_FORMAT),
    moment().format(DATE_BACKEND_FORMAT),
  ];
  const [open, setOpen] = useState(false);
  const [contextParams, setContextParams] = useState<any>({
    investor_id: null,
    payment_method: Object.keys(PAYMENT_METHOD_TYPE),
    receipt_transaction_type: Object.keys(CASH_RECEIPT_TYPE),
    payment_transaction_type: Object.keys(EXPENSE_VOUCHER_TYPE),
    date: currentWeek,
    show_by: 'day',
  });

  const handleRefetch = () => {
    StatisticCashExpenseByTimeRefs.current.refetchData();
    StatisticCashExpenseByPaymentMethodRefs.current.refetchData();
    StatisticCashExpenseRefs.current.refetchData();
  };

  return (
    <StatisticCashExpenseContext.Provider
      value={{ contextParams, setContextParams }}
    >
      <div className="bg-white py-4 px-2.5 rounded-md">
        <div className="flex gap-x-2.5 mb-2.5 justify-end">
          <Tooltip title="Làm mới">
            <span className="flex items-center">
              <ArrowPathOutlined
                className="cursor-pointer w-4 h-4"
                onClick={handleRefetch}
              />
            </span>
          </Tooltip>
          <Tooltip title="Cấu hình tham số">
            <span className="flex items-center">
              <Cog6ToothOutlined
                className="w-4 h-4 cursor-pointer"
                onClick={() => setOpen(true)}
              />
            </span>
          </Tooltip>
        </div>
        <Row className="grid grid-cols-2 gap-[16px]">
          <Col className="col-span-2">
            <StatisticCashExpenseByTime ref={StatisticCashExpenseByTimeRefs} />
          </Col>
          <Col className="col-span-1">
            <StatisticCashExpenseByPaymentMethod
              ref={StatisticCashExpenseByPaymentMethodRefs}
            />
          </Col>
          <Col className="col-span-1">
            <StatisticCashExpense ref={StatisticCashExpenseRefs} />
          </Col>
        </Row>
        {open && (
          <SettingModal
            open={open}
            onClose={() => setOpen(false)}
            defaultValues={contextParams}
            onSubmit={(value) => setContextParams(value)}
          />
        )}
      </div>
    </StatisticCashExpenseContext.Provider>
  );
}

export default GroupByType;
