import { useQuery } from '@tanstack/react-query';
import HeaderViewList from '_common/component/HeaderViewList';
import SumiTab from '_common/component/Sumi/Tab';
import {
  TAB_ITEMS_STATUS,
  TAB_ITEMS_STATUS_RENDER,
} from '_common/constants/common';
import { INVESTMENT_PERMISSION_KEY } from '_common/constants/permission';
import { usePermission } from '_common/hooks/usePermission';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Badge,
  getQueryParams,
  PaginationProps,
  TabItemType,
  updateQueryParams,
} from 'tera-dls';
import InvestmentApi from './api';
import InvestmentFilter from './containers/Filter';
import InvestmentForm from './containers/Form';
import InvestmentSearch from './containers/Search';
import InvestmentTable from './containers/Table';
import { INVESTMENT_URL } from './url';

function Investment() {
  const [openForm, setOpenForm] = useState({ open: false, id: null });
  const [isOpenFilter, setIsOpenFilter] = useState<boolean>(false);

  const { hasPage } = usePermission();
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;

  const handleUpdateQueryParams = (data) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(`${INVESTMENT_URL.list.path}${paramString}`);
  };

  const {
    data: investmentList,
    isLoading,
    refetch,
  } = useQuery(
    ['get-investment-list', queryParams],
    () => {
      const params = {
        ...queryParams,
        page: queryParams?.page || 1,
        limit: queryParams?.limit || 10,
        status: queryParams?.status === 'all' ? undefined : queryParams?.status,
      };
      return InvestmentApi.getList({ params });
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
      onSuccess: (data) => {
        if (Number(data?.last_page) < (Number(queryParams?.page) || 1)) {
          handleUpdateQueryParams({ page: data?.last_page });
        }
      },
    },
  );

  const { data: summaryList } = useQuery(
    ['get-summary-investment-list', queryParams],
    () => {
      const params = {
        ...queryParams,
        page: queryParams?.page || 1,
        limit: queryParams?.limit || 10,
        status: undefined,
      };
      return InvestmentApi.getSummary({ params });
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    const isDiffPageSize =
      Number(pageSize) !== Number(investmentList?.limit || 10);

    handleUpdateQueryParams({
      page: isDiffPageSize ? 1 : page,
      limit: pageSize,
    });
  };

  const handleFilter = (values) => {
    handleUpdateQueryParams({ ...values, page: 1 });
  };

  const handleChangeTab = (key) => {
    handleUpdateQueryParams({ status: key, page: 1 });
  };
  const handleSearch = (value) => {
    handleUpdateQueryParams({ keyword: value?.keyword, page: 1 });
  };

  const tabItems: TabItemType[] = useMemo(() => {
    const tabs = TAB_ITEMS_STATUS_RENDER.map((item) => {
      const count =
        summaryList?.find((i) => i?.status == item)?.total_count || 0;
      return {
        key: item,
        label: (
          <div className="flex items-center gap-x-2">
            {TAB_ITEMS_STATUS[item]} <Badge count={count} showZero />
          </div>
        ),
      };
    });
    return tabs;
  }, [summaryList]);

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="tera-page">
      <HeaderViewList
        title="Danh sách đầu tư"
        buttonFilterKey={INVESTMENT_PERMISSION_KEY.SEARCH}
        buttonCreatingKey={INVESTMENT_PERMISSION_KEY.CREATE}
        onClickFilter={() => setIsOpenFilter(true)}
        onClickButtonAdd={() => setOpenForm({ open: true, id: null })}
        middleContent={
          hasPage(INVESTMENT_PERMISSION_KEY.SEARCH) && (
            <SumiTab
              onChange={handleChangeTab}
              items={tabItems}
              activeKey={queryParams?.status || 'all'}
            />
          )
        }
        actionLeftRender={
          hasPage(INVESTMENT_PERMISSION_KEY.SEARCH) && (
            <InvestmentSearch onSearch={handleSearch} />
          )
        }
        filterCount={{
          filter: queryParams,
          params: ['type', 'investor_id', 'amount_from', 'amount_to'],
        }}
      >
        <InvestmentTable
          rowKey={'id'}
          data={investmentList?.data || []}
          loading={isLoading}
          pagination={{
            onChange: handleChangePage,
            total: investmentList?.total || 0,
            current: investmentList?.current_page,
            pageSize: investmentList?.per_page || 10,
            to: investmentList?.to,
            from: investmentList?.from || 10,
          }}
          hiddenColumns={[
            'sum_quantity_request',
            'sum_quantity_sold',
            'total_revenue',
            'invoice',
          ]}
        />
      </HeaderViewList>

      {isOpenFilter && (
        <InvestmentFilter
          open={isOpenFilter}
          onClose={() => setIsOpenFilter(false)}
          onFilter={handleFilter}
          initialValue={queryParams}
        />
      )}
      {openForm.open && (
        <InvestmentForm
          open={openForm.open}
          id={openForm.id}
          onClose={() => setOpenForm({ open: false, id: null })}
        />
      )}
    </div>
  );
}

export default Investment;
