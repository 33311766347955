import { useQuery } from '@tanstack/react-query';
import VerticalBarChart from '_common/component/Chart/BarChart/VerticalBarChart';
import NoData from '_common/component/NoData';
import { CHART_TYPE, CHART_TYPE_COLOR } from 'pages/Finance/constants';
import StatisticApi from 'pages/Finance/Statistic/api';
import {
  forwardRef,
  useContext,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import customTwMerge from 'tailwind-merge.config';
import { ChartBarProps, Spin } from 'tera-dls';
import { StatisticCashExpenseContext } from '../..';
import Header from '../../../Header';
import ModalChart from './Modal';

export type SaleOfCustomerTypeChartType = 'small' | 'large';
interface IProps {
  type?: SaleOfCustomerTypeChartType;
  onClose?: () => void;
  chartOptions?: ChartBarProps['options'];
  chartClassName?: string;
  defaultLengthShowTick?: number;
  labelMaxLength?: number;
  defaultLengthScroll?: number;
}

export interface StatisticCashExpenseRefs {
  refetchData?: () => void;
}

const Content = (props: IProps, ref) => {
  const { type = 'small', onClose, chartClassName = '' } = props;
  const [openModal, setOpenModal] = useState<boolean>();
  const { contextParams } = useContext(StatisticCashExpenseContext);

  const {
    data: response,
    refetch,
    isRefetching,
  } = useQuery(
    ['get-statistic-by-transaction-type', contextParams],
    () =>
      StatisticApi.getChart({
        params: {
          chart_type: 'byTransactionType',
          investor_id: contextParams?.investor_id,
          receipt_transaction_type:
            contextParams?.receipt_transaction_type?.join(','),
          payment_transaction_type:
            contextParams?.payment_transaction_type?.join(','),
          date_from: contextParams?.date[0],
          date_to: contextParams?.date[1],
          payment_method: contextParams?.payment_method?.join(','),
          show_by: contextParams?.show_by,
        },
      }),
    {
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const dataSource = useMemo(() => {
    const labels = [];
    const data = [];
    const backgroundColor = [];
    Object.entries(CHART_TYPE).forEach(([key, value]) => {
      labels.push(value.title);
      data.push(
        response?.group_by?.[value.parent]?.[value.key]?.total_amount ?? 0,
      );
      backgroundColor.push(CHART_TYPE_COLOR[key]);
    });

    return {
      labels,
      datasets: [
        {
          data,
          backgroundColor,
        },
      ],
    };
  }, [response]);

  const isSmallType = type === 'small';

  const checkData = (data) =>
    data?.datasets?.[0]?.data?.some((value) => value !== 0);

  const maxValue = useMemo(() => {
    const values = Object.values(CHART_TYPE).map(
      (value) =>
        Number(response?.group_by?.[value.parent]?.[value.key]?.total_amount) ||
        0,
    );
    const max = Math.round(Math.max(...values) * 1.05);
    return max;
  }, [response]);

  useImperativeHandle(
    ref,
    () => {
      return {
        refetchData() {
          refetch();
        },
      };
    },
    [],
  );

  return (
    <>
      <div className="rounded-[4px] border-[1px] bg-white flex flex-col h-full overflow-hidden">
        <Header
          title="Thống kê theo loại thu chi"
          onClickRefresh={() => refetch()}
          onZoomOut={() => setOpenModal(true)}
          onZoomIn={() => onClose()}
          isSmallType={isSmallType}
        />
        <Spin spinning={isRefetching}>
          <div className="p-[16px] pb-0 h-full">
            {checkData(dataSource) ? (
              <VerticalBarChart
                containerClassName={
                  (customTwMerge('h-auto'), !isSmallType && 'h-full')
                }
                showLegend={false}
                data={dataSource}
                type={'large'}
                chartClassName={customTwMerge('', chartClassName)}
                defaultLabelMaxLength={30}
                maxX={maxValue}
              />
            ) : (
              <NoData />
            )}
          </div>
        </Spin>
      </div>
      {openModal && (
        <ModalChart
          open={openModal}
          onClose={() => setOpenModal(false)}
          type="large"
        />
      )}
    </>
  );
};

export default forwardRef<StatisticCashExpenseRefs, IProps>(Content);
