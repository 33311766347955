import React from 'react';

function Transaction(props) {
  return (
    <svg
      viewBox="0 0 30 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M25.0781 2.42559C24.6375 1.98496 24.6375 1.27246 25.0781 0.836526C25.5187 0.400588 26.2313 0.395901 26.6672 0.836526L29.6672 3.83653C29.8781 4.04746 29.9953 4.3334 29.9953 4.6334C29.9953 4.9334 29.8781 5.21934 29.6672 5.43028L26.6672 8.43028C26.2266 8.8709 25.5141 8.8709 25.0781 8.43028C24.6422 7.98965 24.6375 7.27715 25.0781 6.84121L26.1562 5.76309L18 5.75371C17.3766 5.75371 16.875 5.25215 16.875 4.62871C16.875 4.00528 17.3766 3.50371 18 3.50371H26.1609L25.0781 2.42559ZM4.92188 18.1756L3.84375 19.2537H12C12.6234 19.2537 13.125 19.7553 13.125 20.3787C13.125 21.0021 12.6234 21.5037 12 21.5037H3.83906L4.91719 22.5818C5.35781 23.0225 5.35781 23.735 4.91719 24.1709C4.47656 24.6068 3.76406 24.6115 3.32812 24.1709L0.328125 21.1756C0.117188 20.9646 0 20.6787 0 20.3787C0 20.0787 0.117188 19.7928 0.328125 19.5818L3.32812 16.5818C3.76875 16.1412 4.48125 16.1412 4.91719 16.5818C5.35313 17.0225 5.35781 17.735 4.91719 18.1709L4.92188 18.1756ZM4.5 3.50371H15.8391C15.6656 3.84121 15.5625 4.2209 15.5625 4.62871C15.5625 5.97403 16.6547 7.06621 18 7.06621H23.5031C23.3156 7.86309 23.5312 8.73028 24.15 9.35371C25.1016 10.3053 26.6438 10.3053 27.5953 9.35371L28.5 8.44903V18.5037C28.5 20.1584 27.1547 21.5037 25.5 21.5037H14.1609C14.3344 21.1662 14.4375 20.7865 14.4375 20.3787C14.4375 19.0334 13.3453 17.9412 12 17.9412H6.49688C6.68438 17.1443 6.46875 16.2772 5.85 15.6537C4.89844 14.7022 3.35625 14.7022 2.40469 15.6537L1.5 16.5584V6.50371C1.5 4.84903 2.84531 3.50371 4.5 3.50371ZM7.5 6.50371H4.5V9.50371C6.15469 9.50371 7.5 8.1584 7.5 6.50371ZM25.5 15.5037C23.8453 15.5037 22.5 16.849 22.5 18.5037H25.5V15.5037ZM15 17.0037C16.1935 17.0037 17.3381 16.5296 18.182 15.6857C19.0259 14.8418 19.5 13.6972 19.5 12.5037C19.5 11.3102 19.0259 10.1656 18.182 9.32173C17.3381 8.47782 16.1935 8.00371 15 8.00371C13.8065 8.00371 12.6619 8.47782 11.818 9.32173C10.9741 10.1656 10.5 11.3102 10.5 12.5037C10.5 13.6972 10.9741 14.8418 11.818 15.6857C12.6619 16.5296 13.8065 17.0037 15 17.0037Z"
        fill={props?.fill || '#E05252'}
      />
    </svg>
  );
}

export default Transaction;
