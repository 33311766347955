import ModalConfirm from '_common/component/ModalConfirm';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { getQueryParams } from 'tera-dls';
import MenuComponent from '../Menu';
import InlineMenu from '../Menu/InlineMenu';

const closedKeys = [
  'detail',
  'update',
  'create',
  'dashboard',
  'approve',
  'check',
];
function BasicLayout() {
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;
  const location = useLocation();
  const [isExpand, setIsExpand] = useState<boolean>(true);

  useEffect(() => {
    if (queryParams?.mode) {
      queryParams?.mode === 'kanban' ? setIsExpand(false) : setIsExpand(true);
      return;
    }
    const isClose = closedKeys.some((key) => location.pathname.includes(key));

    isClose ? setIsExpand(false) : setIsExpand(true);
  }, [location.pathname, JSON.stringify(queryParams?.mode)]);

  return (
    <>
      <div className="w-full">
        <InlineMenu isExpand={isExpand} onChangeSize={setIsExpand} />
        <div
          className={`flex flex-col flex-1 w-full h-full transition-all min-h-screen ${
            isExpand ? 'md:pl-[225px]' : 'md:pl-[50px]'
          }`}
        >
          <MenuComponent isExpand={isExpand} />

          <div className={`pt-[45px] bg-[#F3F3F9] h-full flex-1`}>
            <Outlet />
          </div>
        </div>
      </div>

      <ModalConfirm />
    </>
  );
}

export default observer(BasicLayout);
