import { IRouteProps } from '_common/interface/router';
import { INCURRED_COST_CATEGORY_URL } from './url';
import IncurredCostCategory from '.';

export const IncurredCostCategoryRouter: IRouteProps[] = [
  {
    key: INCURRED_COST_CATEGORY_URL.list.key,
    path: INCURRED_COST_CATEGORY_URL.list.shortenUrl,
    component: <IncurredCostCategory />,
  },
];
