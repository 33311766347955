import { IRouteProps } from '_common/interface/router';
import { PERMISSION_URL } from './url';
import Permission from '.';
import PermissionForm from './containers/Form';
import PermissionDetail from './containers/Detail';

export const PermissionRouter: IRouteProps[] = [
  {
    key: PERMISSION_URL.list.key,
    path: PERMISSION_URL.list.shortenUrl,
    component: <Permission />,
  },
  {
    key: PERMISSION_URL.create.key,
    path: PERMISSION_URL.create.shortenUrl,
    component: <PermissionForm />,
  },
  {
    key: PERMISSION_URL.update.key,
    path: PERMISSION_URL.update.shortenUrl,
    component: <PermissionForm />,
  },
  {
    key: PERMISSION_URL.detail.key,
    path: PERMISSION_URL.detail.shortenUrl,
    component: <PermissionDetail />,
  },
];
