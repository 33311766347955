import { IRouteProps } from '_common/interface/router';
import { INVESTMENT_URL } from './url';
import Investment from '.';
import InvestmentDetail from './containers/Detail';
import InvestmentUpdate from './containers/Form/InvestmentUpdate';

export const InvestmentRouter: IRouteProps[] = [
  {
    key: INVESTMENT_URL.list.key,
    path: INVESTMENT_URL.list.shortenUrl,
    component: <Investment />,
  },
  {
    key: INVESTMENT_URL.update.key,
    path: INVESTMENT_URL.update.shortenUrl,
    component: <InvestmentUpdate />,
  },
  {
    key: INVESTMENT_URL.detail.key,
    path: INVESTMENT_URL.detail.shortenUrl,
    component: <InvestmentDetail />,
  },
];
