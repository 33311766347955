import { IRouteProps } from '_common/interface/router';
import { ORDER_URL } from './url';
import Order from '.';
import OrderDetail from './containers/Detail';
import OrderForm from './containers/Form';
import OrderRequestDetail from './containers/Detail/OrderRequestDetail';
import OrderRequestForm from './containers/Form/OrderRequestForm';

export const OrderRouter: IRouteProps[] = [
  {
    key: ORDER_URL.list.key,
    path: ORDER_URL.list.shortenUrl,
    component: <Order />,
  },
  {
    key: ORDER_URL.create.key,
    path: ORDER_URL.create.shortenUrl,
    component: <OrderForm />,
  },
  {
    key: ORDER_URL.update.key,
    path: ORDER_URL.update.shortenUrl,
    component: <OrderForm />,
  },
  {
    key: ORDER_URL.detail.key,
    path: ORDER_URL.detail.shortenUrl,
    component: <OrderDetail />,
  },
  {
    key: ORDER_URL.approve.key,
    path: ORDER_URL.approve.shortenUrl,
    component: <OrderForm isApprove />,
  },
  {
    key: ORDER_URL.order_request_detail.key,
    path: ORDER_URL.order_request_detail.shortenUrl,
    component: <OrderRequestDetail />,
  },
  {
    key: ORDER_URL.order_request_update.key,
    path: ORDER_URL.order_request_update.shortenUrl,
    component: <OrderRequestForm />,
  },
];
