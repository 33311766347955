import Search from '_common/dof/Control/Search';
import FormTera, { FormTeraItem, useFormTera } from '_common/dof/FormTera';
import { useEffect } from 'react';
import { MagnifyingGlassOutlined } from 'tera-dls';

interface FeedbackSearchProps {
  search: string;
  onSearch: (value) => void;
}

function FeedbackSearch({ search, onSearch }: FeedbackSearchProps) {
  const [formRef] = useFormTera();

  const handleSearch = (value) => {
    if (formRef?.current?.isDirty) {
      onSearch(value);
      formRef?.current?.reset({ ...value }, { keepValues: true });
    }
  };

  useEffect(() => {
    if (search) {
      formRef?.current?.reset({
        keyword: search,
      });
    }
  }, [search]);

  return (
    <FormTera ref={formRef} onSubmit={handleSearch} isLoading={false}>
      <FormTeraItem className="!mb-0" name="keyword">
        <Search
          placeholder="Tìm kiếm theo tên nhà đầu tư, tên đầu tư, tên thiết bị"
          icon={<MagnifyingGlassOutlined />}
          className="!py-[6px]"
        />
      </FormTeraItem>
    </FormTera>
  );
}

export default FeedbackSearch;
