import { IRouteProps } from '_common/interface/router';
import { EMPLOYEE_URL } from './url';
import Employee from '.';

export const EmployeeRouter: IRouteProps[] = [
  {
    key: EMPLOYEE_URL.list.key,
    path: EMPLOYEE_URL.list.shortenUrl,
    component: <Employee />,
  },
];
