import Nodata from 'styles/images/Icons/no-data.png';
import DefaultImage from '../DefaultImage';

interface NoDataProps {
  text?: string;
}

function NoData({ text = 'Không có dữ liệu' }: NoDataProps) {
  return (
    <div className="h-full w-full flex">
      <div className="m-auto flex flex-col gap-y-2 items-center">
        <DefaultImage src={Nodata} alt="no-data" className="w-50" />
        <p>{text}</p>
      </div>
    </div>
  );
}

export default NoData;
