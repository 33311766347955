import { useRef, useState } from 'react';

import moment from 'moment';
import { Row } from 'tera-dls';
import Header from '../Header';
import Investment, { InvestmentRefs } from './Investment';
import Location, { LocationRefs } from './Location';
import NumberOfOrdersByInvestmentPortfolio from './NumberOfOrdersByInvestmentPortfolio';
import { InvestmentOrderRefs } from './NumberOfOrdersByInvestmentPortfolio/Content';
import RevenueByInvestmentPortfolio from './RevenueByInvestmentPortfolio';
import SettingModal from './SettingModal';
import { InvestmentRevenueRefs } from './RevenueByInvestmentPortfolio/Content';

function Order() {
  const locationRef = useRef<LocationRefs>(null);
  const investmentRef = useRef<InvestmentRefs>(null);
  const InvestmentOrderRef = useRef<InvestmentOrderRefs>(null);
  const InvestmentRevenueRef = useRef<InvestmentRevenueRefs>(null);

  const [setting, setSetting] = useState({
    open: false,
    params: {
      date_from: moment().startOf('month').format('YYYY-MM-DD'),
      date_to: moment().endOf('month').format('YYYY-MM-DD'),
    },
  });

  const handleRefetch = () => {
    locationRef.current.refetchData();
    investmentRef.current.refetchData();
    InvestmentOrderRef.current.refetchData();
    InvestmentRevenueRef.current.refetchData();
  };

  return (
    <div className="flex flex-col gap-y-4">
      <Header
        isZoom={false}
        onClickSetting={() => setSetting((prev) => ({ ...prev, open: true }))}
        onClickRefresh={handleRefetch}
      />
      <Location params={setting.params} ref={locationRef} />
      <Investment params={setting.params} ref={investmentRef} />
      <Row className="grid-cols-2 gap-4">
        <RevenueByInvestmentPortfolio
          params={setting?.params}
          ref={InvestmentRevenueRef}
        />
        <NumberOfOrdersByInvestmentPortfolio
          params={setting?.params}
          ref={InvestmentOrderRef}
        />
      </Row>
      {setting.open && (
        <SettingModal
          open={setting.open}
          defaultValues={setting.params}
          onSubmit={(values) => setSetting({ open: false, params: values })}
          onClose={() => setSetting((prev) => ({ ...prev, open: false }))}
        />
      )}
    </div>
  );
}

export default Order;
