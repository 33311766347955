import { useQuery } from '@tanstack/react-query';
import HeaderViewList from '_common/component/HeaderViewList';
import SumiTab from '_common/component/Sumi/Tab';
import {
  TAB_ITEMS_STATUS,
  TAB_ITEMS_STATUS_RENDER,
} from '_common/constants/common';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Badge,
  getQueryParams,
  PaginationProps,
  TabItemType,
  updateQueryParams,
} from 'tera-dls';
import ProductApi from './api';
import ProductFilter from './containers/Filter';
import ProductForm from './containers/Form';
import ProductSearch from './containers/Search';
import ProductTable from './containers/Table';
import { PRODUCT_URL } from './url';
import { usePermission } from '_common/hooks/usePermission';
import { PRODUCT_PERMISSION_KEY } from '_common/constants/permission';

function Product() {
  const [openForm, setOpenForm] = useState({ open: false, id: null });
  const [isOpenFilter, setIsOpenFilter] = useState<boolean>(false);

  const { hasPage } = usePermission();
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;

  const handleUpdateQueryParams = (data) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(`${PRODUCT_URL.list.path}${paramString}`);
  };

  const {
    data: productList,
    isLoading,
    refetch,
  } = useQuery(
    ['get-product-list', queryParams],
    () => {
      const params = {
        ...queryParams,
        page: queryParams?.page || 1,
        limit: queryParams?.limit || 10,
        status: queryParams?.status === 'all' ? undefined : queryParams?.status,
      };
      return ProductApi.getList({ params });
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
      onSuccess: (data) => {
        if (Number(data?.last_page) < (Number(queryParams?.page) || 1)) {
          handleUpdateQueryParams({ page: data?.last_page });
        }
      },
    },
  );

  const { data: summaryList } = useQuery(
    ['get-summary-product-list', queryParams],
    () => {
      const params = {
        ...queryParams,
        page: queryParams?.page || 1,
        limit: queryParams?.limit || 10,
        status: queryParams?.status === 'all' ? undefined : queryParams?.status,
      };
      return ProductApi.getSummary({ params });
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    const isDiffPageSize =
      Number(pageSize) !== Number(productList?.limit || 10);

    handleUpdateQueryParams({
      page: isDiffPageSize ? 1 : page,
      limit: pageSize,
    });
  };

  const handleFilter = (values) => {
    handleUpdateQueryParams({ ...values, page: 1 });
  };

  const handleChangeTab = (key) => {
    handleUpdateQueryParams({ status: key, page: 1 });
  };
  const handleSearch = (value) => {
    handleUpdateQueryParams({ keyword: value?.keyword, page: 1 });
  };

  const tabItems: TabItemType[] = useMemo(() => {
    const tabs = TAB_ITEMS_STATUS_RENDER.map((item) => {
      const count =
        summaryList?.find((i) => i?.status == item)?.total_count || 0;
      return {
        key: item,
        label: (
          <div className="flex items-center gap-x-2">
            {TAB_ITEMS_STATUS[item]} <Badge count={count} showZero />
          </div>
        ),
      };
    });
    return tabs;
  }, [summaryList]);

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="tera-page">
      <HeaderViewList
        title="Danh sách sản phẩm"
        buttonFilterKey={PRODUCT_PERMISSION_KEY.SEARCH}
        buttonCreatingKey={PRODUCT_PERMISSION_KEY.CREATE}
        onClickFilter={() => setIsOpenFilter(true)}
        onClickButtonAdd={() => setOpenForm({ open: true, id: null })}
        middleContent={
          hasPage(PRODUCT_PERMISSION_KEY.SEARCH) && (
            <SumiTab
              onChange={handleChangeTab}
              items={tabItems}
              activeKey={queryParams?.status || 'all'}
            />
          )
        }
        actionLeftRender={
          hasPage(PRODUCT_PERMISSION_KEY.SEARCH) && (
            <ProductSearch onSearch={handleSearch} />
          )
        }
        filterCount={{
          filter: queryParams,
          params: [
            'warehouse_catalog_id',
            'amount_from',
            'amount_to',
            'stock_from',
            'stock_to',
          ],
        }}
      >
        <ProductTable
          rowKey={'id'}
          data={productList?.data || [{ id: 1 }]}
          loading={isLoading}
          pagination={{
            onChange: handleChangePage,
            total: productList?.total || 0,
            current: productList?.current_page,
            pageSize: productList?.per_page || 10,
            to: productList?.to,
            from: productList?.from || 10,
          }}
        />
      </HeaderViewList>

      {isOpenFilter && (
        <ProductFilter
          open={isOpenFilter}
          onClose={() => setIsOpenFilter(false)}
          onFilter={handleFilter}
          initialValue={queryParams}
        />
      )}
      {openForm.open && (
        <ProductForm
          open={openForm.open}
          id={openForm.id}
          onClose={() => setOpenForm({ open: false, id: null })}
        />
      )}
    </div>
  );
}

export default Product;
