import { INCURRED_COST_PERMISSION_KEY } from '_common/constants/permission';

export const INCURRED_COST_URL = {
  list: {
    key: INCURRED_COST_PERMISSION_KEY.VIEW_LIST,
    path: '/investor/incurred-cost/list',
    shortenUrl: 'incurred-cost/list',
  },
  detail: {
    key: INCURRED_COST_PERMISSION_KEY.VIEW_DETAIL,
    path: '/investor/incurred-cost/detail',
    shortenUrl: 'incurred-cost/detail/:id',
  },
};
