import { useQuery } from '@tanstack/react-query';
import NoData from '_common/component/NoData';
import { DATE_TIME_FORMAT } from '_common/constants/common';
import { useEffect } from 'react';
import { formatDate, Modal, Spin, Tag } from 'tera-dls';
import UserApi from '../../api';
import { CAMERA_STATUS, CAMERA_STATUS_COLOR } from '../../constants';

interface CameraDetailProps {
  id: number;
  open: boolean;
  onCancel: () => void;
}

function CameraDetail({ id, open, onCancel }: CameraDetailProps) {
  const {
    data: dataDetails,
    refetch,
    isLoading,
    isError,
  } = useQuery(['get-user-detail', id], () => UserApi.getDetail({ id }), {
    enabled: !!id,
    staleTime: 300000,
    cacheTime: 300000,
  });

  const user = [
    {
      label: 'Tên camera',
      value: dataDetails?.name,
    },
    {
      label: 'Camera ID',
      value: dataDetails?.camID,
    },
    {
      label: 'IP',
      value: dataDetails?.ip,
    },
    {
      label: 'Port',
      value: dataDetails?.port,
    },
    {
      label: 'Tài khoản',
      value: dataDetails?.username,
    },
    {
      label: 'Trạng thái',
      value: (
        <Tag color={CAMERA_STATUS_COLOR[dataDetails?.status]}>
          {CAMERA_STATUS[dataDetails?.status]}
        </Tag>
      ),
    },
    {
      label: 'Ngày tạo',
      value:
        dataDetails?.created_at &&
        formatDate(dataDetails?.created_at, DATE_TIME_FORMAT),
    },
    {
      label: 'Ngày cập nhật',
      value:
        dataDetails?.updated_by &&
        formatDate(dataDetails?.updated_at, DATE_TIME_FORMAT),
    },
  ];

  useEffect(() => {
    refetch();
  }, []);
  dataDetails;
  return (
    <Modal
      title="Chi tiết camera"
      destroyOnClose
      closeIcon={false}
      cancelText="Đóng"
      onCancel={onCancel}
      open={open}
      centered={true}
      className="sm:w-[500px]"
      okButtonProps={{ className: 'hidden' }}
    >
      <Spin spinning={isLoading && !!id}>
        {isError && !!id ? (
          <NoData />
        ) : (
          <div className="flex flex-col gap-y-2.5">
            {user.map((item, index) => (
              <div className="flex items-start" key={index}>
                <div className="detail-key">{item.label}</div>
                <div className="detail-value">{item.value}</div>
              </div>
            ))}
          </div>
        )}
      </Spin>
    </Modal>
  );
}

export default CameraDetail;
