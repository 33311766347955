import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { IconButton } from '_common/component/TableColumnCustom';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import useConfirm from '_common/hooks/useConfirm';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  ArrowSmallLeftOutlined,
  Breadcrumb,
  Button,
  getQueryParams,
  ItemType,
  notification,
  Spin,
  TabItemType,
  Tabs,
  updateQueryParams,
} from 'tera-dls';
import InvoiceApi from '../../api';
import { STATUS_INVOICE_APPROVED } from '../../constants';
import { INVOICE_URL } from '../../url';
import CashReceipt from './CashReceipt';
import ExpenseVoucher from './ExpenseVoucher';
import Information from './Information';
import TransactionHistory from './TransactionHistory';
import { INVOICE_PERMISSION_KEY } from '_common/constants/permission';
import { usePermission } from '_common/hooks/usePermission';

function InvoiceDetail() {
  const navigate = useNavigate();
  const confirm = useConfirm();
  const queryClient = useQueryClient();
  const { id } = useParams();

  const { hasPage } = usePermission();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;
  const activeKey = queryParams?.activeKey || 'information';

  const handleUpdateQueryParams = (data) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(`${INVOICE_URL.detail.path}/${id}${paramString}`);
  };

  const {
    data: dataDetails,
    refetch,
    isLoading,
    isError,
  } = useQuery(['get-invoice-detail', id], () => InvoiceApi.getDetail({ id }), {
    enabled: !!id,
    staleTime: 300000,
    cacheTime: 300000,
  });

  const { mutate: mutateApproval } = useMutation(
    (params: any) =>
      InvoiceApi.approval({ id: params?.id, params: params?.params }),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          refetch();
          queryClient.invalidateQueries(['get-invoice-list']);
          queryClient.invalidateQueries(['get-transaction-history-list']);
          queryClient.invalidateQueries([
            'get-summary-transaction-history-list',
          ]);

          queryClient.invalidateQueries(['get-expense-voucher-invoice-list']);
          queryClient.invalidateQueries(['get-cash-receipt-invoice-list']);
        }
      },
      onError: (error: any) => {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  // const { mutate: mutateDelete } = useMutation(
  //   (id: any) => InvoiceApi.delete({ id }),
  //   {
  //     onSuccess: (res) => {
  //       if (res?.code === 200) {
  //         notification.success({
  //           message: res?.msg,
  //         });
  //         navigate(-1);
  //         queryClient.invalidateQueries(['get-invoice-list']);
  //       }
  //     },
  //     onError: (error: any) => {
  //       ErrorToast({ errorProp: error?.data });
  //     },
  //   },
  // );

  // const handleDelete = () => {
  //   confirm.warning({
  //     title: 'Xác nhận xóa hóa đơn',
  //     content: (
  //       <div className="break-word">
  //         <p>Bạn có chắc chắn muốn xóa hóa đơn</p>
  //         <p>
  //           <b>{dataDetails?.invoice_code}</b> này không?
  //         </p>
  //       </div>
  //     ),
  //     onOk: () => {
  //       mutateDelete(id);
  //     },
  //   });
  // };

  const handlePayment = () => {
    confirm.warning({
      title: 'Xác nhận thanh toán',
      content: (
        <div className="break-word">
          <p>Bạn có chắc chắn muốn thanh toán hóa đơn</p>
          <p>
            <b>{dataDetails?.invoice_code}</b> này không?
          </p>
        </div>
      ),
      onOk: () => {
        mutateApproval({
          id: id,
          params: {
            status: STATUS_INVOICE_APPROVED,
          },
        });
      },
    });
  };

  const BreadcrumbItem: ItemType[] = [
    {
      title: (
        <a
          onClick={() => navigate(INVOICE_URL.list.path)}
          className="!text-blue-400 hover:!text-blue-600"
        >
          Danh sách hóa đơn
        </a>
      ),
    },
    {
      title: 'Chi tiết hóa đơn',
    },
  ];

  const items: TabItemType[] = [
    { label: 'Thông tin chi tiết', key: 'information' },
    { label: 'Lịch sử giao dịch', key: 'transaction-history' },
    ...(dataDetails?.transaction_type === 'deposit' ||
    (dataDetails?.transaction_type === 'pay' &&
      dataDetails?.methods === 'transfer')
      ? [{ label: 'Phiếu thu', key: 'cash-receipt' }]
      : []),
    ...(dataDetails?.transaction_type === 'withdraw'
      ? [{ label: 'Phiếu chi', key: 'expense-voucher' }]
      : []),
  ];

  const renderContent = {
    information: (
      <Information
        dataDetails={dataDetails}
        isLoading={isLoading}
        isError={isError}
      />
    ),
    'transaction-history': <TransactionHistory />,
    'cash-receipt': <CashReceipt />,
    'expense-voucher': <ExpenseVoucher />,
  };

  useEffect(() => {
    if (id) refetch();
  }, [id]);

  return (
    <div className="tera-page-form">
      <div className="page-header-sticky">
        <div className="page-header-v2">
          <div className="page-header-v2__breadcrumb">
            <IconButton
              icon={<ArrowSmallLeftOutlined />}
              callBack={() => navigate(-1)}
            />
            <Breadcrumb separator="/" items={BreadcrumbItem} />
          </div>
          <div className="page-header-v2__function">
            {hasPage(INVOICE_PERMISSION_KEY.CONFIRM) &&
              dataDetails?.status !== STATUS_INVOICE_APPROVED &&
              dataDetails?.transaction_type === 'pay' && (
                <Button className="page-header-v2-btn" onClick={handlePayment}>
                  Xác nhận thanh toán
                </Button>
              )}
          </div>
        </div>
      </div>
      <Spin spinning={false}>
        <div className="page-content-v2">
          <div className="p-4 rounded bg-white">
            <Tabs
              activeKey={activeKey}
              items={items}
              onChange={(key) => handleUpdateQueryParams({ activeKey: key })}
            />
            {renderContent[activeKey]}
          </div>
        </div>
      </Spin>
    </div>
  );
}

export default InvoiceDetail;
