export const TIME_CONFIG = {
  1: '1 giờ',
  2: '2 giờ',
  3: '3 giờ',
  4: '4 giờ',
  5: '5 giờ',
  6: '6 giờ',
  7: '7 giờ',
  8: '8 giờ',
  9: '9 giờ',
  10: '10 giờ',
  11: '11 giờ',
  12: '12 giờ',
  13: '13 giờ',
  14: '14 giờ',
  15: '15 giờ',
  16: '16 giờ',
  17: '17 giờ',
  18: '18 giờ',
  19: '19 giờ',
  20: '20 giờ',
  21: '21 giờ',
  22: '22 giờ',
  23: '23 giờ',
  24: '24 giờ',
};
