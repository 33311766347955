import { IRouteProps } from '_common/interface/router';
import { UNIT_URL } from './url';
import Unit from '.';

export const UnitRouter: IRouteProps[] = [
  {
    key: UNIT_URL.list.key,
    path: UNIT_URL.list.shortenUrl,
    component: <Unit />,
  },
];
