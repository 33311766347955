import React, { useState } from 'react';
import Content from './Content';
export const StatisticsByIncurredExpensesParamContext =
  React.createContext(null);

function StatisticsByIncurredExpenses() {
  const [contextParams, setContextParams] = useState<any>();
  return (
    <StatisticsByIncurredExpensesParamContext.Provider
      value={{ contextParams, setContextParams }}
    >
      <Content chartClassName="!h-[420px]" />
    </StatisticsByIncurredExpensesParamContext.Provider>
  );
}

export default StatisticsByIncurredExpenses;
