import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const PermissionEndpoint = `${endpoint}/portal/config/role`;

const PermissionApi = {
  getListControls: async ({ params }) =>
    await api
      .get(`${PermissionEndpoint}/list-page-control`, params)
      .then((res) => res.data?.data),
  getList: async ({ params }) =>
    await api
      .get(`${PermissionEndpoint}/list`, params)
      .then((res) => res?.data?.data),
  getDetail: async ({ id }) =>
    await api
      .get(`${PermissionEndpoint}/detail/${id}`)
      .then((res) => res?.data?.data),
  create: async ({ params }) =>
    await api
      .post(`${PermissionEndpoint}/create`, params)
      .then((res) => res?.data),
  update: async ({ id, params }) =>
    await api
      .put(`${PermissionEndpoint}/update/${id}`, params)
      .then((res) => res?.data),
  delete: async ({ id }) =>
    await api
      .delete(`${PermissionEndpoint}/delete/${id}`)
      .then((res) => res?.data),
};

export default PermissionApi;
