import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const DashboardEndpoint = `${endpoint}/portal/dashboard`;
const DashboardApi = {
  getPointOfSale: async ({ params }) =>
    await api
      .get(`${DashboardEndpoint}/point-of-sale`, params)
      .then((data) => data.data?.data),
  getInvestmentSituation: async ({ params }) =>
    await api
      .get(`${DashboardEndpoint}/investment-situation`, params)
      .then((data) => data.data?.data),
  getInvestmentRevenue: async ({ params }) =>
    await api
      .get(`${DashboardEndpoint}/pie-invest-revenue`, params)
      .then((data) => data.data?.data),
  getInvestmentOrder: async ({ params }) =>
    await api
      .get(`${DashboardEndpoint}/pie-invest-order`, params)
      .then((data) => data.data?.data),
  getTopInvestment: async () =>
    await api
      .get(`${DashboardEndpoint}/top-selling-point`)
      .then((data) => data.data?.data),
  getTopBestSeller: async () =>
    await api
      .get(`${DashboardEndpoint}/top-selling-product`)
      .then((data) => data.data?.data),
  getTopInvestor: async () =>
    await api
      .get(`${DashboardEndpoint}/top-investor`)
      .then((data) => data.data?.data),
  getIncurredCost: async ({ params }) =>
    await api
      .get(`${DashboardEndpoint}/statistic-incurred-cost`, params)
      .then((data) => data.data?.data),
};
export default DashboardApi;
