import { CEASE_INVESTMENT_PERMISSION_KEY } from '_common/constants/permission';

export const CEASE_INVESTMENT_URL = {
  list: {
    key: CEASE_INVESTMENT_PERMISSION_KEY.VIEW_LIST,
    path: '/investor/cease-investment/list',
    shortenUrl: 'cease-investment/list',
  },
  detail: {
    key: CEASE_INVESTMENT_PERMISSION_KEY.VIEW_DETAIL,
    path: '/investor/cease-investment/detail',
    shortenUrl: 'cease-investment/detail/:id',
  },
};
