import { useQuery, useQueryClient } from '@tanstack/react-query';
import HeaderViewList from '_common/component/HeaderViewList';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getQueryParams, PaginationProps, updateQueryParams } from 'tera-dls';

import { TIMEKEEPING_PERMISSION_KEY } from '_common/constants/permission';
import { usePermission } from '_common/hooks/usePermission';
import TimekeepingApi from './api';
import TimekeepingFilter from './containers/Filter';
import TimekeepingForm from './containers/Form';
import TimekeepingSearch from './containers/Search';
import TimekeepingTable from './containers/Table';
import { TIMEKEEPING_URL } from './url';

function Timekeeping() {
  const [openForm, setOpenForm] = useState({ open: false, id: null });
  const [isOpenFilter, setIsOpenFilter] = useState(false);

  const { hasPage } = usePermission();
  const navigate = useNavigate();

  const { search } = useLocation();
  const queryClient = useQueryClient();
  const queryParams = getQueryParams(search) as any;

  const handleUpdateQueryParams = (data) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(`${TIMEKEEPING_URL.list.path}${paramString}`);
  };

  const {
    data: timekeepingList,
    isLoading,
    refetch,
  } = useQuery(
    ['get-timekeeping-list', queryParams],
    () => {
      const params = {
        ...queryParams,
        page: queryParams?.page || 1,
        limit: queryParams?.limit || 10,
        status: queryParams?.status === 'all' ? undefined : queryParams?.status,
      };
      return TimekeepingApi.getList({ params });
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
      onSuccess: (data) => {
        if (Number(data?.last_page) < (Number(queryParams?.page) || 1)) {
          handleUpdateQueryParams({ page: data?.last_page });
        }
      },
    },
  );

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    const isDiffPageSize =
      Number(pageSize) !== Number(timekeepingList?.limit || 10);

    handleUpdateQueryParams({
      page: isDiffPageSize ? 1 : page,
      limit: pageSize,
    });
  };

  const handleSearch = (value) => {
    handleUpdateQueryParams({ keyword: value?.keyword, page: 1 });
  };

  const handleFilter = (values) => {
    handleUpdateQueryParams({ ...values, page: 1 });
  };

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="tera-page">
      <HeaderViewList
        title="Danh sách chấm công"
        buttonFilterKey={TIMEKEEPING_PERMISSION_KEY.SEARCH}
        buttonCreatingKey={TIMEKEEPING_PERMISSION_KEY.CREATE}
        onClickButtonAdd={() => setOpenForm({ open: true, id: null })}
        actionLeftRender={
          hasPage(TIMEKEEPING_PERMISSION_KEY.SEARCH) && (
            <TimekeepingSearch onSearch={handleSearch} />
          )
        }
        onClickFilter={() => setIsOpenFilter(true)}
        filterCount={{
          filter: queryParams,
          params: ['date', 'check_in_time', 'check_out_time'],
        }}
      >
        <TimekeepingTable
          rowKey={'id'}
          data={timekeepingList?.data || []}
          loading={isLoading}
          pagination={{
            onChange: handleChangePage,
            total: timekeepingList?.total || 0,
            current: timekeepingList?.current_page,
            pageSize: timekeepingList?.per_page || 10,
            to: timekeepingList?.to,
            from: timekeepingList?.from || 10,
          }}
        />
      </HeaderViewList>

      {isOpenFilter && (
        <TimekeepingFilter
          open={isOpenFilter}
          onClose={() => setIsOpenFilter(false)}
          onFilter={handleFilter}
          initialValue={queryParams}
        />
      )}
      {openForm.open && (
        <TimekeepingForm
          open={openForm.open}
          id={openForm.id}
          onClose={() => setOpenForm({ open: false, id: null })}
          onSuccess={() => {
            queryClient.invalidateQueries(['get-timekeeping-list']);
            queryClient.invalidateQueries(['get-summary-timekeeping-list']);
          }}
        />
      )}
    </div>
  );
}

export default Timekeeping;
