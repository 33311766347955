import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import HoverQuickView from '_common/component/HoverQuickView';
import NoData from '_common/component/NoData';
import { IconButton } from '_common/component/TableColumnCustom';
import { DATE_TIME_FORMAT } from '_common/constants/common';
import { ROLE_PERMISSION_KEY } from '_common/constants/permission';
import { usePermission } from '_common/hooks/usePermission';
import classNames from 'classnames';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ArrowSmallLeftOutlined,
  Breadcrumb,
  Button,
  Checkbox,
  Col,
  formatDate,
  ItemType,
  notification,
  PencilSquareOutlined,
  Row,
  Spin,
  Tabs,
  XMarkOutlined,
} from 'tera-dls';
import PermissionApi from '../../api';
import { MODULE_TITLE, PAGE_TITLE } from '../../constants';
import { PERMISSION_URL } from '../../url';
import useConfirm from '_common/hooks/useConfirm';

const headingClassName =
  'text-blue-500 font-medium text-base border-b py-4 px-2.5';

function PermissionDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { hasPage } = usePermission();
  const confirm = useConfirm();
  const queryClient = useQueryClient();

  const [listPermission, setListPermission] = useState<number[]>([]);
  const [activeKeyEpic, setActiveKeyEpic] = useState<string>(null);
  const [activeKeyModule, setActiveKeyModule] = useState<string>('home');

  const {
    data: dataDetails,
    refetch,
    isLoading,
    isError,
  } = useQuery(
    ['get-permission-detail', id],
    () => PermissionApi.getDetail({ id }),
    {
      enabled: !!id,
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const { mutate: mutateDelete, isLoading: loadingDelete } = useMutation(
    (id: any) => PermissionApi.delete({ id }),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          navigate(-1);
          queryClient.invalidateQueries(['get-permission-list']);
        }
      },
      onError: (error: any) => {
        notification.error({
          message: error?.message,
        });
      },
    },
  );

  const { data } = useQuery(
    ['get-control-list', activeKeyModule],
    () => {
      const params = {
        epic_key: activeKeyModule,
      };
      return PermissionApi.getListControls({ params });
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  const handleDelete = () => {
    confirm.warning({
      title: 'Xác nhận xóa cấu hình quyền',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn xóa cấu hình quyền</p>
          <p>
            <b>{dataDetails?.name}</b> này không?
          </p>
        </div>
      ),
      onOk: () => {
        mutateDelete(dataDetails?.id);
      },
    });
  };

  const BreadcrumbItem: ItemType[] = [
    {
      title: (
        <a
          onClick={() => navigate(PERMISSION_URL.list.path)}
          className="!text-blue-400 hover:!text-blue-600"
        >
          Danh sách quyền
        </a>
      ),
    },
    {
      title: 'Chi tiết cấu hình quyền',
    },
  ];

  const detail = [
    {
      label: 'Mã quyền',
      value: dataDetails?.code,
    },
    {
      label: 'Người tạo',
      value: (
        <HoverQuickView
          avatarUrl={dataDetails?.created_by?.avatar}
          name={dataDetails?.created_by?.full_name}
          code={dataDetails?.created_by?.code}
          email={dataDetails?.created_by?.email}
          phone={dataDetails?.created_by?.phone}
        >
          {dataDetails?.created_by?.full_name}
        </HoverQuickView>
      ),
    },
    {
      label: 'Tên phân quyền',
      value: dataDetails?.name,
    },
    {
      label: 'Ngày tạo',
      value:
        dataDetails?.created_at &&
        formatDate(dataDetails?.created_at, DATE_TIME_FORMAT),
    },
    {
      label: '',
      value: '',
    },
    {
      label: 'Người cập nhật',
      value: (
        <HoverQuickView
          avatarUrl={dataDetails?.updated_by?.avatar}
          name={dataDetails?.updated_by?.full_name}
          code={dataDetails?.updated_by?.code}
          email={dataDetails?.updated_by?.email}
          phone={dataDetails?.updated_by?.phone}
        >
          {dataDetails?.updated_by?.full_name}
        </HoverQuickView>
      ),
    },
    {
      label: '',
      value: '',
    },
    {
      label: 'Ngày cập nhật',
      value:
        dataDetails?.updated_by &&
        formatDate(dataDetails?.updated_at, DATE_TIME_FORMAT),
    },
  ];

  const permissions = useMemo(() => {
    if (!data) return undefined;
    const groupedPermission = _.groupBy(data, 'page_key');
    const converted = Object.entries(groupedPermission)?.reduce(
      (result, [key, values]) => ({
        ...result,
        [key]: {
          name: PAGE_TITLE[key],
          controls: values?.map((item) => ({
            id: item?.id,
            title: item?.function_title,
            key: item?.permission_key,
          })),
        },
      }),
      {},
    );
    return converted;
  }, [data]);

  useEffect(() => {
    refetch();
  }, []);

  const disabled = isError || isLoading || loadingDelete;

  const itemModule = Object.entries(MODULE_TITLE).map(([key, label]) => ({
    key,
    label,
  }));

  const itemEpic = useMemo(() => {
    if (!permissions) return [];
    const items = Object.keys(permissions || {}).map((key) => ({
      key,
      label: PAGE_TITLE[key],
    }));
    return items;
  }, [permissions]);

  useEffect(() => {
    if (dataDetails) {
      const role_has_permission = dataDetails?.role_has_permission?.map(
        (item) => item?.permission_id,
      );

      setListPermission(role_has_permission);
    }
  }, [dataDetails]);

  useEffect(() => {
    setActiveKeyEpic(Object.keys(permissions || {})[0]);
  }, [permissions]);

  return (
    <div className="tera-page-form">
      <div className="page-header-sticky">
        <div className="page-header-v2">
          <div className="page-header-v2__breadcrumb">
            <IconButton
              icon={<ArrowSmallLeftOutlined />}
              callBack={() => navigate(-1)}
            />
            <Breadcrumb separator="/" items={BreadcrumbItem} />
          </div>
          <div className="page-header-v2__function">
            {hasPage(ROLE_PERMISSION_KEY.UPDATE) && (
              <Button
                className="page-header-v2-btn"
                prefix={<PencilSquareOutlined />}
                onClick={() => navigate(`${PERMISSION_URL.update.path}/${id}`)}
                disabled={disabled}
              >
                Sửa
              </Button>
            )}
            {hasPage(ROLE_PERMISSION_KEY.DELETE) && (
              <Button
                className="page-header-v2-btn"
                type="danger"
                prefix={<XMarkOutlined />}
                onClick={handleDelete}
                disabled={disabled}
              >
                Xóa
              </Button>
            )}
          </div>
        </div>
      </div>
      <Spin spinning={disabled}>
        <div className="page-content-v2">
          <div className="bg-white rounded-[10px] px-2.5 py-5 shadow">
            <Row className="grid-cols-2">
              {detail.map((item, index) => (
                <div
                  className={classNames('flex items-start gap-x-2.5')}
                  key={index}
                >
                  <div className="detail-key">{item.label}</div>
                  <div className="detail-value">{item.value}</div>
                </div>
              ))}
              <Col className="col-span-2 rounded overflow-hidden border">
                <Row className="grid-cols-4 gap-0">
                  <Col className={classNames(headingClassName, 'col-span-1')}>
                    Danh sách module
                  </Col>
                  <Col
                    className={classNames(
                      headingClassName,
                      'col-span-3 border-l',
                    )}
                  >
                    Danh sách màn hình và chức năng
                  </Col>
                  <Col className="col-span-1 bg-[#F3F4F6]">
                    <Tabs
                      activeClassName="bg-white"
                      className="sumi-system-permission__tab-container border-r-0"
                      itemClassName="sumi-system-permission__tab-item border-b"
                      tabPosition="left"
                      items={itemModule}
                      activeKey={activeKeyModule}
                      onChange={(key) => setActiveKeyModule(key)}
                    />
                  </Col>
                  {data?.length > 0 ? (
                    <Col className="col-span-3 py-2.5 px-4">
                      <Tabs
                        items={itemEpic}
                        activeKey={activeKeyEpic}
                        onChange={(key) => setActiveKeyEpic(key)}
                      />
                      <Row className="pl-10 grid-cols-4 mt-2.5">
                        {permissions?.[activeKeyEpic]?.controls?.map((item) => {
                          const newItemsSet = new Set(listPermission);
                          const checked = newItemsSet.has(item?.id);

                          return (
                            <Checkbox
                              value={item?.id}
                              checked={checked}
                              disabled={checked}
                              className="!ring-0 !cursor-default"
                              labelClassName="!cursor-default"
                            >
                              {item?.title}
                            </Checkbox>
                          );
                        })}
                      </Row>
                    </Col>
                  ) : (
                    <Col className="col-span-3 py-2.5 px-4">
                      <NoData />
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </Spin>
    </div>
  );
}

export default PermissionDetail;
