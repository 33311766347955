import { useQuery } from '@tanstack/react-query';
import NoData from '_common/component/NoData';
import { useEffect } from 'react';
import { Modal, Spin } from 'tera-dls';
import PaymentMethodApi from '../../api';

interface QrCodeProps {
  id: number;
  open: boolean;
  onCancel: () => void;
}

function QrCode({ id, open, onCancel }: QrCodeProps) {
  const {
    data: dataQr,
    refetch,
    isLoading,
    isError,
  } = useQuery(
    ['get-payment-method-detail', id],
    () => PaymentMethodApi.getQrCode({ id }),
    {
      enabled: !!id,
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    if (id) refetch();
  }, [id]);

  return (
    <Modal
      title="QR code"
      destroyOnClose
      closeIcon={false}
      cancelText="Đóng"
      onCancel={onCancel}
      open={open}
      centered={true}
      className="sm:w-[500px]"
      okButtonProps={{ className: 'hidden' }}
    >
      <Spin spinning={isLoading}>
        {isError ? (
          <NoData />
        ) : (
          <img
            src={dataQr?.data?.qrDataURL}
            alt={dataQr?.data?.qrCode}
            className="w-80 h-80 m-auto"
          />
        )}
      </Spin>
    </Modal>
  );
}

export default QrCode;
