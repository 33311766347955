import { IRouteProps } from '_common/interface/router';
import { TIMEKEEPING_URL } from './url';
import Timekeeping from '.';

export const TimekeepingRouter: IRouteProps[] = [
  {
    key: TIMEKEEPING_URL.list.key,
    path: TIMEKEEPING_URL.list.shortenUrl,
    component: <Timekeeping />,
  },
];
