import { IRouteProps } from '_common/interface/router';
import { CAMERA_URL } from './url';
import Camera from '.';

export const CameraRouter: IRouteProps[] = [
  {
    key: CAMERA_URL.list.key,
    path: CAMERA_URL.list.shortenUrl,
    component: <Camera />,
  },
];
