import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import { DATE_FORMAT } from '_common/constants/common';
import { INVESTMENT_CATEGORY } from '_common/dof/Select/SelectInvestmentCategory';
import { TICKET_TYPE } from '_common/dof/Select/SelectTicketType';
import TableTera from '_common/dof/TableTera';
import { ITeraTableProps } from '_common/dof/TableTera/_interfaces';
import { useNavigate } from 'react-router-dom';
import {
  ColumnsType,
  DropdownItem,
  formatCurrency,
  formatDate,
  formatNumber,
} from 'tera-dls';
import { SALE_ORDER_URL } from '../../url';
import { SALE_ORDER_PERMISSION_KEY } from '_common/constants/permission';
import { usePermission } from '_common/hooks/usePermission';
import HoverQuickView from '_common/component/HoverQuickView';

function SaleOrderTable(props: ITeraTableProps) {
  const navigate = useNavigate();
  const { hasPage } = usePermission();

  const renderItemActions = (record) => {
    const items: DropdownItem[] = [];
    if (hasPage(SALE_ORDER_PERMISSION_KEY.VIEW_DETAIL))
      items.push({
        key: '1',
        label: 'Xem',
        onClick: () => navigate(`${SALE_ORDER_URL.detail.path}/${record?.id}`),
      });
    return items;
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Mã đơn hàng',
      dataIndex: 'code',
      width: 100,
      render: (name, record) => (
        <p
          className="line-clamp-2 break-word text-blue-600 cursor-pointer"
          onClick={() =>
            navigate(`${SALE_ORDER_URL.detail.path}/${record?.id}`)
          }
        >
          {name}
        </p>
      ),
    },
    {
      title: 'Đầu tư',
      dataIndex: 'invest_name',
      width: 200,
      render: (invest_name, record) => (
        <HoverQuickView
          name={record?.invest_name}
          avatarUrl={record?.invest_id?.file_upload}
          code={record?.invest_id?.code}
          sub={record?.invest_id?.description}
        >
          <p className="line-clamp-2">{invest_name}</p>
        </HoverQuickView>
      ),
    },
    {
      title: 'Danh mục đầu tư',
      dataIndex: 'type',
      width: 100,
      render: (type) => INVESTMENT_CATEGORY[type],
    },
    {
      title: 'Nhà đầu tư',
      dataIndex: 'investor_name',
      width: 150,
      render: (investor_name) => (
        <HoverQuickView name={investor_name || 'Hệ thống'}>
          {investor_name || 'Hệ thống'}
        </HoverQuickView>
        // <p className="line-clamp-2">{investor_name || 'Hệ thống'}</p>
      ),
    },
    {
      title: 'Tên thiết bị',
      dataIndex: 'device_name',
      width: 100,
      render: (device_name) => <p className="line-clamp-2">{device_name}</p>,
    },
    {
      title: 'Loại phiếu',
      dataIndex: 'ticket_type',
      width: 100,
      render: (ticket_type) => TICKET_TYPE[ticket_type],
    },

    {
      title: 'Tổng SL bán hàng',
      dataIndex: 'sum_quantity_sold',
      width: 100,
      render: (amount) => formatNumber(amount?.total_quantity_sold),
    },
    {
      title: 'Tổng thành tiền',
      dataIndex: 'order_detail_sold_total_price',
      width: 100,
      render: (total) => formatCurrency(total?.total_price),
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'created_at',
      width: 100,
      render: (created_at) => created_at && formatDate(created_at, DATE_FORMAT),
    },
    {
      width: 60,
      fixed: 'right',
      render: (record) => {
        const items = renderItemActions(record);
        return <ActionDropdown dropdownItems={items} trigger="click" />;
      },
    },
  ];

  return (
    <TableTera
      columns={columns}
      loading={props?.loading}
      zebra={false}
      {...props}
    />
  );
}

export default SaleOrderTable;
