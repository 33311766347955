import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import CardFormV2 from '_common/component/CardForm/CardFormV2';
import { IconButton } from '_common/component/TableColumnCustom';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { DATE_TIME_FORMAT, SUMI_SHADOW } from '_common/constants/common';
import { INVESTMENT_CATEGORY } from '_common/dof/Select/SelectInvestmentCategory';
import Row from '_common/dof/TableTera/TeraNormalTable/Row';
import useConfirm from '_common/hooks/useConfirm';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ArrowSmallLeftOutlined,
  Breadcrumb,
  Button,
  formatDate,
  ItemType,
  notification,
  PencilSquareOutlined,
  Spin,
  Tag,
  XMarkOutlined,
} from 'tera-dls';
import CeasesInvestmentApi from '../../api';
import {
  CEASE_INVESTMENT_STATUS,
  CEASE_INVESTMENT_STATUS_COLOR,
} from '../../constants';
import { CEASE_INVESTMENT_URL } from '../../url';
import { usePermission } from '_common/hooks/usePermission';
import { CEASE_INVESTMENT_PERMISSION_KEY } from '_common/constants/permission';
import HoverQuickView from '_common/component/HoverQuickView';
import CeasesInvestmentForm from '../Form';

function CeasesInvestmentDetail() {
  const { id } = useParams();
  const confirm = useConfirm();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { hasPage } = usePermission();

  const [openForm, setOpenForm] = useState<boolean>(false);

  const {
    data: dataDetails,
    refetch,
    isLoading,
    isError,
  } = useQuery(
    ['get-cease-investment-detail', id],
    () => CeasesInvestmentApi.getDetail({ id }),
    {
      enabled: !!id,
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const { mutate: mutateChangeStatus, isLoading: loadingChangeStatus } =
    useMutation(
      (params: any) => {
        if (params?.type === 'approve')
          return CeasesInvestmentApi.approve({ id: params?.id });
        return CeasesInvestmentApi.reject({ id: params?.id });
      },
      {
        onSuccess: (res) => {
          if (res?.code === 200) {
            notification.success({
              message: res?.msg,
            });
            queryClient.invalidateQueries(['get-investment-list']);
            queryClient.invalidateQueries(['get-cease-investment-list']);
            queryClient.invalidateQueries([
              'get-summary-cease-investment-list',
            ]);
            // navigate(-1);
            refetch();
          }
        },
        onError: (error: any) => {
          ErrorToast({ errorProp: error?.data });
        },
      },
    );

  const { mutate: mutateDelete, isLoading: loadingDelete } = useMutation(
    (id: any) => CeasesInvestmentApi.delete({ id }),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });

          navigate(-1);
          queryClient.invalidateQueries(['get-cease-investment-list']);
          queryClient.invalidateQueries(['get-summary-cease-investment-list']);
        }
      },
      onError: (error: any) => {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleApprove = () => {
    confirm.warning({
      title: 'Xác nhận duyệt ngưng đầu tư',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn duyệt ngưng đầu tư</p>
          <p>
            <b>{dataDetails?.code}</b> này không?
          </p>
        </div>
      ),
      onOk: () => {
        mutateChangeStatus({ id: dataDetails?.id, type: 'approve' });
      },
    });
  };

  const handleReject = () => {
    confirm.warning({
      title: 'Xác nhận từ chối ngưng đầu tư',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn từ chối ngưng đầu tư</p>
          <p>
            <b>{dataDetails?.code}</b> này không?
          </p>
        </div>
      ),
      onOk: () => {
        mutateChangeStatus({ id: dataDetails?.id, type: 'reject' });
      },
    });
  };

  const handleDelete = () => {
    confirm.warning({
      title: 'Xác nhận xóa ngưng đầu tư',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn xóa ngưng đầu tư</p>
          <p>
            <b>{dataDetails?.code}</b> này không?
          </p>
        </div>
      ),
      onOk: () => {
        mutateDelete(id);
      },
    });
  };

  const detail = [
    {
      label: 'Mã ngưng đầu tư',
      value: dataDetails?.code,
    },
    {
      label: 'Danh mục đầu tư',
      value: INVESTMENT_CATEGORY[dataDetails?.invest_id?.type],
    },
    {
      label: 'Người tạo',
      value: (
        <HoverQuickView
          avatarUrl={dataDetails?.created_by?.avatar}
          name={dataDetails?.created_by?.full_name}
          code={dataDetails?.created_by?.code}
          email={dataDetails?.created_by?.email}
          phone={dataDetails?.created_by?.phone}
        >
          {dataDetails?.created_by?.full_name}
        </HoverQuickView>
      ),
    },
    {
      label: 'Tên nhà đầu tư',
      value: (
        <HoverQuickView
          avatarUrl={dataDetails?.investor_id?.file_upload}
          name={dataDetails?.investor_id?.investor_name}
          code={dataDetails?.investor_id?.code}
          email={dataDetails?.investor_id?.email}
          phone={dataDetails?.investor_id?.phone}
        >
          {dataDetails?.investor_id?.investor_name}
        </HoverQuickView>
      ),
    },
    {
      label: 'Ngày phê duyệt đơn',
      value:
        dataDetails?.approved_at &&
        formatDate(dataDetails?.approved_at, DATE_TIME_FORMAT),
    },
    {
      label: 'Ngày tạo',
      value:
        dataDetails?.created_at &&
        formatDate(dataDetails?.created_at, DATE_TIME_FORMAT),
    },
    {
      label: 'Đầu tư',
      value: (
        <HoverQuickView
          name={dataDetails?.invest_id?.name}
          avatarUrl={dataDetails?.invest_id?.file_upload}
          code={dataDetails?.invest_id?.code}
          sub={dataDetails?.invest_id?.description}
        >
          {dataDetails?.invest_id?.name}
        </HoverQuickView>
      ),
    },
    {
      label: 'Trạng thái',
      value: (
        <Tag color={CEASE_INVESTMENT_STATUS_COLOR[dataDetails?.status]}>
          {CEASE_INVESTMENT_STATUS[dataDetails?.status]}
        </Tag>
      ),
    },
    {
      label: 'Người cập nhật',
      value: (
        <HoverQuickView
          avatarUrl={dataDetails?.updated_by?.avatar}
          name={dataDetails?.updated_by?.full_name}
          code={dataDetails?.updated_by?.code}
          email={dataDetails?.updated_by?.email}
          phone={dataDetails?.updated_by?.phone}
        >
          {dataDetails?.updated_by?.full_name}
        </HoverQuickView>
      ),
    },
    {
      label: '',
      value: '',
    },
    {
      label: '',
      value: '',
    },
    {
      label: 'Ngày cập nhật',
      value:
        dataDetails?.updated_by &&
        dataDetails?.updated_at &&
        formatDate(dataDetails?.updated_at, DATE_TIME_FORMAT),
    },
  ];

  const BreadcrumbItem: ItemType[] = [
    {
      title: (
        <a
          onClick={() => navigate(CEASE_INVESTMENT_URL.list.path)}
          className="!text-blue-400 hover:!text-blue-600"
        >
          Danh sách ngưng đầu tư
        </a>
      ),
    },
    {
      title: 'Chi tiết ngưng đầu tư',
    },
  ];

  useEffect(() => {
    refetch();
  }, []);

  const disabled = loadingDelete || isError;

  return (
    <Spin spinning={isLoading || loadingChangeStatus}>
      <div className="tera-page-form">
        <div className="page-header-sticky">
          <div className="page-header-v2">
            <div className="page-header-v2__breadcrumb">
              <IconButton
                icon={<ArrowSmallLeftOutlined />}
                callBack={() => navigate(-1)}
              />
              <Breadcrumb separator="/" items={BreadcrumbItem} />
            </div>
            <div className="page-header-v2__function">
              <>
                {dataDetails?.status === 0 &&
                  hasPage(CEASE_INVESTMENT_PERMISSION_KEY.CHANGE_STATUS) && (
                    <>
                      <Button
                        className="page-header-v2-btn"
                        type="danger"
                        onClick={handleReject}
                        disabled={disabled}
                      >
                        Từ chối
                      </Button>

                      <Button
                        className="page-header-v2-btn"
                        disabled={disabled}
                        onClick={handleApprove}
                      >
                        Duyệt
                      </Button>
                    </>
                  )}
                {dataDetails?.order_status !== 'approved' &&
                  [0, 2].includes(dataDetails?.status) && (
                    <>
                      {hasPage(CEASE_INVESTMENT_PERMISSION_KEY.UPDATE) && (
                        <Button
                          className="page-header-v2-btn"
                          prefix={<PencilSquareOutlined />}
                          onClick={() => setOpenForm(true)}
                          disabled={disabled}
                        >
                          Sửa
                        </Button>
                      )}
                      {hasPage(CEASE_INVESTMENT_PERMISSION_KEY.DELETE) && (
                        <Button
                          className="page-header-v2-btn"
                          type="danger"
                          prefix={<XMarkOutlined />}
                          onClick={handleDelete}
                          disabled={disabled}
                        >
                          Xóa
                        </Button>
                      )}
                    </>
                  )}
              </>
            </div>
          </div>
        </div>

        <div className="page-content-v2">
          <div className="bg-white rounded-[10px] px-2.5 py-5 shadow">
            <div
              className={classNames(
                'px-2.5 py-5 rounded-md border border-blue-400',
                SUMI_SHADOW,
              )}
            >
              <CardFormV2 title="Thông tin chung">
                <Row className="grid grid-cols-3 gap-2.5">
                  {detail.map((item, index) => (
                    <div className={'flex items-start'} key={index}>
                      <div className="detail-key">{item.label}</div>
                      <div className="detail-value">{item.value}</div>
                    </div>
                  ))}
                </Row>
              </CardFormV2>
            </div>
          </div>
        </div>
      </div>
      {openForm && (
        <CeasesInvestmentForm
          open={openForm}
          id={id}
          onClose={() => setOpenForm(false)}
        />
      )}
    </Spin>
  );
}

export default CeasesInvestmentDetail;
