import { useQuery } from '@tanstack/react-query';
import HeaderViewList from '_common/component/HeaderViewList';
import SumiTab from '_common/component/Sumi/Tab';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Badge,
  getQueryParams,
  PaginationProps,
  TabItemType,
  Toggle,
  updateQueryParams,
} from 'tera-dls';
import { ORDER_STATUS } from './constants';
import { ORDER_INSPECTION_URL } from './url';
import OrderInspectionApi from './api';
import OrderInspectionFilter from './containers/Filter';
import OrderInspectionSearch from './containers/Search';
import OrderInspectionTable from './containers/Table';
import { usePermission } from '_common/hooks/usePermission';
import { ORDER_INSPECTION_PERMISSION_KEY } from '_common/constants/permission';

function OrderInspection() {
  const [isOpenFilter, setIsOpenFilter] = useState<boolean>(false);

  const { hasPage } = usePermission();
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;

  const handleUpdateQueryParams = (data) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(`${ORDER_INSPECTION_URL.list.path}${paramString}`);
  };

  const {
    data: orderList,
    isLoading,
    refetch,
  } = useQuery(
    ['get-order-inspection-list', queryParams],
    () => {
      const params = {
        ...queryParams,
        page: queryParams?.page || 1,
        limit: queryParams?.limit || 10,
        status: queryParams?.status === 'all' ? undefined : queryParams?.status,
      };
      return OrderInspectionApi.getList({ params });
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
      onSuccess: (data) => {
        if (Number(data?.last_page) < (Number(queryParams?.page) || 1)) {
          handleUpdateQueryParams({ page: data?.last_page });
        }
      },
    },
  );

  const { data: summaryList } = useQuery(
    ['get-summary-order-inspection-list', queryParams],
    () => {
      const params = {
        ...queryParams,
        page: queryParams?.page || 1,
        limit: queryParams?.limit || 10,
        status: undefined,
      };
      return OrderInspectionApi.getSummary({ params });
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    const isDiffPageSize = Number(pageSize) !== Number(orderList?.limit || 10);

    handleUpdateQueryParams({
      page: isDiffPageSize ? 1 : page,
      limit: pageSize,
    });
  };

  const handleFilter = (values) => {
    handleUpdateQueryParams({ ...values, page: 1 });
  };

  const handleChangeTab = (key) => {
    handleUpdateQueryParams({ status: key, page: 1 });
  };
  const handleSearch = (value) => {
    handleUpdateQueryParams({ keyword: value?.keyword, page: 1 });
  };

  const tabItems: TabItemType[] = useMemo(() => {
    const tabs = Object.entries(ORDER_STATUS).map(([key, label]) => {
      const count =
        summaryList?.find((i) => i?.order_status == key)?.total_count || 0;
      return {
        key: key,
        label: (
          <div className="flex items-center gap-x-2">
            {label} <Badge count={count} showZero />
          </div>
        ),
      };
    });
    return tabs;
  }, [summaryList]);

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="tera-page">
      <HeaderViewList
        title="Danh sách đơn kiểm hàng"
        buttonFilterKey={ORDER_INSPECTION_PERMISSION_KEY.SEARCH}
        buttonCreatingKey={ORDER_INSPECTION_PERMISSION_KEY.CREATE}
        onClickFilter={() => setIsOpenFilter(true)}
        middleContent={
          hasPage(ORDER_INSPECTION_PERMISSION_KEY.SEARCH) && (
            <SumiTab
              onChange={handleChangeTab}
              items={tabItems}
              activeKey={queryParams?.status || 'all'}
            />
          )
        }
        actionLeftRender={
          hasPage(ORDER_INSPECTION_PERMISSION_KEY.SEARCH) && (
            <div className="flex items-center gap-x-2.5">
              <div className="flex items-center gap-x-2.5">
                <span>Bánh yêu cầu: </span>
                <Toggle
                  title="Bánh yêu cầu"
                  onChange={(e) => {
                    handleUpdateQueryParams({
                      ticket_type: e.target.checked ? 'request' : null,
                      page: 1,
                    });
                  }}
                  checked={queryParams?.ticket_type === 'request'}
                />
              </div>
              <OrderInspectionSearch onSearch={handleSearch} />
            </div>
          )
        }
        filterCount={{
          filter: queryParams,
          params: [
            'investor',
            'invest_type',
            'ticket_type',
            'quantity_check_from',
            'quantity_check_to',
          ],
        }}
      >
        <OrderInspectionTable
          rowKey={'id'}
          data={orderList?.data || []}
          loading={isLoading}
          pagination={{
            onChange: handleChangePage,
            total: orderList?.total || 0,
            current: orderList?.current_page,
            pageSize: orderList?.per_page || 10,
            to: orderList?.to,
            from: orderList?.from || 10,
          }}
        />
      </HeaderViewList>

      {isOpenFilter && (
        <OrderInspectionFilter
          open={isOpenFilter}
          onClose={() => setIsOpenFilter(false)}
          onFilter={handleFilter}
          initialValue={queryParams}
        />
      )}
    </div>
  );
}

export default OrderInspection;
