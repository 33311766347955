import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const PreviewEndpoint = `${endpoint}/sumi/warehouse`;
const ProductEndpoint = `${endpoint}/sumi/warehouse/product`;
const ProductInvestmentEndpoint = `${endpoint}/sumi/sell/investment-product`;

const ProductApi = {
  getList: async ({ params }) =>
    await api
      .get(`${ProductEndpoint}/list`, params)
      .then((res) => res.data?.data),
  getFeedback: async ({ id, params }) =>
    await api
      .get(`${PreviewEndpoint}/review/review-by-warehouse/${id}`, params)
      .then((res) => res.data?.data),
  getListInvestment: async ({ params }) =>
    await api
      .get(`${ProductInvestmentEndpoint}/list`, params)
      .then((res) => res.data?.data),
  getDetail: async ({ id }) =>
    await api
      .get(`${ProductEndpoint}/detail/${id}`)
      .then((res) => res.data?.data),
  getSummary: async ({ params }) =>
    await api
      .get(`${ProductEndpoint}/summary-list`, params)
      .then((res) => res.data?.data),
  create: async ({ params }) =>
    await api.post(`${ProductEndpoint}/create`, params).then((res) => res.data),
  update: async ({ id, params }) =>
    await api
      .put(`${ProductEndpoint}/update/${id}`, params)
      .then((res) => res.data),
  changeStatus: async ({ params }) =>
    await api.post(`${ProductEndpoint}/active`, params).then((res) => res.data),
  delete: async ({ id }) =>
    await api.delete(`${ProductEndpoint}/delete/${id}`).then((res) => res.data),
};

export default ProductApi;
