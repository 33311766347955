import { DATE_BACKEND_FORMAT } from '_common/constants/common';
import { messageWarning } from '_common/constants/message';
import RangePicker from '_common/dof/Control/RangePicker';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectInvestor from '_common/dof/Select/SelectInvestor';
import useConfirm from '_common/hooks/useConfirm';
import moment from 'moment';
import {
  CASH_RECEIPT_TYPE,
  EXPENSE_VOUCHER_TYPE,
  PAYMENT_METHOD_TYPE,
} from 'pages/Finance/constants';
import { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, Row } from 'tera-dls';
import { StatisticCashExpenseContext } from '..';

interface IProps {
  open: boolean;
  onClose?: () => void;
  onSubmit: (value: any) => void;
  defaultValues?: any;
}

const SettingModal = (props: IProps) => {
  const { onClose, open, onSubmit, defaultValues } = props;
  const form = useForm({ mode: 'onChange' });
  const confirm = useConfirm();
  const isDirty = form?.formState?.isDirty;
  const investor_id = form?.watch('investor_id');

  const { setContextParams } = useContext(StatisticCashExpenseContext);

  useEffect(() => {
    if (defaultValues) {
      form.reset({
        ...defaultValues,
        date: [moment(defaultValues.date[0]), moment(defaultValues.date[1])],
      });
    }
  }, [defaultValues]);

  const handleSubmitForm = (value: any): void => {
    const data = {
      ...value,
      ...(!!value.date?.[0] && {
        date: [
          value.date[0]?.format(DATE_BACKEND_FORMAT),
          value.date[1]?.format(DATE_BACKEND_FORMAT),
        ],
      }),
    };
    setContextParams(data);
    onSubmit(data);
    onClose();
  };

  const handleCloseConfirm = () => {
    if (isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>{messageWarning.WARNING_EXIT_1}</p>
            <p>{messageWarning.WARNING_EXIT_2}</p>
          </>
        ),
        onOk: () => {
          onClose();
        },
      });
    } else onClose();
  };

  const paymentMethod = Object.entries(PAYMENT_METHOD_TYPE).map(
    ([key, value]) => ({
      label: value,
      value: key,
    }),
  );

  const expenseVoucherTypeOptions = Object.entries(EXPENSE_VOUCHER_TYPE).map(
    ([key, value]) => ({
      label: value,
      value: key,
    }),
  );

  const cashReceiptTypeOptions = Object.entries(CASH_RECEIPT_TYPE).map(
    ([key, value]) => ({
      label: value,
      value: key,
    }),
  );

  const showyByOptions = [
    { value: 'day', label: 'Ngày' },
    { value: 'week', label: 'Tuần' },
    { value: 'month', label: 'Tháng' },
    { value: 'year', label: 'Năm' },
  ];

  return (
    <Modal
      title="Tham số báo cáo"
      destroyOnClose
      closeIcon={false}
      width={500}
      cancelText="Hủy"
      okText="Đồng ý"
      onOk={form.handleSubmit(handleSubmitForm)}
      onCancel={handleCloseConfirm}
      open={open}
      centered={true}
    >
      <FormTera form={form} onSubmit={handleSubmitForm}>
        <Row className="grid grid-cols-1 gap-x-5 gap-y-0">
          <FormTeraItem
            className="col-span-1"
            label="Nhà đầu tư"
            name="investor_id"
          >
            <SelectInvestor
              paramsApi={{ include_id: investor_id }}
              placeholder="Tất cả"
            />
          </FormTeraItem>

          <FormTeraItem
            className="col-span-1"
            label="Loại phiếu chi"
            name="payment_transaction_type"
          >
            <Select
              placeholder="Vui lòng chọn"
              options={expenseVoucherTypeOptions}
              mode="multiple"
              allowClear
            />
          </FormTeraItem>
          <FormTeraItem
            className="col-span-1"
            label="Loại phiếu thu"
            name="receipt_transaction_type"
          >
            <Select
              allowClear
              placeholder="Vui lòng chọn"
              options={cashReceiptTypeOptions}
              mode="multiple"
            />
          </FormTeraItem>
          <FormTeraItem label="Thời gian" name="date">
            <RangePicker
              allowClear={false}
              className="w-full"
              placeholder={['Từ ngày', 'Đến ngày']}
            />
          </FormTeraItem>
          <FormTeraItem
            className="col-span-1"
            label="Phương thức thanh toán"
            name="payment_method"
          >
            <Select
              allowClear
              placeholder="Vui lòng chọn"
              mode="multiple"
              options={paymentMethod}
            />
          </FormTeraItem>
          <FormTeraItem
            className="col-span-1"
            label="Hiển thị theo"
            name="show_by"
          >
            <Select placeholder="Vui lòng chọn" options={showyByOptions} />
          </FormTeraItem>
        </Row>
      </FormTera>
    </Modal>
  );
};

export default SettingModal;
