import { ORDER_RETURN_PERMISSION_KEY } from '_common/constants/permission';

export const SALE_ORDER_RETURN_URL = {
  list: {
    key: ORDER_RETURN_PERMISSION_KEY.VIEW_LIST,
    path: '/sale/sale-order-return/list',
    shortenUrl: 'sale-order-return/list',
  },
  create: {
    key: ORDER_RETURN_PERMISSION_KEY.CREATE,
    path: '/sale/sale-order-return/create',
    shortenUrl: 'sale-order-return/create',
  },
  update: {
    key: ORDER_RETURN_PERMISSION_KEY.UPDATE,
    path: '/sale/sale-order-return/update',
    shortenUrl: 'sale-order-return/update/:id',
  },
  detail: {
    key: ORDER_RETURN_PERMISSION_KEY.VIEW_LIST,
    path: '/sale/sale-order-return/detail',
    shortenUrl: 'sale-order-return/detail/:id',
  },
};
