import menu from '../component/Layout/Menu/menu.json';
import { useStores } from './useStores';

const useSubMenu = () => {
  const {
    commonStore: { activeMenu },
  } = useStores();

  const menus = menu.parentMenu?.filter(
    (item) => item?.parentKey === activeMenu,
  );

  return menus;
};

export default useSubMenu;
