import { useMutation, useQuery } from '@tanstack/react-query';
import NoData from '_common/component/NoData';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { DATE_BACKEND_FORMAT, TIME_FORMAT } from '_common/constants/common';
import {
  messageValidate,
  messageValidateLength,
  messageWarning,
} from '_common/constants/message';
import DatePicker from '_common/dof/Control/DatePicker';
import Image from '_common/dof/Control/Image';
import RangeTime from '_common/dof/Control/RangeTime';
import TextArea from '_common/dof/Control/TextArea';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectInvestment from '_common/dof/Select/SelectInvestment';
import useConfirm from '_common/hooks/useConfirm';
import moment from 'moment';
import InvestmentApi from 'pages/Invest/Invesment/api';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { formatDate, Modal, notification, Row, Spin } from 'tera-dls';
import TimekeepingApi from '../../api';
import Input from '_common/dof/Control/Input';

interface TimekeepingFormProps {
  id: string | number;
  open: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
}

function TimekeepingForm(props: TimekeepingFormProps) {
  const { id, open, onClose, onSuccess } = props;
  const form = useForm({ mode: 'onChange' });
  const confirm = useConfirm();
  const { isDirty } = form.formState;
  const invest_id = form.watch('invest_id');
  const TIME_FORMAT_BACKEND = 'YYYY-MM-DD HH:mm:ss';

  const { data: dataInvest } = useQuery(
    ['get-investment-detail', invest_id],
    () => InvestmentApi.getDetail({ id: invest_id }),
    {
      enabled: !!invest_id,
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const {
    data: dataDetails,
    refetch,
    isLoading,
    isError,
  } = useQuery(
    ['get-timekeeping-detail', id],
    () => TimekeepingApi.getDetail({ id }),
    {
      enabled: !!id,
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const { mutate: mutateTimekeeping, isLoading: isLoadingMutate } = useMutation(
    (params: any) => {
      if (id) return TimekeepingApi.update({ id, params });
      return TimekeepingApi.create({ params });
    },
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          onClose && onClose();
          onSuccess && onSuccess();
        }
      },
      onError: (error: any) => {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleCloseConfirm = () => {
    if (isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>{messageWarning.WARNING_EXIT_1}</p>
            <p>{messageWarning.WARNING_EXIT_2}</p>
          </>
        ),
        onOk: () => {
          onClose();
        },
      });
    } else onClose();
  };

  const handleSubmitForm = (values) => {
    if ((isLoading && !!id) || isLoadingMutate) return;
    const data = {
      ...values,
      check_in_time:
        values?.check_in_time &&
        moment(values?.check_in_time).format(TIME_FORMAT_BACKEND),
      check_out_time:
        values?.check_out_time &&
        moment(values?.check_out_time).format(TIME_FORMAT_BACKEND),
      working_date:
        values?.working_date &&
        moment(values?.working_date).format(DATE_BACKEND_FORMAT),
    };

    mutateTimekeeping(data);
  };

  useEffect(() => {
    if (dataDetails) {
      const data = {
        invest_id: dataDetails?.invest_id,
        device_id: dataDetails?.device_id,
        check_in_image: dataDetails?.check_in_image,
        check_out_image: dataDetails?.check_out_image,
        description: dataDetails?.description,
        note: dataDetails?.note,
        working_date:
          dataDetails?.working_date && moment(dataDetails?.working_date),
        check_in_time:
          dataDetails?.check_in_time &&
          moment(
            formatDate(dataDetails?.check_in_time, TIME_FORMAT),
            TIME_FORMAT,
          ),
        check_out_time:
          dataDetails?.check_out_time &&
          moment(
            formatDate(dataDetails?.check_out_time, TIME_FORMAT),
            TIME_FORMAT,
          ),
      };

      form.reset(data);
    }
  }, [dataDetails]);

  useEffect(() => {
    if (dataInvest) {
      form.setValue('device_id', dataInvest?.device_id);
      form.setValue('device', dataInvest?.device?.full_name);
    }
  }, [dataInvest]);

  useEffect(() => {
    if (id) refetch();
  }, [id]);

  const disabledDate = (current) => {
    return current && current >= moment().startOf('day');
  };

  return (
    <Modal
      title={id ? 'Sửa chấm công' : 'Thêm chấm công'}
      destroyOnClose
      closeIcon={false}
      cancelText="Hủy"
      okText="Đồng ý"
      onOk={() => form.handleSubmit(handleSubmitForm)()}
      onCancel={handleCloseConfirm}
      open={open}
      centered={true}
      className="sm:w-[500px]"
      confirmLoading={(isLoading && !!id) || isLoadingMutate}
    >
      <Spin spinning={(isLoading && !!id) || isLoadingMutate}>
        {isError && !!id ? (
          <NoData />
        ) : (
          <FormTera form={form}>
            <Row className="grid-cols-2">
              <FormTeraItem
                label="Hình ảnh check in"
                name="check_in_image"
                rules={[
                  {
                    required: messageValidate.emptySelect,
                  },
                ]}
              >
                <Image folder="timekeeping" object_key="timekeeping" />
              </FormTeraItem>
              <FormTeraItem
                label="Hình ảnh check out"
                name="check_out_image"
                rules={[
                  {
                    required: messageValidate.emptySelect,
                  },
                ]}
              >
                <Image folder="timekeeping" object_key="timekeeping" />
              </FormTeraItem>
            </Row>
            <FormTeraItem
              label="Đầu tư"
              name="invest_id"
              rules={[
                {
                  required: messageValidate.emptySelect,
                },
              ]}
            >
              <SelectInvestment
                allowClear={false}
                paramsApi={{
                  include_id: form.watch('invest_id'),
                  isActive: true,
                  is_device: false,
                }}
                disabled={!!id}
              />
            </FormTeraItem>
            <FormTeraItem label="Tên thiết bị" name="device">
              <Input disabled={true} />
            </FormTeraItem>
            <FormTeraItem
              label="Ngày làm việc"
              name="working_date"
              rules={[
                {
                  required: messageValidate.emptySelect,
                },
              ]}
            >
              <DatePicker disabled={!!id} disabledDate={disabledDate} />
            </FormTeraItem>

            <RangeTime
              startFormName="check_in_time"
              endFormName="check_out_time"
              // startTimeRuleProps={{ isEqual: false }}
              // endTimeRuleProps={{ isEqual: false }}
              endFormItemProps={{
                label: 'Thời gian ra ca',
                rules: [
                  {
                    required: messageValidate.emptySelect,
                  },
                ],
              }}
              startFormItemProps={{
                label: 'Thời gian vào ca',
                rules: [
                  {
                    required: messageValidate.emptySelect,
                  },
                ],
              }}
            />

            <FormTeraItem
              label="Mô tả"
              name="description"
              rules={[
                {
                  maxLength: {
                    value: 1000,
                    message: messageValidateLength.textArea,
                  },
                },
              ]}
            >
              <TextArea rows={5} />
            </FormTeraItem>
            <FormTeraItem
              label="Ghi chú"
              name="note"
              rules={[
                {
                  maxLength: {
                    value: 1000,
                    message: messageValidateLength.textArea,
                  },
                },
              ]}
            >
              <TextArea rows={5} />
            </FormTeraItem>
          </FormTera>
        )}
      </Spin>
    </Modal>
  );
}

export default TimekeepingForm;
