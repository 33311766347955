import { INVESTMENT_PERMISSION_KEY } from '_common/constants/permission';

export const INVESTMENT_URL = {
  list: {
    key: INVESTMENT_PERMISSION_KEY.VIEW_LIST,
    path: '/investor/investment/list',
    shortenUrl: 'investment/list',
  },
  update: {
    key: INVESTMENT_PERMISSION_KEY.UPDATE,
    path: '/investor/investment/update',
    shortenUrl: 'investment/update/:id',
  },
  detail: {
    key: INVESTMENT_PERMISSION_KEY.VIEW_DETAIL,
    path: '/investor/investment/detail',
    shortenUrl: 'investment/detail/:id',
  },
};
