import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const DeviceEndpoint = `${endpoint}/portal/device`;
const AuthEndpoint = `${endpoint}/auth`;

const DeviceApi = {
  getList: async ({ params }) =>
    await api
      .get(`${DeviceEndpoint}/list`, params)
      .then((res) => res?.data?.data),
  getDetail: async ({ id }) =>
    await api
      .get(`${DeviceEndpoint}/detail/${id}`)
      .then((res) => res?.data?.data),
  getSummary: async ({ params }) =>
    await api
      .get(`${DeviceEndpoint}/summary-list`, params)
      .then((res) => res.data?.data),
  create: async ({ params }) =>
    await api.post(`${DeviceEndpoint}/create`, params).then((res) => res?.data),
  update: async ({ id, params }) =>
    await api
      .put(`${DeviceEndpoint}/update/${id}`, params)
      .then((res) => res?.data),
  resetPassword: async ({ params }) =>
    await api
      .put(`${AuthEndpoint}/change-password`, params)
      .then((res) => res?.data),
  delete: async ({ id }) =>
    await api.delete(`${DeviceEndpoint}/delete/${id}`).then((res) => res?.data),
};

export default DeviceApi;
