import { useQuery } from '@tanstack/react-query';
import DefaultImage from '_common/component/DefaultImage';
import HoverQuickView from '_common/component/HoverQuickView';
import NoData from '_common/component/NoData';
import { DATE_TIME_FORMAT, GENDER } from '_common/constants/common';
import { useEffect } from 'react';
import { Col, formatDate, Modal, Row, Spin, Tag } from 'tera-dls';
import UserApi from '../../api';
import { TAB_ITEMS_STATUS, TAB_ITEMS_STATUS_COLOR } from '../../constants';

interface UserDetailProps {
  id: number;
  open: boolean;
  onCancel: () => void;
}

function UserDetail({ id, open, onCancel }: UserDetailProps) {
  const {
    data: dataDetails,
    refetch,
    isLoading,
    isError,
  } = useQuery(['get-user-detail', id], () => UserApi.getDetail({ id }), {
    enabled: !!id,
    staleTime: 300000,
    cacheTime: 300000,
  });

  const detail = [
    {
      label: 'Ảnh đại diện',
      value: (
        <DefaultImage
          src={dataDetails?.avatar}
          alt={dataDetails?.avatar}
          className="w-[100px] h-[100px] rounded-[10px] overflow-hidden object-cover"
        />
      ),
    },
    {
      label: 'Họ và tên',
      value: dataDetails?.full_name,
    },
    {
      label: 'Ngày sinh',
      value: formatDate(dataDetails?.birthday),
    },
    {
      label: 'Giới tính',
      value: dataDetails?.gender && GENDER[dataDetails?.gender],
    },
    {
      label: 'Số điện thoại',
      value: dataDetails?.phone,
    },
    {
      label: 'Email',
      value: dataDetails?.email,
    },
    {
      label: 'Địa chỉ',
      value: dataDetails?.address,
    },
  ];

  const user = [
    {
      label: 'Tên đăng nhập',
      value: dataDetails?.username,
    },
    {
      label: 'Phân quyền',
      value: dataDetails?.role?.name,
    },
    {
      label: 'Trạng thái',
      value: (
        <Tag color={TAB_ITEMS_STATUS_COLOR[dataDetails?.status]}>
          {TAB_ITEMS_STATUS[dataDetails?.status]}
        </Tag>
      ),
    },
    {
      label: 'Người tạo',
      value: (
        <HoverQuickView
          avatarUrl={dataDetails?.created_by?.avatar}
          name={dataDetails?.created_by?.full_name}
          code={dataDetails?.created_by?.code}
          email={dataDetails?.created_by?.email}
          phone={dataDetails?.created_by?.phone}
        >
          {dataDetails?.created_by?.full_name}
        </HoverQuickView>
      ),
    },
    {
      label: 'Ngày tạo',
      value:
        dataDetails?.created_at &&
        formatDate(dataDetails?.created_at, DATE_TIME_FORMAT),
    },
    {
      label: 'Người cập nhật',
      value: (
        <HoverQuickView
          avatarUrl={dataDetails?.updated_by?.avatar}
          name={dataDetails?.updated_by?.full_name}
          code={dataDetails?.updated_by?.code}
          email={dataDetails?.updated_by?.email}
          phone={dataDetails?.updated_by?.phone}
        >
          {dataDetails?.updated_by?.full_name}
        </HoverQuickView>
      ),
    },
    {
      label: 'Ngày cập nhật',
      value:
        dataDetails?.updated_by &&
        formatDate(dataDetails?.updated_at, DATE_TIME_FORMAT),
    },
  ];

  useEffect(() => {
    refetch();
  }, []);

  return (
    <Modal
      title="Chi tiết người dùng"
      destroyOnClose
      closeIcon={false}
      cancelText="Đóng"
      onCancel={onCancel}
      open={open}
      centered={true}
      className="sm:w-[1000px]"
      okButtonProps={{ className: 'hidden' }}
    >
      <Spin spinning={isLoading && !!id}>
        {isError && !!id ? (
          <NoData />
        ) : (
          <Row className="grid-cols-2 gap-x-2.5">
            <Col>
              <p className="mb-2.5 text-blue-600 font-medium">
                Thông tin người dùng
              </p>
              <div className="flex flex-col gap-y-2.5">
                {detail.map((item, index) => (
                  <div className="flex items-start" key={index}>
                    <div className="detail-key">{item.label}</div>
                    <div className="detail-value">{item.value}</div>
                  </div>
                ))}
              </div>
            </Col>
            <Col>
              <p className="mb-2.5 text-blue-600 font-medium">
                Thông tin đăng nhập
              </p>
              <div className="flex flex-col gap-y-2.5">
                {user.map((item, index) => (
                  <div className="flex items-start" key={index}>
                    <div className="detail-key">{item.label}</div>
                    <div className="detail-value">{item.value}</div>
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        )}
      </Spin>
    </Modal>
  );
}

export default UserDetail;
