import { IRouteProps } from '_common/interface/router';
import { SALES_EXPENSES_URL } from './url';
import SalesExpenses from '.';
import SalesExpensesDetail from './containers/Detail';
import SalesExpensesForm from './containers/Form';

export const SalesExpensesRouter: IRouteProps[] = [
  {
    key: SALES_EXPENSES_URL.list.key,
    path: SALES_EXPENSES_URL.list.shortenUrl,
    component: <SalesExpenses />,
  },
  {
    key: SALES_EXPENSES_URL.create.key,
    path: SALES_EXPENSES_URL.create.shortenUrl,
    component: <SalesExpensesForm />,
  },
  {
    key: SALES_EXPENSES_URL.update.key,
    path: SALES_EXPENSES_URL.update.shortenUrl,
    component: <SalesExpensesForm />,
  },
  {
    key: SALES_EXPENSES_URL.detail.key,
    path: SALES_EXPENSES_URL.detail.shortenUrl,
    component: <SalesExpensesDetail />,
  },
];
