import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ModalChangePassword from '_common/component/ModalChangePassword';
import NoData from '_common/component/NoData';
import SumiTab from '_common/component/Sumi/Tab';
import { IconButton } from '_common/component/TableColumnCustom';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import { INVESTOR_PERMISSION_KEY } from '_common/constants/permission';
import useConfirm from '_common/hooks/useConfirm';
import { usePermission } from '_common/hooks/usePermission';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ArrowSmallLeftOutlined,
  Breadcrumb,
  Button,
  DropdownItem,
  ItemType,
  notification,
  PencilSquareOutlined,
  Spin,
  TabItemType,
  XMarkOutlined,
} from 'tera-dls';
import InvestorApi from '../../api';
import { INVESTOR_URL } from '../../url';
import InvestorForm from '../Form';
import Information from './Information';
import Invested from './Invested';
import Trial from './Trial';

function InvestorDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const confirm = useConfirm();
  const queryClient = useQueryClient();
  const { hasPage } = usePermission();

  const [openForm, setOpenForm] = useState<boolean>(false);
  const [openResetPassword, setOpenResetPassword] = useState<boolean>(false);
  const [activeKey, setActiveKey] = useState<string>('information');

  const {
    data: dataDetails,
    refetch,
    isLoading,
    isError,
  } = useQuery(
    ['get-investor-detail', id],
    () => InvestorApi.getDetail({ id }),
    {
      enabled: !!id,
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const { mutate: mutateDelete, isLoading: loadingDelete } = useMutation(
    (id: any) => InvestorApi.delete({ id }),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          navigate(-1);
          queryClient.invalidateQueries(['get-investor-list']);
          queryClient.invalidateQueries(['get-summary-investor-list']);
        }
      },
      onError: (error: any) => {
        notification.error({
          message: error?.message,
        });
      },
    },
  );

  const { mutate: mutateChangeStatus, isLoading: loadingChangeStatus } =
    useMutation((params: any) => InvestorApi.changeStatus({ params }), {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries(['get-investor-list']);
          queryClient.invalidateQueries(['get-summary-investor-list']);
          refetch();
        }
      },
      onError: (error: any) => {
        notification.error({
          message: error?.message,
        });
      },
    });

  const handleChangeStatus = () => {
    const text = dataDetails?.status == 1 ? 'ngưng' : 'mở';
    confirm.warning({
      title: `Xác nhận ${text} hoạt động nhà đầu tư`,
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn {text} hoạt động nhà đầu tư</p>
          <p>
            <b>{dataDetails?.investor_name}</b> này không?
          </p>
        </div>
      ),
      onOk: () => {
        const params = {
          id: dataDetails?.id,
          status: dataDetails?.status == 1 ? 0 : 1,
        };
        mutateChangeStatus(params);
      },
    });
  };

  const handleDelete = () => {
    confirm.warning({
      title: 'Xác nhận xóa nhà đầu tư',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn xóa nhà đầu tư</p>
          <p>
            <b>{dataDetails?.investor_name}</b> này không?
          </p>
        </div>
      ),
      onOk: () => {
        mutateDelete(dataDetails?.id);
      },
    });
  };

  const BreadcrumbItem: ItemType[] = [
    {
      title: (
        <a
          onClick={() => navigate(INVESTOR_URL.list.path)}
          className="!text-blue-400 hover:!text-blue-600"
        >
          Danh sách nhà đầu tư
        </a>
      ),
    },
    {
      title: 'Chi tiết nhà đầu tư',
    },
  ];

  const items: DropdownItem[] = [
    ...(hasPage(INVESTOR_PERMISSION_KEY.CHANGE_STATUS)
      ? [
          {
            key: 'status',
            label: dataDetails?.status == 1 ? 'Ngưng hoạt động' : 'Hoạt động',
            onClick: handleChangeStatus,
            disabled: isError || isLoading,
          },
        ]
      : []),
    ...(hasPage(INVESTOR_PERMISSION_KEY.CHANGE_PASSWORD)
      ? [
          {
            key: 'reset_password',
            label: 'Đổi mật khẩu',
            onClick: () => setOpenResetPassword(true),
            disabled: isError || isLoading,
          },
        ]
      : []),
  ];

  const renderTab = {
    information: <Information dataDetails={dataDetails} />,
    invested: <Invested id={dataDetails?.id} />,
    trial: <Trial id={dataDetails?.id} />,
  };

  const itemsTab: TabItemType[] = [
    {
      key: 'information',
      label: 'Thông tin chi tiểt',
      disabled: isError,
    },
    {
      key: 'invested',
      label: 'Đã đầu tư',
      disabled: isError,
    },
    {
      key: 'trial',
      label: 'Dùng thử',
      disabled: isError,
    },
  ];

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="tera-page-form">
      <div className="page-header-sticky">
        <div className="page-header-v2">
          <div className="page-header-v2__breadcrumb">
            <IconButton
              icon={<ArrowSmallLeftOutlined />}
              callBack={() => navigate(-1)}
            />
            <Breadcrumb separator="/" items={BreadcrumbItem} />
          </div>
          <div className="page-header-v2__function">
            {hasPage(INVESTOR_PERMISSION_KEY.UPDATE) && (
              <Button
                className="page-header-v2-btn"
                prefix={<PencilSquareOutlined />}
                onClick={() => setOpenForm(true)}
                disabled={isError || isLoading}
              >
                Sửa
              </Button>
            )}

            {hasPage(INVESTOR_PERMISSION_KEY.DELETE) &&
              !dataDetails?.status && (
                <Button
                  className="page-header-v2-btn"
                  type="danger"
                  prefix={<XMarkOutlined />}
                  onClick={handleDelete}
                  disabled={isError || isLoading}
                >
                  Xóa
                </Button>
              )}
            {(hasPage(INVESTOR_PERMISSION_KEY.CHANGE_PASSWORD) ||
              hasPage(INVESTOR_PERMISSION_KEY.CHANGE_STATUS)) && (
              <ActionDropdown
                dropdownItems={items}
                trigger="click"
                buttonClassNames="py-[6px] px-[6px] rounded"
              />
            )}
          </div>
        </div>
      </div>
      <Spin spinning={isLoading || loadingDelete || loadingChangeStatus}>
        <div className="page-content-v2">
          <div className="bg-white rounded-[10px] px-2.5 pb-5 shadow">
            <SumiTab
              className="mb-2.5"
              items={itemsTab}
              activeKey={activeKey}
              onChange={setActiveKey}
            />
            {isError ? <NoData /> : renderTab[activeKey]}
          </div>
        </div>
      </Spin>
      {openForm && (
        <InvestorForm
          open={openForm}
          id={id}
          onClose={() => setOpenForm(false)}
          onSuccess={refetch}
        />
      )}
      {openResetPassword && (
        <ModalChangePassword
          userId={dataDetails?.user_id}
          open={openResetPassword}
          onClose={() => setOpenResetPassword(false)}
        />
      )}
    </div>
  );
}

export default InvestorDetail;
