import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const OrderEndpoint = `${endpoint}/portal`;

const PortalApi = {
  print: async ({ params }) =>
    await api
      .post(`${OrderEndpoint}/import-export/export-system`, params)
      .then((res) => res.data),
};

export default PortalApi;
