import Select from '_common/dof/Control/Select';
import { SelectProps } from 'tera-dls';

export const INVESTMENT_CATEGORY = {
  location: 'Địa điểm',
  vehicle: 'Xe',
  kiot: 'KIOT',
  bakery: 'Bakery',
};

function SelectInvestmentCategory(props: SelectProps) {
  const optionsInvestmentCategory = Object.entries(INVESTMENT_CATEGORY).map(
    ([value, label]) => ({
      value,
      label,
    }),
  );

  return <Select options={optionsInvestmentCategory} allowClear {...props} />;
}

export default SelectInvestmentCategory;
