import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import NoData from '_common/component/NoData';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import {
  DATE_BACKEND_FORMAT,
  DATE_FORMAT,
  REGEX,
} from '_common/constants/common';
import {
  messageValidate,
  messageValidateLength,
  messageWarning,
} from '_common/constants/message';
import { INVESTOR_PERMISSION_KEY } from '_common/constants/permission';
import CheckBox from '_common/dof/Control/CheckBox';
import DatePicker from '_common/dof/Control/DatePicker';
import Image from '_common/dof/Control/Image';
import Input from '_common/dof/Control/Input';
import InputPassword from '_common/dof/Control/InputPassword';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import useConfirm from '_common/hooks/useConfirm';
import { usePermission } from '_common/hooks/usePermission';
import moment from 'moment';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { DatePickerProps, Modal, notification, Row, Spin } from 'tera-dls';
import InvestorApi from '../../api';

interface InvestorFormProps {
  id: string | number;
  open: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
}

function InvestorForm(props: InvestorFormProps) {
  const { id, open, onClose, onSuccess } = props;
  const form = useForm({ mode: 'onChange', defaultValues: { status: true } });
  const confirm = useConfirm();
  const { isDirty } = form.formState;
  const queryClient = useQueryClient();
  const { hasPage } = usePermission();

  const {
    data: dataDetails,
    refetch,
    isLoading,
    isError,
  } = useQuery(
    ['get-investor-detail', id],
    () => InvestorApi.getDetail({ id }),
    {
      enabled: !!id,
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const { mutate: mutateInvestor, isLoading: isLoadingMutate } = useMutation(
    (params: any) => {
      if (id) return InvestorApi.update({ id, params });
      return InvestorApi.create({ params });
    },
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          onClose();
          queryClient.invalidateQueries(['get-investor-list']);
          queryClient.invalidateQueries(['get-summary-investor-list']);
          onSuccess && onSuccess();
        }
      },
      onError: (error: any) => {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleCloseConfirm = () => {
    if (isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>{messageWarning.WARNING_EXIT_1}</p>
            <p>{messageWarning.WARNING_EXIT_2}</p>
          </>
        ),
        onOk: () => {
          onClose();
        },
      });
    } else onClose();
  };

  const handleSubmitForm = (value) => {
    if ((isLoading && !!id) || isLoadingMutate) return;
    const data = {
      ...value,
      status: value?.status ? 1 : 0,
      birthday:
        value?.birthday && moment(value?.birthday).format(DATE_BACKEND_FORMAT),
      file_upload: value?.file_upload,
    };
    mutateInvestor(data);
  };

  const disabledDate: DatePickerProps['disabledDate'] = (current) => {
    const today = moment();
    return current && current > today;
  };

  useEffect(() => {
    if (dataDetails) {
      const initData = {
        name: dataDetails?.investor_name,
        phone: dataDetails?.phone,
        email: dataDetails?.email,
        address: dataDetails?.address,
        birthday: dataDetails?.birthday && moment(dataDetails?.birthday),
        status: dataDetails?.status == 1 ? true : false,
        file_upload: dataDetails?.file_upload,
      };
      form.reset(initData);
    }
  }, [dataDetails]);

  useEffect(() => {
    if (id) refetch();
  }, [id]);

  return (
    <Modal
      title={id ? 'Sửa nhà đầu tư' : 'Thêm nhà đầu tư'}
      destroyOnClose
      closeIcon={false}
      cancelText="Hủy"
      okText="Đồng ý"
      onOk={() => form.handleSubmit(handleSubmitForm)()}
      onCancel={handleCloseConfirm}
      open={open}
      centered={true}
      className="sm:w-[90%] xl:w-[1000px]"
      confirmLoading={(isLoading && !!id) || isLoadingMutate}
    >
      <Spin spinning={(isLoading && !!id) || isLoadingMutate}>
        {isError && !!id ? (
          <NoData />
        ) : (
          <FormTera form={form}>
            <Row className="grid grid-cols-2 gap-x-4 gap-y-0">
              <FormTeraItem
                label="Ảnh đại diện"
                className="flex flex-col col-span-2"
                name="file_upload"
              >
                <Image folder="system" object_key="system" />
              </FormTeraItem>
              <FormTeraItem
                label="Tên nhà đầu tư"
                name="name"
                rules={[
                  {
                    required: messageValidate.emptyText,
                    maxLength: {
                      value: 255,
                      message: messageValidateLength.textLong,
                    },
                  },
                ]}
              >
                <Input />
              </FormTeraItem>
              <FormTeraItem
                label="Số điện thoại"
                name="phone"
                rules={[
                  {
                    required: messageValidate.emptyText,
                    pattern: {
                      value: new RegExp(REGEX.PHONE_NUMBER),
                      message: messageValidate.phone,
                    },
                  },
                ]}
              >
                <Input disabled={!!id} />
              </FormTeraItem>
              <FormTeraItem
                label="Địa chỉ"
                name="address"
                rules={[
                  {
                    maxLength: {
                      value: 255,
                      message: messageValidateLength.textLong,
                    },
                  },
                ]}
              >
                <Input />
              </FormTeraItem>
              <FormTeraItem
                label="Email"
                name="email"
                rules={[
                  {
                    pattern: {
                      value: new RegExp(REGEX.EMAIL),
                      message: messageValidate.email,
                    },
                    maxLength: {
                      value: 100,
                      message: messageValidateLength.text,
                    },
                  },
                ]}
              >
                <Input type="confirm-password" />
              </FormTeraItem>
              {!id && (
                <FormTeraItem
                  label="Mật khẩu"
                  name="password"
                  rules={[
                    {
                      required: messageValidate.emptyText,
                      pattern: {
                        value: new RegExp(REGEX.PASSWORD),
                        message: messageValidate.password,
                      },
                      minLength: {
                        value: 8,
                        message: messageValidate.min_length_password,
                      },
                      maxLength: {
                        value: 16,
                        message: messageValidate.max_length_password,
                      },
                    },
                  ]}
                >
                  <InputPassword />
                </FormTeraItem>
              )}
              <FormTeraItem label="Ngày sinh" name="birthday">
                <DatePicker format={DATE_FORMAT} disabledDate={disabledDate} />
              </FormTeraItem>

              {id && !hasPage(INVESTOR_PERMISSION_KEY.CHANGE_STATUS) ? (
                <></>
              ) : (
                <FormTeraItem name="status" className="col-span-2">
                  <CheckBox labelClassName="font-normal">Hoạt động</CheckBox>
                </FormTeraItem>
              )}
            </Row>
          </FormTera>
        )}
      </Spin>
    </Modal>
  );
}

export default InvestorForm;
