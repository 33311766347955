import classNames from 'classnames';
import { useRef } from 'react';
import { ChartBar, ChartBarProps } from 'tera-dls';

export type HorizontalBarChartType = 'small' | 'large';
interface IData {
  labels: Array<string>;
  datasets: any;
}

interface IProps {
  data: IData;
  type?: HorizontalBarChartType;
  options?: ChartBarProps['options'];
  chartClassName?: string;
  defaultShowTickLength?: number;
  defaultLabelMaxLength?: number;
  wrapperClassName?: string;
  containerClassName?: string;
  maxY?: number;
  plugins?: any;
}

const HorizontalBarChart = (props: IProps) => {
  const {
    type = 'small',
    data,
    chartClassName,
    options,
    defaultLabelMaxLength = 15,
    wrapperClassName = '',
    containerClassName = '',
    maxY,
    plugins,
  } = props;
  const { labels } = data ?? {};
  const chartRef = useRef<any>(null);

  const isSmallType = type === 'small';

  const mainOptions: any = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
      },
      // datalabels: {
      //   display: displayTick,
      //   color: 'black',
      //   formatter: (val, context) => {
      //     if (val !== null && typeof val === 'object') {
      //       return formatNumber(
      //         val?.[context?.dataset?.parsing?.yAxisKey] || 0,
      //       );
      //     }
      //     return formatNumber(val);
      //   },
      //   anchor: 'end',
      //   offset: -20,
      //   align: 'start',
      // },
      ...plugins,
    },
    scales: {
      x: {
        ticks: {
          display: true,
          maxRotation: 45,
          minRotation: 0,
          callback: (_, index) => {
            const label = labels[index];
            return label?.length > defaultLabelMaxLength
              ? label.substring(0, defaultLabelMaxLength) + '...'
              : label;
          },
        },
        beginAtZero: true,
      },
      y: {
        ...(maxY && { max: maxY }),
        beginAtZero: true,
      },
    },
    maintainAspectRatio: false,
    ...options,
  };

  const renderChart = () => (
    <ChartBar
      id={'chart-Test'}
      ref={chartRef}
      className={chartClassName}
      data={data}
      options={mainOptions}
      // plugins={['data-labels']}
    />
  );
  return (
    <>
      <div className={classNames('w-full h-full ', containerClassName)}>
        {isSmallType ? (
          <div className={wrapperClassName}>{renderChart()}</div>
        ) : (
          <>
            <div className={classNames('h-full', wrapperClassName)}>
              {renderChart()}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default HorizontalBarChart;
