import { useQuery } from '@tanstack/react-query';
import NoData from '_common/component/NoData';
import {
  DATE_TIME_FORMAT,
  TAB_ITEMS_STATUS,
  TAB_ITEMS_STATUS_COLOR,
} from '_common/constants/common';
import { useEffect } from 'react';
import { formatCurrency, formatDate, Image, Modal, Spin, Tag } from 'tera-dls';
import EmployeeApi from '../../api';
import { INVESTMENT_TYPE } from '../../constants';
import HoverQuickView from '_common/component/HoverQuickView';

interface InvestmentTypeDetailProps {
  id: number;
  open: boolean;
  onCancel: () => void;
}

function InvestmentTypeDetail({
  id,
  open,
  onCancel,
}: InvestmentTypeDetailProps) {
  const {
    data: dataDetails,
    refetch,
    isLoading,
    isError,
  } = useQuery(
    ['get-employee-detail', id],
    () => EmployeeApi.getDetail({ id }),
    {
      enabled: !!id,
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const detail = [
    {
      label: 'Mã loại hình đầu tư',
      value: dataDetails?.code,
    },
    {
      label: 'Tên loại hình đầu tư',
      value: dataDetails?.name,
    },
    {
      label: 'Giá bán',
      value: dataDetails?.price && formatCurrency(dataDetails?.price),
    },
    {
      label: 'Loại đầu tư',
      value: dataDetails?.type && INVESTMENT_TYPE?.[dataDetails?.type],
    },
    {
      label: 'Trạng thái',
      value: (
        <Tag color={TAB_ITEMS_STATUS_COLOR[dataDetails?.status]}>
          {TAB_ITEMS_STATUS[dataDetails?.status]}
        </Tag>
      ),
    },
    {
      label: 'Người tạo',
      value: (
        <HoverQuickView
          avatarUrl={dataDetails?.created_by?.avatar}
          name={dataDetails?.created_by?.full_name}
          code={dataDetails?.created_by?.code}
          email={dataDetails?.created_by?.email}
          phone={dataDetails?.created_by?.phone}
        >
          {dataDetails?.created_by?.full_name}
        </HoverQuickView>
      ),
    },
    {
      label: 'Ngày tạo',
      value:
        dataDetails?.created_at &&
        formatDate(dataDetails?.created_at, DATE_TIME_FORMAT),
    },
    {
      label: 'Người cập nhật',
      value: (
        <HoverQuickView
          avatarUrl={dataDetails?.updated_by?.avatar}
          name={dataDetails?.updated_by?.full_name}
          code={dataDetails?.updated_by?.code}
          email={dataDetails?.updated_by?.email}
          phone={dataDetails?.updated_by?.phone}
        >
          {dataDetails?.updated_by?.full_name}
        </HoverQuickView>
      ),
    },
    {
      label: 'Ngày cập nhật',
      value:
        dataDetails?.updated_by &&
        formatDate(dataDetails?.updated_at, DATE_TIME_FORMAT),
    },
  ];

  useEffect(() => {
    refetch();
  }, []);

  return (
    <Modal
      title="Chi tiết loại hình đầu tư"
      destroyOnClose
      closeIcon={false}
      cancelText="Đóng"
      onCancel={onCancel}
      open={open}
      centered={true}
      className="sm:w-[500px]"
      okButtonProps={{ className: 'hidden' }}
    >
      <Spin spinning={isLoading && !!id}>
        {isError && !!id ? (
          <NoData />
        ) : (
          <div className="flex flex-col gap-y-2.5">
            {dataDetails?.file_upload && (
              <Image
                src={dataDetails?.file_upload}
                alt={dataDetails?.file_upload}
                containerClassName="w-[100px] h-[100px] rounded-[10px] overflow-hidden m-auto"
              />
            )}
            {detail.map((item, index) => (
              <div className="flex items-start" key={index}>
                <div className="detail-key">{item.label}</div>
                <div className="detail-value">{item.value}</div>
              </div>
            ))}
          </div>
        )}
      </Spin>
    </Modal>
  );
}

export default InvestmentTypeDetail;
