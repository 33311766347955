import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import HeaderViewList from '_common/component/HeaderViewList';
import TableTera from '_common/dof/TableTera';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  DropdownItem,
  getQueryParams,
  notification,
  PaginationProps,
  updateQueryParams,
} from 'tera-dls';

import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import useConfirm from '_common/hooks/useConfirm';
import UnitApi from './api';
import UnitDetail from './containers/Detail';
import UnitForm from './containers/Form';
import UnitSearch from './containers/Search';
import { UNIT_URL } from './url';
import { PRODUCT_UNIT_PERMISSION_KEY } from '_common/constants/permission';
import { usePermission } from '_common/hooks/usePermission';

function Unit() {
  const [openForm, setOpenForm] = useState({ open: false, id: null });
  const [openDetail, setOpenDetail] = useState({ open: false, id: null });

  const navigate = useNavigate();
  const confirm = useConfirm();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;
  const queryClient = useQueryClient();
  const { hasPage } = usePermission();

  const handleUpdateQueryParams = (data) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(`${UNIT_URL.list.path}${paramString}`);
  };

  const {
    data: unitList,
    isLoading,
    refetch,
  } = useQuery(
    ['get-unit-list', queryParams],
    () => {
      const params = {
        ...queryParams,
        page: queryParams?.page || 1,
        limit: queryParams?.limit || 10,
      };
      return UnitApi.getList({ params });
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
      onSuccess: (data) => {
        if (Number(data?.last_page) < (Number(queryParams?.page) || 1)) {
          handleUpdateQueryParams({ page: data?.last_page });
        }
      },
    },
  );

  const { mutate: mutateDelete, isLoading: loadingDelete } = useMutation(
    (id: any) => UnitApi.delete({ id }),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries(['get-unit-list']);
        }
      },
      onError: (error: any) => {
        notification.error({
          message: error?.message,
        });
      },
    },
  );

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    const isDiffPageSize = Number(pageSize) !== Number(unitList?.limit || 10);

    handleUpdateQueryParams({
      page: isDiffPageSize ? 1 : page,
      limit: pageSize,
    });
  };

  const handleSearch = (value) => {
    handleUpdateQueryParams({ keyword: value?.keyword, page: 1 });
  };

  const handleDelete = (record) => {
    confirm.warning({
      title: 'Xác nhận xóa đơn vị',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn xóa đơn vị</p>
          <p>
            <b>{record?.name}</b> này không?
          </p>
        </div>
      ),
      onOk: () => {
        mutateDelete(record?.id);
      },
    });
  };

  const renderItemActions = (record) => {
    const items: DropdownItem[] = [];
    if (hasPage(PRODUCT_UNIT_PERMISSION_KEY.VIEW_DETAIL))
      items.push({
        key: '1',
        label: 'Xem',
        onClick: () => setOpenDetail({ open: true, id: record?.id }),
      });
    if (hasPage(PRODUCT_UNIT_PERMISSION_KEY.UPDATE))
      items.push({
        key: '2',
        label: 'Sửa',
        onClick: () => setOpenForm({ open: true, id: record?.id }),
      });
    if (hasPage(PRODUCT_UNIT_PERMISSION_KEY.DELETE))
      items.push({
        key: '3',
        label: <span className="text-red-500">Xóa</span>,
        onClick: () => handleDelete(record),
      });
    return items;
  };

  const columns: any = [
    {
      title: 'Tên đơn vị',
      dataIndex: 'name',
      width: 500,
      render: (name, record) => (
        <p
          className="line-clamp-2 break-word text-blue-600 cursor-pointer"
          onClick={() => setOpenDetail({ open: true, id: record?.id })}
        >
          {name}
        </p>
      ),
    },
    {
      title: 'Tên viết tắt',
      dataIndex: 'short_name',
      width: 500,
      render: (text) => <p className="line-clamp-2">{text}</p>,
    },
    {
      width: 60,
      fixed: 'right',
      render: (record) => {
        const items = renderItemActions(record);
        return <ActionDropdown dropdownItems={items} trigger="click" />;
      },
    },
  ];

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="tera-page">
      <HeaderViewList
        title="Danh sách đơn vị"
        onClickButtonAdd={() => setOpenForm({ open: true, id: null })}
        actionLeftRender={
          hasPage(PRODUCT_UNIT_PERMISSION_KEY.SEARCH) && (
            <UnitSearch onSearch={handleSearch} />
          )
        }
        buttonCreatingKey={PRODUCT_UNIT_PERMISSION_KEY.CREATE}
      >
        <TableTera
          zebra={false}
          rowKey={'id'}
          columns={columns}
          data={unitList?.data || []}
          loading={isLoading || loadingDelete}
          pagination={{
            onChange: handleChangePage,
            total: unitList?.total || 0,
            current: unitList?.current_page,
            pageSize: unitList?.per_page || 10,
            to: unitList?.to,
            from: unitList?.from || 10,
          }}
        />
      </HeaderViewList>

      {openDetail.open && (
        <UnitDetail
          open={openDetail.open}
          id={openDetail.id}
          onCancel={() => setOpenDetail({ open: false, id: null })}
        />
      )}
      {openForm.open && (
        <UnitForm
          open={openForm.open}
          id={openForm.id}
          onClose={() => setOpenForm({ open: false, id: null })}
          onSuccess={() => queryClient.invalidateQueries(['get-unit-list'])}
        />
      )}
    </div>
  );
}

export default Unit;
