import React from 'react';
import customTwMerge from 'tailwind-merge.config';
import { Tabs, TabsProps } from 'tera-dls';

function SumiTab(props: TabsProps) {
  return (
    <Tabs
      {...props}
      className={customTwMerge('mb-0', props?.className)}
      itemClassName={customTwMerge('py-3 px-4', props?.itemClassName)}
    />
  );
}

export default SumiTab;
