import { useQuery } from '@tanstack/react-query';
import HeaderViewList from '_common/component/HeaderViewList';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getQueryParams, PaginationProps, updateQueryParams } from 'tera-dls';
import SalesExpensesApi from './api';
import SalesExpensesFilter from './containers/Filter';
import SalesExpensesSearch from './containers/Search';
import SalesExpensesTable from './containers/Table';
import { SALES_EXPENSES_URL } from './url';
import { usePermission } from '_common/hooks/usePermission';
import { SALES_EXPENSES_PERMISSION_KEY } from '_common/constants/permission';

function SalesExpenses() {
  const [isOpenFilter, setIsOpenFilter] = useState<boolean>(false);

  const { hasPage } = usePermission();
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;

  const handleUpdateQueryParams = (data) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(`${SALES_EXPENSES_URL.list.path}${paramString}`);
  };

  const {
    data: salesExpensesList,
    isLoading,
    refetch,
  } = useQuery(
    ['get-sales-expenses-list', queryParams],
    () => {
      const params = {
        ...queryParams,
        page: queryParams?.page || 1,
        limit: queryParams?.limit || 10,
        status: queryParams?.status === 'all' ? undefined : queryParams?.status,
      };
      return SalesExpensesApi.getList({ params });
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
      onSuccess: (data) => {
        if (Number(data?.last_page) < (Number(queryParams?.page) || 1)) {
          handleUpdateQueryParams({ page: data?.last_page });
        }
      },
    },
  );

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    const isDiffPageSize =
      Number(pageSize) !== Number(salesExpensesList?.limit || 10);

    handleUpdateQueryParams({
      page: isDiffPageSize ? 1 : page,
      limit: pageSize,
    });
  };

  const handleFilter = (values) => {
    handleUpdateQueryParams({ ...values, page: 1 });
  };

  const handleSearch = (value) => {
    handleUpdateQueryParams({ keyword: value?.keyword, page: 1 });
  };

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="tera-page">
      <HeaderViewList
        title="Danh sách chi phí bán hàng"
        buttonFilterKey={SALES_EXPENSES_PERMISSION_KEY.SEARCH}
        buttonCreatingKey={SALES_EXPENSES_PERMISSION_KEY.CREATE}
        onClickFilter={() => setIsOpenFilter(true)}
        onClickButtonAdd={() => navigate(SALES_EXPENSES_URL.create.path)}
        actionLeftRender={
          hasPage(SALES_EXPENSES_PERMISSION_KEY.SEARCH) && (
            <SalesExpensesSearch onSearch={handleSearch} />
          )
        }
        filterCount={{
          filter: queryParams,
          params: [
            'invest_type',
            'investor_id',
            'total_price_from',
            'total_price_to',
          ],
        }}
      >
        <SalesExpensesTable
          rowKey={'id'}
          data={salesExpensesList?.data || []}
          loading={isLoading}
          pagination={{
            onChange: handleChangePage,
            total: salesExpensesList?.total || 0,
            current: salesExpensesList?.current_page,
            pageSize: salesExpensesList?.per_page || 10,
            to: salesExpensesList?.to,
            from: salesExpensesList?.from || 10,
          }}
        />
      </HeaderViewList>

      {isOpenFilter && (
        <SalesExpensesFilter
          open={isOpenFilter}
          onClose={() => setIsOpenFilter(false)}
          onFilter={handleFilter}
          initialValue={queryParams}
        />
      )}
    </div>
  );
}

export default SalesExpenses;
