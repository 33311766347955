import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const SalesExpensesItemEndpoint = `${endpoint}/portal/sale-cost-item`;

const SalesExpensesItemApi = {
  getList: async ({ params }) =>
    await api
      .get(`${SalesExpensesItemEndpoint}/list`, params)
      .then((data) => data?.data?.data),
  create: async ({ params }) =>
    await api
      .post(`${SalesExpensesItemEndpoint}/create`, params)
      .then((result) => result?.data),
  update: async ({ params, id }) =>
    await api
      .put(`${SalesExpensesItemEndpoint}/update/${id}`, params)
      .then((result) => result?.data),
  delete: async ({ id }) =>
    await api
      .delete(`${SalesExpensesItemEndpoint}/delete/${id}`)
      .then((result) => result?.data),
};
export default SalesExpensesItemApi;
