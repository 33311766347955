import { observer } from 'mobx-react-lite';
import { BuildingStorefrontOutlined } from 'tera-dls';

const UserCompany = observer(() => {
  return (
    <div className="flex p-2 items-end gap-x-[5px] text-blue-600 cursor-pointer">
      <BuildingStorefrontOutlined className="w-5 h-5" />
      <div className="flex gap-x-[15px]">
        <span className="font-bold max-w-[150px] truncate">SUMI</span>
      </div>
    </div>
  );
});

export default UserCompany;
