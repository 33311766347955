import React from 'react';

function NotiFinance(props) {
  return (
    <svg
      width="15"
      height="11"
      viewBox="0 0 15 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.601562 7.47453L1.61019 6.4659L3.18705 8.04277L9.08254 0.840332L10.1764 1.73531L3.28649 10.1595L0.601562 7.47453ZM8.91917 5.54962H12.4707V6.97022H8.91917V5.54962ZM6.78827 8.39081H10.3398V9.81141H6.78827V8.39081ZM11.0501 2.70842H14.6016V4.12902H11.0501V2.70842Z"
        fill="#6B7280"
      />
    </svg>
  );
}

export default NotiFinance;
