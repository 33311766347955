import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const PaymentMethodEndpoint = `${endpoint}/portal/finance`;

const PaymentMethodApi = {
  getList: async ({ params }) =>
    await api
      .get(`${PaymentMethodEndpoint}/payment-method-list`, params)
      .then((res) => res.data?.data),
  getDetail: async ({ id }) =>
    await api
      .get(`${PaymentMethodEndpoint}/card-detail/${id}`)
      .then((res) => res.data?.data),
  create: async (params?) =>
    await api
      .post(`${PaymentMethodEndpoint}/add-card`, params)
      .then((result) => result.data),
  update: async (id: string | number, params?) =>
    await api
      .put(`${PaymentMethodEndpoint}/update-card/${id}`, params)
      .then((result) => result.data),
  delete: async ({ id }) =>
    await api
      .delete(`${PaymentMethodEndpoint}/delete-card/${id}`)
      .then((result) => result.data),
  getQrCode: async ({ id }) =>
    await api
      .get(`${PaymentMethodEndpoint}/card-vietQR/${id}`)
      .then((result) => result.data?.data),

  // transaction
  getTransactionDetail: async (params?) =>
    await api
      .get(`${PaymentMethodEndpoint}/transaction-detail`, params)
      .then((result) => result?.data?.data),
  getChart: async (params?) =>
    await api
      .get(`${PaymentMethodEndpoint}/chart`, params)
      .then((result) => result?.data?.data),
  getTransactionAll: async (params?) =>
    await api
      .get(`${PaymentMethodEndpoint}/transaction-all`, params)
      .then((result) => result?.data?.data),
  getTransactionFind: async (id: number) =>
    await api
      .get(`${PaymentMethodEndpoint}/transaction-find/${id}`)
      .then((result) => result?.data?.data),
  getTransactionUpdate: async (id: number, params: any) =>
    await api
      .put(`${PaymentMethodEndpoint}/transaction-update/${id}`, params)
      .then((result) => result?.data),
  transaction: async (params: any) =>
    await api
      .post(`${PaymentMethodEndpoint}/transaction`, params)
      .then((result) => result?.data),
};

export default PaymentMethodApi;
