import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const NotificationEndpoint = `${endpoint}/portal/config`;

const NotificationApi = {
  getList: async ({ params }) =>
    await api
      .get(`${NotificationEndpoint}/notifications`, params)
      .then((res) => res?.data?.data),
  getSummary: async ({ params }) =>
    await api
      .get(`${NotificationEndpoint}/notifications/summary-list`, params)
      .then((res) => res?.data?.data),
  update: async ({ id }) =>
    await api
      .put(`${NotificationEndpoint}/update-notifications/${id}`)
      .then((res) => res?.data),
  addToken: async ({ params }) =>
    await api
      .post(`${NotificationEndpoint}/add-token`, params)
      .then((res) => res?.data),
};

export default NotificationApi;
