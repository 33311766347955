import { IRouteProps } from '_common/interface/router';
import { USER_URL } from './url';
import User from '.';

export const UserRouter: IRouteProps[] = [
  {
    key: USER_URL.list.key,
    path: USER_URL.list.shortenUrl,
    component: <User />,
  },
];
