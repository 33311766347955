import { useQuery } from '@tanstack/react-query';
import {
  TAB_ITEMS_STATUS,
  TAB_ITEMS_STATUS_COLOR,
} from '_common/constants/common';
import { INVESTMENT_CATEGORY } from '_common/dof/Select/SelectInvestmentCategory';
import TableTera from '_common/dof/TableTera';
import { IPagination } from '_common/interface';
import InvestmentApi from 'pages/Invest/Invesment/api';
import { useState } from 'react';
import {
  ColumnsType,
  formatCurrency,
  Image,
  PaginationProps,
  Tag,
} from 'tera-dls';

function Trial({ id }) {
  const [pagination, setPagination] = useState<IPagination>({
    page: 1,
    limit: 10,
  });
  const { data: trialList, isLoading } = useQuery(
    ['get-trial-list', id],
    () => {
      const params = {
        investor_id: id,
        page: pagination?.page || 1,
        limit: pagination?.limit || 10,
        is_trial: 1,
      };
      return InvestmentApi.getList({ params });
    },
    {
      enabled: !!id,
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  const columns: ColumnsType<any> = [
    {
      title: 'Mã đầu tư',
      dataIndex: 'code',
      width: 100,
      render: (name) => <p className="line-clamp-2 break-word">{name}</p>,
    },
    {
      title: 'Hình ảnh',
      dataIndex: 'file_upload',
      width: 80,
      render: (file_upload) =>
        file_upload && (
          <Image
            src={file_upload}
            alt={file_upload}
            containerClassName="w-[30px] h-[30px] rounded overflow-hidden border"
          />
        ),
    },
    {
      title: 'Tên đầu tư',
      dataIndex: 'name',
      width: 200,
      render: (name) => <p className="line-clamp-2 break-word">{name}</p>,
    },
    {
      title: 'Danh mục đầu tư',
      dataIndex: 'type',
      width: 150,
      render: (type) => INVESTMENT_CATEGORY[type],
    },
    {
      title: 'Địa điểm',
      dataIndex: 'address',
      width: 150,
      render: (text) => <p className="line-clamp-2">{text}</p>,
    },
    {
      title: 'Giá bán',
      dataIndex: 'amount',
      width: 150,
      render: (amount) => formatCurrency(amount),
    },
    {
      title: 'Tổng doanh thu',
      dataIndex: 'total_revenue',
      width: 150,
      render: (total_revenue) => formatCurrency(total_revenue),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      width: 100,
      render: (status) => (
        <Tag color={TAB_ITEMS_STATUS_COLOR[status]}>
          {TAB_ITEMS_STATUS[status]}
        </Tag>
      ),
    },
  ];

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    const isDiffPageSize = Number(pageSize) !== Number(pagination?.limit || 10);
    setPagination({ page: isDiffPageSize ? 1 : page, limit: pageSize });
  };

  return (
    <TableTera
      data={trialList?.data || []}
      loading={isLoading}
      pagination={{
        onChange: handleChangePage,
        total: trialList?.total || 0,
        current: trialList?.current_page,
        pageSize: trialList?.per_page || 10,
        to: trialList?.to,
        from: trialList?.from || 10,
      }}
      columns={columns}
    />
  );
}

export default Trial;
