import { useQuery } from '@tanstack/react-query';
import NoData from '_common/component/NoData';
import { useEffect } from 'react';
import { formatCurrency, formatNumber, Modal, Spin } from 'tera-dls';
import PaymentMethodApi from '../../api';

interface PaymentMethodDetailProps {
  id: number;
  open: boolean;
  onCancel: () => void;
}

function PaymentMethodDetail({ id, open, onCancel }: PaymentMethodDetailProps) {
  const {
    data: dataDetails,
    refetch,
    isLoading,
    isError,
  } = useQuery(
    ['get-payment-method-detail', id],
    () => PaymentMethodApi.getDetail({ id }),
    {
      enabled: !!id,
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  useEffect(() => {
    if (id) refetch();
  }, [id]);

  return (
    <Modal
      title="Chi tiết phương thức thanh toán"
      destroyOnClose
      closeIcon={false}
      cancelText="Đóng"
      onCancel={onCancel}
      open={open}
      centered={true}
      className="sm:w-[500px]"
      okButtonProps={{ className: 'hidden' }}
    >
      <Spin spinning={isLoading}>
        {isError ? (
          <NoData />
        ) : (
          <div className="flex flex-col gap-y-4">
            <div className="relative h-full flex flex-col text-white rounded-2xl flex-1 overflow-hidden">
              <div className="relative pt-6 pb-6 px-4 bg-[#1E429F] w-full flex-1 flex flex-col justify-between gap-y-4">
                <div className="flex flex-col">
                  <span className="font-bold text-[#D0C1E8] text-xl max-w-[80%] line-clamp-1 break-word">
                    {dataDetails?.card_type?.card_name}
                  </span>
                  <span className="text-[#D0C1E8] max-w-[70%] line-clamp-3 break-word">
                    {dataDetails?.card_type?.description}
                  </span>
                </div>
                <div className="flex items-baseline gap-x-2 text-xl">
                  {dataDetails?.account_number}
                </div>
                <div
                  className="absolute w-[150px] h-[150px] border top-0 right-0 z-50 -translate-y-[45px] translate-x-[70px]"
                  style={{ borderBottomLeftRadius: '100px' }}
                />
                <div
                  className="absolute w-[150px] h-[150px] border top-0 right-0 z-50 -translate-y-[70px] translate-x-[50px] -rotate-[28deg]"
                  style={{ borderBottomLeftRadius: '100px' }}
                />
              </div>
              <div className="p-4 flex items-center justify-between bg-[#082D59] border-t border-white">
                <p className="uppercase text-lg font-semibold break-word line-clamp-1">
                  {dataDetails?.account_name}
                </p>
              </div>
              <div className="w-[30px] h-[30px] absolute top-[10px] right-[20px]">
                <img
                  src={dataDetails?.card_type?.image_bank}
                  alt={dataDetails?.card_type?.card_name}
                  className="w-full"
                />
              </div>
            </div>

            <div className="flex items-center pt-4 border-t border-dashed">
              <p className="min-w-[150px]">Tổng thu</p>
              <p>{formatCurrency(dataDetails?.total_revenue)}</p>
            </div>
            <div className="flex items-center">
              <p className="min-w-[150px]">Số lần giao dịch</p>
              <p>{formatNumber(dataDetails?.transactions_number)}</p>
            </div>
          </div>
        )}
      </Spin>
    </Modal>
  );
}

export default PaymentMethodDetail;
