import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const CashReceiptEndpoint = `${endpoint}/portal/finance/accounting-voucher/receipt`;
const CashReceiptApi = {
  getList: async ({ params }) =>
    await api
      .get(`${CashReceiptEndpoint}/list`, params)
      .then((data) => data?.data?.data),
  create: async ({ params }) =>
    await api
      .post(`${CashReceiptEndpoint}/create`, params)
      .then((result) => result?.data),
  update: async ({ id, params }) =>
    await api
      .put(`${CashReceiptEndpoint}/update/${id}`, params)
      .then((result) => result?.data),
  getDetail: async ({ id }) =>
    await api
      .get(`${CashReceiptEndpoint}/detail/${id}`)
      .then((data) => data?.data?.data),
  getCodeSuggestion: async () =>
    await api
      .get(`${CashReceiptEndpoint}/suggest-code`)
      .then((data) => data?.data?.data),
  getStatistic: async () =>
    await api
      .get(`${CashReceiptEndpoint}/revenue`)
      .then((data) => data?.data?.data),
  delete: async ({ id, params }) =>
    await api
      .delete(`${CashReceiptEndpoint}/delete/${id}`, params)
      .then((result) => result?.data),

  getExplainList: async ({ id, params }) =>
    await api
      .get(`${CashReceiptEndpoint}/explain/list/${id}`, params)
      .then((data) => data?.data?.data),
  saveExplain: async ({ params }) =>
    await api
      .put(`${CashReceiptEndpoint}/save-explain`, params)
      .then((result) => result?.data),
  createExplain: async ({ params }) =>
    await api
      .post(`${CashReceiptEndpoint}/explain/create`, params)
      .then((result) => result?.data),
  updateExplain: async ({ id, params }) =>
    await api
      .put(`${CashReceiptEndpoint}/explain/update/${id}`, params)
      .then((result) => result?.data),
  deleteExplain: async ({ id }) =>
    await api
      .delete(`${CashReceiptEndpoint}/explain/delete/${id}`)
      .then((result) => result?.data),
};
export default CashReceiptApi;
