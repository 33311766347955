import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import CardFormV2 from '_common/component/CardForm/CardFormV2';
import DefaultImage from '_common/component/DefaultImage';
import HoverQuickView from '_common/component/HoverQuickView';
import NoData from '_common/component/NoData';
import { IconButton } from '_common/component/TableColumnCustom';
import { DATE_TIME_FORMAT, SUMI_SHADOW } from '_common/constants/common';
import { ORDER_PERMISSION_KEY } from '_common/constants/permission';
import { INVESTMENT_CATEGORY } from '_common/dof/Select/SelectInvestmentCategory';
import { TICKET_TYPE } from '_common/dof/Select/SelectTicketType';
import useConfirm from '_common/hooks/useConfirm';
import { usePermission } from '_common/hooks/usePermission';
import classNames from 'classnames';
import printJS from 'print-js';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PortalApi from 'states/api/portal';
import { FreeMode, Navigation, Thumbs } from 'swiper';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
  ArrowSmallLeftOutlined,
  Breadcrumb,
  Button,
  formatCurrency,
  formatDate,
  InformationCircleOutlined,
  ItemType,
  notification,
  PencilSquareOutlined,
  PrinterOutlined,
  Row,
  Spin,
  Tag,
  Tooltip,
} from 'tera-dls';
import OrderApi from '../../api';
import { ORDER_STATUS, ORDER_STATUS_COLOR } from '../../constants';
import { ORDER_URL } from '../../url';

function OrderRequestDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const confirm = useConfirm();
  const queryClient = useQueryClient();
  const { hasPage } = usePermission();
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const {
    data: dataDetails,
    refetch,
    isLoading,
    isError,
  } = useQuery(['get-order-detail', id], () => OrderApi.getDetail({ id }), {
    enabled: !!id,
    staleTime: 300000,
    cacheTime: 300000,
  });

  const detailProduct = dataDetails?.order_detail[0];

  const { mutate, isLoading: loadingChangeStatus } = useMutation(
    (params: any) =>
      OrderApi.approval({ id: params?.id, params: params?.params }),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries(['get-order-detail']);
          queryClient.invalidateQueries(['get-order-list']);
          queryClient.invalidateQueries(['get-summary-order-list']);
        }
      },
      onError: (error: any) => {
        notification.error({
          message: error?.message,
        });
      },
    },
  );

  // const { mutate: mutateDelete, isLoading: loadingDelete } = useMutation(
  //   (id: any) => OrderApi.delete({ id }),
  //   {
  //     onSuccess: (res) => {
  //       if (res?.code === 200) {
  //         notification.success({
  //           message: res?.msg,
  //         });
  //         navigate(-1);
  //         queryClient.invalidateQueries(['get-order-list']);
  //         queryClient.invalidateQueries(['get-summary-order-list']);
  //       }
  //     },
  //     onError: (error: any) => {
  //       notification.error({
  //         message: error?.message,
  //       });
  //     },
  //   },
  // );

  const { mutate: mutatePrint, isLoading: loadingPrint } = useMutation(
    (params: any) => PortalApi.print({ params }),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          printJS({
            printable: res?.data,
          });
        }
      },
      onError: (error: any) => {
        notification.error({
          message: error?.message,
        });
      },
    },
  );

  const handleReject = () => {
    confirm.warning({
      title: 'Xác nhận từ chối đơn đặt hàng',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn từ chối đơn đặt hàng</p>
          <p>
            <b>{dataDetails?.code}</b> này không?
          </p>
        </div>
      ),
      onOk: () => {
        const params = {
          id,
          params: {
            status: 'reject',
            type_status: 'sumi',
          },
        };
        mutate(params);
      },
    });
  };

  const handleApprove = () => {
    confirm.warning({
      title: 'Xác nhận duyệt đơn đặt hàng',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn duyệt đơn đặt hàng</p>
          <p>
            <b>{dataDetails?.code}</b> này không?
          </p>
        </div>
      ),
      onOk: () => {
        const params = {
          id,
          params: {
            status: 'approved',
            note: dataDetails?.note,
            invest_id: dataDetails?.invest_id?.id,
            type_status: 'sumi',
            ticket_type: dataDetails?.ticket_type,
            products: [
              {
                investment_product_id:
                  dataDetails?.order_detail?.[0]?.investment_product_id,
                quantity_approved:
                  dataDetails?.order_detail?.[0]?.quantity_request,
              },
            ],
          },
        };
        mutate(params);
      },
    });
  };

  const handlePrint = () => {
    mutatePrint({
      export_pdf: 1,
      order_id: id,
      type: 'order',
    });
  };

  // const handleDelete = () => {
  //   confirm.warning({
  //     title: 'Xác nhận xóa đơn đặt hàng',
  //     content: (
  //       <div className="break-word">
  //         <p>Bạn có chắc muốn xóa đơn đặt hàng</p>
  //         <p>
  //           <b>{dataDetails?.code}</b> này không?
  //         </p>
  //       </div>
  //     ),
  //     onOk: () => {
  //       mutateDelete(dataDetails?.id);
  //     },
  //   });
  // };

  const BreadcrumbItem: ItemType[] = [
    {
      title: (
        <a
          onClick={() => navigate(ORDER_URL.list.path)}
          className="!text-blue-400 hover:!text-blue-600"
        >
          Danh sách đơn đặt hàng
        </a>
      ),
    },
    {
      title: 'Chi tiết đơn đặt hàng theo yêu cầu',
    },
  ];

  const extra = [
    {
      label: 'Người tạo',
      value: (
        <HoverQuickView
          avatarUrl={dataDetails?.created_by?.avatar}
          name={dataDetails?.created_by?.full_name}
          code={dataDetails?.created_by?.code}
          email={dataDetails?.created_by?.email}
          phone={dataDetails?.created_by?.phone}
        >
          {dataDetails?.created_by?.full_name}
        </HoverQuickView>
      ),
    },
    {
      label: 'Ngày tạo',
      value:
        dataDetails?.created_at &&
        formatDate(dataDetails?.created_at, DATE_TIME_FORMAT),
    },
    {
      label: 'Người cập nhật',
      value: (
        <HoverQuickView
          avatarUrl={dataDetails?.updated_by?.avatar}
          name={dataDetails?.updated_by?.full_name}
          code={dataDetails?.updated_by?.code}
          email={dataDetails?.updated_by?.email}
          phone={dataDetails?.updated_by?.phone}
        >
          {dataDetails?.updated_by?.full_name}
        </HoverQuickView>
      ),
    },
    {
      label: 'Ngày cập nhật',
      value:
        dataDetails?.updated_by &&
        dataDetails?.updated_at &&
        formatDate(dataDetails?.updated_at, DATE_TIME_FORMAT),
    },
  ];

  const detail = [
    {
      label: 'Nhà đầu tư',
      value: (
        <HoverQuickView name={dataDetails?.investor_name || 'Hệ thống'}>
          {dataDetails?.investor_name || 'Hệ thống'}
        </HoverQuickView>
      ),
    },
    {
      label: 'Đầu tư',
      value: (
        <HoverQuickView
          name={dataDetails?.invest_name}
          avatarUrl={dataDetails?.invest?.file_upload}
          code={dataDetails?.invest?.code}
          sub={dataDetails?.invest?.description}
        >
          {dataDetails?.invest_name}
        </HoverQuickView>
      ),
    },
    {
      label: 'Danh mục đầu tư',
      value: INVESTMENT_CATEGORY[dataDetails?.type],
    },
    {
      label: 'Tên thiết bị đặt hàng',
      value: dataDetails?.device_name,
    },
    {
      label: 'Mã đơn hàng',
      value: dataDetails?.code,
    },
    {
      label: 'Ngày duyệt đơn hàng',
      value:
        dataDetails?.approved_date &&
        formatDate(dataDetails?.approved_date, DATE_TIME_FORMAT),
    },
    {
      label: 'Loại phiếu',
      value: TICKET_TYPE[dataDetails?.ticket_type],
    },
    {
      label: 'Trạng thái',
      value: dataDetails?.order_status && (
        <div className="flex gap-x-2.5 items-center">
          <Tag color={ORDER_STATUS_COLOR[dataDetails?.order_status]}>
            {ORDER_STATUS[dataDetails?.order_status]}
          </Tag>
          {dataDetails?.type_status === 'sumi' && (
            <Tooltip
              title={`SUMI ${ORDER_STATUS[dataDetails?.order_status]}`}
              placement="top"
            >
              <div>
                <InformationCircleOutlined className="w-5 h-5" />
              </div>
            </Tooltip>
          )}
        </div>
      ),
    },
  ];

  const request = [
    {
      label: 'Tên sản phẩm',
      value: detailProduct?.product_name,
    },
    {
      label: 'Số lượng',
      value: detailProduct?.quantity_request,
    },
    {
      label: 'Thời gian nhận',
      value:
        dataDetails?.receipt_date &&
        formatDate(dataDetails?.receipt_date, DATE_TIME_FORMAT),
    },
    {
      label: 'Giá bán',
      value: formatCurrency(detailProduct?.amount),
    },
  ];

  useEffect(() => {
    refetch();
  }, []);

  const disabled =
    loadingChangeStatus ||
    isError ||
    isLoading ||
    // loadingDelete ||
    loadingPrint;

  return (
    <div className="tera-page-form">
      <div className="page-header-sticky">
        <div className="page-header-v2">
          <div className="page-header-v2__breadcrumb">
            <IconButton
              icon={<ArrowSmallLeftOutlined />}
              callBack={() => navigate(-1)}
            />
            <Breadcrumb separator="/" items={BreadcrumbItem} />
          </div>
          <div className="page-header-v2__function">
            {hasPage(ORDER_PERMISSION_KEY.PRINT) && (
              <Button
                type="success"
                className="page-header-v2-btn bg-green-500 hover:bg-green-600"
                onClick={handlePrint}
                disabled={disabled}
                prefix={<PrinterOutlined />}
              >
                In đơn
              </Button>
            )}
            {dataDetails?.order_status === 'pending' &&
              hasPage(ORDER_PERMISSION_KEY.CHANGE_STATUS) && (
                <>
                  <Button
                    type="danger"
                    className="page-header-v2-btn"
                    onClick={handleReject}
                    disabled={disabled}
                  >
                    Từ chối
                  </Button>
                  <Button
                    type="success"
                    className="page-header-v2-btn"
                    onClick={handleApprove}
                    disabled={disabled}
                  >
                    Duyệt
                  </Button>
                </>
              )}
            {dataDetails?.order_status !== 'approved' && (
              <>
                {hasPage(ORDER_PERMISSION_KEY.UPDATE) && (
                  <Button
                    className="page-header-v2-btn"
                    prefix={<PencilSquareOutlined />}
                    onClick={() =>
                      navigate(`${ORDER_URL.order_request_update.path}/${id}`)
                    }
                    disabled={disabled}
                  >
                    Sửa
                  </Button>
                )}
                {/* {hasPage(ORDER_PERMISSION_KEY.DELETE) &&
                  dataDetails?.ticket_type !== 'request' && (
                    <Button
                      className="page-header-v2-btn"
                      type="danger"
                      prefix={<XMarkOutlined />}
                      onClick={handleDelete}
                      disabled={disabled}
                    >
                      Xóa
                    </Button>
                  )} */}
              </>
            )}
          </div>
        </div>
      </div>
      <Spin spinning={disabled}>
        <div className="page-content-v2">
          <div className="bg-white rounded-[10px] px-2.5 py-5 shadow grid grid-cols-2 gap-x-2.5 h-[calc(100vh-115px)]">
            <div className="flex flex-col gap-4 overflow-auto">
              <div
                className={classNames(
                  'px-2.5 py-5 rounded-md border border-blue-400',
                  SUMI_SHADOW,
                )}
              >
                <CardFormV2 title="Thông tin chung">
                  <Row className="grid grid-cols-2 gap-2.5">
                    <div className="flex flex-col gap-y-2.5">
                      {detail.map((item, index) => (
                        <div className="flex items-start" key={index}>
                          <div className="detail-key">{item.label}</div>
                          <div className="detail-value">{item.value}</div>
                        </div>
                      ))}
                    </div>
                    <div className="flex flex-col gap-y-2.5">
                      {extra.map((item, index) => (
                        <div className="flex items-start" key={index}>
                          <div className="detail-key">{item.label}</div>
                          <div className="detail-value">{item.value}</div>
                        </div>
                      ))}
                    </div>
                  </Row>
                </CardFormV2>
              </div>
              <div
                className={classNames(
                  'px-2.5 py-5 rounded-md border border-blue-400',
                  SUMI_SHADOW,
                )}
              >
                <CardFormV2 title="Thông tin đặt bánh">
                  <Row className="grid grid-cols-2 gap-2.5">
                    {request.map((item, index) => (
                      <div className="flex items-start" key={index}>
                        <div className="detail-key">{item.label}</div>
                        <div className="detail-value">{item.value}</div>
                      </div>
                    ))}
                  </Row>
                </CardFormV2>
                <CardFormV2 title="Ghi chú thông tin khách hàng">
                  <p className="text-3xl break-word">
                    {dataDetails?.customer_note}
                  </p>
                </CardFormV2>
                <CardFormV2 title="Ghi chú mô tả">
                  <p className="text-3xl break-word">{dataDetails?.note}</p>
                </CardFormV2>
              </div>
            </div>

            <div className="h-full flex gap-x-4 overflow-hidden select-none">
              {!disabled && detailProduct?.product_image_request?.length > 0 ? (
                <>
                  {thumbsSwiper?.mounted && (
                    <Swiper
                      spaceBetween={10}
                      navigation={true}
                      modules={[FreeMode, Navigation, Thumbs]}
                      className="sumi-carousel-main rounded-md"
                      thumbs={{ swiper: thumbsSwiper }}
                    >
                      {detailProduct?.product_image_request.map(
                        (slide, index) => (
                          <SwiperSlide key={index}>
                            <DefaultImage
                              src={slide}
                              alt={slide}
                              className="rounded-md cursor-grab"
                            />
                          </SwiperSlide>
                        ),
                      )}
                    </Swiper>
                  )}
                  <Swiper
                    direction="vertical"
                    onSwiper={(swiper) => {
                      setTimeout(() => {
                        setThumbsSwiper(swiper);
                      }, 100);
                    }}
                    spaceBetween={10}
                    slidesPerView={5}
                    freeMode={true}
                    watchSlidesProgress={true}
                    modules={[FreeMode, Navigation, Thumbs]}
                    className="sumi-carousel-navigation rounded-md "
                  >
                    {detailProduct?.product_image_request?.map(
                      (slide, index) => (
                        <SwiperSlide key={index}>
                          <DefaultImage
                            src={slide}
                            alt={slide}
                            className="rounded-md cursor-pointer"
                          />
                        </SwiperSlide>
                      ),
                    )}
                  </Swiper>
                </>
              ) : (
                <NoData text="Không có hình ảnh" />
              )}
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
}

export default OrderRequestDetail;
