import { Button, Modal } from 'tera-dls';
import TopInvestment from '.';

interface IProps {
  open: boolean;
  onClose?: () => void;
  type?: any;
}
const ModalChart = (props: IProps) => {
  const { onClose, open, type } = props;
  return (
    <Modal
      title={''}
      destroyOnClose
      closeIcon={false}
      closable
      onCancel={onClose}
      className="xl:w-[1200px]"
      open={open}
      centered={true}
      footer={<Button onClick={onClose}>Đóng</Button>}
    >
      <TopInvestment type={type} onClose={onClose} />
    </Modal>
  );
};

export default ModalChart;
