import { useMutation, useQueryClient } from '@tanstack/react-query';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import { DATE_FORMAT } from '_common/constants/common';
import { INVESTMENT_CATEGORY } from '_common/dof/Select/SelectInvestmentCategory';
import { TICKET_TYPE } from '_common/dof/Select/SelectTicketType';
import TableTera from '_common/dof/TableTera';
import { ITeraTableProps } from '_common/dof/TableTera/_interfaces';
import useConfirm from '_common/hooks/useConfirm';
import { useNavigate } from 'react-router-dom';
import {
  ColumnsType,
  DropdownItem,
  formatCurrency,
  formatDate,
  formatNumber,
  InformationCircleOutlined,
  notification,
  Tag,
  Tooltip,
} from 'tera-dls';
import SaleOrderReturnApi from '../../api';
import { SALE_ORDER_RETURN_URL } from '../../url';
import { ORDER_STATUS, ORDER_STATUS_COLOR } from '../../constants';
import { usePermission } from '_common/hooks/usePermission';
import { ORDER_RETURN_PERMISSION_KEY } from '_common/constants/permission';
import HoverQuickView from '_common/component/HoverQuickView';

function SaleOrderReturnTable(props: ITeraTableProps) {
  const navigate = useNavigate();
  const confirm = useConfirm();
  const queryClient = useQueryClient();
  const { hasPage } = usePermission();

  const { mutate, isLoading: loadingDelete } = useMutation(
    (params: any) => {
      if (params?.type === 'delete')
        return SaleOrderReturnApi.delete({ id: params?.id });

      return SaleOrderReturnApi.approve({
        id: params?.id,
        params: {
          status: params?.status,
          type_status: 'sumi',
        },
      });
    },
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries(['get-sale-order-return-list']);
          queryClient.invalidateQueries(['get-summary-sale-order-return-list']);
        }
      },
      onError: (error: any) => {
        notification.error({
          message: error?.message,
        });
      },
    },
  );

  const handleDelete = (record) => {
    confirm.warning({
      title: 'Xác nhận xóa đơn trả hàng bán',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn xóa đơn trả hàng bán</p>
          <p>
            <b>{record?.code}</b> này không?
          </p>
        </div>
      ),
      onOk: () => {
        mutate({
          type: 'delete',
          id: record?.id,
        });
      },
    });
  };

  const handleApprove = (record) => {
    confirm.warning({
      title: 'Xác nhận duyệt đơn trả hàng bán',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn duyệt đơn trả hàng bán</p>
          <p>
            <b>{record?.code}</b> này không?
          </p>
        </div>
      ),
      onOk: () => {
        mutate({
          type: 'approve',
          id: record?.id,
          status: 'approved',
        });
      },
    });
  };

  const handleReject = (record) => {
    confirm.warning({
      title: 'Xác nhận từ chối đơn trả hàng bán',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn từ chối đơn trả hàng bán</p>
          <p>
            <b>{record?.code}</b> này không?
          </p>
        </div>
      ),
      onOk: () => {
        mutate({
          type: 'reject',
          id: record?.id,
          status: 'reject',
        });
      },
    });
  };

  const renderItemActions = (record) => {
    const items: DropdownItem[] = [];

    if (hasPage(ORDER_RETURN_PERMISSION_KEY.VIEW_DETAIL))
      items.push({
        key: '1',
        label: 'Xem',
        onClick: () =>
          record?.id &&
          navigate(`${SALE_ORDER_RETURN_URL.detail.path}/${record?.id}`),
      });
    if (
      hasPage(ORDER_RETURN_PERMISSION_KEY.CHANGE_STATUS) &&
      record?.order_status === 'pending'
    )
      items.push({
        key: '2',
        label: 'Duyệt',
        onClick: () => handleApprove(record),
      });
    if (
      hasPage(ORDER_RETURN_PERMISSION_KEY.CHANGE_STATUS) &&
      record?.order_status === 'pending'
    )
      items.push({
        key: '3',
        label: 'Từ chối',
        onClick: () => handleReject(record),
      });
    if (
      hasPage(ORDER_RETURN_PERMISSION_KEY.UPDATE) &&
      record?.order_status !== 'approved'
    )
      items.push({
        key: '4',
        label: 'Sửa',
        onClick: () =>
          navigate(`${SALE_ORDER_RETURN_URL.update.path}/${record?.id}`),
      });
    if (
      hasPage(ORDER_RETURN_PERMISSION_KEY.DELETE) &&
      record?.order_status !== 'approved'
    )
      items.push({
        key: '5',
        label: <span className="text-red-500">Xóa</span>,
        onClick: () => handleDelete(record),
      });

    return items;
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Mã đơn hàng',
      dataIndex: 'code',
      width: 100,
      render: (name, record) => (
        <p
          className="line-clamp-2 break-word text-blue-600 cursor-pointer"
          onClick={() =>
            navigate(`${SALE_ORDER_RETURN_URL.detail.path}/${record?.id}`)
          }
        >
          {name}
        </p>
      ),
    },
    {
      title: 'Đầu tư',
      dataIndex: 'invest_name',
      width: 200,
      render: (invest_name, record) => (
        <HoverQuickView
          name={record?.invest_name}
          avatarUrl={record?.invest_id?.file_upload}
          code={record?.invest_id?.code}
          sub={record?.invest_id?.description}
        >
          <p className="line-clamp-2">{invest_name}</p>
        </HoverQuickView>
      ),
    },
    {
      title: 'Danh mục đầu tư',
      dataIndex: 'type',
      width: 100,
      render: (type) => INVESTMENT_CATEGORY[type],
    },
    {
      title: 'Nhà đầu tư',
      dataIndex: 'investor_name',
      width: 150,
      render: (investor_name) => (
        <HoverQuickView name={investor_name || 'Hệ thống'}>
          {investor_name || 'Hệ thống'}
        </HoverQuickView>
        // <p className="line-clamp-2">{investor_name || 'Hệ thống'}</p>
      ),
    },
    {
      title: 'Tên thiết bị',
      dataIndex: 'device_name',
      width: 100,
      render: (device_name) => <p className="line-clamp-2">{device_name}</p>,
    },
    {
      title: 'Loại phiếu',
      dataIndex: 'ticket_type',
      width: 100,
      render: (ticket_type) => TICKET_TYPE[ticket_type],
    },

    {
      title: 'Tổng SL trả hàng',
      dataIndex: 'sum_quantity_return',
      width: 100,
      render: (amount) => amount && formatNumber(amount?.total_quantity_return),
    },
    {
      title: 'Tổng thành tiền',
      dataIndex: 'order_detail_return_total_price',
      width: 100,
      render: (total) => total && formatCurrency(total?.total_price),
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'created_at',
      width: 100,
      render: (created_at) => created_at && formatDate(created_at, DATE_FORMAT),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'order_status',
      width: 100,
      render: (status, record) =>
        status && (
          <div className="flex gap-x-2.5 items-center">
            <Tag color={ORDER_STATUS_COLOR[status]}>{ORDER_STATUS[status]}</Tag>
            {record?.type_status === 'sumi' && (
              <Tooltip title={`SUMI ${ORDER_STATUS[status]}`} placement="top">
                <div>
                  <InformationCircleOutlined className="w-5 h-5" />
                </div>
              </Tooltip>
            )}
          </div>
        ),
    },
    {
      width: 60,
      fixed: 'right',
      render: (record) => {
        const items = renderItemActions(record);
        return <ActionDropdown dropdownItems={items} trigger="click" />;
      },
    },
  ];

  return (
    <TableTera
      zebra={false}
      columns={columns}
      loading={props?.loading || loadingDelete}
      {...props}
    />
  );
}

export default SaleOrderReturnTable;
