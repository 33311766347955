import { DATE_BACKEND_FORMAT } from '_common/constants/common';
import { messageWarning } from '_common/constants/message';
import RangeDatePicker from '_common/dof/Control/RangeDatePicker';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectIncurredCostCategory from '_common/dof/Select/SelectIncurredCostCategory';
import SelectInvestment from '_common/dof/Select/SelectInvestment';
import useConfirm from '_common/hooks/useConfirm';
import moment from 'moment';
import { INCURRED_COST_STATUS } from 'pages/Invest/IncurredCost/constants';
import { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { formatDate, Modal, Row } from 'tera-dls';
import { StatisticsByIncurredExpensesParamContext } from '.';

interface IProps {
  open: boolean;
  onClose?: () => void;
  onSubmit: (value: any) => void;
  defaultValues?: any;
}

const SettingModal = (props: IProps) => {
  const { open, defaultValues, onClose, onSubmit } = props;
  const form = useForm({ mode: 'onChange' });
  const confirm = useConfirm();
  const isDirty = form?.formState?.isDirty;

  const invest_id = form?.watch('invest_id');
  const incurred_cost_id = form?.watch('incurred_cost_id');

  const { setContextParams } = useContext(
    StatisticsByIncurredExpensesParamContext,
  );

  const handleSubmitForm = (value: any): void => {
    const data = {
      ...value,
      date_from:
        value?.date_from && formatDate(value?.date_from, DATE_BACKEND_FORMAT),
      date_to:
        value?.date_to && formatDate(value?.date_to, DATE_BACKEND_FORMAT),
    };
    setContextParams(data);
    onSubmit(data);
    onClose();
  };

  const handleCloseConfirm = () => {
    if (isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>{messageWarning.WARNING_EXIT_1}</p>
            <p>{messageWarning.WARNING_EXIT_2}</p>
          </>
        ),
        onOk: () => {
          onClose();
        },
      });
    } else onClose();
  };

  useEffect(() => {
    if (defaultValues) {
      form.reset({
        ...defaultValues,
        date_from:
          defaultValues?.date_from &&
          moment(defaultValues?.date_from, DATE_BACKEND_FORMAT),
        date_to:
          defaultValues?.date_to &&
          moment(defaultValues?.date_to, DATE_BACKEND_FORMAT),
      });
    }
  }, [defaultValues]);

  const statusOptions = Object.entries(INCURRED_COST_STATUS).map(
    ([value, label]) => ({
      value,
      label,
    }),
  );

  return (
    <Modal
      title="Tham số báo cáo"
      destroyOnClose
      closeIcon={false}
      cancelText="Hủy"
      okText="Đồng ý"
      onOk={form.handleSubmit(handleSubmitForm)}
      onCancel={handleCloseConfirm}
      open={open}
      centered={true}
      className="xl:w-[1200px]"
    >
      <FormTera form={form} onSubmit={handleSubmitForm}>
        <Row className="grid grid-cols-3 gap-x-2.5 gap-y-0">
          <RangeDatePicker
            wrapperClassName="col-span-2"
            startFormName="date_from"
            endFormName="date_to"
            // startTimeRuleProps={{ isEqual: false }}
            // endTimeRuleProps={{ isEqual: false }}
            startFormItemProps={{ label: 'Từ ngày' }}
            endFormItemProps={{ label: 'Đến ngày' }}
            startInputProps={{ allowClear: false }}
            endInputProps={{ allowClear: false }}
          />
          <FormTeraItem label="Trạng thái chi phí phát sinh" name="status">
            <Select options={statusOptions} placeholder="Tất cả" allowClear />
          </FormTeraItem>
          <FormTeraItem
            label="Danh mục chi phí phát sinh"
            name="incurred_cost_cate_id"
          >
            <SelectIncurredCostCategory
              placeholder="Tất cả"
              paramsApi={{ include_id: incurred_cost_id }}
            />
          </FormTeraItem>
          <FormTeraItem label="Đầu tư" name="invest_id">
            <SelectInvestment
              placeholder="Tất cả"
              paramsApi={{ include_id: invest_id, isActive: true }}
            />
          </FormTeraItem>
        </Row>
      </FormTera>
    </Modal>
  );
};

export default SettingModal;
