import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import NoData from '_common/component/NoData';
import SumiTab from '_common/component/Sumi/Tab';
import { IconButton } from '_common/component/TableColumnCustom';
import useConfirm from '_common/hooks/useConfirm';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ArrowSmallLeftOutlined,
  Breadcrumb,
  Button,
  ItemType,
  notification,
  PencilSquareOutlined,
  Spin,
  XMarkOutlined,
} from 'tera-dls';
import ProductApi from '../../api';
import { PRODUCT_URL } from '../../url';
import ProductForm from '../Form';
import Information from './Information';
import Feedback from './Feedback';
import { PRODUCT_PERMISSION_KEY } from '_common/constants/permission';
import { usePermission } from '_common/hooks/usePermission';

function ProductDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const confirm = useConfirm();
  const queryClient = useQueryClient();
  const { hasPage } = usePermission();

  const [openForm, setOpenForm] = useState<boolean>(false);
  const [activeKey, setActiveKey] = useState<string>('information');

  const {
    data: dataDetails,
    refetch,
    isLoading,
    isError,
  } = useQuery(['get-product-detail', id], () => ProductApi.getDetail({ id }), {
    enabled: !!id,
    staleTime: 300000,
    cacheTime: 300000,
  });

  const { mutate: mutateDelete, isLoading: loadingDelete } = useMutation(
    (id: any) => ProductApi.delete({ id }),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          navigate(-1);
          queryClient.invalidateQueries(['get-product-list']);
          queryClient.invalidateQueries(['get-summary-product-list']);
        }
      },
      onError: (error: any) => {
        notification.error({
          message: error?.message,
        });
      },
    },
  );

  const handleDelete = () => {
    confirm.warning({
      title: 'Xác nhận xóa sản phẩm',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn xóa sản phẩm</p>
          <p>
            <b>{dataDetails?.name}</b> này không?
          </p>
        </div>
      ),
      onOk: () => {
        mutateDelete(dataDetails?.id);
      },
    });
  };

  const BreadcrumbItem: ItemType[] = [
    {
      title: (
        <a
          onClick={() => navigate(PRODUCT_URL.list.path)}
          className="!text-blue-400 hover:!text-blue-600"
        >
          Danh sách sản phẩm
        </a>
      ),
    },
    {
      title: 'Chi tiết sản phẩm',
    },
  ];

  const renderTab = {
    information: <Information dataDetails={dataDetails} />,
    feedback: <Feedback />,
  };

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="tera-page-form">
      <div className="page-header-sticky">
        <div className="page-header-v2">
          <div className="page-header-v2__breadcrumb">
            <IconButton
              icon={<ArrowSmallLeftOutlined />}
              callBack={() => navigate(-1)}
            />
            <Breadcrumb separator="/" items={BreadcrumbItem} />
          </div>
          <div className="page-header-v2__function">
            {hasPage(PRODUCT_PERMISSION_KEY.UPDATE) && (
              <Button
                className="page-header-v2-btn"
                prefix={<PencilSquareOutlined />}
                onClick={() => setOpenForm(true)}
                disabled={isError || isLoading}
              >
                Sửa
              </Button>
            )}
            {hasPage(PRODUCT_PERMISSION_KEY.DELETE) && !dataDetails?.status && (
              <Button
                className="page-header-v2-btn"
                type="danger"
                prefix={<XMarkOutlined />}
                onClick={handleDelete}
                disabled={isError || isLoading}
              >
                Xóa
              </Button>
            )}
          </div>
        </div>
      </div>
      <Spin spinning={isLoading || loadingDelete}>
        <div className="page-content-v2">
          <div className="bg-white rounded-[10px] px-2.5 pb-5 shadow">
            <SumiTab
              className="mb-2.5"
              items={[
                {
                  key: 'information',
                  label: 'Thông tin chi tiểt',
                  disabled: isError,
                },
                {
                  key: 'feedback',
                  label: 'Danh sách đánh giá',
                  disabled: isError,
                },
              ]}
              activeKey={activeKey}
              onChange={(key) => setActiveKey(key)}
            />
            {isError ? <NoData /> : renderTab[activeKey]}
          </div>
        </div>
      </Spin>
      {openForm && (
        <ProductForm
          open={openForm}
          id={id}
          onClose={() => setOpenForm(false)}
          onSuccess={refetch}
        />
      )}
    </div>
  );
}

export default ProductDetail;
