import { useQuery } from '@tanstack/react-query';
import NoData from '_common/component/NoData';
import {
  DATE_BACKEND_FORMAT,
  DATE_TIME_FORMAT,
  TIME_FORMAT,
} from '_common/constants/common';
import DatePicker from '_common/dof/Control/DatePicker';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import classNames from 'classnames';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Col, formatDate, Modal, Row, Spin } from 'tera-dls';
import CameraApi from '../../api/camera';

interface CameraHistory {
  id: number;
  invest_name: string;
  open: boolean;
  onCancel: () => void;
}

function CameraHistory({ id, open, invest_name, onCancel }: CameraHistory) {
  const [screen, setScreen] = useState({
    video: null,
  });

  const form = useForm({
    defaultValues: {
      date: moment(),
    },
  });

  const date = form.watch('date');

  const {
    data: dataDetails,
    refetch,
    isLoading,
  } = useQuery(
    ['get-camera-history-detail', id, date],
    () => {
      const params = {
        camID: id,
        date: formatDate(date, DATE_BACKEND_FORMAT),
        limit: 200,
      };
      return CameraApi.history({ params });
    },
    {
      enabled: !!id,
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const disabledDate = (current) => {
    return current && current > moment().endOf('day');
  };

  const [error, setError] = useState(false);

  const handleVideoError = () => {
    setError(true);
  };

  const handleVideoEnded = () => {
    const index = dataDetails?.data?.findIndex(
      (item) => item?.hisID === screen.video?.hisID,
    );

    if (index !== 0)
      setScreen({ ...screen, video: dataDetails?.data[index - 1] });
  };

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    setScreen({ video: dataDetails?.data?.[0] });
  }, [dataDetails]);

  useEffect(() => {
    setError(false);
  }, [screen?.video]);

  return (
    <Modal
      title={
        <p>
          Lịch sử camera{' '}
          <span className="text-green-500">đầu tư {invest_name}</span>
        </p>
      }
      destroyOnClose
      closeIcon={false}
      cancelText="Đóng"
      onCancel={onCancel}
      open={open}
      centered={true}
      className="sm:w-full  xl:w-[1200px] 2xl:w-[1500px]"
      okButtonProps={{ className: 'hidden' }}
      bodyClassName="!overflow-y-hidden h-[80vh]"
    >
      <Row className="grid-cols-4 gap-5 h-full">
        <Col className="col-span-3 h-full">
          <div className="rounded h-full flex flex-col rounded overflow-hidden bg-gray-300">
            <div className="p-2 bg-gray-500 flex justify-between items-center">
              <p className="text-white">
                {`[${id}] ${formatDate(screen.video?.from, DATE_TIME_FORMAT)}`}
              </p>
            </div>

            {error ? (
              <NoData text="Video không thể phát" />
            ) : (
              <video
                src={screen.video?.mp4_url || 'error'}
                controls
                autoPlay
                className="w-full h-full object-fill"
                onError={handleVideoError}
                onEnded={handleVideoEnded}
              />
            )}
          </div>
        </Col>
        <Col className="flex flex-col h-full">
          <FormTera form={form}>
            <FormTeraItem label="Ngày tạo" name="date">
              <DatePicker disabledDate={disabledDate} />
            </FormTeraItem>
          </FormTera>
          <Spin spinning={isLoading}>
            {dataDetails?.data?.length > 0 ? (
              <div className="flex flex-col gap-0 overflow-auto pr-2">
                {dataDetails?.data?.map((item) => (
                  <div
                    className={classNames(
                      'flex justify-between cursor-pointer group p-2.5 border-l-[3px] border-gray-300',
                      {
                        '!border-blue-600 !bg-blue-100':
                          screen.video?.hisID === item?.hisID,
                        'bg-gray-50': Number(formatDate(item?.from, 'HH')) % 2,
                      },
                    )}
                    onClick={() =>
                      setScreen({
                        video: item,
                      })
                    }
                  >
                    <p>
                      {formatDate(item?.from, TIME_FORMAT)} -{' '}
                      {formatDate(item?.to, TIME_FORMAT)}
                    </p>
                  </div>
                ))}
              </div>
            ) : (
              <NoData />
            )}
          </Spin>
        </Col>
      </Row>
    </Modal>
  );
}

export default CameraHistory;
