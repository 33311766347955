import Filter from '_common/component/Filter';
import { TIME_FORMAT } from '_common/constants/common';
import RangeTime from '_common/dof/Control/RangeTime';
import FormTera from '_common/dof/FormTera';
import moment from 'moment';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormItem } from 'tera-dls';

interface DeviceFilterProps {
  open: boolean;
  onClose: () => void;
  onFilter: (value) => void;
  initialValue: any;
}

function DeviceFilter({
  open,
  onClose,
  onFilter,
  initialValue,
}: DeviceFilterProps) {
  const form = useForm({ mode: 'onChange' });

  const handleSubmitForm = (values) => {
    const data = {
      time_from:
        values?.time_from && moment(values?.time_from).format(TIME_FORMAT),
      time_to: values?.time_to && moment(values?.time_to).format(TIME_FORMAT),
    };
    onFilter(data);
    onClose();
  };

  const handleReset = () => {
    const values = {
      time_from: null,
      time_to: null,
    };
    onFilter(values);
    onClose();
  };

  useEffect(() => {
    const values = {
      time_from:
        initialValue?.time_from && moment(initialValue?.time_from, TIME_FORMAT),
      time_to:
        initialValue?.time_to && moment(initialValue?.time_to, TIME_FORMAT),
    };

    form.reset(values);
  }, [initialValue]);

  return (
    <Filter
      open={open}
      onCancel={onClose}
      onClose={onClose}
      onFilter={() => form.handleSubmit(handleSubmitForm)()}
    >
      <FormTera form={form} onSubmit={form.handleSubmit(handleSubmitForm)}>
        <FormItem label="Thời gian làm việc">
          <RangeTime startFormName="time_from" endFormName="time_to" />
        </FormItem>

        <a
          className="text-red-500 text-sm font-normal cursor-pointer"
          onClick={() => handleReset()}
        >
          Hủy bộ lọc
        </a>
      </FormTera>
    </Filter>
  );
}

export default DeviceFilter;
