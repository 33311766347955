import { IRouteProps } from '_common/interface/router';
import IncurredCost from '.';
import IncurredCostDetail from './containers/Detail';
import { INCURRED_COST_URL } from './url';

export const IncurredCostRouter: IRouteProps[] = [
  {
    key: INCURRED_COST_URL.list.key,
    path: INCURRED_COST_URL.list.shortenUrl,
    component: <IncurredCost />,
  },
  {
    key: INCURRED_COST_URL.detail.key,
    path: INCURRED_COST_URL.detail.shortenUrl,
    component: <IncurredCostDetail />,
  },
];
