import { useQueryClient } from '@tanstack/react-query';
import { SUMI_SHADOW } from '_common/constants/common';
import { messageWarning } from '_common/constants/message';
import useConfirm from '_common/hooks/useConfirm';
import classNames from 'classnames';
import { useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ArrowSmallLeftSolid,
  BookmarkOutlined,
  Breadcrumb,
  Button,
  Row,
} from 'tera-dls';
import SalesExpensesFormContent, {
  ISalesExpensesFormContentRef,
} from './SalesExpensesFormContent';

const SalesExpensesForm = () => {
  const actionRef = useRef<ISalesExpensesFormContentRef>(null);
  const confirm = useConfirm();
  const queryClient = useQueryClient();
  const { id } = useParams();

  const navigate = useNavigate();

  const handleCloseConfirm = async () => {
    if (await actionRef.current?.getIsDirty()) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>{messageWarning.WARNING_EXIT_1}</p>
            <p>{messageWarning.WARNING_EXIT_2}</p>
          </>
        ),
        onOk: () => {
          navigate(-1);
        },
      });
    } else navigate(-1);
  };

  const items = [
    {
      title: (
        <a onClick={handleCloseConfirm}>
          <span className="!text-blue-400 hover:!text-blue-600">
            Danh sách chi phí bán hàng
          </span>
        </a>
      ),
    },
    {
      title: id ? 'Sửa chi phí bán hàng' : 'Thêm chi phí bán hàng',
    },
  ];

  return (
    <>
      <div className="tera-page-form">
        <div className="page-header-sticky">
          <div className="page-header-v2">
            <div className="page-header-v2__breadcrumb">
              <div
                className="page-header__breadcrumb-back cursor-pointer"
                onClick={handleCloseConfirm}
              >
                <ArrowSmallLeftSolid className="h-6 w-6" />
              </div>
              <Breadcrumb separator={'/'} items={items} />
            </div>
            <div className="page-header-v2__function">
              <Button
                htmlType="submit"
                type="success"
                onClick={async () => {
                  const isNotEditing = await actionRef?.current?.checkError();
                  isNotEditing && actionRef?.current?.submit();
                }}
                className="page-header-btn px-[12px]"
                prefix={<BookmarkOutlined />}
              >
                Lưu
              </Button>
            </div>
          </div>
        </div>
        <Row className="page-content-v2">
          <div
            className={classNames('bg-white p-2.5 rounded-[10px]', SUMI_SHADOW)}
          >
            <SalesExpensesFormContent
              ref={actionRef}
              id={id}
              onSuccess={() => {
                navigate(-1);
                queryClient.invalidateQueries(['get-expense-voucher-list']);
                queryClient.invalidateQueries([
                  'get-expense-voucher-statistic',
                ]);
              }}
            />
          </div>
        </Row>
      </div>
    </>
  );
};

export default SalesExpensesForm;
