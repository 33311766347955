import React from 'react';

function Withdraw(props) {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.66602 10.4C7.19402 10.4 7.06602 9.712 7.06602 9.6H5.46602C5.46602 10.336 5.99402 11.64 7.86602 11.936V12.8H9.46602V11.936C11.066 11.664 11.866 10.632 11.866 9.6C11.866 8.704 11.45 7.2 8.66602 7.2C7.06602 7.2 7.06602 6.696 7.06602 6.4C7.06602 6.104 7.62602 5.6 8.66602 5.6C9.70602 5.6 9.77802 6.112 9.78602 6.4H11.386C11.3752 5.855 11.1792 5.32992 10.8302 4.91116C10.4813 4.4924 10.0001 4.20491 9.46602 4.096V3.2H7.86602V4.072C6.26602 4.336 5.46602 5.368 5.46602 6.4C5.46602 7.296 5.88202 8.8 8.66602 8.8C10.266 8.8 10.266 9.344 10.266 9.6C10.266 9.856 9.77002 10.4 8.66602 10.4Z"
        fill="#E02424"
      />
      <path
        d="M0.666016 0V1.6H2.26602V15.2C2.26602 15.4122 2.3503 15.6157 2.50033 15.7657C2.65036 15.9157 2.85384 16 3.06602 16H14.266C14.4782 16 14.6817 15.9157 14.8317 15.7657C14.9817 15.6157 15.066 15.4122 15.066 15.2V1.6H16.666V0H0.666016ZM13.466 14.4H3.86602V1.6H13.466V14.4Z"
        fill="#E02424"
      />
    </svg>
  );
}

export default Withdraw;
