import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import NoData from '_common/component/NoData';
import SumiTab from '_common/component/Sumi/Tab';
import { IconButton } from '_common/component/TableColumnCustom';
import {
  INCURRED_COST_PERMISSION_KEY,
  INVESTMENT_PERMISSION_KEY,
  ORDER_INSPECTION_PERMISSION_KEY,
  ORDER_PERMISSION_KEY,
  ORDER_RETURN_PERMISSION_KEY,
  SALE_ORDER_PERMISSION_KEY,
  SALES_EXPENSES_PERMISSION_KEY,
} from '_common/constants/permission';
import useConfirm from '_common/hooks/useConfirm';
import { usePermission } from '_common/hooks/usePermission';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  ArrowSmallLeftOutlined,
  Breadcrumb,
  Button,
  getQueryParams,
  ItemType,
  notification,
  PencilSquareOutlined,
  Spin,
  TabItemType,
  updateQueryParams,
  VideoCameraOutlined,
  XMarkOutlined,
} from 'tera-dls';
import InvestmentApi from '../../api';
import { INVESTMENT_URL } from '../../url';
import Information from './Information';
import OrderInspection from './OrderInspection';
import OrderRequest from './OrderRequest';
import SaleOrder from './SaleOrder';
import SaleOrderReturn from './SaleOrderReturn';
import SalesExpenses from './SalesExpenses';
import IncurredCost from './IncurredCost';
import TimeKeeping from './TimeKeeping';
import InvestmentHistory from './InvestmentHistory';
import CameraHistory from '../CameraHistory';

function InvestmentDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const confirm = useConfirm();
  const queryClient = useQueryClient();

  const { hasPage } = usePermission();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;
  const activeKey = queryParams?.activeKey || 'information';

  const [openCameraHistory, setOpenCameraHistory] = useState({
    open: false,
    id: null,
    name: null,
  });

  const handleUpdateQueryParams = (data) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(`${INVESTMENT_URL.detail.path}/${id}${paramString}`);
  };

  const {
    data: dataDetails,
    refetch,
    isLoading,
    isError,
  } = useQuery(
    ['get-investment-detail', id],
    () => InvestmentApi.getDetail({ id }),
    {
      enabled: !!id,
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const { mutate: mutateDelete, isLoading: loadingDelete } = useMutation(
    (id: any) => InvestmentApi.delete({ id }),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          navigate(-1);
          queryClient.invalidateQueries(['get-investment-list']);
          queryClient.invalidateQueries(['get-summary-investment-list']);
        }
      },
      onError: (error: any) => {
        notification.error({
          message: error?.message,
        });
      },
    },
  );

  const handleDelete = () => {
    confirm.warning({
      title: 'Xác nhận xóa đầu tư',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn xóa đầu tư</p>
          <p>
            <b>{dataDetails?.name}</b> này không?
          </p>
        </div>
      ),
      onOk: () => {
        mutateDelete(dataDetails?.id);
      },
    });
  };

  const BreadcrumbItem: ItemType[] = [
    {
      title: (
        <a
          onClick={() => navigate(INVESTMENT_URL.list.path)}
          className="!text-blue-400 hover:!text-blue-600"
        >
          Danh sách đầu tư
        </a>
      ),
    },
    {
      title: 'Chi tiết đầu tư',
    },
  ];

  const renderItems = (): TabItemType[] => {
    const items = [
      {
        key: 'information',
        label: 'Thông tin chi tiểt',
        disabled: isError,
      },
    ];
    if (hasPage(ORDER_PERMISSION_KEY.VIEW_LIST))
      items.push({
        key: 'order-request',
        label: 'Danh sách đơn đặt hàng',
        disabled: isError,
      });
    if (hasPage(ORDER_INSPECTION_PERMISSION_KEY.VIEW_LIST))
      items.push({
        key: 'order-inspection',
        label: 'Danh sách đơn kiểm hàng',
        disabled: isError,
      });
    if (hasPage(ORDER_RETURN_PERMISSION_KEY.VIEW_LIST))
      items.push({
        key: 'sale-order-return',
        label: 'Danh sách đơn trả hàng bán',
        disabled: isError,
      });
    if (hasPage(SALE_ORDER_PERMISSION_KEY.VIEW_LIST))
      items.push({
        key: 'sale-order',
        label: 'Danh sách đơn bán hàng',
        disabled: isError,
      });
    if (true)
      items.push({
        key: 'timekeeping',
        label: 'Lịch sử chấm công',
        disabled: isError,
      });
    if (true)
      items.push({
        key: 'investment-history',
        label: 'Lịch sử nhà đầu tư',
        disabled: isError,
      });
    if (hasPage(INCURRED_COST_PERMISSION_KEY.VIEW_LIST))
      items.push({
        key: 'incurred-cost',
        label: 'Danh sách chi phí phát sinh',
        disabled: isError,
      });
    if (hasPage(SALES_EXPENSES_PERMISSION_KEY.VIEW_LIST))
      items.push({
        key: 'sales-expenses',
        label: 'Danh sách chi phí bán hàng',
        disabled: isError,
      });

    return items;
  };

  const renderTab = {
    information: <Information dataDetails={dataDetails} />,
    'order-request': <OrderRequest id={dataDetails?.id} />,
    'order-inspection': <OrderInspection id={dataDetails?.id} />,
    'sale-order-return': <SaleOrderReturn id={dataDetails?.id} />,
    'sale-order': <SaleOrder id={dataDetails?.id} />,
    timekeeping: <TimeKeeping id={dataDetails?.id} />,
    'investment-history': <InvestmentHistory id={dataDetails?.id} />,
    'incurred-cost': <IncurredCost id={dataDetails?.id} />,
    'sales-expenses': <SalesExpenses id={dataDetails?.id} />,
  };

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="tera-page-form">
      <div className="page-header-sticky">
        <div className="page-header-v2">
          <div className="page-header-v2__breadcrumb">
            <IconButton
              icon={<ArrowSmallLeftOutlined />}
              callBack={() => navigate(-1)}
            />
            <Breadcrumb separator="/" items={BreadcrumbItem} />
          </div>
          <div className="page-header-v2__function">
            {hasPage(INVESTMENT_PERMISSION_KEY.VIEW_CAMERA) &&
              dataDetails?.camera_id && (
                <Button
                  type="success"
                  className="page-header-v2-btn"
                  prefix={<VideoCameraOutlined />}
                  disabled={isError || isLoading}
                  onClick={() =>
                    setOpenCameraHistory({
                      open: true,
                      id: dataDetails?.camera_id,
                      name: dataDetails?.name,
                    })
                  }
                >
                  Lịch sử camera
                </Button>
              )}
            {hasPage(INVESTMENT_PERMISSION_KEY.UPDATE) && (
              <Button
                className="page-header-v2-btn"
                prefix={<PencilSquareOutlined />}
                onClick={() => navigate(`${INVESTMENT_URL.update.path}/${id}`)}
                disabled={isError || isLoading}
              >
                Sửa
              </Button>
            )}
            {hasPage(INVESTMENT_PERMISSION_KEY.DELETE) &&
              !dataDetails?.status && (
                <Button
                  className="page-header-v2-btn"
                  type="danger"
                  prefix={<XMarkOutlined />}
                  onClick={handleDelete}
                  disabled={isError || isLoading}
                >
                  Xóa
                </Button>
              )}
          </div>
        </div>
      </div>
      <Spin spinning={isLoading || loadingDelete}>
        <div className="page-content-v2">
          <div className="bg-white rounded-[10px] px-2.5 pb-5 shadow">
            <SumiTab
              className="mb-2.5"
              items={renderItems()}
              activeKey={activeKey}
              onChange={(key) => handleUpdateQueryParams({ activeKey: key })}
            />
            {isError ? <NoData /> : renderTab[activeKey]}
          </div>
        </div>
      </Spin>
      {openCameraHistory.open && (
        <CameraHistory
          id={openCameraHistory.id}
          open={openCameraHistory.open}
          onCancel={() =>
            setOpenCameraHistory({ open: false, id: null, name: null })
          }
          invest_name={openCameraHistory?.name}
        />
      )}
    </div>
  );
}

export default InvestmentDetail;
