import { useQuery } from '@tanstack/react-query';
import { TParamsApiDof } from '_common/dof/interfaces';
import { useDebounce } from '_common/hooks/useDebounce';
import { forwardRef, useMemo, useState } from 'react';
import { OptionProps } from 'tera-dls';
import { SelectProps } from 'tera-dls/lib/components/Select';
import SelectEntity from '../SelectEntity';
import SumiApi from '_common/dof/_api';

interface SelectRoleProps extends SelectProps {
  paramsApi?: TParamsApiDof;
}

const SelectRole = forwardRef<HTMLInputElement, SelectRoleProps>(
  ({ placeholder = 'Vui lòng chọn', paramsApi, ...props }, ref) => {
    const [search, setSearch] = useState<string>('');
    const searchDebounce = useDebounce(search, 300);

    const { data: permissionList, isLoading } = useQuery(
      ['get-permission-list', searchDebounce, paramsApi],
      () => {
        const params = {
          page: 1,
          limit: 15,
          keyword: searchDebounce,
          ...paramsApi,
        };
        return SumiApi.getPermissionList({ params });
      },
      {
        staleTime: 300000,
        cacheTime: 300000,
      },
    );

    const options: OptionProps[] = useMemo(() => {
      return (
        permissionList?.data.map((permission) => ({
          label: permission?.name,
          value: permission?.id,
          labelDisplay: (
            <p>
              <span className="text-green-500">[{permission?.code}]</span> -{' '}
              <span>{permission?.name}</span>
            </p>
          ),
        })) || []
      );
    }, [permissionList]);

    return (
      <SelectEntity
        ref={ref}
        allowClear
        showSearch
        labelInValue
        loading={isLoading}
        placeholder={placeholder}
        options={options}
        searchValue={search}
        onSearch={setSearch}
        {...props}
      />
    );
  },
);

export default SelectRole;
