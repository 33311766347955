import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AuthApi } from 'states/api';

import { useStores } from '_common/hooks';

import { onMessageListener, requestForToken } from 'firebase.js';
import moment from 'moment';
import NotificationApi from 'states/api/notification';
import { notification } from 'tera-dls';
import { Routers } from './routers';

const basename = document.querySelector('base')?.getAttribute('href') ?? '/';
moment.updateLocale('vn', {
  week: {
    dow: 1,
  },
});
const Root = observer(() => {
  const queryClient = useQueryClient();
  const {
    authStore,
    commonStore: { setBusiness },
  } = useStores();

  // onMessageListener()

  useEffect(() => {
    const handleNotification = (payload) => {
      queryClient.invalidateQueries(['get-summary-notification-list']);
      notification.open({
        message: payload?.data?.title,
        description: payload?.data?.content,
      });
    };

    const unsubscribe = onMessageListener(handleNotification);

    return () => {
      unsubscribe();
    };
  }, []);

  useQuery(['get_device'], AuthApi.getDeviceCode, {
    staleTime: 300000,
    // enabled: !authStore.device,
    onSuccess: (data) => {
      authStore.setInitData(data);
    },
  });

  useQuery(['get_profile'], AuthApi.getProfile, {
    staleTime: 300000,
    enabled: !!authStore.token,
    onSuccess: (res) => {
      setBusiness({
        id: res?.data?.business?.id,
        name: res?.data?.business?.name,
        ownerName: res?.data?.business?.owner_name,
      });

      const permissions = res?.data?.role?.role_has_permission?.map(
        (item) => item?.page_control?.permission_key,
      );

      authStore.updatePermissions(permissions);
      authStore.updateUser({ user: res?.data });
    },
  });

  // useQuery(['get_permissions'], AuthApi.getPermissions, {
  //   staleTime: 300000,
  //   enabled: !!authStore.token,
  //   onSuccess: (res) => {
  //     authStore.updatePermissions(res?.data);
  //   },
  // });

  const { mutate: mutationReadNotify } = useMutation(async () => {
    const token = await requestForToken();

    const params = {
      user_id: authStore?.user?.id,
      token,
    };
    return NotificationApi.addToken({ params });
  });

  useEffect(() => {
    mutationReadNotify();
  }, []);

  return (
    <BrowserRouter basename={basename}>
      <Routers />
    </BrowserRouter>
  );
});

export default Root;
