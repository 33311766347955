import { IRouteProps } from '_common/interface/router';
import { ORDER_INSPECTION_URL } from './url';
import OrderInspection from '.';
import OrderInspectionDetail from './containers/Detail';
import OrderInspectionForm from './containers/Form';

export const OrderInspectionRouter: IRouteProps[] = [
  {
    key: ORDER_INSPECTION_URL.list.key,
    path: ORDER_INSPECTION_URL.list.shortenUrl,
    component: <OrderInspection />,
  },
  {
    key: ORDER_INSPECTION_URL.inspection.key,
    path: ORDER_INSPECTION_URL.inspection.shortenUrl,
    component: <OrderInspectionForm />,
  },
  {
    key: ORDER_INSPECTION_URL.detail.key,
    path: ORDER_INSPECTION_URL.detail.shortenUrl,
    component: <OrderInspectionDetail />,
  },
];
