import { useMutation, useQuery } from '@tanstack/react-query';
import NoData from '_common/component/NoData';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { messageValidate, messageWarning } from '_common/constants/message';
import CheckBox from '_common/dof/Control/CheckBox';
import Input from '_common/dof/Control/Input';
import InputPassword from '_common/dof/Control/InputPassword';
import TextArea from '_common/dof/Control/TextArea';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import useConfirm from '_common/hooks/useConfirm';
import { pick } from 'lodash';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, notification, Spin } from 'tera-dls';
import UserApi from '../../api';

interface CameraFormProps {
  id: string | number;
  open: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
}

function CameraForm(props: CameraFormProps) {
  const { id, open, onClose, onSuccess } = props;
  const form = useForm({
    mode: 'onChange',
    defaultValues: { status: true, port: '21' },
  });
  const confirm = useConfirm();
  const { isDirty } = form.formState;

  const {
    data: dataDetails,
    refetch,
    isLoading,
    isError,
  } = useQuery(['get-user-detail', id], () => UserApi.getDetail({ id }), {
    enabled: !!id,
    staleTime: 300000,
    cacheTime: 300000,
  });

  const { mutate: mutateUser, isLoading: isLoadingMutate } = useMutation(
    (params: any) => {
      if (id) return UserApi.update({ id, params });
      return UserApi.create({ params });
    },
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          onClose && onClose();
          onSuccess && onSuccess();
        }
      },
      onError: (error: any) => {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleCloseConfirm = () => {
    if (isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>{messageWarning.WARNING_EXIT_1}</p>
            <p>{messageWarning.WARNING_EXIT_2}</p>
          </>
        ),
        onOk: () => {
          onClose();
        },
      });
    } else onClose();
  };

  const handleSubmitForm = (value) => {
    if ((isLoading && !!id) || isLoadingMutate) return;
    const data = {
      ...value,
      storage_url: '/ftp-api/assets/upload',
      status: value?.status ? 'active' : 'inactive',
    };
    mutateUser(data);
  };

  useEffect(() => {
    if (dataDetails) {
      const values = pick(dataDetails, [
        'name',
        'camID',
        'ip',
        'port',
        'username',
        'password',
        'status',
        'description',
      ]);

      const data = {
        ...values,
        status: values?.status === 'active' ? true : false,
      };

      form.reset(data);
    }
  }, [dataDetails]);

  useEffect(() => {
    if (id) refetch();
  }, [id]);

  return (
    <Modal
      title={id ? 'Sửa camera' : 'Thêm camera'}
      destroyOnClose
      closeIcon={false}
      cancelText="Hủy"
      okText="Đồng ý"
      onOk={() => form.handleSubmit(handleSubmitForm)()}
      onCancel={handleCloseConfirm}
      open={open}
      centered={true}
      className="sm:w-[500px]"
      confirmLoading={(isLoading && !!id) || isLoadingMutate}
    >
      <Spin spinning={(isLoading && !!id) || isLoadingMutate}>
        {isError && !!id ? (
          <NoData />
        ) : (
          <FormTera form={form}>
            <FormTeraItem
              label="Tên camera"
              name="name"
              rules={[
                {
                  required: messageValidate.emptyText,
                },
              ]}
            >
              <Input />
            </FormTeraItem>
            <FormTeraItem
              label="Camera ID"
              name="camID"
              rules={[
                {
                  required: messageValidate.emptyText,
                },
              ]}
            >
              <Input />
            </FormTeraItem>
            <FormTeraItem
              label="IP"
              name="ip"
              rules={[
                {
                  required: messageValidate.emptyText,
                },
              ]}
            >
              <Input />
            </FormTeraItem>
            <FormTeraItem
              label="Port"
              name="port"
              rules={[
                {
                  required: messageValidate.emptyText,
                },
              ]}
            >
              <Input />
            </FormTeraItem>
            <FormTeraItem
              label="Tài khoản"
              name="username"
              rules={[
                {
                  required: messageValidate.emptyText,
                },
              ]}
            >
              <Input />
            </FormTeraItem>

            <FormTeraItem
              label="Mật khẩu"
              name="password"
              rules={
                !id
                  ? [
                      {
                        required: messageValidate.emptyText,
                      },
                    ]
                  : []
              }
            >
              <InputPassword />
            </FormTeraItem>

            <FormTeraItem label="Mô tả" name="description">
              <TextArea rows={5} />
            </FormTeraItem>
            <FormTeraItem name="status">
              <CheckBox labelClassName="font-normal">Hoạt động</CheckBox>
            </FormTeraItem>
          </FormTera>
        )}
      </Spin>
    </Modal>
  );
}

export default CameraForm;
