import React, { useCallback, useContext, useEffect } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';
import useConfirm from './useConfirm';

const useConfirmExit = (
  confirmExit: (confirmNavigation: () => void) => void,
  when = true,
) => {
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    if (!when) return;

    const originalPush = navigator.push;
    const originalReplace = navigator.replace;
    const originalGo = navigator.go;

    navigator.push = (...args: Parameters<typeof originalPush>) => {
      const confirm = () => originalPush(...args);
      confirmExit(confirm);
    };

    navigator.replace = (...args: Parameters<typeof originalReplace>) => {
      const confirm = () => originalReplace(...args);
      confirmExit(confirm);
    };

    navigator.go = (...args: Parameters<typeof originalGo>) => {
      const confirm = () => originalGo(...args);
      confirmExit(confirm);
    };

    return () => {
      navigator.push = originalPush;
      navigator.replace = originalReplace;
      navigator.go = originalGo;
    };
  }, [navigator, confirmExit, when]);
};

export const usePrompt = (
  message?: string | React.ReactNode,
  when = true,
  onContinue?: () => void,
) => {
  const confirm = useConfirm();

  const confirmExit = useCallback(
    (confirmNavigation: () => void) => {
      if (!message) {
        if (onContinue) onContinue();
        confirmNavigation();
        return;
      }
      const onOk = () => {
        if (onContinue) onContinue();
        confirmNavigation();
      };

      confirm.warning({
        title: 'Thoát bản ghi',
        content: message,
        onOk,
      });
    },
    [message, onContinue],
  );

  useConfirmExit(confirmExit, when);
};
