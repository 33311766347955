import { useMutation, useQuery } from '@tanstack/react-query';
import CardFormV2 from '_common/component/CardForm/CardFormV2';
import NoData from '_common/component/NoData';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { REGEX, TIME_FORMAT } from '_common/constants/common';
import {
  messageValidate,
  messageValidateLength,
  messageWarning,
} from '_common/constants/message';
import Input from '_common/dof/Control/Input';
import InputPassword from '_common/dof/Control/InputPassword';
import RangeTime from '_common/dof/Control/RangeTime';
import TextArea from '_common/dof/Control/TextArea';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectInvestment from '_common/dof/Select/SelectInvestment';
import useConfirm from '_common/hooks/useConfirm';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { formatDate, FormItem, Modal, notification, Spin } from 'tera-dls';
import DeviceApi from '../../api';
import moment from 'moment';

interface DeviceFormProps {
  id: string | number;
  open: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
}

function DeviceForm(props: DeviceFormProps) {
  const { id, open, onClose, onSuccess } = props;
  const form = useForm({ mode: 'onChange' });
  const confirm = useConfirm();
  const { isDirty } = form.formState;

  const {
    data: dataDetails,
    refetch,
    isLoading,
    isError,
  } = useQuery(['get-device-detail', id], () => DeviceApi.getDetail({ id }), {
    enabled: !!id,
    staleTime: 300000,
    cacheTime: 300000,
  });

  const { mutate: mutateDevice, isLoading: isLoadingMutate } = useMutation(
    (params: any) => {
      if (id) return DeviceApi.update({ id, params });
      return DeviceApi.create({ params });
    },
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          onClose && onClose();
          onSuccess && onSuccess();
        }
      },
      onError: (error: any) => {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleCloseConfirm = () => {
    if (isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>{messageWarning.WARNING_EXIT_1}</p>
            <p>{messageWarning.WARNING_EXIT_2}</p>
          </>
        ),
        onOk: () => {
          onClose();
        },
      });
    } else onClose();
  };

  const handleSubmitForm = (values) => {
    if ((isLoading && !!id) || isLoadingMutate) return;

    const data = {
      ...values,
      time_from:
        values?.time_from && formatDate(values?.time_from, TIME_FORMAT),
      time_to: values?.time_to && formatDate(values?.time_to, TIME_FORMAT),
    };

    mutateDevice(data);
  };

  useEffect(() => {
    if (dataDetails) {
      const data = {
        name: dataDetails?.full_name,
        invest_id: dataDetails?.invest?.id,
        time_from:
          dataDetails?.time_from && moment(dataDetails?.time_from, TIME_FORMAT),
        time_to:
          dataDetails?.time_to && moment(dataDetails?.time_to, TIME_FORMAT),
        description: dataDetails?.description,
        username: dataDetails?.username,
      };
      form.reset(data);
    }
  }, [dataDetails]);

  useEffect(() => {
    if (id) refetch();
  }, [id]);

  return (
    <Modal
      title={id ? 'Sửa thiết bị' : 'Thêm thiết bị'}
      destroyOnClose
      closeIcon={false}
      cancelText="Hủy"
      okText="Đồng ý"
      onOk={() => form.handleSubmit(handleSubmitForm)()}
      onCancel={handleCloseConfirm}
      open={open}
      centered={true}
      className="sm:w-[500px]"
      confirmLoading={(isLoading && !!id) || isLoadingMutate}
    >
      <Spin spinning={(isLoading && !!id) || isLoadingMutate}>
        {isError && !!id ? (
          <NoData />
        ) : (
          <FormTera form={form}>
            <CardFormV2 title="Thông tin làm việc">
              <FormTeraItem
                label="Tên thiết bị"
                name="name"
                rules={[
                  {
                    required: messageValidate.emptyText,
                    maxLength: {
                      value: 255,
                      message: messageValidateLength.textLong,
                    },
                  },
                ]}
              >
                <Input />
              </FormTeraItem>
              <FormTeraItem
                label="Đầu tư"
                name="invest_id"
                rules={[
                  {
                    required: messageValidate.emptyText,
                  },
                ]}
              >
                <SelectInvestment
                  paramsApi={{
                    include_id: form.watch('invest_id'),
                    isActive: true,
                    is_device: true,
                  }}
                />
              </FormTeraItem>
              <FormItem label="Thời gian làm việc">
                <RangeTime
                  startFormName="time_from"
                  endFormName="time_to"
                  startTimeRuleProps={{ isEqual: false }}
                  endTimeRuleProps={{ isEqual: false }}
                  startFormItemProps={{
                    rules: [{ required: messageValidate.emptySelect }],
                  }}
                  endFormItemProps={{
                    rules: [{ required: messageValidate.emptySelect }],
                  }}
                />
              </FormItem>

              <FormTeraItem
                label="Ghi chú"
                name="description"
                rules={[
                  {
                    maxLength: {
                      value: 1000,
                      message: messageValidateLength.textArea,
                    },
                  },
                ]}
              >
                <TextArea rows={10} />
              </FormTeraItem>
            </CardFormV2>

            <CardFormV2 title="Thông tin đăng nhập">
              <FormTeraItem
                label="Tên đăng nhập"
                name="username"
                rules={[
                  {
                    required: messageValidate.emptyText,
                    maxLength: {
                      value: 15,
                      message: 'Không nhập quá 15 kí tự',
                    },
                    pattern: {
                      value: new RegExp(REGEX.USERNAME),
                      message: messageValidate.username,
                    },
                  },
                ]}
              >
                <Input />
              </FormTeraItem>
              {!id && (
                <FormTeraItem
                  label="Mật khẩu"
                  name="password"
                  rules={[
                    {
                      required: messageValidate.emptyText,
                      pattern: {
                        value: new RegExp(REGEX.PASSWORD),
                        message: messageValidate.password,
                      },
                      minLength: {
                        value: 8,
                        message: messageValidate.min_length_password,
                      },
                      maxLength: {
                        value: 16,
                        message: messageValidate.max_length_password,
                      },
                    },
                  ]}
                >
                  <InputPassword />
                </FormTeraItem>
              )}
            </CardFormV2>
          </FormTera>
        )}
      </Spin>
    </Modal>
  );
}

export default DeviceForm;
