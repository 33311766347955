import { useMutation, useQuery } from '@tanstack/react-query';
import CardFormV2 from '_common/component/CardForm/CardFormV2';
import HoverQuickView from '_common/component/HoverQuickView';
import { IconButton } from '_common/component/TableColumnCustom';
import { DATE_TIME_FORMAT, SUMI_SHADOW } from '_common/constants/common';
import { SALE_ORDER_PERMISSION_KEY } from '_common/constants/permission';
import { INVESTMENT_CATEGORY } from '_common/dof/Select/SelectInvestmentCategory';
import { TICKET_TYPE } from '_common/dof/Select/SelectTicketType';
import TableTera from '_common/dof/TableTera';
import { usePermission } from '_common/hooks/usePermission';
import classNames from 'classnames';
import printJS from 'print-js';
import { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PortalApi from 'states/api/portal';
import {
  ArrowSmallLeftOutlined,
  Breadcrumb,
  Button,
  ColumnsType,
  formatCurrency,
  formatDate,
  Image,
  ItemType,
  notification,
  PrinterOutlined,
  Row,
  Spin,
} from 'tera-dls';
import OrderApi from '../../api';
import { SALE_ORDER_URL } from '../../url';

function SaleOrderDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { hasPage } = usePermission();

  const {
    data: dataDetails,
    refetch,
    isLoading,
    isError,
  } = useQuery(['get-order-detail', id], () => OrderApi.getDetail({ id }), {
    enabled: !!id,
    staleTime: 300000,
    cacheTime: 300000,
  });

  const { mutate: mutatePrint, isLoading: loadingPrint } = useMutation(
    (params: any) => PortalApi.print({ params }),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          printJS({
            printable: res?.data,
          });
        }
      },
      onError: (error: any) => {
        notification.error({
          message: error?.message,
        });
      },
    },
  );

  const handlePrint = () => {
    mutatePrint({
      export_pdf: 1,
      order_id: id,
      type: 'sell',
    });
  };

  const BreadcrumbItem: ItemType[] = [
    {
      title: (
        <a
          onClick={() => navigate(SALE_ORDER_URL.list.path)}
          className="!text-blue-400 hover:!text-blue-600"
        >
          Danh sách đơn bán hàng
        </a>
      ),
    },
    {
      title: 'Chi tiết đơn bán hàng',
    },
  ];

  const detail = [
    {
      label: 'Đầu tư',
      value: (
        <HoverQuickView
          name={dataDetails?.invest_name}
          avatarUrl={dataDetails?.invest_id?.file_upload}
          code={dataDetails?.invest_id?.code}
          sub={dataDetails?.invest_id?.description}
        >
          {dataDetails?.invest_name}
        </HoverQuickView>
      ),
    },
    {
      label: 'Mã đơn hàng',
      value: dataDetails?.code,
    },
    {
      label: 'Người tạo',
      value: (
        <HoverQuickView
          avatarUrl={dataDetails?.created_by?.avatar}
          name={dataDetails?.created_by?.full_name}
          code={dataDetails?.created_by?.code}
          email={dataDetails?.created_by?.email}
          phone={dataDetails?.created_by?.phone}
        >
          {dataDetails?.created_by?.full_name}
        </HoverQuickView>
      ),
    },
    {
      label: 'Danh mục đầu tư',
      value: INVESTMENT_CATEGORY[dataDetails?.type],
    },
    {
      label: 'Tên thiết bị',
      value: dataDetails?.device_name,
    },
    {
      label: 'Ngày tạo',
      value:
        dataDetails?.created_at &&
        formatDate(dataDetails?.created_at, DATE_TIME_FORMAT),
    },
    {
      label: 'Nhà đầu tư',
      value: (
        <HoverQuickView name={dataDetails?.investor_name || 'Hệ thống'}>
          {dataDetails?.investor_name || 'Hệ thống'}
        </HoverQuickView>
      ),
    },
    {
      label: 'Loại phiếu',
      value: TICKET_TYPE[dataDetails?.ticket_type],
    },
    ...(dataDetails?.ticket_type === 'request'
      ? [
          {
            label: 'Thời gian nhận',
            value:
              dataDetails?.receipt_date &&
              formatDate(dataDetails?.receipt_date, DATE_TIME_FORMAT),
          },
          {
            label: 'Ghi chú thông tin khách hàng',
            value: dataDetails?.customer_note,
          },
          {
            label: 'Ghi chú mô tả',
            value: dataDetails?.note,
          },
        ]
      : []),
  ];

  const columns: ColumnsType<any> = [
    {
      title: 'Tên sản phẩm',
      dataIndex: 'name',
      width: 200,
      render: (name, record) => {
        const image =
          dataDetails?.ticket_type === 'order'
            ? record?.file_upload
            : record?.product_image_request;

        return (
          <div className="flex gap-x-2.5">
            <Image
              src={image}
              alt={image}
              containerClassName="w-[30px] h-[30px] rounded overflow-hidden border shrink-0"
              imageClassName="object-cover"
            />
            <p className="line-clamp-2 break-word">{name}</p>
          </div>
        );
      },
    },
    {
      title: 'Tên viết tắt',
      dataIndex: 'abbreviation',
      width: 100,
    },
    {
      title: 'Danh mục',
      dataIndex: 'catalog_name',
      width: 200,
      render: (catalog_name) => <p className="line-clamp-2">{catalog_name}</p>,
    },
    {
      title: 'Đơn vị',
      dataIndex: 'unit_name',
      width: 100,
      render: (unit_name) => unit_name,
    },
    {
      title: 'Số lượng đặt',
      dataIndex: 'quantity_request',
      width: 150,
    },
    {
      title: 'Số lượng thực nhận',
      dataIndex: 'quantity_check',
      width: 150,
    },
    {
      title: 'Số lượng trả hàng',
      dataIndex: 'quantity_return',
      width: 150,
    },
    {
      title: 'Số lượng bán',
      dataIndex: 'quantity_sold',
      width: 150,
    },
    {
      title: 'Đơn giá',
      dataIndex: 'amount',
      width: 150,
      render: (amount) => amount && formatCurrency(amount),
    },
    {
      title: 'Thành tiền',
      dataIndex: 'total',
      width: 200,
      render: (total) => formatCurrency(total),
    },
  ];

  const products = useMemo(() => {
    if (!dataDetails?.order_detail) return;
    const data = dataDetails?.order_detail?.map((prod) => ({
      name: prod?.product_name,
      abbreviation: prod?.abbreviation,
      catalog_name: prod?.catalog_name,
      unit_name: prod?.unit_name,
      file_upload: prod?.image,
      amount: prod?.amount,
      quantity_request: prod?.quantity_request,
      quantity_check: prod?.quantity_check,
      quantity_return: prod?.quantity_return,
      quantity_sold: prod?.quantity_sold,
      total: prod?.amount * prod?.quantity_sold,
      product_image_request: prod?.product_image_request?.[0],
    }));
    return data;
  }, [dataDetails]);

  const total = useMemo(() => {
    const sum = dataDetails?.order_detail?.reduce((total, current) => {
      return total + current?.quantity_sold * current?.amount;
    }, 0);
    return sum;
  }, [dataDetails]);

  useEffect(() => {
    refetch();
  }, []);

  const disabled = isError || isLoading || loadingPrint;

  return (
    <div className="tera-page-form">
      <div className="page-header-sticky">
        <div className="page-header-v2">
          <div className="page-header-v2__breadcrumb">
            <IconButton
              icon={<ArrowSmallLeftOutlined />}
              callBack={() => navigate(-1)}
            />
            <Breadcrumb separator="/" items={BreadcrumbItem} />
          </div>
          <div className="page-header-v2__function">
            {hasPage(SALE_ORDER_PERMISSION_KEY.PRINT) && (
              <Button
                type="success"
                className="page-header-v2-btn bg-green-500 hover:bg-green-600"
                onClick={handlePrint}
                disabled={disabled}
                prefix={<PrinterOutlined />}
              >
                In đơn
              </Button>
            )}
          </div>
        </div>
      </div>
      <Spin spinning={disabled}>
        <div className="page-content-v2">
          <div className="bg-white rounded-[10px] px-2.5 py-5 shadow">
            <div className="flex flex-col gap-4">
              <div
                className={classNames(
                  'px-2.5 py-5 rounded-md border border-blue-400',
                  SUMI_SHADOW,
                )}
              >
                <CardFormV2 title="Thông tin chung">
                  <Row className="grid grid-cols-3 gap-2.5">
                    {detail.map((item, index) => (
                      <div className="flex items-start gap-2.5" key={index}>
                        <div className="detail-key">{item.label}</div>
                        <div className="detail-value">{item.value}</div>
                      </div>
                    ))}
                  </Row>
                </CardFormV2>
              </div>
              <div
                className={classNames(
                  'px-2.5 py-5 rounded-md border border-blue-400',
                  SUMI_SHADOW,
                )}
              >
                <CardFormV2 title="Sản phẩm">
                  <TableTera
                    zebra={false}
                    data={products}
                    columns={columns}
                    pagination={{}}
                    hiddenColumns={
                      dataDetails?.ticket_type === 'request' && [
                        'abbreviation',
                        'catalog_name',
                        'unit_name',
                      ]
                    }
                  />
                  <div className="ml-auto max-w-max p-2.5 text-base">
                    <span className="text-gray-800">Tổng thành tiền: </span>
                    <span className="text-green-500 font-medium">
                      {formatCurrency(total)}
                    </span>
                  </div>
                </CardFormV2>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
}

export default SaleOrderDetail;
