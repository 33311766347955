import { IRouteProps } from '_common/interface/router';
import { SALE_ORDER_RETURN_URL } from './url';
import SaleOrderReturn from '.';
import SaleOrderReturnDetail from './containers/Detail';
import SaleOrderReturnForm from './containers/Form';

export const SaleOrderReturnRouter: IRouteProps[] = [
  {
    key: SALE_ORDER_RETURN_URL.list.key,
    path: SALE_ORDER_RETURN_URL.list.shortenUrl,
    component: <SaleOrderReturn />,
  },
  {
    key: SALE_ORDER_RETURN_URL.create.key,
    path: SALE_ORDER_RETURN_URL.create.shortenUrl,
    component: <SaleOrderReturnForm />,
  },
  {
    key: SALE_ORDER_RETURN_URL.update.key,
    path: SALE_ORDER_RETURN_URL.update.shortenUrl,
    component: <SaleOrderReturnForm />,
  },
  {
    key: SALE_ORDER_RETURN_URL.detail.key,
    path: SALE_ORDER_RETURN_URL.detail.shortenUrl,
    component: <SaleOrderReturnDetail />,
  },
];
