import { INVOICE_PERMISSION_KEY } from '_common/constants/permission';

export const INVOICE_URL = {
  list: {
    key: INVOICE_PERMISSION_KEY.VIEW_LIST,
    path: '/finance/invoice',
    shortenUrl: 'invoice',
  },
  detail: {
    key: INVOICE_PERMISSION_KEY.VIEW_DETAIL,
    path: '/finance/invoice',
    shortenUrl: 'invoice/:id',
  },
};
