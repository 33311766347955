import { useQuery } from '@tanstack/react-query';
import TableTera from '_common/dof/TableTera';
import ProductApi from 'pages/Sale/Product/api';
import { useState } from 'react';
import {
  AnyObject,
  ColumnsType,
  formatCurrency,
  Image,
  Modal,
  PaginationProps,
  TableRowSelection,
} from 'tera-dls';
import SearchProduct from './SearchProduct';

interface ModalSelectProductInvestmentProps {
  open: boolean;
  paramsApi?: any;
  onCancel: () => void;
  onOk?: (selectedRows, selectedRowKeys) => void;
}

function ModalSelectProductInvestment(
  props: ModalSelectProductInvestmentProps,
) {
  const { open, paramsApi, onCancel, onOk } = props;
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  const [pagination, setPagination] = useState<any>({
    page: 1,
    limit: 10,
  });

  const { data: productList, isLoading } = useQuery(
    ['get-product-list-investment', pagination, searchKeyword, paramsApi],
    () => {
      const params = {
        page: pagination.page || 1,
        limit: pagination.limit || 10,
        keyword: searchKeyword,
        status: 1,
        ...paramsApi,
      };
      return ProductApi.getListInvestment({ params });
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    const isDiffPageSize =
      Number(pageSize) !== Number(productList?.limit || 10);

    setPagination({ page: isDiffPageSize ? 1 : page, limit: pageSize });
  };

  const handleSearch = (value) => {
    setSearchKeyword(value?.keyword);
    setPagination({ ...pagination, page: 1 });
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Tên sản phẩm',
      dataIndex: 'name',
      width: 200,
      render: (_, record) => (
        <div className="flex gap-x-2.5">
          <Image
            src={record?.product_warehouse?.file_upload}
            alt={record?.product_warehouse?.file_upload}
            containerClassName="w-[30px] h-[30px] rounded overflow-hidden border shrink-0"
          />
          <p className="line-clamp-2 break-word">
            {record?.product_warehouse?.name}
          </p>
        </div>
      ),
    },
    {
      title: 'Tên viết tắt',
      dataIndex: 'abbreviation',
      width: 200,
    },
    {
      title: 'Danh mục',
      dataIndex: 'warehouse_cata_log',
      width: 200,
      render: (_, record) => (
        <p className="line-clamp-2">
          {record?.product_warehouse?.warehouse_cata_log?.name}
        </p>
      ),
    },
    {
      title: 'Đơn vị',
      dataIndex: 'product_unit_id',
      width: 100,
      render: (_, record) => record?.product_warehouse?.product_unit_id?.name,
    },
    {
      title: 'Đơn giá',
      dataIndex: 'amount',
      width: 100,
      render: (amount) => amount && formatCurrency(amount),
    },
  ];

  const rowSelection: TableRowSelection<AnyObject> = {
    type: 'checkbox',
    selectedRowKeys: selectedRowKeys,
    onChange: (selectedRowKeys, record) => {
      setSelectedRows(record);
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  const handleOk = () => {
    onOk(selectedRows, selectedRowKeys);
    onCancel();
  };

  return (
    <Modal
      title="Danh sách sản phẩm"
      destroyOnClose
      closeIcon={false}
      cancelText="Hủy"
      okText="Đồng ý"
      open={open}
      centered={true}
      className="sm:w-[90%] xl:w-[80%]"
      onCancel={onCancel}
      onOk={handleOk}
    >
      <div className="flex items-center gap-x-2.5 mb-2.5">
        <SearchProduct onSearch={handleSearch} />
        {selectedRowKeys.length > 0 && (
          <span>Đã chọn: {selectedRowKeys.length}</span>
        )}
      </div>
      <TableTera
        loading={isLoading}
        columns={columns}
        data={productList?.data || []}
        rowSelection={rowSelection}
        pagination={{
          onChange: handleChangePage,
          total: productList?.total || 0,
          current: productList?.current_page,
          pageSize: productList?.per_page || 10,
          to: productList?.to,
          from: productList?.from || 10,
        }}
        onRow={(record: any) => ({
          onClick: () => {
            if (!selectedRowKeys?.includes(record?.id)) {
              setSelectedRows((pre) => [...(pre ?? []), record]);
              setSelectedRowKeys((pre) => [...pre, record?.id]);
              return;
            }
            const filterData = selectedRows?.filter(
              (item) => item?.id !== record?.id,
            );
            const filterId = filterData.map((item) => item?.id);
            setSelectedRows(filterData);
            setSelectedRowKeys(filterId);
          },
          className: selectedRowKeys.includes(record?.id) && '!bg-[#e8f5ff]',
        })}
      />
    </Modal>
  );
}

export default ModalSelectProductInvestment;
