import { useMutation, useQueryClient } from '@tanstack/react-query';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import TableTera from '_common/dof/TableTera';
import { ITeraTableProps } from '_common/dof/TableTera/_interfaces';
import useConfirm from '_common/hooks/useConfirm';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ColumnsType,
  DropdownItem,
  formatCurrency,
  formatNumber,
  notification,
} from 'tera-dls';
import PaymentMethodApi from '../../api';
import { PAYMENT_METHOD_URL } from '../../url';
import PaymentMethodDetail from '../Detail';
import QrCode from '../QR';
import { usePermission } from '_common/hooks/usePermission';
import { PAYMENT_METHOD_PERMISSION_KEY } from '_common/constants/permission';

function PaymentMethodTable(props: ITeraTableProps) {
  const [isOpenDetail, setIsOpenDetail] = useState({ open: false, id: null });
  const [isOpenQR, setIsOpenQR] = useState({ open: false, id: null });
  const navigate = useNavigate();
  const { hasPage } = usePermission();
  const confirm = useConfirm();
  const queryClient = useQueryClient();

  const { mutate: mutateDelete, isLoading: loadingDelete } = useMutation(
    (id: any) => PaymentMethodApi.delete({ id }),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries(['get-payment-method-list']);
        }
      },
      onError: (error: any) => {
        notification.error({
          message: error?.message,
        });
      },
    },
  );

  const handleDelete = (record) => {
    confirm.warning({
      title: 'Xác nhận xóa phương thức thanh toán',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn xóa phương thức thanh toán</p>
          <p>
            <b>
              {record?.card_type?.card_name} - {record?.account_number}
            </b>{' '}
            này không?
          </p>
        </div>
      ),
      onOk: () => {
        mutateDelete(record?.id);
      },
    });
  };

  const renderItemActions = (record) => {
    const items: DropdownItem[] = [];

    if (hasPage(PAYMENT_METHOD_PERMISSION_KEY.VIEW_DETAIL))
      items.push({
        key: '1',
        label: 'Xem',
        onClick: () => setIsOpenDetail({ open: true, id: record?.id }),
      });
    if (
      hasPage(PAYMENT_METHOD_PERMISSION_KEY.QR) &&
      record?.card_type?.object_type === 1
    )
      items.push({
        key: '3',
        label: 'Quét mã',
        onClick: () => setIsOpenQR({ open: true, id: record?.id }),
      });
    if (hasPage(PAYMENT_METHOD_PERMISSION_KEY.UPDATE))
      items.push({
        key: '4',
        label: 'Sửa',
        onClick: () =>
          navigate(`${PAYMENT_METHOD_URL.update.path}/${record?.id}`),
      });
    if (hasPage(PAYMENT_METHOD_PERMISSION_KEY.DELETE))
      items.push({
        key: '5',
        label: <span className="text-red-500">Xóa</span>,
        onClick: () => handleDelete(record),
      });
    return items;
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Tên phương thức thanh toán',
      dataIndex: 'card_type',
      width: 100,
      render: (card_type, record) => (
        <p
          className="line-clamp-2 break-word text-blue-600 cursor-pointer"
          onClick={() => setIsOpenDetail({ open: true, id: record?.id })}
        >
          {card_type?.card_name}
        </p>
      ),
    },
    {
      title: 'Tên đầy đủ',
      dataIndex: 'description',
      width: 200,
      render: (_, record) => record?.card_type?.description,
    },
    {
      title: 'Người thụ hưởng',
      dataIndex: 'account_name',
      width: 100,
      render: (account_name) => (
        <p className="line-clamp-2 uppercase">{account_name}</p>
      ),
    },
    {
      title: 'STK/Số điện thoại',
      dataIndex: 'account_number',
      width: 150,
    },
    {
      title: 'Tổng thu',
      dataIndex: 'total_revenue',
      width: 100,
      render: (total_revenue) => formatCurrency(total_revenue),
    },
    {
      title: 'Số lần giao dịch',
      dataIndex: 'transactions_number',
      width: 100,
      render: (transactions_number) => formatNumber(transactions_number),
    },
    {
      width: 60,
      fixed: 'right',
      render: (record) => {
        const items = renderItemActions(record);
        return <ActionDropdown dropdownItems={items} trigger="click" />;
      },
    },
  ];

  return (
    <>
      <TableTera
        zebra={false}
        columns={columns}
        loading={props?.loading || loadingDelete}
        {...props}
      />
      {isOpenDetail.open && (
        <PaymentMethodDetail
          id={isOpenDetail.id}
          open={isOpenDetail.open}
          onCancel={() => setIsOpenDetail({ open: false, id: null })}
        />
      )}
      {isOpenQR.open && (
        <QrCode
          id={isOpenQR.id}
          open={isOpenQR.open}
          onCancel={() => setIsOpenQR({ open: false, id: null })}
        />
      )}
    </>
  );
}

export default PaymentMethodTable;
