import { useQuery } from '@tanstack/react-query';
import { IPagination } from '_common/interface';
import OrderInspectionApi from 'pages/Sale/OrderInspection/api';
import OrderInspectionTable from 'pages/Sale/OrderInspection/containers/Table';
import { useState } from 'react';
import { PaginationProps } from 'tera-dls';

interface OrderInspectionProps {
  id: number;
}

function OrderInspection({ id }: OrderInspectionProps) {
  const [pagination, setPagination] = useState<IPagination>({
    page: 1,
    limit: 10,
  });
  const { data: orderList, isLoading } = useQuery(
    ['get-order-inspection-list', id],
    () => {
      const params = {
        invest_id: id,
        page: pagination?.page || 1,
        limit: pagination?.limit || 10,
      };
      return OrderInspectionApi.getList({ params });
    },
    {
      enabled: !!id,
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    const isDiffPageSize = Number(pageSize) !== Number(pagination?.limit || 10);
    setPagination({ page: isDiffPageSize ? 1 : page, limit: pageSize });
  };

  return (
    <OrderInspectionTable
      data={orderList?.data || []}
      loading={isLoading}
      pagination={{
        onChange: handleChangePage,
        total: orderList?.total || 0,
        current: orderList?.current_page,
        pageSize: orderList?.per_page || 10,
        to: orderList?.to,
        from: orderList?.from || 10,
      }}
    />
  );
}

export default OrderInspection;
