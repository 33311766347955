import { DEFAULT_IMAGE } from '_common/constants/common';
import React, { useState } from 'react';
import { Image } from 'tera-dls';
// import image from 'styles/images/uiNew/logo.png';

interface DefaultImageProps {
  src: string;
  alt: string;
  defaultSrc?: string;
  style?: React.CSSProperties;
  zoom?: boolean;
  className?: string;
  imageClassName?: string;
  onClick?: () => void;
}

function DefaultImage({
  src,
  alt,
  defaultSrc = DEFAULT_IMAGE,
  style,
  className = 'w-[15px] h-[15px]',
  imageClassName,
  zoom = false,
  onClick,
}: DefaultImageProps) {
  const [imgSrc, setImgSrc] = useState(src);

  function onError() {
    setImgSrc(defaultSrc);
  }

  return (
    <>
      {zoom ? (
        <Image
          src={imgSrc || defaultSrc}
          alt={alt}
          style={style}
          containerClassName={className}
          imageClassName={imageClassName}
          onClick={onClick}
          onError={onError}
        />
      ) : (
        <img
          src={imgSrc || defaultSrc}
          alt={alt}
          style={style}
          className={className}
          onClick={onClick}
          onError={onError}
        />
      )}
    </>
  );
}

export default DefaultImage;
