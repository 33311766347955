export const STATUS_INVOICE = {
  pending: 'Chưa thanh toán',
  approved: 'Hoàn thành',
  // error: 'Chưa thanh toán',
  fail: 'Thất bại',
};

export const STATUS_INVOICE_COLOR = {
  pending: 'yellow03',
  approved: 'green03',
  // error: 'yellow03',
  fail: 'red03',
};

export const STATUS_INVOICE_APPROVED = 'approved';

export const INVOICE_METHOD = {
  transfer: 'Chuyển khoản',
  wallet: 'Ví SUMI',
};
