import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import HeaderViewList from '_common/component/HeaderViewList';
import TableTera from '_common/dof/TableTera';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  DropdownItem,
  formatDate,
  getQueryParams,
  Image,
  notification,
  PaginationProps,
  updateQueryParams,
} from 'tera-dls';

import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import useConfirm from '_common/hooks/useConfirm';
import CategoryApi from './api';
import CategoryDetail from './containers/Detail';
import CategoryForm from './containers/Form';
import CategorySearch from './containers/Search';
import { CATEGORY_URL } from './url';
import { DATE_TIME_FORMAT } from '_common/constants/common';
import { usePermission } from '_common/hooks/usePermission';
import { PRODUCT_CATEGORY_PERMISSION_KEY } from '_common/constants/permission';

function Category() {
  const [openForm, setOpenForm] = useState({ open: false, id: null });
  const [openDetail, setOpenDetail] = useState({ open: false, id: null });

  const navigate = useNavigate();
  const confirm = useConfirm();
  const { hasPage } = usePermission();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;
  const queryClient = useQueryClient();

  const handleUpdateQueryParams = (data) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(`${CATEGORY_URL.list.path}${paramString}`);
  };

  const {
    data: categoryList,
    isLoading,
    refetch,
  } = useQuery(
    ['get-category-list', queryParams],
    () => {
      const params = {
        ...queryParams,
        page: queryParams?.page || 1,
        limit: queryParams?.limit || 10,
      };
      return CategoryApi.getList({ params });
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
      onSuccess: (data) => {
        if (Number(data?.last_page) < (Number(queryParams?.page) || 1)) {
          handleUpdateQueryParams({ page: data?.last_page });
        }
      },
    },
  );

  const { mutate: mutateDelete, isLoading: loadingDelete } = useMutation(
    (id: any) => CategoryApi.delete({ id }),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries(['get-category-list']);
        }
      },
      onError: (error: any) => {
        notification.error({
          message: error?.message,
        });
      },
    },
  );

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    const isDiffPageSize =
      Number(pageSize) !== Number(categoryList?.limit || 10);

    handleUpdateQueryParams({
      page: isDiffPageSize ? 1 : page,
      limit: pageSize,
    });
  };

  const handleSearch = (value) => {
    handleUpdateQueryParams({ keyword: value?.keyword, page: 1 });
  };

  const handleDelete = (record) => {
    confirm.warning({
      title: 'Xác nhận xóa danh mục sản phẩm',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn xóa danh mục sản phẩm</p>
          <p>
            <b>{record?.name}</b> này không?
          </p>
        </div>
      ),
      onOk: () => {
        mutateDelete(record?.id);
      },
    });
  };

  const renderItemActions = (record) => {
    const items: DropdownItem[] = [];
    if (hasPage(PRODUCT_CATEGORY_PERMISSION_KEY.VIEW_DETAIL))
      items.push({
        key: '1',
        label: 'Xem',
        onClick: () => setOpenDetail({ open: true, id: record?.id }),
      });
    if (hasPage(PRODUCT_CATEGORY_PERMISSION_KEY.UPDATE))
      items.push({
        key: '2',
        label: 'Sửa',
        onClick: () => setOpenForm({ open: true, id: record?.id }),
      });
    if (hasPage(PRODUCT_CATEGORY_PERMISSION_KEY.DELETE))
      items.push({
        key: '3',
        label: <span className="text-red-500">Xóa</span>,
        onClick: () => handleDelete(record),
      });

    return items;
  };

  const columns: any = [
    {
      title: 'Hình ảnh',
      dataIndex: 'file_upload',
      width: 100,
      render: (file_upload) =>
        file_upload && (
          <Image
            src={file_upload}
            alt={file_upload}
            containerClassName="w-[30px] h-[30px] rounded overflow-hidden border"
            imageClassName="object-cover"
          />
        ),
    },
    {
      title: 'Tên danh mục',
      dataIndex: 'name',
      render: (name, record) => (
        <p
          className="line-clamp-2 break-word text-blue-600 cursor-pointer"
          onClick={() => setOpenDetail({ open: true, id: record?.id })}
        >
          {name}
        </p>
      ),
    },
    {
      title: 'Mô tả',
      dataIndex: 'description',
      render: (description) => <p className="line-clamp-2">{description}</p>,
    },
    {
      title: 'Người tạo',
      dataIndex: 'created_by',
      render: (created_by) => created_by?.full_name,
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'created_at',
      render: (created_at) =>
        created_at && formatDate(created_at, DATE_TIME_FORMAT),
    },
    {
      width: 60,
      fixed: 'right',
      render: (record) => {
        const items = renderItemActions(record);
        return <ActionDropdown dropdownItems={items} trigger="click" />;
      },
    },
  ];

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="tera-page">
      <HeaderViewList
        title="Danh sách danh mục sản phẩm"
        buttonCreatingKey={PRODUCT_CATEGORY_PERMISSION_KEY.CREATE}
        onClickButtonAdd={() => setOpenForm({ open: true, id: null })}
        actionLeftRender={
          hasPage(PRODUCT_CATEGORY_PERMISSION_KEY.SEARCH) && (
            <CategorySearch onSearch={handleSearch} />
          )
        }
      >
        <TableTera
          zebra={false}
          rowKey={'id'}
          columns={columns}
          data={categoryList?.data || []}
          loading={isLoading || loadingDelete}
          pagination={{
            onChange: handleChangePage,
            total: categoryList?.total || 0,
            current: categoryList?.current_page,
            pageSize: categoryList?.per_page || 10,
            to: categoryList?.to,
            from: categoryList?.from || 10,
          }}
        />
      </HeaderViewList>

      {openDetail.open && (
        <CategoryDetail
          open={openDetail.open}
          id={openDetail.id}
          onCancel={() => setOpenDetail({ open: false, id: null })}
        />
      )}
      {openForm.open && (
        <CategoryForm
          open={openForm.open}
          id={openForm.id}
          onClose={() => setOpenForm({ open: false, id: null })}
          onSuccess={() => queryClient.invalidateQueries(['get-category-list'])}
        />
      )}
    </div>
  );
}

export default Category;
