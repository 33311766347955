import { ORDER_INSPECTION_PERMISSION_KEY } from '_common/constants/permission';

export const ORDER_INSPECTION_URL = {
  list: {
    key: ORDER_INSPECTION_PERMISSION_KEY.VIEW_LIST,
    path: '/sale/order-inspection/list',
    shortenUrl: 'order-inspection/list',
  },
  inspection: {
    key: ORDER_INSPECTION_PERMISSION_KEY.CHECKING,
    path: '/sale/order-inspection/check',
    shortenUrl: 'order-inspection/check/:id',
  },
  detail: {
    key: ORDER_INSPECTION_PERMISSION_KEY.VIEW_DETAIL,
    path: '/sale/order-inspection/detail',
    shortenUrl: 'order-inspection/detail/:id',
  },
};
