import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import HeaderViewList from '_common/component/HeaderViewList';
import SumiTab from '_common/component/Sumi/Tab';
import TableTera from '_common/dof/TableTera';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Badge,
  DropdownItem,
  getQueryParams,
  notification,
  PaginationProps,
  TabItemType,
  Tag,
  updateQueryParams,
} from 'tera-dls';

import ModalChangePassword from '_common/component/ModalChangePassword';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import { TAB_ITEMS_STATUS_RENDER } from '_common/constants/common';
import { USER_PERMISSION_KEY } from '_common/constants/permission';
import useConfirm from '_common/hooks/useConfirm';
import { usePermission } from '_common/hooks/usePermission';
import UserApi from './api';
import { TAB_ITEMS_STATUS, TAB_ITEMS_STATUS_COLOR } from './constants';
import UserDetail from './containers/Detail';
import UserFilter from './containers/Filter';
import UserForm from './containers/Form';
import UserSearch from './containers/Search';
import { USER_URL } from './url';

function User() {
  const [openForm, setOpenForm] = useState({ open: false, id: null });
  const [openDetail, setOpenDetail] = useState({ open: false, id: null });
  const [isOpenFilter, setIsOpenFilter] = useState<boolean>(false);
  const [openResetPassword, setOpenResetPassword] = useState({
    open: false,
    id: null,
  });

  const { hasPage } = usePermission();
  const navigate = useNavigate();
  const confirm = useConfirm();
  const { search } = useLocation();
  const queryClient = useQueryClient();
  const queryParams = getQueryParams(search) as any;

  const handleUpdateQueryParams = (data) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(`${USER_URL.list.path}${paramString}`);
  };

  const {
    data: userList,
    isLoading,
    refetch,
  } = useQuery(
    ['get-user-list', queryParams],
    () => {
      const params = {
        ...queryParams,
        page: queryParams?.page || 1,
        limit: queryParams?.limit || 10,
        status: queryParams?.status === 'all' ? undefined : queryParams?.status,
      };
      return UserApi.getList({ params });
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
      onSuccess: (data) => {
        if (Number(data?.last_page) < (Number(queryParams?.page) || 1)) {
          handleUpdateQueryParams({ page: data?.last_page });
        }
      },
    },
  );

  const { data: summaryList } = useQuery(
    ['get-summary-user-list', queryParams],
    () => {
      const params = {
        ...queryParams,
        page: queryParams?.page || 1,
        limit: queryParams?.limit || 10,
        status: queryParams?.status === 'all' ? undefined : queryParams?.status,
      };
      return UserApi.getSummary({ params });
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  const { mutate: mutateDelete, isLoading: loadingDelete } = useMutation(
    (id: any) => UserApi.delete({ id }),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries(['get-user-list']);
          queryClient.invalidateQueries(['get-summary-user-list']);
        }
      },
      onError: (error: any) => {
        notification.error({
          message: error?.message,
        });
      },
    },
  );

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    const isDiffPageSize = Number(pageSize) !== Number(userList?.limit || 10);

    handleUpdateQueryParams({
      page: isDiffPageSize ? 1 : page,
      limit: pageSize,
    });
  };

  const handleChangeTab = (key) => {
    handleUpdateQueryParams({ status: key, page: 1 });
  };
  const handleSearch = (value) => {
    handleUpdateQueryParams({ keyword: value?.keyword, page: 1 });
  };
  const handleFilter = (values) => {
    handleUpdateQueryParams({ ...values, page: 1 });
  };

  const handleDelete = (record) => {
    confirm.warning({
      title: 'Xác nhận xóa người dùng',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn xóa người dùng</p>
          <p>
            <b>{record?.name}</b> này không?
          </p>
        </div>
      ),
      onOk: () => {
        mutateDelete(record?.id);
      },
    });
  };

  const renderItemActions = (record) => {
    const items: DropdownItem[] = [];

    if (hasPage(USER_PERMISSION_KEY.VIEW_DETAIL))
      items.push({
        key: '1',
        label: 'Xem',
        onClick: () => setOpenDetail({ open: true, id: record?.id }),
      });
    if (hasPage(USER_PERMISSION_KEY.CHANGE_PASSWORD))
      items.push({
        key: '2',
        label: 'Đổi mật khẩu',
        onClick: () => setOpenResetPassword({ open: true, id: record?.id }),
      });
    if (hasPage(USER_PERMISSION_KEY.UPDATE))
      items.push({
        key: '3',
        label: 'Sửa',
        onClick: () => setOpenForm({ open: true, id: record?.id }),
      });
    if (hasPage(USER_PERMISSION_KEY.DELETE) && !record?.status)
      items.push({
        key: '4',
        label: <span className="text-red-500">Xóa</span>,
        onClick: () => handleDelete(record),
      });

    return items;
  };

  const columns: any = [
    {
      title: 'Tên đăng nhập',
      dataIndex: 'username',
      render: (username) => <p className="line-clamp-2">{username}</p>,
    },
    {
      title: 'Phân quyền',
      dataIndex: 'role',
      render: (role) => <p className="line-clamp-2">{role?.name}</p>,
    },
    {
      title: 'Họ và tên',
      dataIndex: 'full_name',
      render: (full_name, record) => (
        <p
          className="line-clamp-2 break-word text-blue-600 cursor-pointer"
          onClick={() => setOpenDetail({ open: true, id: record?.id })}
        >
          {full_name}
        </p>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phone',
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      render: (status) => (
        <Tag color={TAB_ITEMS_STATUS_COLOR[status]}>
          {TAB_ITEMS_STATUS[status]}
        </Tag>
      ),
    },
    {
      width: 60,
      fixed: 'right',
      render: (record) => {
        const items = renderItemActions(record);
        return <ActionDropdown dropdownItems={items} trigger="click" />;
      },
    },
  ];

  const tabItems: TabItemType[] = useMemo(() => {
    const tabs = TAB_ITEMS_STATUS_RENDER.map((item) => {
      const count =
        summaryList?.find((i) => i?.status == item)?.total_count || 0;
      return {
        key: item,
        label: (
          <div className="flex items-center gap-x-2">
            {TAB_ITEMS_STATUS[item]} <Badge count={count} showZero />
          </div>
        ),
      };
    });
    return tabs;
  }, [summaryList]);

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="tera-page">
      <HeaderViewList
        title="Danh sách người dùng"
        buttonFilterKey={USER_PERMISSION_KEY.SEARCH}
        buttonCreatingKey={USER_PERMISSION_KEY.CREATE}
        onClickFilter={() => setIsOpenFilter(true)}
        onClickButtonAdd={() => setOpenForm({ open: true, id: null })}
        middleContent={
          hasPage(USER_PERMISSION_KEY.SEARCH) && (
            <SumiTab onChange={handleChangeTab} items={tabItems} />
          )
        }
        actionLeftRender={
          hasPage(USER_PERMISSION_KEY.SEARCH) && (
            <UserSearch onSearch={handleSearch} />
          )
        }
        filterCount={{
          filter: queryParams,
          params: ['role_id'],
        }}
      >
        <TableTera
          rowKey={'id'}
          columns={columns}
          data={userList?.data || [{ id: 1 }]}
          loading={isLoading || loadingDelete}
          pagination={{
            onChange: handleChangePage,
            total: userList?.total || 0,
            current: userList?.current_page,
            pageSize: userList?.per_page || 10,
            to: userList?.to,
            from: userList?.from || 10,
          }}
          zebra={false}
        />
      </HeaderViewList>

      {isOpenFilter && (
        <UserFilter
          open={isOpenFilter}
          onClose={() => setIsOpenFilter(false)}
          onFilter={handleFilter}
          initialValue={queryParams}
        />
      )}

      {openDetail.open && (
        <UserDetail
          open={openDetail.open}
          id={openDetail.id}
          onCancel={() => setOpenDetail({ open: false, id: null })}
        />
      )}
      {openForm.open && (
        <UserForm
          open={openForm.open}
          id={openForm.id}
          onClose={() => setOpenForm({ open: false, id: null })}
          onSuccess={() => {
            queryClient.invalidateQueries(['get-user-list']);
            queryClient.invalidateQueries(['get-summary-user-list']);
          }}
        />
      )}
      {openResetPassword.open && (
        <ModalChangePassword
          userId={openResetPassword?.id}
          open={openResetPassword.open}
          onClose={() => setOpenResetPassword({ open: false, id: null })}
        />
      )}
    </div>
  );
}

export default User;
