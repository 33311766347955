import Nodata from 'styles/images/Icons/no-data.png';
import DefaultImage from '../DefaultImage';
function NoPermission() {
  return (
    <div className="h-full w-full flex py-10">
      <div className="m-auto flex flex-col gap-y-2 items-center">
        <DefaultImage src={Nodata} alt="no-data" className="w-50" />
        <p>Bạn không có quyền truy cập</p>
      </div>
    </div>
  );
}

export default NoPermission;
