export const PAGE_TITLE = {
  home: 'Trang chủ',
  product: 'Sản phẩm',
  category: 'Danh mục',
  unit: 'Đơn vị',
  order: 'Đơn đặt hàng',
  order_inspection: 'Đơn kiểm hàng',
  sale_order_return: 'Đơn trả hàng',
  sale_order: 'Đơn bán hàng',
  investor: 'Nhà đầu tư',
  investment: 'Đầu tư',
  investment_type: 'Loại hình đầu tư',
  cease_investment: 'Ngưng đầu tư',
  incurred_cost: 'Chi phí phát sinh',
  sales_expenses: 'Chi phí bán hàng',
  incurred_cost_category: 'Danh mục chi phí phát sinh',
  employee: 'Nhân viên',
  timekeeping: 'Chấm công',
  device: 'Thiết bị',
  invoice: 'Hóa đơn',
  cash_receipt: 'Phiếu thu',
  expense_voucher: 'Phiếu chi',
  statistic: 'Thống kê thu chi',
  payment_method: 'Phương thức thanh toán',
  transaction_history: 'Lịch sử giao dịch',
  system: 'Cấu hình thời gian',
  user: 'Người dùng',
  role: 'Phân quyền',
};

export const MODULE_TITLE = {
  home: 'Trang chủ',
  investor: 'Nhà đầu tư',
  sale: 'Bán hàng',
  hr: 'Nhân sự',
  finance: 'Tài chính',
  system: 'Hệ thống',
};
