import BasicLayout from '_common/component/Layout/BasicLayout';
import PageLayout from '_common/component/Layout/PageLayout';
import UnAuthLayout from '_common/component/Layout/UnAuthLayout';
import PageNotfound from '_common/component/PageNotfound';
import PageNotPermission from '_common/component/PageNotPermission';
import PageUnauthorized from '_common/component/PageUnauthorized';
import { IRouteProps } from '_common/interface/router';
import CheckAuthPage from 'pages/Auth/CheckAuth';
import LoginPage from 'pages/Auth/Login';
import { DashboardRouter } from 'pages/Dashboard/router';
import { CashReceiptRouter } from 'pages/Finance/CashReceipt/router';
import { ExpenseVoucherRouter } from 'pages/Finance/ExpenseVoucher/router';
import { InvoiceRouter } from 'pages/Finance/Invoice/router';
import { PaymentMethodRouter } from 'pages/Finance/PaymentMethod/router';
import { StatisticRouter } from 'pages/Finance/Statistic/router';
import { TransactionHistoryRouter } from 'pages/Finance/TransactionHistory/router';
import { DeviceRouter } from 'pages/Hr/Device/router';
import { EmployeeRouter } from 'pages/Hr/Employee/router';
import { TimekeepingRouter } from 'pages/Hr/Timekeeping/router';
import { CeaseInvestmentRouter } from 'pages/Invest/CeaseInvestment/router';
import { IncurredCostRouter } from 'pages/Invest/IncurredCost/router';
import { IncurredCostCategoryRouter } from 'pages/Invest/IncurredCostCategory/router';
import { InvestmentRouter } from 'pages/Invest/Invesment/router';
import { InvestmentTypeRouter } from 'pages/Invest/InvestmentType/router';
import { InvestorRouter } from 'pages/Invest/Investor/router';
import { SalesExpensesRouter } from 'pages/Invest/SalesExpenses/router';
import { CategoryRouter } from 'pages/Sale/Category/router';
import { OrderRouter } from 'pages/Sale/Order/router';
import { OrderInspectionRouter } from 'pages/Sale/OrderInspection/router';
import { ProductRouter } from 'pages/Sale/Product/router';
import { SaleOrderRouter } from 'pages/Sale/SaleOrder/router';
import { SaleOrderReturnRouter } from 'pages/Sale/SaleOrderReturn/router';
import { UnitRouter } from 'pages/Sale/Unit/router';
import { CameraRouter } from 'pages/System/Camera/router';
import { PermissionRouter } from 'pages/System/Permission/router';
import { TimeConfigRouter } from 'pages/System/TimeConfig/router';
import { UserRouter } from 'pages/System/User/router';
import { Navigate, Route, Routes } from 'react-router-dom';
import CheckAuth from 'routers/CheckAuth';
import MiddlewareRouter from 'routers/MiddlewareRouter';

// dashboard router
const renderRouter = (objectRouter: IRouteProps[]) => {
  return objectRouter.map((route: IRouteProps) => {
    const { key, component, path, actions, action_type } = route;
    return (
      <Route
        key={key}
        path={path}
        element={
          <PageLayout
            page_key={key}
            actions={actions}
            action_type={action_type}
          >
            {component}
          </PageLayout>
        }
      />
    );
  });
};
renderRouter;

export const Routers = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <MiddlewareRouter>
            <BasicLayout />
          </MiddlewareRouter>
        }
      >
        <Route index element={<Navigate to="dashboard" />} />
        <Route path="dashboard">{renderRouter(DashboardRouter)}</Route>

        <Route path="investor">
          {renderRouter(InvestorRouter)}
          {renderRouter(InvestmentTypeRouter)}
          {renderRouter(IncurredCostCategoryRouter)}
          {renderRouter(InvestmentRouter)}
          {renderRouter(SalesExpensesRouter)}
          {renderRouter(CeaseInvestmentRouter)}
          {renderRouter(IncurredCostRouter)}
        </Route>
        <Route path="sale">
          {renderRouter(ProductRouter)}
          {renderRouter(CategoryRouter)}
          {renderRouter(UnitRouter)}
          {renderRouter(OrderRouter)}
          {renderRouter(SaleOrderRouter)}
          {renderRouter(SaleOrderReturnRouter)}
          {renderRouter(OrderInspectionRouter)}
        </Route>
        <Route path="hr">
          {renderRouter(EmployeeRouter)}
          {renderRouter(DeviceRouter)}
          {renderRouter(TimekeepingRouter)}
        </Route>
        <Route path="finance">
          {renderRouter(InvoiceRouter)}
          {renderRouter(CashReceiptRouter)}
          {renderRouter(ExpenseVoucherRouter)}
          {renderRouter(StatisticRouter)}
          {renderRouter(TransactionHistoryRouter)}
          {renderRouter(PaymentMethodRouter)}
        </Route>
        <Route path="system">
          {renderRouter(TimeConfigRouter)}
          {renderRouter(UserRouter)}
          {renderRouter(PermissionRouter)}
          {renderRouter(CameraRouter)}
        </Route>
      </Route>

      <Route
        path="auth"
        element={
          <CheckAuth>
            <UnAuthLayout />
          </CheckAuth>
        }
      >
        <Route path="check-auth" element={<CheckAuthPage />} />
        <Route path="login" element={<LoginPage />} />
      </Route>

      <Route path="*" element={<PageNotfound />} />
      <Route path="/403" element={<PageNotPermission />} />
      <Route path="/401" element={<PageUnauthorized />} />
    </Routes>
  );
};
