export const DATE_FORMAT = 'DD/MM/YYYY';
export const MONTH_FORMAT = 'MM/YYYY';
export const YEAR_FORMAT = 'YYYY';
export const DATE_BACKEND_FORMAT = 'YYYY-MM-DD';
export const DATE_TIME_BACKEND_FORMAT = 'YYYY-MM-DD - HH:mm:ss';
export const DATE_TIME_FORMAT = 'DD/MM/YYYY - HH:mm';
export const TIME_FORMAT = 'HH:mm';
export const LIMIT_MAX_DATE_TIME = '01/01/2038';
export const LIMIT_MIN_DATE_TIME = '01/01/1970';
export const LOCATION_KEY = 'location_id';
export const MAXIMUM_CURRENCY = 999999999999999;
export const MAXIMUM_QUANTITY = 9999;

export const FORMAT_DATE_BY_VARIABLE = {
  day: DATE_FORMAT,
  week: DATE_FORMAT,
  month: MONTH_FORMAT,
  year: YEAR_FORMAT,
};

export const LocalStorage = {
  Token: 'tera_cms_token',
  Device: 'tera_device_init',
  Permission: 'tera_permission',
  StockId: 'tera_stock_id',
  LocationId: 'tera_location_id',
  RefCode: 'tera_ref_code',
};

export const endpointMockData =
  'https://c271729d-0a4c-448c-9d8b-f8523695ef42.mock.pstmn.io';

export const endpoint = `${process.env.REACT_APP_API}/api`;
export const ftpEndpoint = `${process.env.REACT_APP_FTP_API}/api`;
// export const adminEndpoint = `${process.env.REACT_APP_ADMIN_API}/api`;

export const config = {
  google: {
    clientID: '',
    keyGMap: '',
  },
  fbConfig: {
    appId: '',
    version: 'v1.0',
  },
  hasHeader: false,
  hasMobile: true,
  templates: ['tera'],
  languages: ['vn'],
  app: {},
  uploadKey: '9074c259a7',
  appId: '2',
  privateKey: process.env.REACT_APP_PRIVATE_KEY,
};

export const GENDER = {
  male: 'Nam',
  female: 'Nữ',
  other: 'Khác',
};

export const genderFull = {
  all: 'Tất cả',
  male: 'Nam',
  female: 'Nữ',
};

export const REGEX = {
  PHONE_NUMBER: /^0\d{7,10}$/,
  KEY: /^[a-z]*$/,
  CODE: /^[a-zA-Z0-9_]*$/,
  EMAIL: /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)*(\.[a-zA-Z]{2,})$/,
  IDENTIFY_NUMBER: /^(?:[0-9]{9,12})?$/,
  WEBSITE: /^(https?|ftp):\/\/([^\s\/]+)(\/[^\s]*)*$/,
  PASSWORD: /^[a-zA-Z0-9!@#$%^&*()_+=-]+$/,
  USERNAME: /^[a-zA-Z0-9!@#$%^&*()_+=\-\.]+$/,
  ABBREVIATION: /^([\S]+\s?){1,2}$/,
  CARD_NUMBER: /^[a-z0-9]+$/u,
};

export const month = {
  1: 'Tháng 1',
  2: 'Tháng 2',
  3: 'Tháng 3',
  4: 'Tháng 4',
  5: 'Tháng 5',
  6: 'Tháng 6',
  7: 'Tháng 7',
  8: 'Tháng 8',
  9: 'Tháng 9',
  10: 'Tháng 10',
  11: 'Tháng 11',
  12: 'Tháng 12',
};

export const timeFormat = {
  date_time: 'DD/MM/YYYY HH:mm',
  YYYY_MM_DD: 'YYYY/MM/DD HH:mm',
  time_half: TIME_FORMAT,
  time_full: TIME_FORMAT,
};

export const TypesOverTime = {
  weekday: 'Ngày thường',
  weekday_night: 'Ngày thường (đêm)',
  off: 'Ngày nghỉ',
  off_night: 'Ngày nghỉ (đêm)',
  holiday: 'Ngày lễ',
  holiday_night: 'Ngày lễ (đêm)',
};

export const TypesOverForm = {
  payroll: 'Tính lương',
  compensatory_leave: 'Nghỉ bù',
};

export const StatusYesNo = {
  0: {
    title: 'Không',
    color: 'red03',
  },
  1: {
    title: 'Có',
    color: 'green03',
  },
};

export const optionsCustom = {
  label: 'Đứng đầu',
  value: 0,
};

export const PAGE_KEY = {
  PRICE_QUOTATION: 'crm_quotation',
};

export const STATUS_APPROVAL = {
  CANCEL: 'cancel',
};

export const SUMI_SHADOW =
  'shadow-[0_4px_4px_0_rgba(0,0,0,0.1),0_-4px_4px_0_rgba(0,0,0,0.03)]';

export const TAB_ITEMS_STATUS = {
  all: 'Tất cả',
  1: 'Đang hoạt động',
  0: 'Ngưng hoạt động',
};

export const TAB_ITEMS_STATUS_COLOR = {
  1: 'green03',
  0: 'red03',
};

export const TAB_ITEMS_STATUS_APPROVE = {
  all: 'Tất cả',
  pending: 'Chờ duyệt',
  approved: 'Duyệt',
  reject: 'Từ chối',
};

export const TAB_ITEMS_STATUS_APPROVE_COLOR = {
  pending: 'yellow03',
  approve: 'green03',
  reject: 'red03',
};

export const TAB_ITEMS_STATUS_RENDER = ['all', '0', '1'];

export const DEFAULT_IMAGE =
  'https://sumi-api.teravn.com/assets/upload/system/1724142696_image-default.png';
