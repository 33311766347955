import Filter from '_common/component/Filter';
import { DATE_BACKEND_FORMAT } from '_common/constants/common';
import DatePicker from '_common/dof/Control/DatePicker';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectInvestmentCategory from '_common/dof/Select/SelectInvestmentCategory';
import _ from 'lodash';
import moment from 'moment';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

interface CeasesInvestmentFilterProps {
  open: boolean;
  onClose: () => void;
  onFilter: (value) => void;
  initialValue: any;
}

function CeasesInvestmentFilter({
  open,
  onClose,
  onFilter,
  initialValue,
}: CeasesInvestmentFilterProps) {
  const form = useForm({ mode: 'onChange' });

  const handleSubmitForm = (values) => {
    const valuesFilter = {
      ...values,
      created_at: values?.created_at
        ? moment(values?.created_at).format(DATE_BACKEND_FORMAT)
        : null,
    };

    onFilter(valuesFilter);
    onClose();
  };

  const handleReset = () => {
    const values = {
      created_at: null,
      invest_model: null,
    };
    onFilter(values);
    onClose();
  };

  useEffect(() => {
    const values = _.pick(initialValue, ['invest_model', 'created_at']);

    form.reset({
      ...values,
      created_at: values?.created_at
        ? moment(values?.created_at, DATE_BACKEND_FORMAT)
        : null,
    });
  }, [initialValue]);

  return (
    <Filter
      open={open}
      onCancel={onClose}
      onClose={onClose}
      onFilter={() => form.handleSubmit(handleSubmitForm)()}
    >
      <FormTera form={form} onSubmit={form.handleSubmit(handleSubmitForm)}>
        <FormTeraItem label="Danh mục đầu tư" name="invest_model">
          <SelectInvestmentCategory />
        </FormTeraItem>
        <FormTeraItem label="Ngày yêu cầu" name="created_at">
          <DatePicker placeholder="Vui lòng chọn" format="DD/MM/YYYY" />
        </FormTeraItem>

        <a
          className="text-red-500 text-sm font-normal cursor-pointer"
          onClick={() => handleReset()}
        >
          Hủy bộ lọc
        </a>
      </FormTera>
    </Filter>
  );
}

export default CeasesInvestmentFilter;
