import { messageValidate } from '_common/constants/message';
import { FormTeraItem } from '_common/dof/FormTera';
import { FormTeraItemProps } from '_common/dof/FormTera/FormTeraItem';
import { useTeraForm } from '_common/dof/FormTera/TeraFormContext';
import _ from 'lodash';
import { useCallback, useEffect } from 'react';
import customTwMerge from 'tailwind-merge.config';
import { TimePickerProps } from 'tera-dls';
import TimePicker from '../TimePicker';
interface IProps {
  startFormName: string;
  endFormName: string;
  startFormItemProps?: FormTeraItemProps;
  endFormItemProps?: FormTeraItemProps;
  startInputProps?: TimePickerProps;
  endInputProps?: TimePickerProps;
  wrapperClassName?: string;
  startRuleMessageError?: string;
  endRuleMessageError?: string;
  startTimeRuleProps?: any;
  endTimeRuleProps?: any;
}
const RangeTime = (props: IProps) => {
  const {
    startFormName = 'start',
    endFormName = 'end',
    startFormItemProps = {},
    endFormItemProps = {},
    startInputProps = {},
    endInputProps = {},
    wrapperClassName,
    startRuleMessageError,
    endRuleMessageError,
    startTimeRuleProps = {},
    endTimeRuleProps = {},
  } = props;
  const { form } = useTeraForm();

  const { rules: startRules = [], ...restStartFormItemProps } =
    startFormItemProps;
  const { rules: endRules = [], ...restEndFormItemProps } = endFormItemProps;

  const startFormNameWatching = form?.watch(startFormName);
  const endFormNameWatching = form?.watch(endFormName);

  const triggerAll = useCallback(
    _.debounce((startData, endData) => {
      if (startData && endData && form) {
        form?.trigger(startFormName);
        form?.trigger(endFormName);
      }
    }, 100),
    [form],
  );

  useEffect(() => {
    triggerAll(startFormNameWatching, endFormNameWatching);
  }, [startFormNameWatching, endFormNameWatching, triggerAll]);

  return (
    <div
      className={customTwMerge('grid grid-cols-2 gap-2.5', wrapperClassName)}
    >
      <FormTeraItem
        name={startFormName}
        rules={[
          {
            lessThan: {
              key: endFormName,
              message: startRuleMessageError ?? messageValidate.min_date,
              ...startTimeRuleProps,
            },
          },
          ...startRules,
        ]}
        {...restStartFormItemProps}
      >
        <TimePicker format="HH:mm" {...startInputProps} />
      </FormTeraItem>
      <FormTeraItem
        name={endFormName}
        rules={[
          {
            greaterThan: {
              key: startFormName,
              message: endRuleMessageError ?? messageValidate.max_date,
              ...endTimeRuleProps,
            },
          },
          ...endRules,
        ]}
        {...restEndFormItemProps}
      >
        <TimePicker format="HH:mm" {...endInputProps} />
      </FormTeraItem>
    </div>
  );
};

export default RangeTime;
