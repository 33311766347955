import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const InvestmentEndpoint = `${endpoint}/sumi/invest`;

const InvestmentApi = {
  getList: async ({ params }) =>
    await api
      .get(`${InvestmentEndpoint}/list`, params)
      .then((res) => res.data?.data),
  getDetail: async ({ id }) =>
    await api
      .get(`${InvestmentEndpoint}/detail/${id}`)
      .then((res) => res.data?.data),
  getSummary: async ({ params }) =>
    await api
      .get(`${InvestmentEndpoint}/summary-list`, params)
      .then((res) => res.data?.data),
  create: async ({ params }) =>
    await api
      .post(`${InvestmentEndpoint}/create`, params)
      .then((res) => res.data),
  update: async ({ id, params }) =>
    await api
      .put(`${InvestmentEndpoint}/update/${id}`, params)
      .then((res) => res.data),
  changeStatus: async ({ params }) =>
    await api
      .post(`${InvestmentEndpoint}/active`, params)
      .then((res) => res.data),
  resetPassword: async ({ params }) =>
    await api
      .put(`${InvestmentEndpoint}/reset-password`, params)
      .then((res) => res.data),
  delete: async ({ id }) =>
    await api
      .delete(`${InvestmentEndpoint}/delete/${id}`)
      .then((res) => res.data),
};

export default InvestmentApi;
