import { IRouteProps } from '_common/interface/router';
import { CATEGORY_URL } from './url';
import Category from '.';

export const CategoryRouter: IRouteProps[] = [
  {
    key: CATEGORY_URL.list.key,
    path: CATEGORY_URL.list.shortenUrl,
    component: <Category />,
  },
];
