export const CEASE_INVESTMENT_STATUS = {
  all: 'Tất cả',
  0: 'Chờ duyệt',
  1: 'Duyệt',
  2: 'Từ chối',
};

export const CEASE_INVESTMENT_STATUS_COLOR = {
  0: 'yellow03',
  1: 'green03',
  2: 'red03',
};

export const CEASE_INVESTMENT_STATUS_RENDER = ['all', '0', '1', '2'];
