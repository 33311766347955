import { IRouteProps } from '_common/interface/router';
import { PAYMENT_METHOD_URL } from './url';
import PaymentMethod from '.';
import PaymentMethodForm from './containers/Form';

export const PaymentMethodRouter: IRouteProps[] = [
  {
    key: PAYMENT_METHOD_URL.list.key,
    path: PAYMENT_METHOD_URL.list.shortenUrl,
    component: <PaymentMethod />,
  },
  {
    key: PAYMENT_METHOD_URL.create.key,
    path: PAYMENT_METHOD_URL.create.shortenUrl,
    component: <PaymentMethodForm />,
  },
  {
    key: PAYMENT_METHOD_URL.update.key,
    path: PAYMENT_METHOD_URL.update.shortenUrl,
    component: <PaymentMethodForm />,
  },
];
