import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import NoData from '_common/component/NoData';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import {
  messageValidate,
  messageValidateLength,
  messageWarning,
} from '_common/constants/message';
import Image from '_common/dof/Control/Image';
import Input from '_common/dof/Control/Input';
import TextArea from '_common/dof/Control/TextArea';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import useConfirm from '_common/hooks/useConfirm';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, notification, Spin } from 'tera-dls';
import CategoryApi from '../../api';

interface CategoryFormProps {
  id: string | number;
  open: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
}

function CategoryForm(props: CategoryFormProps) {
  const { id, open, onClose, onSuccess } = props;
  const form = useForm({ mode: 'onChange' });
  const confirm = useConfirm();
  const queryClient = useQueryClient();
  const { isDirty } = form.formState;

  const {
    data: dataDetails,
    refetch,
    isLoading,
    isError,
  } = useQuery(
    ['get-category-detail', id],
    () => CategoryApi.getDetail({ id }),
    {
      enabled: !!id,
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const { mutate: mutateCategory, isLoading: isLoadingMutate } = useMutation(
    (params: any) => {
      if (id) return CategoryApi.update({ id, params });
      return CategoryApi.create({ params });
    },
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries(['get-category-list']);
          onClose && onClose();
          onSuccess && onSuccess();
        }
      },
      onError: (error: any) => {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleCloseConfirm = () => {
    if (isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>{messageWarning.WARNING_EXIT_1}</p>
            <p>{messageWarning.WARNING_EXIT_2}</p>
          </>
        ),
        onOk: () => {
          onClose();
        },
      });
    } else onClose();
  };

  const handleSubmitForm = (value) => {
    if ((isLoading && !!id) || isLoadingMutate) return;
    mutateCategory(value);
  };

  useEffect(() => {
    if (dataDetails) {
      const initData = {
        name: dataDetails?.name,
        description: dataDetails?.description,
        file_upload: dataDetails?.file_upload,
      };
      form.reset(initData);
    }
  }, [dataDetails]);

  useEffect(() => {
    if (id) refetch();
  }, [id]);

  return (
    <Modal
      title={id ? 'Sửa danh mục sản phẩm' : 'Thêm danh mục sản phẩm'}
      destroyOnClose
      closeIcon={false}
      cancelText="Hủy"
      okText="Đồng ý"
      onOk={() => form.handleSubmit(handleSubmitForm)()}
      onCancel={handleCloseConfirm}
      open={open}
      centered={true}
      className="sm:w-[500px]"
      confirmLoading={(isLoading && !!id) || isLoadingMutate}
    >
      <Spin spinning={(isLoading && !!id) || isLoadingMutate}>
        {isError && !!id ? (
          <NoData />
        ) : (
          <FormTera form={form}>
            <FormTeraItem
              label="Ảnh đại diện"
              className="flex flex-col col-span-2"
              name="file_upload"
              rules={[
                {
                  required: messageValidate.emptySelect,
                },
              ]}
            >
              <Image folder="category" object_key="category" />
            </FormTeraItem>
            <FormTeraItem
              label="Tên danh mục"
              name="name"
              rules={[
                {
                  required: messageValidate.emptyText,
                  maxLength: {
                    value: 255,
                    message: messageValidateLength.textLong,
                  },
                },
              ]}
            >
              <Input />
            </FormTeraItem>
            <FormTeraItem
              label="Mô tả"
              name="description"
              rules={[
                {
                  maxLength: {
                    value: 1000,
                    message: messageValidateLength.textArea,
                  },
                },
              ]}
            >
              <TextArea rows={10} />
            </FormTeraItem>
          </FormTera>
        )}
      </Spin>
    </Modal>
  );
}

export default CategoryForm;
