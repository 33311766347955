import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const SaleOrderReturnEndpoint = `${endpoint}/sumi/sell/order-return`;

const SaleOrderReturnApi = {
  getList: async ({ params }) =>
    await api
      .get(`${SaleOrderReturnEndpoint}/list`, params)
      .then((res) => res.data?.data),
  getDetail: async ({ id }) =>
    await api
      .get(`${SaleOrderReturnEndpoint}/detail/${id}`)
      .then((res) => res.data?.data),
  getSummary: async ({ params }) =>
    await api
      .get(`${SaleOrderReturnEndpoint}/summary-list`, params)
      .then((res) => res.data?.data),
  create: async ({ params }) =>
    await api
      .post(`${SaleOrderReturnEndpoint}/create`, params)
      .then((res) => res.data),
  update: async ({ id, params }) =>
    await api
      .put(`${SaleOrderReturnEndpoint}/update/${id}`, params)
      .then((res) => res.data),
  delete: async ({ id }) =>
    await api
      .delete(`${SaleOrderReturnEndpoint}/delete/${id}`)
      .then((res) => res.data),
  approve: async ({ id, params }) =>
    await api
      .post(`${SaleOrderReturnEndpoint}/approval/${id}`, params)
      .then((res) => res.data),
};

export default SaleOrderReturnApi;
