export const TAB_ITEMS_STATUS = {
  all: 'Tất cả',
  1: 'Đang hoạt động',
  0: 'Ngưng hoạt động',
};

export const TAB_ITEMS_STATUS_COLOR = {
  1: 'green03',
  0: 'gray01',
};

export const GENDER = {
  male: 'Nam',
  female: 'Nữ',
};
