import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const InvestorEndpoint = `${endpoint}/portal/investor`;

const InvestorApi = {
  getList: async ({ params }) =>
    await api
      .get(`${InvestorEndpoint}/list`, params)
      .then((res) => res.data?.data),
  getDetail: async ({ id }) =>
    await api
      .get(`${InvestorEndpoint}/detail/${id}`)
      .then((res) => res.data?.data),
  getSummary: async ({ params }) =>
    await api
      .get(`${InvestorEndpoint}/summary-list`, params)
      .then((res) => res.data?.data),
  create: async ({ params }) =>
    await api
      .post(`${InvestorEndpoint}/create`, params)
      .then((res) => res.data),
  update: async ({ id, params }) =>
    await api
      .put(`${InvestorEndpoint}/update/${id}`, params)
      .then((res) => res.data),
  changeStatus: async ({ params }) =>
    await api
      .post(`${InvestorEndpoint}/active`, params)
      .then((res) => res.data),
  resetPassword: async ({ params }) =>
    await api
      .put(`${InvestorEndpoint}/reset-password`, params)
      .then((res) => res.data),
  delete: async ({ id }) =>
    await api
      .delete(`${InvestorEndpoint}/delete/${id}`)
      .then((res) => res.data),
};

export default InvestorApi;
