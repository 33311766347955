import React from 'react';

function NotiHr(props) {
  return (
    <svg
      width="13"
      height="15"
      viewBox="0 0 13 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.89522 5.88531H7.39493C7.89473 5.88531 8.37405 6.08385 8.72745 6.43726C9.08086 6.79066 9.2794 7.26998 9.2794 7.76977C9.2794 8.26956 9.08086 8.74888 8.72745 9.10229C8.37405 9.4557 7.89473 9.65424 7.39493 9.65424H6.58731M3.89522 5.88531L5.51047 4.27005M3.89522 5.88531L5.51047 7.50056M11.9715 2.30088V14.5L9.2794 13.4232L6.58731 14.5L3.89522 13.4232L1.20312 14.5V2.30088C1.20312 1.50546 1.78175 0.824178 2.57214 0.732288C5.23996 0.422571 7.93465 0.422571 10.6025 0.732288C11.3922 0.824178 11.9715 1.50546 11.9715 2.30088Z"
        stroke="#6B7280"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default NotiHr;
