import { EXPENSE_VOUCHER_PERMISSION_KEY } from '_common/constants/permission';

export const EXPENSE_VOUCHER_URL = {
  list: {
    key: EXPENSE_VOUCHER_PERMISSION_KEY.VIEW_LIST,
    path: '/finance/expense-voucher/list',
    shortenUrl: 'expense-voucher/list',
  },
  create: {
    key: EXPENSE_VOUCHER_PERMISSION_KEY.CREATE,
    path: '/finance/expense-voucher/create',
    shortenUrl: 'expense-voucher/create',
  },
  update: {
    key: EXPENSE_VOUCHER_PERMISSION_KEY.UPDATE,
    path: '/finance/expense-voucher/update',
    shortenUrl: 'expense-voucher/update/:id',
  },
};
