import { useQuery } from '@tanstack/react-query';
import HeaderViewList from '_common/component/HeaderViewList';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getQueryParams, PaginationProps, updateQueryParams } from 'tera-dls';
import PaymentMethodApi from './api';
import PaymentMethodFilter from './containers/Filter';
import PaymentMethodSearch from './containers/Search';
import PaymentMethodTable from './containers/Table';
import { PAYMENT_METHOD_URL } from './url';
import { PAYMENT_METHOD_PERMISSION_KEY } from '_common/constants/permission';
import { usePermission } from '_common/hooks/usePermission';

function PaymentMethod() {
  const [isOpenFilter, setIsOpenFilter] = useState<boolean>(false);

  const navigate = useNavigate();
  const { search } = useLocation();
  const { hasPage } = usePermission();
  const queryParams = getQueryParams(search) as any;

  const handleUpdateQueryParams = (data) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(`${PAYMENT_METHOD_URL.list.path}${paramString}`);
  };

  const {
    data: paymentMethodList,
    isLoading,
    refetch,
  } = useQuery(
    ['get-payment-method-list', queryParams],
    () => {
      const params = {
        ...queryParams,
        page: queryParams?.page || 1,
        limit: queryParams?.limit || 10,
        status: queryParams?.status === 'all' ? undefined : queryParams?.status,
        author: 'sumi',
      };
      return PaymentMethodApi.getList({ params });
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
      onSuccess: (data) => {
        if (Number(data?.last_page) < (Number(queryParams?.page) || 1)) {
          handleUpdateQueryParams({ page: data?.last_page });
        }
      },
    },
  );

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    const isDiffPageSize =
      Number(pageSize) !== Number(paymentMethodList?.limit || 10);

    handleUpdateQueryParams({
      page: isDiffPageSize ? 1 : page,
      limit: pageSize,
    });
  };

  const handleFilter = (values) => {
    handleUpdateQueryParams({ ...values, page: 1 });
  };

  const handleSearch = (value) => {
    handleUpdateQueryParams({ keyword: value?.keyword, page: 1 });
  };

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="tera-page">
      <HeaderViewList
        title="Danh sách phương thức thanh toán"
        buttonFilterKey={PAYMENT_METHOD_PERMISSION_KEY.SEARCH}
        buttonCreatingKey={PAYMENT_METHOD_PERMISSION_KEY.CREATE}
        onClickButtonAdd={() => navigate(PAYMENT_METHOD_URL.create.path)}
        onClickFilter={() => setIsOpenFilter(true)}
        actionLeftRender={
          hasPage(PAYMENT_METHOD_PERMISSION_KEY.SEARCH) && (
            <PaymentMethodSearch onSearch={handleSearch} />
          )
        }
        filterCount={{
          filter: queryParams,
          params: ['object_type', 'card_type_id'],
        }}
      >
        <PaymentMethodTable
          rowKey={'id'}
          data={paymentMethodList?.data || []}
          loading={isLoading}
          pagination={{
            onChange: handleChangePage,
            total: paymentMethodList?.total || 0,
            current: paymentMethodList?.current_page,
            pageSize: paymentMethodList?.per_page || 10,
            to: paymentMethodList?.to,
            from: paymentMethodList?.from || 10,
          }}
        />
      </HeaderViewList>

      {isOpenFilter && (
        <PaymentMethodFilter
          open={isOpenFilter}
          onClose={() => setIsOpenFilter(false)}
          onFilter={handleFilter}
          initialValue={queryParams}
        />
      )}
    </div>
  );
}

export default PaymentMethod;
