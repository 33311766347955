import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const IncurredCostEndpoint = `${endpoint}/portal/incurred-cost`;

const IncurredCostApi = {
  getList: async ({ params }) =>
    await api
      .get(`${IncurredCostEndpoint}/list`, params)
      .then((res) => res.data?.data),
  getDetail: async ({ id }) =>
    await api
      .get(`${IncurredCostEndpoint}/detail/${id}`)
      .then((res) => res.data?.data),
  getSummary: async ({ params }) =>
    await api
      .get(`${IncurredCostEndpoint}/summary-list`, params)
      .then((res) => res.data?.data),
  create: async ({ params }) =>
    await api
      .post(`${IncurredCostEndpoint}/create`, params)
      .then((res) => res.data),
  update: async ({ id, params }) =>
    await api
      .put(`${IncurredCostEndpoint}/update/${id}`, params)
      .then((res) => res.data),
  changeStatus: async ({ params }) =>
    await api
      .post(`${IncurredCostEndpoint}/active/${params?.id}`, params)
      .then((res) => res.data),
  delete: async ({ id }) =>
    await api
      .delete(`${IncurredCostEndpoint}/delete/${id}`)
      .then((res) => res.data),
};

export default IncurredCostApi;
