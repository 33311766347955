import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const TimekeepingEndpoint = `${endpoint}/portal/timekeeping`;

const TimekeepingApi = {
  getList: async ({ params }) =>
    await api
      .get(`${TimekeepingEndpoint}/list`, params)
      .then((res) => res?.data?.data),
  getDetail: async ({ id }) =>
    await api
      .get(`${TimekeepingEndpoint}/detail/${id}`)
      .then((res) => res?.data?.data),
  getSummary: async ({ params }) =>
    await api
      .get(`${TimekeepingEndpoint}/summary-list`, params)
      .then((res) => res.data?.data),
  create: async ({ params }) =>
    await api
      .post(`${TimekeepingEndpoint}/create`, params)
      .then((res) => res?.data),
  update: async ({ id, params }) =>
    await api
      .put(`${TimekeepingEndpoint}/update/${id}`, params)
      .then((res) => res?.data),
  delete: async ({ id }) =>
    await api
      .delete(`${TimekeepingEndpoint}/delete/${id}`)
      .then((res) => res?.data),
};

export default TimekeepingApi;
