import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import CardFormV2 from '_common/component/CardForm/CardFormV2';
import HoverQuickView from '_common/component/HoverQuickView';
import { IconButton } from '_common/component/TableColumnCustom';
import { DATE_TIME_FORMAT, SUMI_SHADOW } from '_common/constants/common';
import { ORDER_RETURN_PERMISSION_KEY } from '_common/constants/permission';
import { INVESTMENT_CATEGORY } from '_common/dof/Select/SelectInvestmentCategory';
import { TICKET_TYPE } from '_common/dof/Select/SelectTicketType';
import TableTera from '_common/dof/TableTera';
import useConfirm from '_common/hooks/useConfirm';
import { usePermission } from '_common/hooks/usePermission';
import classNames from 'classnames';
import printJS from 'print-js';
import { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PortalApi from 'states/api/portal';
import {
  ArrowSmallLeftOutlined,
  Breadcrumb,
  Button,
  ColumnsType,
  formatCurrency,
  formatDate,
  Image,
  InformationCircleOutlined,
  ItemType,
  notification,
  PencilSquareOutlined,
  PrinterOutlined,
  Row,
  Spin,
  Tag,
  Tooltip,
  XMarkOutlined,
} from 'tera-dls';
import SaleOrderReturnApi from '../../api';
import { ORDER_STATUS, ORDER_STATUS_COLOR } from '../../constants';
import { SALE_ORDER_RETURN_URL } from '../../url';

function InvestmentDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const confirm = useConfirm();
  const queryClient = useQueryClient();
  const { hasPage } = usePermission();

  const {
    data: dataDetails,
    refetch,
    isLoading,
    isError,
  } = useQuery(
    ['get-sale-order-return-detail', id],
    () => SaleOrderReturnApi.getDetail({ id }),
    {
      enabled: !!id,
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const { mutate, isLoading: loadingChangeStatus } = useMutation(
    (params: any) => SaleOrderReturnApi.approve({ id, params }),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          refetch();
          queryClient.invalidateQueries(['get-sale-order-return-list']);
          queryClient.invalidateQueries(['get-summary-sale-order-return-list']);
        }
      },
      onError: (error: any) => {
        notification.error({
          message: error?.message,
        });
      },
    },
  );

  const { mutate: mutateDelete, isLoading: loadingDelete } = useMutation(
    (id: any) => SaleOrderReturnApi.delete({ id }),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          navigate(-1);
          queryClient.invalidateQueries(['get-sale-order-return-list']);
          queryClient.invalidateQueries(['get-summary-sale-order-return-list']);
        }
      },
      onError: (error: any) => {
        notification.error({
          message: error?.message,
        });
      },
    },
  );

  const { mutate: mutatePrint, isLoading: loadingPrint } = useMutation(
    (params: any) => PortalApi.print({ params }),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          printJS({
            printable: res?.data,
          });
        }
      },
      onError: (error: any) => {
        notification.error({
          message: error?.message,
        });
      },
    },
  );

  const handlePrint = () => {
    mutatePrint({
      export_pdf: 1,
      order_id: id,
      type: 'return',
    });
  };

  const handleDelete = () => {
    confirm.warning({
      title: 'Xác nhận xóa đơn trả hàng bán',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn xóa đơn trả hàng bán</p>
          <p>
            <b>{dataDetails?.code}</b> này không?
          </p>
        </div>
      ),
      onOk: () => {
        mutateDelete(dataDetails?.id);
      },
    });
  };

  const handleApprove = () => {
    confirm.warning({
      title: 'Xác nhận duyệt đơn trả hàng bán',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn duyệt đơn trả hàng bán</p>
          <p>
            <b>{dataDetails?.code}</b> này không?
          </p>
        </div>
      ),
      onOk: () => {
        mutate({
          status: 'approved',
          type_status: 'sumi',
        });
      },
    });
  };

  const handleReject = () => {
    confirm.warning({
      title: 'Xác nhận từ chối đơn trả hàng bán',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn từ chối đơn trả hàng bán</p>
          <p>
            <b>{dataDetails?.code}</b> này không?
          </p>
        </div>
      ),
      onOk: () => {
        mutate({
          status: 'reject',
        });
      },
    });
  };

  const BreadcrumbItem: ItemType[] = [
    {
      title: (
        <a
          onClick={() => navigate(SALE_ORDER_RETURN_URL.list.path)}
          className="!text-blue-400 hover:!text-blue-600"
        >
          Danh sách đơn trả hàng bán
        </a>
      ),
    },
    {
      title: 'Chi tiết đơn trả hàng bán',
    },
  ];

  const detail = [
    {
      label: 'Đầu tư',
      value: (
        <HoverQuickView
          name={dataDetails?.invest_name}
          avatarUrl={dataDetails?.invest_id?.file_upload}
          code={dataDetails?.invest_id?.code}
          sub={dataDetails?.invest_id?.description}
        >
          {dataDetails?.invest_name}
        </HoverQuickView>
      ),
    },
    {
      label: 'Mã đơn hàng',
      value: dataDetails?.code,
    },
    {
      label: 'Người tạo',
      value: (
        <HoverQuickView
          avatarUrl={dataDetails?.created_by?.avatar}
          name={dataDetails?.created_by?.full_name}
          code={dataDetails?.created_by?.code}
          email={dataDetails?.created_by?.email}
          phone={dataDetails?.created_by?.phone}
        >
          {dataDetails?.created_by?.full_name}
        </HoverQuickView>
      ),
    },
    {
      label: 'Danh mục đầu tư',
      value: INVESTMENT_CATEGORY[dataDetails?.type],
    },
    {
      label: 'Ngày duyệt đơn trả hàng bán',
      value:
        dataDetails?.approved_date &&
        formatDate(dataDetails?.approved_date, DATE_TIME_FORMAT),
    },
    {
      label: 'Ngày tạo',
      value:
        dataDetails?.created_at &&
        formatDate(dataDetails?.created_at, DATE_TIME_FORMAT),
    },
    {
      label: 'Nhà đầu tư',
      value: (
        <HoverQuickView name={dataDetails?.investor_name || 'Hệ thống'}>
          {dataDetails?.investor_name || 'Hệ thống'}
        </HoverQuickView>
      ),
      // dataDetails?.investor_name || 'Hệ thống',
    },
    {
      label: 'Loại phiếu',
      value: TICKET_TYPE[dataDetails?.ticket_type],
    },
    {
      label: 'Người cập nhật',
      value: (
        <HoverQuickView
          avatarUrl={dataDetails?.updated_by?.avatar}
          name={dataDetails?.updated_by?.full_name}
          code={dataDetails?.updated_by?.code}
          email={dataDetails?.updated_by?.email}
          phone={dataDetails?.updated_by?.phone}
        >
          {dataDetails?.updated_by?.full_name}
        </HoverQuickView>
      ),
    },
    {
      label: 'Tên thiết bị',
      value: dataDetails?.device_name,
    },
    {
      label: 'Thời gian nhận',
      value:
        dataDetails?.receipt_date &&
        formatDate(dataDetails?.receipt_date, DATE_TIME_FORMAT),
    },
    {
      label: 'Ngày cập nhật',
      value:
        dataDetails?.updated_by &&
        dataDetails?.updated_at &&
        formatDate(dataDetails?.updated_at, DATE_TIME_FORMAT),
    },
    ...(dataDetails?.ticket_type === 'request'
      ? [
          {
            label: 'Ghi chú thông tin khách hàng',
            value: dataDetails?.customer_note,
          },
        ]
      : []),
    {
      label: 'Trạng thái',
      value: dataDetails?.order_status && (
        <div className="flex gap-x-2.5 items-center">
          <Tag color={ORDER_STATUS_COLOR[dataDetails?.order_status]}>
            {ORDER_STATUS[dataDetails?.order_status]}
          </Tag>
          {dataDetails?.type_status === 'sumi' && (
            <Tooltip
              title={`SUMI ${ORDER_STATUS[dataDetails?.order_status]}`}
              placement="top"
            >
              <div>
                <InformationCircleOutlined className="w-5 h-5" />
              </div>
            </Tooltip>
          )}
        </div>
      ),
    },
    ...(dataDetails?.ticket_type === 'request'
      ? [
          {
            label: '',
            value: '',
          },
          {
            label: 'Ghi chú mô tả',
            value: dataDetails?.note,
          },
        ]
      : [
          {
            label: 'Ghi chú',
            value: dataDetails?.note,
          },
        ]),
  ];

  const columns: ColumnsType<any> = [
    {
      title: 'Tên sản phẩm',
      dataIndex: 'name',
      width: 200,
      render: (name, record) => {
        const image =
          dataDetails?.ticket_type === 'order'
            ? record?.file_upload
            : record?.product_image_request;

        return (
          <div className="flex gap-x-2.5">
            <Image
              src={image}
              alt={image}
              containerClassName="w-[30px] h-[30px] rounded overflow-hidden border shrink-0"
              imageClassName="object-cover"
            />
            <p className="line-clamp-2 break-word">{name}</p>
          </div>
        );
      },
    },
    {
      title: 'Tên viết tắt',
      dataIndex: 'abbreviation',
      width: 100,
    },
    {
      title: 'Danh mục',
      dataIndex: 'catalog_name',
      width: 200,
      render: (catalog_name) => <p className="line-clamp-2">{catalog_name}</p>,
    },
    {
      title: 'Đơn vị',
      dataIndex: 'unit_name',
      width: 100,
      render: (unit_name) => unit_name,
    },
    {
      title: 'Số lượng thực nhận',
      dataIndex: 'quantity_check',
      width: 200,
    },
    {
      title: 'Số lượng trả hàng',
      dataIndex: 'quantity_return',
      width: 200,
    },
    {
      title: 'Đơn giá',
      dataIndex: 'amount',
      width: 150,
      render: (amount) => formatCurrency(amount),
    },
    {
      title: 'Thành tiền',
      dataIndex: 'total',
      width: 200,
      render: (total) => formatCurrency(total),
    },
  ];

  const products = useMemo(() => {
    if (!dataDetails?.order_detail) return;
    const data = dataDetails?.order_detail
      ?.filter((item) => item?.quantity_check)
      ?.map((prod) => ({
        name: prod?.product_name,
        abbreviation: prod?.abbreviation,
        catalog_name: prod?.catalog_name,
        unit_name: prod?.unit_name,
        file_upload: prod?.image,
        amount: prod?.amount,
        product_image_request: prod?.product_image_request?.[0],

        total: prod?.quantity_return * prod?.amount,
        quantity_check: prod?.quantity_check,
        quantity_return: prod?.quantity_return,
      }));
    return data;
  }, [dataDetails]);

  useEffect(() => {
    refetch();
  }, []);

  const disabled =
    isError ||
    isLoading ||
    loadingDelete ||
    loadingChangeStatus ||
    loadingPrint;

  return (
    <div className="tera-page-form">
      <div className="page-header-sticky">
        <div className="page-header-v2">
          <div className="page-header-v2__breadcrumb">
            <IconButton
              icon={<ArrowSmallLeftOutlined />}
              callBack={() => navigate(-1)}
            />
            <Breadcrumb separator="/" items={BreadcrumbItem} />
          </div>
          <div className="page-header-v2__function">
            {hasPage(ORDER_RETURN_PERMISSION_KEY.PRINT) && (
              <Button
                type="success"
                className="page-header-v2-btn"
                onClick={handlePrint}
                disabled={disabled}
                prefix={<PrinterOutlined />}
              >
                In đơn
              </Button>
            )}
            {dataDetails?.order_status === 'pending' &&
              hasPage(ORDER_RETURN_PERMISSION_KEY.CHANGE_STATUS) && (
                <>
                  <Button
                    type="danger"
                    className="page-header-v2-btn"
                    onClick={handleReject}
                    disabled={disabled}
                  >
                    Từ chối
                  </Button>
                  <Button
                    type="success"
                    className="page-header-v2-btn"
                    onClick={handleApprove}
                    disabled={disabled}
                  >
                    Duyệt
                  </Button>
                </>
              )}
            {dataDetails?.order_status !== 'approved' && (
              <>
                {hasPage(ORDER_RETURN_PERMISSION_KEY.UPDATE) && (
                  <Button
                    className="page-header-v2-btn"
                    prefix={<PencilSquareOutlined />}
                    onClick={() =>
                      navigate(`${SALE_ORDER_RETURN_URL.update.path}/${id}`)
                    }
                    disabled={disabled}
                  >
                    Sửa
                  </Button>
                )}

                {hasPage(ORDER_RETURN_PERMISSION_KEY.DELETE) && (
                  <Button
                    className="page-header-v2-btn"
                    type="danger"
                    prefix={<XMarkOutlined />}
                    onClick={handleDelete}
                    disabled={disabled}
                  >
                    Xóa
                  </Button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <Spin spinning={disabled}>
        <div className="page-content-v2">
          <div className="bg-white rounded-[10px] px-2.5 py-5 shadow">
            <div className="flex flex-col gap-4">
              <div
                className={classNames(
                  'px-2.5 py-5 rounded-md border border-blue-400',
                  SUMI_SHADOW,
                )}
              >
                <CardFormV2 title="Thông tin chung">
                  <Row className="grid grid-cols-3 gap-2.5">
                    {detail.map((item, index) => (
                      <div
                        className={classNames('flex items-start gap-x-2.5')}
                        key={index}
                      >
                        <div className="detail-key">{item.label}</div>
                        <div className="detail-value">{item.value}</div>
                      </div>
                    ))}
                  </Row>
                </CardFormV2>
              </div>
              <div
                className={classNames(
                  'px-2.5 py-5 rounded-md border border-blue-400',
                  SUMI_SHADOW,
                )}
              >
                <CardFormV2 title="Sản phẩm">
                  <TableTera
                    zebra={false}
                    data={products}
                    columns={columns}
                    pagination={{}}
                    hiddenColumns={
                      dataDetails?.ticket_type === 'request' && [
                        'abbreviation',
                        'catalog_name',
                        'unit_name',
                      ]
                    }
                  />
                </CardFormV2>
              </div>
              {dataDetails?.feedback_image?.length > 0 && (
                <div
                  className={classNames(
                    'px-2.5 py-5 rounded-md border border-blue-400 ',
                    SUMI_SHADOW,
                  )}
                >
                  <CardFormV2 title="Hình ảnh trả hàng">
                    <div className="flex items-center gap-x-2.5 flex-wrap">
                      {dataDetails?.feedback_image?.map((img, index) => (
                        <Image
                          key={index}
                          src={img}
                          alt={img}
                          containerClassName="w-[100px] h-[100px] rounded-md overflow-hidden border"
                          imageClassName="object-cover"
                        />
                      ))}
                    </div>
                  </CardFormV2>
                </div>
              )}
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
}

export default InvestmentDetail;
