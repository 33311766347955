import { DATE_FORMAT } from '_common/constants/common';
import Header from '../Header';
import ModalChart from './ModalChart';
import DefaultImage from '_common/component/DefaultImage';
import classNames from 'classnames';
import moment from 'moment';
import { useState } from 'react';
import { formatNumber, Progress, Spin, Tooltip } from 'tera-dls';
import { useQuery } from '@tanstack/react-query';
import DashboardApi from '../api';
import NoData from '_common/component/NoData';

function TopBestSeller(props) {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { type = 'small', wrapperClassName, onClose } = props;
  const isSmallType = type === 'small';

  const startOfMonth = moment().startOf('month').format(DATE_FORMAT);
  const endOfMonth = moment().endOf('month').format(DATE_FORMAT);

  const { data, isLoading } = useQuery(
    ['get-top-best-seller-list'],
    () => DashboardApi.getTopBestSeller(),
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  return (
    <Spin spinning={isLoading}>
      <Header
        isSmallType={isSmallType}
        title="Top 10 sản phẩm bán chạy"
        isRefetch={false}
        isSetting={false}
        onZoomIn={onClose}
        onZoomOut={() => setOpenModal(true)}
      />
      <span className="text-[10px] text-[#6B7280]">
        {startOfMonth} - {endOfMonth}
      </span>
      <div
        className={classNames('mt-2.5 overflow-auto pr-2.5', wrapperClassName)}
      >
        {data?.length > 0 ? (
          <>
            {data?.map((item, index) => {
              const percentage = Number.isInteger(item?.percentage)
                ? item?.percentage
                : item?.percentage?.toFixed(1);
              return (
                <div
                  key={index}
                  className="flex flex-col gap-y-[5px] [&:not(:last-child)]:pb-5"
                >
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-x-2.5">
                      <DefaultImage
                        src={item?.file_upload}
                        alt={item?.name}
                        className="w-[35px] h-[35px] rounded"
                      />
                      <p>{item.name}</p>
                    </div>
                    <Tooltip title={formatNumber(item?.total_quantity)}>
                      <p className="text-purple-500">{percentage}%</p>
                    </Tooltip>
                  </div>
                  <Progress percent={percentage} size="4px" />
                </div>
              );
            })}
          </>
        ) : (
          <NoData />
        )}
      </div>
      {openModal && (
        <ModalChart
          open={openModal}
          onClose={() => setOpenModal(false)}
          type="large"
        />
      )}
    </Spin>
  );
}

export default TopBestSeller;
