import { useQuery } from '@tanstack/react-query';
import HeaderViewList from '_common/component/HeaderViewList';
import SumiTab from '_common/component/Sumi/Tab';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Badge,
  getQueryParams,
  PaginationProps,
  TabItemType,
  updateQueryParams,
} from 'tera-dls';
import {
  TAB_ITEMS_STATUS,
  TAB_ITEMS_STATUS_RENDER,
} from '_common/constants/common';
import IncurredCostCategoryApi from './api';
import IncurredCostCategoryForm from './containers/Form';
import IncurredCostCategorySearch from './containers/Search';
import IncurredCostCategoryTable from './containers/Table';
import { INCURRED_COST_CATEGORY_URL } from './url';
import { INCURRED_COST_CATEGORY_PERMISSION_KEY } from '_common/constants/permission';
import { usePermission } from '_common/hooks/usePermission';

function IncurredCostCategory() {
  const [openForm, setOpenForm] = useState({ open: false, id: null });

  const { hasPage } = usePermission();
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;

  const handleUpdateQueryParams = (data: any) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(`${INCURRED_COST_CATEGORY_URL.list.path}${paramString}`);
  };

  const {
    data: incurredCostCategoryList,
    isLoading,
    refetch,
  } = useQuery(
    ['get-incurred-cost-category-list', queryParams],
    () => {
      const params = {
        ...queryParams,
        page: queryParams?.page || 1,
        limit: queryParams?.limit || 10,
        status: queryParams?.status === 'all' ? undefined : queryParams?.status,
      };
      return IncurredCostCategoryApi.getList({ params });
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
      onSuccess: (data) => {
        if (Number(data?.last_page) < (Number(queryParams?.page) || 1)) {
          handleUpdateQueryParams({ page: data?.last_page });
        }
      },
    },
  );

  const { data: summaryList } = useQuery(
    ['get-summary-incurred-cost-category-list', queryParams],
    () => {
      const params = {
        ...queryParams,
        page: queryParams?.page || 1,
        limit: queryParams?.limit || 10,
        status: undefined,
      };
      return IncurredCostCategoryApi.getSummary({ params });
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    const isDiffPageSize =
      Number(pageSize) !== Number(incurredCostCategoryList?.limit || 10);

    handleUpdateQueryParams({
      page: isDiffPageSize ? 1 : page,
      limit: pageSize,
    });
  };

  const handleChangeTab = (key: string) => {
    handleUpdateQueryParams({ status: key, page: 1 });
  };

  const handleSearch = (value: any) => {
    handleUpdateQueryParams({ keyword: value?.keyword, page: 1 });
  };

  const tabItems: TabItemType[] = useMemo(() => {
    const tabs = TAB_ITEMS_STATUS_RENDER.map((item) => {
      const count =
        summaryList?.find((i) => i?.status == item)?.total_count || 0;
      return {
        key: item,
        label: (
          <div className="flex items-center gap-x-2">
            {TAB_ITEMS_STATUS[item]} <Badge count={count} showZero />
          </div>
        ),
      };
    });
    return tabs;
  }, [summaryList]);

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="tera-page">
      <HeaderViewList
        title="Danh mục chi phí phát sinh"
        buttonFilterKey={INCURRED_COST_CATEGORY_PERMISSION_KEY.SEARCH}
        buttonCreatingKey={INCURRED_COST_CATEGORY_PERMISSION_KEY.CREATE}
        onClickButtonAdd={() => setOpenForm({ open: true, id: null })}
        middleContent={
          hasPage(INCURRED_COST_CATEGORY_PERMISSION_KEY.SEARCH) && (
            <SumiTab onChange={handleChangeTab} items={tabItems} />
          )
        }
        actionLeftRender={
          hasPage(INCURRED_COST_CATEGORY_PERMISSION_KEY.SEARCH) && (
            <IncurredCostCategorySearch onSearch={handleSearch} />
          )
        }
      >
        <IncurredCostCategoryTable
          rowKey={'id'}
          data={incurredCostCategoryList?.data || []}
          loading={isLoading}
          pagination={{
            onChange: handleChangePage,
            total: incurredCostCategoryList?.total || 0,
            current: incurredCostCategoryList?.current_page,
            pageSize: incurredCostCategoryList?.per_page || 10,
            to: incurredCostCategoryList?.to,
            from: incurredCostCategoryList?.from || 10,
          }}
        />
      </HeaderViewList>
      {openForm.open && (
        <IncurredCostCategoryForm
          open={openForm.open}
          id={openForm.id}
          onClose={() => setOpenForm({ open: false, id: null })}
        />
      )}
    </div>
  );
}

export default IncurredCostCategory;
