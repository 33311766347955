import { IRouteProps } from '_common/interface/router';
import { CEASE_INVESTMENT_URL } from './url';
import CeaseInvestment from '.';
import CeaseInvestmentDetail from './containers/Detail';

export const CeaseInvestmentRouter: IRouteProps[] = [
  {
    key: CEASE_INVESTMENT_URL.list.key,
    path: CEASE_INVESTMENT_URL.list.shortenUrl,
    component: <CeaseInvestment />,
  },
  {
    key: CEASE_INVESTMENT_URL.detail.key,
    path: CEASE_INVESTMENT_URL.detail.shortenUrl,
    component: <CeaseInvestmentDetail />,
  },
];
