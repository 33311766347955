import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import { TIME_FORMAT } from '_common/constants/common';
import { TIMEKEEPING_PERMISSION_KEY } from '_common/constants/permission';
import TableTera from '_common/dof/TableTera';
import { ITeraTableProps } from '_common/dof/TableTera/_interfaces';
import React, { useState } from 'react';
import { DropdownItem, formatDate, notification } from 'tera-dls';
import TimekeepingApi from '../../api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { usePermission } from '_common/hooks/usePermission';
import useConfirm from '_common/hooks/useConfirm';
import TimekeepingForm from '../Form';
import TimekeepingDetail from '../Detail';

function TimekeepingTable(props: ITeraTableProps) {
  const [openForm, setOpenForm] = useState({ open: false, id: null });
  const [openDetail, setOpenDetail] = useState({ open: false, id: null });

  const { hasPage } = usePermission();
  const queryClient = useQueryClient();
  const confirm = useConfirm();
  const { mutate: mutateDelete, isLoading: loadingDelete } = useMutation(
    (id: any) => TimekeepingApi.delete({ id }),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries(['get-timekeeping-list']);
          queryClient.invalidateQueries(['get-summary-timekeeping-list']);
        }
      },
      onError: (error: any) => {
        notification.error({
          message: error?.message,
        });
      },
    },
  );

  const handleDelete = (record) => {
    confirm.warning({
      title: 'Xác nhận xóa chấm công',
      content: 'Bạn có chắc muốn xóa chấm công này không?',
      onOk: () => {
        mutateDelete(record?.id);
      },
    });
  };

  const renderItemActions = (record) => {
    const items: DropdownItem[] = [];

    if (hasPage(TIMEKEEPING_PERMISSION_KEY.VIEW_DETAIL))
      items.push({
        key: '1',
        label: 'Xem',
        onClick: () => setOpenDetail({ open: true, id: record?.id }),
      });
    if (hasPage(TIMEKEEPING_PERMISSION_KEY.UPDATE))
      items.push({
        key: '2',
        label: 'Sửa',
        onClick: () => setOpenForm({ open: true, id: record?.id }),
      });
    if (hasPage(TIMEKEEPING_PERMISSION_KEY.DELETE))
      items.push({
        key: '3',
        label: <span className="text-red-500">Xóa</span>,
        onClick: () => handleDelete(record),
      });

    return items;
  };
  const columns: any = [
    {
      title: 'Đầu tư',
      dataIndex: 'invest',
      render: (invest, record) => (
        <p
          className="line-clamp-2 break-word text-blue-600 cursor-pointer"
          onClick={() => setOpenDetail({ open: true, id: record?.id })}
        >
          {invest?.name}
        </p>
      ),
    },
    {
      title: 'Ngày làm việc',
      dataIndex: 'working_date',
      render: (working_date) => formatDate(working_date),
    },
    {
      title: 'Thời gian vào ca',
      dataIndex: 'check_in_time',
      render: (check_in_time) => formatDate(check_in_time, TIME_FORMAT),
    },
    {
      title: 'Thời gian ra ca',
      dataIndex: 'check_out_time',
      render: (check_out_time) => formatDate(check_out_time, TIME_FORMAT),
    },
    {
      title: 'Mô tả',
      dataIndex: 'description',
      render: (description) => <p className="line-clamp-2">{description}</p>,
    },
    {
      width: 60,
      fixed: 'right',
      render: (record) => {
        const items = renderItemActions(record);
        return <ActionDropdown dropdownItems={items} trigger="click" />;
      },
    },
  ];

  return (
    <>
      <TableTera
        zebra={false}
        columns={columns}
        loading={props?.loading || loadingDelete}
        {...props}
      />
      {openDetail.open && (
        <TimekeepingDetail
          open={openDetail.open}
          id={openDetail.id}
          onCancel={() => setOpenDetail({ open: false, id: null })}
        />
      )}
      {openForm.open && (
        <TimekeepingForm
          open={openForm.open}
          id={openForm.id}
          onClose={() => setOpenForm({ open: false, id: null })}
          onSuccess={() => {
            queryClient.invalidateQueries(['get-timekeeping-list']);
            queryClient.invalidateQueries(['get-summary-timekeeping-list']);
          }}
        />
      )}
    </>
  );
}

export default TimekeepingTable;
