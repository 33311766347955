import { useMutation, useQuery } from '@tanstack/react-query';
import HeaderViewList from '_common/component/HeaderViewList';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import { useForm } from 'react-hook-form';
import {
  BookmarkOutlined,
  Button,
  Col,
  notification,
  Row,
  Spin,
} from 'tera-dls';
import TimeConfigApi from './api';
import { TIME_CONFIG } from './constants';
import { useEffect } from 'react';
import { SYSTEM_PERMISSION_KEY } from '_common/constants/permission';
import { usePermission } from '_common/hooks/usePermission';
import { usePrompt } from '_common/hooks/usePrompt';
import { messageWarning } from '_common/constants/message';
function TimeConfig() {
  const form = useForm();
  const { hasPage } = usePermission();
  const isDirty = form.formState.isDirty;
  const isUpdate = hasPage(SYSTEM_PERMISSION_KEY.UPDATE);

  const {
    data: dataDetails,
    isLoading,
    refetch,
  } = useQuery(['get-time-config-list'], () => TimeConfigApi.getList(), {
    staleTime: 300000,
    cacheTime: 300000,
  });

  const { mutate: mutateConfig, isLoading: isLoadingMutate } = useMutation(
    (params: any) => TimeConfigApi.create({ params }),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          form.reset();
          refetch();
        }
      },
      onError: (error: any) => {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleSubmitForm = (values) => {
    const data = Object.entries(values).map(([key, time]) => ({
      key,
      time,
    }));
    mutateConfig({ time: data });
  };

  const options = Object.entries(TIME_CONFIG).map(([value, label]) => ({
    label,
    value,
  }));

  useEffect(() => {
    if (dataDetails) {
      const fields = dataDetails?.reduce((result, current) => {
        return { ...result, [current?.key]: current?.time };
      }, {});
      form.reset(fields);
    }
  }, [dataDetails]);

  const disabled = isLoading || isLoadingMutate;

  usePrompt(
    <>
      <p>{messageWarning.WARNING_EXIT_1}</p>
      <p>{messageWarning.WARNING_EXIT_2}</p>
    </>,
    isDirty,
  );

  return (
    <div className="tera-page">
      <HeaderViewList
        title="Cấu hình thời gian"
        buttonAddRender={() =>
          isUpdate && (
            <Button
              type="success"
              prefix={<BookmarkOutlined />}
              onClick={() => form.handleSubmit(handleSubmitForm)()}
              disabled={!isDirty || disabled}
              className="bg-green-500 hover:bg-green-600"
            >
              Lưu
            </Button>
          )
        }
      >
        <Spin spinning={disabled}>
          <FormTera form={form} className="tera-page-form">
            <div className="page-content-v2 !py-4">
              <Row className="grid-cols-2 gap-x-[30px]">
                <Col>
                  <p className="font-medium text-blue-500 mb-4">
                    Thời gian duyệt đơn
                  </p>
                  <p className="font-light text-gray-500 italic mb-2.5">
                    Cài đặt khoảng thời gian hệ thống tự duyệt đơn kể từ lúc đơn
                    về hệ thống
                  </p>
                  <FormTeraItem
                    name="approve_order"
                    label="Đơn đặt hàng"
                    layout="inline"
                    labelClassName="w-1/2"
                  >
                    <Select allowClear options={options} disabled={!isUpdate} />
                  </FormTeraItem>
                  <FormTeraItem
                    name="approve_order_checking"
                    label="Đơn kiểm hàng"
                    layout="inline"
                    labelClassName="w-1/2"
                  >
                    <Select allowClear options={options} disabled={!isUpdate} />
                  </FormTeraItem>
                  <FormTeraItem
                    name="approve_order_return"
                    label="Đơn trả hàng"
                    layout="inline"
                    labelClassName="w-1/2"
                  >
                    <Select allowClear options={options} disabled={!isUpdate} />
                  </FormTeraItem>
                  <FormTeraItem
                    name="approve_curred_cost"
                    label="Chi phí phát sinh"
                    layout="inline"
                    labelClassName="w-1/2"
                  >
                    <Select allowClear options={options} disabled={!isUpdate} />
                  </FormTeraItem>
                </Col>
                <Col>
                  <p className="font-medium text-blue-500 mb-4">
                    Thời gian xác nhận đơn
                  </p>
                  <p className="font-light text-gray-500 italic mb-2.5">
                    Cài đặt khoảng thời gian hệ thống tự xác nhận đơn khi kết
                    thúc thời gian làm việc của thiết bị
                  </p>
                  <FormTeraItem
                    name="create_order"
                    label="Đơn đặt hàng"
                    layout="inline"
                    labelClassName="w-1/2"
                  >
                    <Select allowClear options={options} disabled={!isUpdate} />
                  </FormTeraItem>
                  <FormTeraItem
                    name="create_order_return"
                    label="Đơn trả hàng"
                    layout="inline"
                    labelClassName="w-1/2"
                  >
                    <Select allowClear options={options} disabled={!isUpdate} />
                  </FormTeraItem>
                </Col>
              </Row>
            </div>
          </FormTera>
        </Spin>
      </HeaderViewList>
    </div>
  );
}

export default TimeConfig;
