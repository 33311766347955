import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import HeaderViewList from '_common/component/HeaderViewList';
import TableTera from '_common/dof/TableTera';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  DropdownItem,
  getQueryParams,
  notification,
  PaginationProps,
  updateQueryParams,
} from 'tera-dls';

import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import useConfirm from '_common/hooks/useConfirm';
import PermissionApi from './api';
import PermissionSearch from './containers/Search';
import { PERMISSION_URL } from './url';
import { usePermission } from '_common/hooks/usePermission';
import { ROLE_PERMISSION_KEY } from '_common/constants/permission';

function Permission() {
  const { hasPage } = usePermission();
  const navigate = useNavigate();
  const confirm = useConfirm();
  const { search } = useLocation();
  const queryClient = useQueryClient();
  const queryParams = getQueryParams(search) as any;

  const handleUpdateQueryParams = (data) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(`${PERMISSION_URL.list.path}${paramString}`);
  };

  const {
    data: permissionList,
    isLoading,
    refetch,
  } = useQuery(
    ['get-permission-list', queryParams],
    () => {
      const params = {
        ...queryParams,
        page: queryParams?.page || 1,
        limit: queryParams?.limit || 10,
      };
      return PermissionApi.getList({ params });
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
      onSuccess: (data) => {
        if (Number(data?.last_page) < (Number(queryParams?.page) || 1)) {
          handleUpdateQueryParams({ page: data?.last_page });
        }
      },
    },
  );

  const { mutate: mutateDelete, isLoading: loadingDelete } = useMutation(
    (id: any) => PermissionApi.delete({ id }),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries(['get-permission-list']);
        }
      },
      onError: (error: any) => {
        notification.error({
          message: error?.message,
        });
      },
    },
  );

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    const isDiffPageSize =
      Number(pageSize) !== Number(permissionList?.limit || 10);

    handleUpdateQueryParams({
      page: isDiffPageSize ? 1 : page,
      limit: pageSize,
    });
  };

  const handleSearch = (value) => {
    handleUpdateQueryParams({ keyword: value?.keyword, page: 1 });
  };

  const handleDelete = (record) => {
    confirm.warning({
      title: 'Xác nhận xóa cấu hình quyền',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn xóa cấu hình quyền</p>
          <p>
            <b>{record?.name}</b> này không?
          </p>
        </div>
      ),
      onOk: () => {
        mutateDelete(record?.id);
      },
    });
  };

  const renderItemActions = (record) => {
    const items: DropdownItem[] = [];

    if (hasPage(ROLE_PERMISSION_KEY.VIEW_DETAIL))
      items.push({
        key: '1',
        label: 'Xem',
        onClick: () => navigate(`${PERMISSION_URL.detail.path}/${record?.id}`),
      });
    if (hasPage(ROLE_PERMISSION_KEY.UPDATE))
      items.push({
        key: '3',
        label: 'Sửa',
        onClick: () => navigate(`${PERMISSION_URL.update.path}/${record?.id}`),
      });
    if (hasPage(ROLE_PERMISSION_KEY.DELETE))
      items.push({
        key: '4',
        label: <span className="text-red-500">Xóa</span>,
        onClick: () => handleDelete(record),
      });

    return items;
  };

  const columns: any = [
    {
      title: 'Mã quyền',
      dataIndex: 'code',
      width: 500,
      render: (full_name, record) => (
        <p
          className="line-clamp-2 break-word text-blue-600 cursor-pointer"
          onClick={() =>
            navigate(`${PERMISSION_URL.detail.path}/${record?.id}`)
          }
        >
          {full_name}
        </p>
      ),
    },
    {
      title: 'Tên quyền',
      dataIndex: 'name',
      render: (name) => <p className="line-clamp-2">{name}</p>,
    },
    {
      width: 60,
      fixed: 'right',
      render: (record) => {
        const items = renderItemActions(record);
        return <ActionDropdown dropdownItems={items} trigger="click" />;
      },
    },
  ];

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="tera-page">
      <HeaderViewList
        title="Danh sách quyền"
        buttonCreatingKey={ROLE_PERMISSION_KEY.CREATE}
        onClickButtonAdd={() => navigate(PERMISSION_URL.create.path)}
        actionLeftRender={
          hasPage(ROLE_PERMISSION_KEY.SEARCH) && (
            <PermissionSearch onSearch={handleSearch} />
          )
        }
      >
        <TableTera
          zebra={false}
          rowKey={'id'}
          columns={columns}
          data={permissionList?.data || [{ id: 1 }]}
          loading={isLoading || loadingDelete}
          pagination={{
            onChange: handleChangePage,
            total: permissionList?.total || 0,
            current: permissionList?.current_page,
            pageSize: permissionList?.per_page || 10,
            to: permissionList?.to,
            from: permissionList?.from || 10,
          }}
        />
      </HeaderViewList>
    </div>
  );
}

export default Permission;
