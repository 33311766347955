import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const SalesExpansesEndpoint = `${endpoint}/portal/sale-cost`;

const SalesExpansesApi = {
  getList: async ({ params }) =>
    await api
      .get(`${SalesExpansesEndpoint}/list`, params)
      .then((res) => res.data?.data),
  getDetail: async ({ id }) =>
    await api
      .get(`${SalesExpansesEndpoint}/detail/${id}`)
      .then((res) => res.data?.data),
  create: async ({ params }) =>
    await api
      .post(`${SalesExpansesEndpoint}/create`, params)
      .then((res) => res.data),
  update: async ({ id, params }) =>
    await api
      .put(`${SalesExpansesEndpoint}/update/${id}`, params)
      .then((res) => res.data),
  delete: async ({ id }) =>
    await api
      .delete(`${SalesExpansesEndpoint}/delete/${id}`)
      .then((res) => res.data),
};

export default SalesExpansesApi;
