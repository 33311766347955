import { useTeraForm } from '_common/dof/FormTera/TeraFormContext';
import { useTeraFormItem } from '_common/dof/FormTera/TeraItemContext';
import React from 'react';
import { Controller } from 'react-hook-form';
import customTwMerge from 'tailwind-merge.config';
import { InputNumberProps, InputNumber as InputNumberTera } from 'tera-dls';

const InputNumber = React.memo(({ ...props }: InputNumberProps) => {
  const { form } = useTeraForm();
  const { item, config, rules } = useTeraFormItem();
  const { control } = form;

  return (
    <Controller
      {...item}
      control={control}
      rules={rules}
      render={({ field }) => (
        <InputNumberTera
          formatter={(value) =>
            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          }
          parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
          data-object_type={item?.object_type}
          data-object_id={item?.object_id}
          placeholder="Vui lòng nhập"
          {...field}
          {...props}
          {...config?.field}
          className={customTwMerge(
            'w-full',
            props?.className,
            config?.class_name,
          )}
        />
      )}
    />
  );
});

export default InputNumber;
