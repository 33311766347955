import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const InvestmentTypeEndpoint = `${endpoint}/portal/invest-model`;

const InvestmentTypeApi = {
  getList: async ({ params }) =>
    await api
      .get(`${InvestmentTypeEndpoint}/list`, params)
      .then((res) => res.data?.data),
  getDetail: async ({ id }) =>
    await api
      .get(`${InvestmentTypeEndpoint}/detail/${id}`)
      .then((res) => res.data?.data),
  getSummary: async ({ params }) =>
    await api
      .get(`${InvestmentTypeEndpoint}/summary-list`, params)
      .then((res) => res.data?.data),
  create: async ({ params }) =>
    await api
      .post(`${InvestmentTypeEndpoint}/create`, params)
      .then((res) => res.data),
  update: async ({ id, params }) =>
    await api
      .put(`${InvestmentTypeEndpoint}/update/${id}`, params)
      .then((res) => res.data),
  changeStatus: async ({ params }) =>
    await api
      .post(`${InvestmentTypeEndpoint}/update-status/${params?.id}`, params)
      .then((res) => res.data),
  delete: async ({ id }) =>
    await api
      .delete(`${InvestmentTypeEndpoint}/delete/${id}`)
      .then((res) => res.data),
};

export default InvestmentTypeApi;
