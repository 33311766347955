import { DATE_BACKEND_FORMAT } from '_common/constants/common';
import { messageWarning } from '_common/constants/message';
import RangeDatePicker from '_common/dof/Control/RangeDatePicker';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectInvestmentCategory from '_common/dof/Select/SelectInvestmentCategory';
import SelectInvestor from '_common/dof/Select/SelectInvestor';
import useConfirm from '_common/hooks/useConfirm';
import moment from 'moment';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { formatDate, Modal, Row } from 'tera-dls';
import { ORDER_TYPE } from '../constants';

interface IProps {
  open: boolean;
  onClose?: () => void;
  onSubmit: (value: any) => void;
  defaultValues?: any;
}

const SettingModal = (props: IProps) => {
  const { open, defaultValues, onClose, onSubmit } = props;
  const form = useForm({ mode: 'onChange' });
  const confirm = useConfirm();
  const isDirty = form?.formState?.isDirty;

  const investor_id = form?.watch('investor_id');

  const handleSubmitForm = (value: any): void => {
    const data = {
      ...value,
      invest_type:
        value?.invest_type?.length > 0
          ? value?.invest_type?.join(',')
          : undefined,
      date_from:
        value?.date_from && formatDate(value?.date_from, DATE_BACKEND_FORMAT),
      date_to:
        value?.date_to && formatDate(value?.date_to, DATE_BACKEND_FORMAT),
      investor_id: value?.investor_id === 'system' ? 0 : value?.investor_id,
    };

    onSubmit(data);
    onClose();
  };

  const handleCloseConfirm = () => {
    if (isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>{messageWarning.WARNING_EXIT_1}</p>
            <p>{messageWarning.WARNING_EXIT_2}</p>
          </>
        ),
        onOk: () => {
          onClose();
        },
      });
    } else onClose();
  };

  useEffect(() => {
    if (defaultValues) {
      form.reset({
        ...defaultValues,
        invest_type: defaultValues?.invest_type
          ? defaultValues?.invest_type?.split(',')
          : [],
        date_from:
          defaultValues?.date_from &&
          moment(defaultValues?.date_from, DATE_BACKEND_FORMAT),
        date_to:
          defaultValues?.date_to &&
          moment(defaultValues?.date_to, DATE_BACKEND_FORMAT),
        investor_id:
          defaultValues?.investor_id === 0
            ? 'system'
            : defaultValues?.investor_id,
      });
    }
  }, [defaultValues]);

  const optionsOrderType = Object.entries(ORDER_TYPE).map(([key, value]) => ({
    label: value,
    value: key,
  }));

  return (
    <Modal
      title="Tham số báo cáo"
      destroyOnClose
      closeIcon={false}
      cancelText="Hủy"
      okText="Đồng ý"
      onOk={form.handleSubmit(handleSubmitForm)}
      onCancel={handleCloseConfirm}
      open={open}
      centered={true}
      className="xl:w-[1200px]"
    >
      <FormTera form={form} onSubmit={handleSubmitForm}>
        <Row className="grid grid-cols-3 gap-x-2.5 gap-y-0">
          <FormTeraItem label="Danh mục đầu tư" name="invest_type">
            <SelectInvestmentCategory placeholder="Tất cả" mode="multiple" />
          </FormTeraItem>
          <RangeDatePicker
            wrapperClassName="col-span-2"
            startFormName="date_from"
            endFormName="date_to"
            // startTimeRuleProps={{ isEqual: false }}
            // endTimeRuleProps={{ isEqual: false }}
            startFormItemProps={{ label: 'Từ ngày' }}
            endFormItemProps={{ label: 'Đến ngày' }}
            startInputProps={{ allowClear: false }}
            endInputProps={{ allowClear: false }}
          />
          <FormTeraItem label="Loại đơn hàng" name="order_type">
            <Select
              options={optionsOrderType}
              placeholder="Tất cả"
              allowClear
            />
          </FormTeraItem>
          <FormTeraItem label="Nhà đầu tư" name="investor_id">
            <SelectInvestor
              paramsApi={{ include_id: investor_id }}
              placeholder="Tất cả"
              optionCustom={[
                {
                  value: 'system',
                  label: 'Hệ thống',
                },
              ]}
            />
          </FormTeraItem>

          {/* <FormTeraItem label="Tên thiết bị" name="device_id">
            <SelectDevice
              paramsApi={{ include_id: device_id, investor_id }}
              placeholder="Tất cả"
              disabled={!investor_id}
            />
          </FormTeraItem> */}
        </Row>
      </FormTera>
    </Modal>
  );
};

export default SettingModal;
