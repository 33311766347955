import { PAYMENT_METHOD_PERMISSION_KEY } from '_common/constants/permission';

export const PAYMENT_METHOD_URL = {
  list: {
    key: PAYMENT_METHOD_PERMISSION_KEY.VIEW_LIST,
    path: '/finance/payment-method/list',
    shortenUrl: 'payment-method/list',
  },
  create: {
    key: PAYMENT_METHOD_PERMISSION_KEY.CREATE,
    path: '/finance/payment-method/create',
    shortenUrl: 'payment-method/create',
  },
  update: {
    key: PAYMENT_METHOD_PERMISSION_KEY.UPDATE,
    path: '/finance/payment-method/update',
    shortenUrl: 'payment-method/update/:id',
  },
};
