import NoData from '_common/component/NoData';
import { DATE_TIME_FORMAT, SUMI_SHADOW } from '_common/constants/common';
import { INVESTMENT_CATEGORY } from '_common/dof/Select/SelectInvestmentCategory';
import classNames from 'classnames';
import {
  PAYMENT_METHOD_TYPE_TEXT,
  PAYMENT_TYPE,
} from 'pages/Finance/constants';
import { INVESTMENT_TYPE } from 'pages/Invest/InvestmentType/constants';
import { formatCurrency, formatDate, Row, Spin, Tag } from 'tera-dls';
import { STATUS_INVOICE, STATUS_INVOICE_COLOR } from '../../constants';

function Information({ dataDetails, isLoading, isError }) {
  const detail = [
    {
      label: 'Mã hóa đơn',
      value: dataDetails?.invoice_code,
    },
    {
      label: 'Tổng giá trị',
      value: formatCurrency(dataDetails?.amount),
    },
    {
      label: 'Loại giao dịch',
      value: PAYMENT_TYPE[dataDetails?.transaction_type],
    },
    {
      label: 'Ngày thanh toán',
      value:
        dataDetails?.updated_at &&
        formatDate(dataDetails?.updated_at, DATE_TIME_FORMAT),
    },
    {
      label: 'Trạng thái',
      value: dataDetails?.status && (
        <Tag color={STATUS_INVOICE_COLOR[dataDetails?.status] as any}>
          {STATUS_INVOICE[dataDetails?.status]}
        </Tag>
      ),
    },
    // {
    //   label: 'Ghi chú',
    //   value: '',
    // },
  ];

  const investor = [
    {
      label: 'Họ và tên',
      value: dataDetails?.investor_name,
    },
    // {
    //   label: 'Email',
    //   value: dataDetails?.investor?.email,
    // },
    {
      label: 'Số điện thoại',
      value: dataDetails?.investor?.phone,
    },
    // {
    //   label: 'Địa chỉ',
    //   value: dataDetails?.investor?.address,
    // },
  ];
  const invest = [
    {
      label: 'Đầu tư',
      value: dataDetails?.invest?.name,
    },
    {
      label: 'Danh mục đầu tư',
      value: INVESTMENT_CATEGORY[dataDetails?.invest?.type],
    },
    {
      label: 'Địa điểm',
      value: dataDetails?.invest?.address,
    },
    ...(dataDetails?.invest_model
      ? [
          {
            label: 'Loại đầu tư',
            value: INVESTMENT_TYPE[dataDetails?.invest_model?.type],
          },
          {
            label: 'Loại hình đầu tư',
            value: dataDetails?.invest_model?.name,
          },
        ]
      : []),
  ];

  return (
    <Spin spinning={isLoading}>
      {isError ? (
        <NoData />
      ) : (
        <div className="grid grid-cols-12 gap-x-6">
          <div
            className={classNames(
              'col-span-8 p-8 rounded-md bg-[#FAFAF9] flex flex-col gap-y-6',
              SUMI_SHADOW,
            )}
          >
            <h2 className="text-[#11181C] font-bold	text-[22px]">Hóa đơn</h2>
            <div className="grid grid-cols-2 gap-2.5">
              {detail.map((item, index) => (
                <div className="flex items-start" key={index}>
                  <div className="detail-key">{item.label}</div>
                  <div className="detail-value">{item.value}</div>
                </div>
              ))}
            </div>
            <Row className="grid-cols-2">
              <div>
                <p className="text-blue-500 mb-2.5">Thông tin nhà đầu tư</p>
                <div className="flex flex-col gap-2.5">
                  {investor.map((item, index) => (
                    <div className="flex items-start" key={index}>
                      <div className="detail-key">{item.label}</div>
                      <div className="detail-value">{item.value}</div>
                    </div>
                  ))}
                </div>
              </div>
              {dataDetails?.invest && (
                <div>
                  <p className="text-blue-500 mb-2.5">Thông tin đầu tư</p>
                  <div className="flex flex-col gap-2.5">
                    {invest.map((item, index) => (
                      <div className="flex items-start" key={index}>
                        <div className="detail-key">{item.label}</div>
                        <div className="detail-value">{item.value}</div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </Row>
          </div>
          <div className="col-span-4 flex flex-col gap-y-6 text-base">
            <div
              className={classNames(
                'p-4 rounded-md flex flex-col gap-y-8 bg-[#FAFAF9] ',
                SUMI_SHADOW,
              )}
            >
              <h3 className="text-[#252F4A] font-semibold">Thanh toán</h3>
              <div className="flex justify-between">
                <div className="shrink-0">Tổng thanh toán</div>
                <div className="text-[20px] font-medium text-green-600">
                  {formatCurrency(dataDetails?.amount)}
                </div>
              </div>
            </div>
            <div
              className={classNames(
                'p-4 rounded-md flex flex-col gap-y-8 bg-[#FAFAF9]',
                SUMI_SHADOW,
              )}
            >
              <h3 className="text-[#252F4A] font-semibold">
                Thông tin thanh toán
              </h3>
              <div className="flex justify-between">
                <div className="shrink-0">Phương tức thanh toán</div>
                <div className="font-medium text-gray-800">
                  {PAYMENT_METHOD_TYPE_TEXT[dataDetails?.methods]}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Spin>
  );
}

export default Information;
