import Filter from '_common/component/Filter';
import RangeNumber from '_common/dof/Control/RangeNumber';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectInvestmentCategory from '_common/dof/Select/SelectInvestmentCategory';
import SelectInvestor from '_common/dof/Select/SelectInvestor';
import _ from 'lodash';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormItem } from 'tera-dls';

interface SaleExpensesFilterProps {
  open: boolean;
  onClose: () => void;
  onFilter: (value) => void;
  initialValue: any;
}

function SaleExpensesFilter({
  open,
  onClose,
  onFilter,
  initialValue,
}: SaleExpensesFilterProps) {
  const form = useForm({ mode: 'onChange' });

  const handleSubmitForm = (values) => {
    onFilter(values);
    onClose();
  };

  const handleReset = () => {
    const values = {
      invest_type: null,
      investor_id: null,
      total_price_from: null,
      total_price_to: null,
    };
    onFilter(values);
    onClose();
  };

  useEffect(() => {
    const values = _.pick(initialValue, [
      'invest_type',
      'investor_id',
      'total_price_from',
      'total_price_to',
    ]);

    form.reset(values);
  }, [initialValue]);

  return (
    <Filter
      open={open}
      onCancel={onClose}
      onClose={onClose}
      onFilter={() => form.handleSubmit(handleSubmitForm)()}
    >
      <FormTera form={form} onSubmit={form.handleSubmit(handleSubmitForm)}>
        <FormTeraItem label="Danh mục đầu tư" name="invest_type">
          <SelectInvestmentCategory />
        </FormTeraItem>
        <FormTeraItem label="Nhà đầu tư" name="investor_id">
          <SelectInvestor
            optionCustom={[
              {
                label: 'Hệ thống',
                value: 'system',
              },
            ]}
            paramsApi={{ include_id: form.watch('investor_id') }}
          />
        </FormTeraItem>
        <FormItem label="Tổng chi phí bán hàng">
          <RangeNumber
            startFormName="total_price_from"
            endFormName="total_price_to"
            endInputProps={{
              min: 0,
            }}
            startInputProps={{
              min: 0,
            }}
          />
        </FormItem>

        <a
          className="text-red-500 text-sm font-normal cursor-pointer"
          onClick={() => handleReset()}
        >
          Hủy bộ lọc
        </a>
      </FormTera>
    </Filter>
  );
}

export default SaleExpensesFilter;
