import { SALES_EXPENSES_PERMISSION_KEY } from '_common/constants/permission';

export const SALES_EXPENSES_URL = {
  list: {
    key: SALES_EXPENSES_PERMISSION_KEY.VIEW_LIST,
    path: '/investor/sales-expenses/list',
    shortenUrl: 'sales-expenses/list',
  },
  create: {
    key: SALES_EXPENSES_PERMISSION_KEY.CREATE,
    path: '/investor/sales-expenses/create',
    shortenUrl: 'sales-expenses/create',
  },
  update: {
    key: SALES_EXPENSES_PERMISSION_KEY.UPDATE,
    path: '/investor/sales-expenses/update',
    shortenUrl: 'sales-expenses/update/:id',
  },
  detail: {
    key: SALES_EXPENSES_PERMISSION_KEY.VIEW_LIST,
    path: '/investor/sales-expenses/detail',
    shortenUrl: 'sales-expenses/detail/:id',
  },
};
