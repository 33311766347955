import { IRouteProps } from '_common/interface/router';
import { DEVICE_URL } from './url';
import Device from '.';

export const DeviceRouter: IRouteProps[] = [
  {
    key: DEVICE_URL.list.key,
    path: DEVICE_URL.list.shortenUrl,
    component: <Device />,
  },
];
