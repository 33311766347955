import { useMutation, useQueryClient } from '@tanstack/react-query';
import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { INVESTMENT_CATEGORY } from '_common/dof/Select/SelectInvestmentCategory';
import TableTera from '_common/dof/TableTera';
import { ITeraTableProps } from '_common/dof/TableTera/_interfaces';
import useConfirm from '_common/hooks/useConfirm';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ColumnsType, DropdownItem, notification, Tag } from 'tera-dls';
import CeaseInvestmentApi from '../../api';
import {
  CEASE_INVESTMENT_STATUS,
  CEASE_INVESTMENT_STATUS_COLOR,
} from '../../constants';
import { CEASE_INVESTMENT_URL } from '../../url';
import CeaseInvestmentForm from '../Form';
import { usePermission } from '_common/hooks/usePermission';
import { CEASE_INVESTMENT_PERMISSION_KEY } from '_common/constants/permission';
import HoverQuickView from '_common/component/HoverQuickView';

function CeaseInvestmentTable(props: ITeraTableProps) {
  const [openForm, setOpenForm] = useState({ open: false, id: null });

  const { hasPage } = usePermission();
  const navigate = useNavigate();
  const confirm = useConfirm();
  const queryClient = useQueryClient();

  const { mutate: mutateDelete, isLoading: loadingDelete } = useMutation(
    (id: any) => CeaseInvestmentApi.delete({ id }),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries(['get-cease-investment-list']);
          queryClient.invalidateQueries(['get-summary-cease-investment-list']);
        }
      },
      onError: (error: any) => {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const { mutate: mutateChangeStatus, isLoading: loadingChangeStatus } =
    useMutation(
      (params: any) => {
        if (params?.type === 'approve')
          return CeaseInvestmentApi.approve({ id: params?.id });
        return CeaseInvestmentApi.reject({ id: params?.id });
      },
      {
        onSuccess: (res) => {
          if (res?.code === 200) {
            notification.success({
              message: res?.msg,
            });
            queryClient.invalidateQueries(['get-investment-list']);
            queryClient.invalidateQueries(['get-cease-investment-list']);
            queryClient.invalidateQueries([
              'get-summary-cease-investment-list',
            ]);
          }
        },
        onError: (error: any) => {
          ErrorToast({ errorProp: error?.data });
        },
      },
    );

  const handleApprove = (record) => {
    confirm.warning({
      title: 'Xác nhận duyệt ngưng đầu tư',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn duyệt ngưng đầu tư</p>
          <p>
            <b>{record?.code}</b> này không?
          </p>
        </div>
      ),
      onOk: () => {
        mutateChangeStatus({ id: record?.id, type: 'approve' });
      },
    });
  };

  const handleReject = (record) => {
    confirm.warning({
      title: 'Xác nhận từ chối ngưng đầu tư',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn từ chối ngưng đầu tư</p>
          <p>
            <b>{record?.code}</b> này không?
          </p>
        </div>
      ),
      onOk: () => {
        mutateChangeStatus({ id: record?.id, type: 'reject' });
      },
    });
  };

  const handleDelete = (record) => {
    confirm.warning({
      title: 'Xác nhận xóa ngưng đầu tư',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn xóa ngưng đầu tư</p>
          <p>
            <b>{record?.invest_id?.name}</b> này không?
          </p>
        </div>
      ),
      onOk: () => {
        mutateDelete(record?.id);
      },
    });
  };

  const renderItemActions = (record) => {
    const items: DropdownItem[] = [];
    if (hasPage(CEASE_INVESTMENT_PERMISSION_KEY.VIEW_DETAIL))
      items.push({
        key: '1',
        label: 'Xem',
        onClick: () =>
          record?.id &&
          navigate(`${CEASE_INVESTMENT_URL.detail.path}/${record?.id}`),
      });
    if (
      hasPage(CEASE_INVESTMENT_PERMISSION_KEY.CHANGE_STATUS) &&
      [0].includes(record?.status)
    )
      items.push({
        key: '4',
        label: 'Duyệt',
        onClick: () => handleApprove(record),
      });
    if (
      hasPage(CEASE_INVESTMENT_PERMISSION_KEY.CHANGE_STATUS) &&
      [0].includes(record?.status)
    )
      items.push({
        key: '4',
        label: 'Từ chối',
        onClick: () => handleReject(record),
      });
    if (
      hasPage(CEASE_INVESTMENT_PERMISSION_KEY.UPDATE) &&
      [0, 2].includes(record?.status)
    )
      items.push({
        key: '2',
        label: 'Sửa',
        onClick: () => setOpenForm({ open: true, id: record?.id }),
      });
    if (
      hasPage(CEASE_INVESTMENT_PERMISSION_KEY.DELETE) &&
      [0, 2].includes(record?.status)
    )
      items.push({
        key: '3',
        label: <span className="text-red-500">Xóa</span>,
        onClick: () => handleDelete(record),
      });

    return items;
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Mã ngưng đầu tư',
      dataIndex: 'code',
      width: 100,
      render: (name, record) => (
        <p
          className="line-clamp-2 break-word text-blue-600 cursor-pointer"
          onClick={() =>
            navigate(`${CEASE_INVESTMENT_URL.detail.path}/${record?.id}`)
          }
        >
          {name}
        </p>
      ),
    },
    {
      title: 'Nhà đầu tư',
      dataIndex: 'investor_id',
      width: 200,
      render: (investor_id) => (
        <HoverQuickView
          avatarUrl={investor_id?.file_upload}
          name={investor_id ? investor_id.investor_name : 'Hệ thống'}
          code={investor_id.code}
          email={investor_id.email}
          phone={investor_id.phone}
        >
          <p className="line-clamp-2">{investor_id?.investor_name}</p>
        </HoverQuickView>
        // <p className="line-clamp-2">{investor_id?.investor_name}</p>
      ),
    },
    {
      title: 'Danh mục đầu tư',
      dataIndex: 'type',
      width: 100,
      render: (_, record) =>
        record?.invest_id?.type && INVESTMENT_CATEGORY[record?.invest_id?.type],
    },
    {
      title: 'Đầu tư',
      dataIndex: 'invest_id',
      width: 150,
      render: (invest_id) => (
        <HoverQuickView
          name={invest_id?.name}
          avatarUrl={invest_id?.file_upload}
          code={invest_id?.code}
          sub={invest_id?.description}
        >
          {invest_id?.name}
        </HoverQuickView>
      ),
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      width: 100,
      render: (status) => (
        <Tag color={CEASE_INVESTMENT_STATUS_COLOR[status]}>
          {CEASE_INVESTMENT_STATUS[status]}
        </Tag>
      ),
    },
    {
      width: 60,
      fixed: 'right',
      render: (record) => {
        const items = renderItemActions(record);
        return <ActionDropdown dropdownItems={items} trigger="click" />;
      },
    },
  ];

  return (
    <>
      <TableTera
        zebra={false}
        columns={columns}
        loading={props?.loading || loadingDelete || loadingChangeStatus}
        {...props}
      />
      {openForm.open && (
        <CeaseInvestmentForm
          open={openForm.open}
          id={openForm.id}
          onClose={() => setOpenForm({ open: false, id: null })}
        />
      )}
    </>
  );
}

export default CeaseInvestmentTable;
