import React, { createContext } from 'react';
export const NormalTableContext = createContext(null);

const CustomRow: React.FC<any> = (props) => {
  const { checkboxRender, record, ...restProps } = props;

  return (
    <NormalTableContext.Provider value={{ checkboxRender, record }}>
      <tr {...restProps} />
    </NormalTableContext.Provider>
  );
};

export default React.memo(CustomRow);
