import { useQuery } from '@tanstack/react-query';
import CardFormV2 from '_common/component/CardForm/CardFormV2';
import NoData from '_common/component/NoData';
import { useEffect } from 'react';
import { formatDate, Modal, Spin } from 'tera-dls';
import DeviceApi from '../../api';
import { DATE_TIME_FORMAT } from '_common/constants/common';
import HoverQuickView from '_common/component/HoverQuickView';

interface DeviceDetailProps {
  id: number;
  open: boolean;
  onCancel: () => void;
}

function DeviceDetail({ id, open, onCancel }: DeviceDetailProps) {
  const {
    data: dataDetails,
    refetch,
    isLoading,
    isError,
  } = useQuery(['get-device-detail', id], () => DeviceApi.getDetail({ id }), {
    enabled: !!id,
    staleTime: 300000,
    cacheTime: 300000,
  });

  const detail = [
    {
      label: 'Tên thiết bị',
      value: dataDetails?.full_name,
    },
    {
      label: 'Đầu tư',
      value: (
        <HoverQuickView
          name={dataDetails?.invest?.name}
          avatarUrl={dataDetails?.invest?.file_upload}
          code={dataDetails?.invest?.code}
          sub={dataDetails?.invest?.description}
        >
          {dataDetails?.invest?.name}
        </HoverQuickView>
      ),
    },
    {
      label: 'Thời gian làm việc',
      value:
        (dataDetails?.time_from || dataDetails?.time_to) &&
        `${dataDetails?.time_from || '__'} - ${dataDetails?.time_to || '__'}`,
    },
    {
      label: 'Ghi chú',
      value: dataDetails?.description,
    },
  ];

  const general = [
    {
      label: 'Tên đăng nhập',
      value: dataDetails?.username,
    },
    {
      label: 'Người tạo',
      value: (
        <HoverQuickView
          avatarUrl={dataDetails?.created_by?.avatar}
          name={dataDetails?.created_by?.full_name}
          code={dataDetails?.created_by?.code}
          email={dataDetails?.created_by?.email}
          phone={dataDetails?.created_by?.phone}
        >
          {dataDetails?.created_by?.full_name}
        </HoverQuickView>
      ),
    },
    {
      label: 'Ngày tạo',
      value:
        dataDetails?.created_at &&
        formatDate(dataDetails?.created_at, DATE_TIME_FORMAT),
    },
    {
      label: 'Người cập nhật',
      value: (
        <HoverQuickView
          avatarUrl={dataDetails?.updated_by?.avatar}
          name={dataDetails?.updated_by?.full_name}
          code={dataDetails?.updated_by?.code}
          email={dataDetails?.updated_by?.email}
          phone={dataDetails?.updated_by?.phone}
        >
          {dataDetails?.updated_by?.full_name}
        </HoverQuickView>
      ),
    },
    {
      label: 'Ngày cập nhật ',
      value:
        dataDetails?.updated_by &&
        formatDate(dataDetails?.updated_at, DATE_TIME_FORMAT),
    },
  ];

  useEffect(() => {
    refetch();
  }, []);

  return (
    <Modal
      title="Chi tiết thiết bị"
      destroyOnClose
      closeIcon={false}
      cancelText="Đóng"
      onCancel={onCancel}
      open={open}
      centered={true}
      className="sm:w-[500px]"
      okButtonProps={{ className: 'hidden' }}
    >
      <Spin spinning={isLoading && !!id}>
        {isError && !!id ? (
          <NoData />
        ) : (
          <>
            <CardFormV2 title="Thông tin làm việc">
              <div className="flex flex-col gap-y-2.5">
                {detail.map((item, index) => (
                  <div className="flex items-start" key={index}>
                    <div className="detail-key">{item.label}</div>
                    <div className="detail-value">{item.value}</div>
                  </div>
                ))}
              </div>
            </CardFormV2>
            <CardFormV2 title="Thông tin chung">
              <div className="flex flex-col gap-y-2.5">
                {general.map((item, index) => (
                  <div className="flex items-start" key={index}>
                    <div className="detail-key">{item.label}</div>
                    <div className="detail-value">{item.value}</div>
                  </div>
                ))}
              </div>
            </CardFormV2>
          </>
        )}
      </Spin>
    </Modal>
  );
}

export default DeviceDetail;
