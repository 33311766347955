import NoPermission from '_common/component/NoPermission';
import { usePermission } from '_common/hooks/usePermission';
import { observer } from 'mobx-react-lite';
import { createContext, useMemo } from 'react';
//import { useQuery } from '@tanstack/react-query';
//import ManagePageApi from 'pages/System/containers/ManagePage/ListPage/_api';
import classNames from 'classnames';
import { IMenu } from '../Menu/interface';
import menu from './../Menu/menu.json';

interface PageLayoutProp {
  page_key: string;
  children?: any;
  actions?: any;
  action_type?: string;
}

export const PageContext = createContext({});

const PageLayout = observer(
  ({ actions, page_key, children }: PageLayoutProp) => {
    const { hasPage } = usePermission();
    // const { data: pageConfig } = useQuery(
    //   ['get-page-by-object-type', page_key],
    //   () => ManagePageApi.getConfig({ object_type: page_key }),
    //   {
    //     cacheTime: 300000,
    //     staleTime: 300000,
    //     enabled: !!page_key,
    //   },
    // );
    const pageConfig = undefined;

    const config = useMemo(() => {
      const defaultConfig: IMenu = menu.subMenu.find(
        (item) => item.code === page_key,
      );
      return { ...defaultConfig, ...(pageConfig ?? {}) };
    }, [pageConfig, page_key]);

    if (!hasPage(page_key)) return <NoPermission />;

    if (!hasPage(page_key)) return <NoPermission />;

    return (
      <PageContext.Provider value={config}>
        <div
          className={classNames('flex h-full', {
            'pr-[40px]': actions?.length > 0,
          })}
        >
          <div data-object_type={page_key} className="h-full w-full">
            {children}
          </div>
        </div>
      </PageContext.Provider>
    );
  },
);

export default PageLayout;
