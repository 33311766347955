import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const OrderInspectionEndpoint = `${endpoint}/sumi/sell/order-checking`;

const OrderInspectionApi = {
  getList: async ({ params }) =>
    await api
      .get(`${OrderInspectionEndpoint}/list`, params)
      .then((res) => res.data?.data),
  getDetail: async ({ id }) =>
    await api
      .get(`${OrderInspectionEndpoint}/detail/${id}`)
      .then((res) => res.data?.data),
  getSummary: async ({ params }) =>
    await api
      .get(`${OrderInspectionEndpoint}/summary-list`, params)
      .then((res) => res.data?.data),
  check: async ({ params }) =>
    await api
      .put(`${OrderInspectionEndpoint}/check`, params)
      .then((res) => res.data),
  approve: async ({ id }) =>
    await api
      .post(`${OrderInspectionEndpoint}/approve/${id}`)
      .then((res) => res.data),
};

export default OrderInspectionApi;
