import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const SaleOrderEndpoint = `${endpoint}/sumi/sell/sell-order`;

const SaleOrderApi = {
  getList: async ({ params }) =>
    await api
      .get(`${SaleOrderEndpoint}/list`, params)
      .then((res) => res.data?.data),
  getDetail: async ({ id }) =>
    await api
      .get(`${SaleOrderEndpoint}/detail/${id}`)
      .then((res) => res.data?.data),
  create: async ({ params }) =>
    await api
      .post(`${SaleOrderEndpoint}/create`, params)
      .then((res) => res.data),
  update: async ({ id, params }) =>
    await api
      .put(`${SaleOrderEndpoint}/update/${id}`, params)
      .then((res) => res.data),
  delete: async ({ id }) =>
    await api
      .delete(`${SaleOrderEndpoint}/delete/${id}`)
      .then((res) => res.data),
};

export default SaleOrderApi;
