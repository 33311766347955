import { useQuery } from '@tanstack/react-query';
import TableTera from '_common/dof/TableTera';
import { IPagination } from '_common/interface';
import InvoiceApi from 'pages/Finance/Invoice/api';
import { useState } from 'react';
import { formatDate, PaginationProps } from 'tera-dls';

interface InvestmentHistoryProps {
  id: number;
}

function InvestmentHistory({ id }: InvestmentHistoryProps) {
  const [pagination, setPagination] = useState<IPagination>({
    page: 1,
    limit: 10,
  });
  const { data: investmentHistoryList, isLoading } = useQuery(
    ['get-investment-history-list', id],
    () => {
      const params = {
        invest_id: id,
        transaction_type: 'pay',
        status: 'approved',
        page: pagination?.page || 1,
        limit: pagination?.limit || 10,
      };
      return InvoiceApi.getList({ params });
    },
    {
      enabled: !!id,
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  const columns = [
    {
      title: 'Nhà đầu tư',
      dataIndex: 'investor_name',
    },
    {
      title: 'Ngày mua',
      dataIndex: 'updated_at',
      render: (date) => formatDate(date),
    },
  ];

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    const isDiffPageSize = Number(pageSize) !== Number(pagination?.limit || 10);
    setPagination({ page: isDiffPageSize ? 1 : page, limit: pageSize });
  };

  return (
    <TableTera
      columns={columns}
      data={investmentHistoryList?.data || []}
      loading={isLoading}
      pagination={{
        onChange: handleChangePage,
        total: investmentHistoryList?.total || 0,
        current: investmentHistoryList?.current_page,
        pageSize: investmentHistoryList?.per_page || 10,
        to: investmentHistoryList?.to,
        from: investmentHistoryList?.from || 10,
      }}
    />
  );
}

export default InvestmentHistory;
