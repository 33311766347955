import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import FormTera from '_common/dof/FormTera';
// import { OptionsSelectBank } from '_common/dof/Select/SelectBank';
import { pick } from 'lodash';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as IconBank } from 'styles/images/Icons/bank.svg';
import { ReactComponent as IconEWallet } from 'styles/images/Icons/e-wallet.svg';
import ImgEmpty from 'styles/images/Icons/empty-img.png';
import customTwMerge from 'tailwind-merge.config';
import {
  ArrowSmallLeftSolid,
  BookmarkOutlined,
  Breadcrumb,
  Button,
  Col,
  notification,
  Row,
} from 'tera-dls';
import PaymentMethodApi from '../../api';
import { PAYMENT_TYPE_ENUM, PaymentType } from '../../interfaces';
import { PAYMENT_METHOD_URL } from '../../url';
import BankForm from './components/BankForm';
import EWalletForm from './components/EWalletForm';

const activePaymentTypeClasses = 'border-blue-600 bg-blue-100';

const listPaymentType = [
  {
    type: PAYMENT_TYPE_ENUM.BANK,
    icon: (
      <i className="stroke-blue-600 w-8 h-8">
        <IconBank />
      </i>
    ),
  },
  {
    type: PAYMENT_TYPE_ENUM.E_WALLET,
    icon: (
      <i className="fill-blue-600 w-8 h-8">
        <IconEWallet />
      </i>
    ),
  },
];

const PaymentMethodForm = () => {
  const queryClient = useQueryClient();
  const { id: paramId } = useParams();
  const id = Number(paramId);
  const [imageBank, setImageBank] = useState(null);

  const [typeForm, setTypeForm] = useState<PaymentType>(PAYMENT_TYPE_ENUM.BANK);

  const form = useForm({ mode: 'onChange' });
  const { handleSubmit, reset, setValue } = form;
  const navigate = useNavigate();

  const { data: dataDetail, refetch } = useQuery(
    ['get-payment-method-detail', id],
    () => PaymentMethodApi.getDetail({ id }),
    {
      enabled: !!id,
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const { mutate: submitForm, isLoading: loadingSubmit } = useMutation(
    (variable) => {
      if (id) return PaymentMethodApi.update(id, variable);
      return PaymentMethodApi.create(variable);
    },
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          queryClient.invalidateQueries(['get-payment-method-list']);
          queryClient.invalidateQueries(['get-payment-method-detail']);
          navigate(PAYMENT_METHOD_URL.list.path);
          notification.success({
            message: res?.msg,
          });
        }
      },
      onError: (error: any) => ErrorToast({ errorProp: error?.data }),
    },
  );
  const renderForm = {
    bank: <BankForm onChangeBank={(value) => setImageBank(value)} />,
    'e-wallet': <EWalletForm />,
  };

  useEffect(() => {
    if (!dataDetail) return;
    setTypeForm(
      dataDetail?.card_type?.object_type === 1
        ? PAYMENT_TYPE_ENUM.BANK
        : PAYMENT_TYPE_ENUM.E_WALLET,
    );
    const dataForm = pick(dataDetail, [
      'object_type',
      'card_type_id',
      'account_number',
      'account_name',
      'description',
      'account_phone',
    ]);
    setImageBank({ image: dataDetail?.card_type?.image_bank });
    Object.entries(dataForm).forEach(([name, value]: any) =>
      setValue(name, value),
    );
  }, [dataDetail]);

  const fieldLogo = (
    <div>
      <h4>Logo</h4>
      <div className="h-[156px] flex items-center justify-center">
        <img
          src={imageBank?.image || ImgEmpty}
          width={imageBank?.image ? 150 : undefined}
        />
      </div>
    </div>
  );

  useEffect(() => {
    if (id) refetch();
  }, [id]);

  return (
    <div className="tera-page-form !gap-0 relative">
      <div className="sticky top-[45px] z-10 bg-[#F3F3F9]">
        <div className="page-header-v2">
          <div className="page-header-v2__breadcrumb">
            <div
              className="page-header__breadcrumb-back cursor-pointer"
              onClick={() => navigate(-1)}
            >
              <ArrowSmallLeftSolid className="h-6 w-6" />
            </div>
            <Breadcrumb
              separator={'>'}
              items={[
                {
                  title: (
                    <span className="text-blue-400 hover:text-blue-600">
                      Danh sách phương thức thanh toán
                    </span>
                  ),
                  onClick: () => navigate(PAYMENT_METHOD_URL.list.path),
                },
                {
                  title: id
                    ? 'Sửa phương thức thanh toán'
                    : 'Thêm phương thức thanh toán',
                },
              ]}
            />
          </div>
          <div className="page-header-v2__function">
            <Button
              htmlType="submit"
              type="success"
              className="page-header-btn"
              loading={loadingSubmit}
              prefix={<BookmarkOutlined className="w-4" />}
              onClick={handleSubmit((values: any) => {
                const data = {
                  ...values,
                  author: 'sumi',
                  object_type: typeForm === PAYMENT_TYPE_ENUM.BANK ? 1 : 2,
                };

                submitForm(data);
              })}
            >
              Lưu
            </Button>
          </div>
        </div>
      </div>
      <div className="flex gap-4 mx-2.5">
        {!id && (
          <div className="max-w-[376px] w-full p-4 bg-white">
            <h3 className="text-blue-500 font-medium mb-4">
              Phương thức thanh toán
            </h3>
            <Row className="grid-cols-2 lg:grid-cols-3 gap-4">
              {listPaymentType.map((paymentType) => (
                <button
                  className={customTwMerge(
                    'border-2 border-gray-200 bg-white rounded-2xl flex items-center justify-center py-3.5 px-[18px]',
                    typeForm === paymentType.type && activePaymentTypeClasses,
                  )}
                  onClick={() => {
                    reset();
                    setImageBank(null);
                    setTypeForm(paymentType.type);
                  }}
                >
                  {paymentType.icon}
                </button>
              ))}
            </Row>
          </div>
        )}
        <div className="w-full p-4 bg-white">
          <h3 className="text-blue-500 font-medium mb-4">Thông tin chi tiết</h3>
          <FormTera form={form} className="grid grid-cols-12 gap-4">
            <Col
              className={
                typeForm === PAYMENT_TYPE_ENUM.BANK
                  ? 'col-span-8'
                  : 'col-span-12'
              }
            >
              {renderForm[typeForm]}
            </Col>
            {typeForm === PAYMENT_TYPE_ENUM.BANK && (
              <Col className="space-y-4 col-span-4">{fieldLogo}</Col>
            )}
          </FormTera>
        </div>
      </div>
    </div>
  );
};

export default PaymentMethodForm;
