export const INCURRED_COST_STATUS = {
  pending: 'Chờ duyệt',
  reject: 'Từ chối',
  approved: 'Duyệt',
};
export const INCURRED_COST_STATUS_COLOR = {
  pending: 'yellow03',
  reject: 'red03',
  approved: 'green03',
};
