import { IRouteProps } from '_common/interface/router';
import InvestmentType from '.';
import { INVESTMENT_TYPE_URL } from './url';

export const InvestmentTypeRouter: IRouteProps[] = [
  {
    key: INVESTMENT_TYPE_URL.list.key,
    path: INVESTMENT_TYPE_URL.list.shortenUrl,
    component: <InvestmentType />,
  },
];
