import Header from 'pages/Dashboard/Header';
import image01 from 'styles/images/pages/dashboard/dashboard-01.png';
import image03 from 'styles/images/pages/dashboard/dashboard-02.png';
import image02 from 'styles/images/pages/finance/price_order.png';
import { formatNumber } from 'tera-dls';
import Card from '../Card';
import { useQuery } from '@tanstack/react-query';
import DashboardApi from 'pages/Dashboard/api';
import { forwardRef, useEffect, useImperativeHandle, useMemo } from 'react';

export interface LocationRefs {
  refetchData?: () => void;
}

interface LocationProps {
  params?: any;
}

function Location({ params }, ref) {
  const { data, isLoading, isRefetching, refetch } = useQuery(
    ['get-point-of-sale-dashboard', params],
    () => DashboardApi.getPointOfSale({ params }),
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  const dataOrder = useMemo(() => {
    return [
      {
        title: 'Số lượng bán',
        content: (
          <p>
            <span className="text-blue-600">Bán hàng</span>{' '}
            <span className="text-xl">
              {formatNumber(data?.sale_quantity?.sale_count)}
            </span>{' '}
            <span className="text-green-600">Đặt hàng</span>{' '}
            <span className="text-xl">
              {formatNumber(data?.sale_quantity?.order_count)}
            </span>
          </p>
        ),
        image: image01,
      },
      {
        title: 'Giá trị đơn hàng',
        content: (
          <p>
            <span className="text-green-500 text-2xl font-semibold">
              {formatNumber(data?.order_value)}
            </span>{' '}
            <span className="text-xs">vnd</span>
          </p>
        ),
        image: image02,
      },
      {
        title: 'Doanh thu đơn hàng',
        content: (
          <p>
            <span className="text-green-500 text-2xl font-semibold">
              {formatNumber(data?.order_revenue)}
            </span>{' '}
            <span className="text-xs">vnd</span>
          </p>
        ),
        image: image03,
        imageClassName: '!w-[50px]',
      },
    ];
  }, [data]);

  useEffect(() => {
    refetch();
  }, []);

  useImperativeHandle(
    ref,
    () => {
      return {
        refetchData() {
          refetch();
        },
      };
    },
    [],
  );

  return (
    <div className="w-full p-[15px] rounded border">
      <Header
        title="Điểm bán hàng"
        isZoom={false}
        isRefetch={false}
        isSetting={false}
      />
      <div className="mt-[15px] grid grid-cols-3 gap-[15px]">
        {dataOrder.map((data, index) => (
          <Card
            key={index}
            title={data.title}
            content={data.content}
            image={data.image}
            imageClassName={data.imageClassName}
            loading={isLoading || isRefetching}
          />
        ))}
      </div>
    </div>
  );
}

export default forwardRef<LocationRefs, LocationProps>(Location);
