import { RECEIPT_PERMISSION_KEY } from '_common/constants/permission';

export const CASH_RECEIPT_URL = {
  list: {
    key: RECEIPT_PERMISSION_KEY.VIEW_LIST,
    path: '/finance/cash-receipt/list',
    shortenUrl: 'cash-receipt/list',
  },
  create: {
    key: RECEIPT_PERMISSION_KEY.CREATE,
    path: '/finance/cash-receipt/create',
    shortenUrl: 'cash-receipt/create',
  },
  update: {
    key: RECEIPT_PERMISSION_KEY.UPDATE,
    path: '/finance/cash-receipt/update',
    shortenUrl: 'cash-receipt/update/:id',
  },
};
