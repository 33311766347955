import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const IncurredCostCategoryEndpoint = `${endpoint}/portal/incurred-cost-category`;

const IncurredCostCategoryApi = {
  getList: async ({ params }) =>
    await api
      .get(`${IncurredCostCategoryEndpoint}/list`, params)
      .then((res) => res?.data?.data),
  getDetail: async ({ id }) =>
    await api
      .get(`${IncurredCostCategoryEndpoint}/detail/${id}`)
      .then((res) => res?.data?.data),
  getSummary: async ({ params }) =>
    await api
      .get(`${IncurredCostCategoryEndpoint}/summary-list`, params)
      .then((res) => res.data?.data),
  create: async ({ params }) =>
    await api
      .post(`${IncurredCostCategoryEndpoint}/create`, params)
      .then((res) => res?.data),
  update: async ({ id, params }) =>
    await api
      .put(`${IncurredCostCategoryEndpoint}/update/${id}`, params)
      .then((res) => res?.data),
  changeStatus: async ({ params }) =>
    await api
      .post(`${IncurredCostCategoryEndpoint}/active`, params)
      .then((res) => res?.data),
  delete: async ({ id }) =>
    await api
      .delete(`${IncurredCostCategoryEndpoint}/delete/${id}`)
      .then((res) => res?.data),
};

export default IncurredCostCategoryApi;
