import Filter from '_common/component/Filter';
import { MAXIMUM_CURRENCY } from '_common/constants/common';
import RangeNumber from '_common/dof/Control/RangeNumber';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectInvestmentCategory from '_common/dof/Select/SelectInvestmentCategory';
import SelectInvestor from '_common/dof/Select/SelectInvestor';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

interface InvestorFilterProps {
  open: boolean;
  onClose: () => void;
  onFilter: (value) => void;
  initialValue: any;
}

function InvestorFilter({
  open,
  onClose,
  onFilter,
  initialValue,
}: InvestorFilterProps) {
  const form = useForm({ mode: 'onChange' });

  const handleSubmitForm = (values) => {
    const data = {
      ...values,
      investor_id: values?.investor_id === 'system' ? 0 : values?.investor_id,
    };

    onFilter(data);
    onClose();
  };

  const handleReset = () => {
    const values = {
      investor_id: null,
      type: null,
      amount_from: null,
      amount_to: null,
    };
    onFilter(values);
    onClose();
  };

  useEffect(() => {
    const values = {
      investor_id:
        initialValue?.investor_id == 0 ? 'system' : initialValue?.investor_id,
      type: initialValue?.type,
      amount_from: initialValue?.amount_from,
      amount_to: initialValue?.amount_to,
    };

    form.reset(values);
  }, [initialValue]);

  return (
    <Filter
      open={open}
      onCancel={onClose}
      onClose={onClose}
      onFilter={() => form.handleSubmit(handleSubmitForm)()}
    >
      <FormTera form={form} onSubmit={form.handleSubmit(handleSubmitForm)}>
        <FormTeraItem label="Nhà đầu tư" name="investor_id">
          <SelectInvestor
            optionCustom={[{ value: 'system', label: 'Hệ thống' }]}
          />
        </FormTeraItem>
        <FormTeraItem label="Danh mục đầu tư" name="type">
          <SelectInvestmentCategory allowClear />
        </FormTeraItem>

        <p className="mb-2.5">Giá bán</p>

        <RangeNumber
          startFormName="amount_from"
          endFormName="amount_to"
          endInputProps={{
            min: 0,
            max: MAXIMUM_CURRENCY,
          }}
          startInputProps={{
            min: 0,
            max: MAXIMUM_CURRENCY,
          }}
        />

        <a
          className="text-red-500 text-sm font-normal cursor-pointer"
          onClick={() => handleReset()}
        >
          Hủy bộ lọc
        </a>
      </FormTera>
    </Filter>
  );
}

export default InvestorFilter;
