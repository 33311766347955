import { forwardRef } from 'react';
import Content from './Content';

function RevenueByInvestmentPortfolio({ params }, ref) {
  return (
    <div className="rounded-[5px] border bg-white h-full overflow-hidden p-[15px]">
      <Content chartClassName="!h-[250px]" params={params} ref={ref} />
    </div>
  );
}

export default forwardRef(RevenueByInvestmentPortfolio);
