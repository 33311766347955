import { useQuery } from '@tanstack/react-query';
import DashboardApi from 'pages/Dashboard/api';
import Header from 'pages/Dashboard/Header';
import { forwardRef, useEffect, useImperativeHandle, useMemo } from 'react';
import image04 from 'styles/images/pages/dashboard/dashboard-03.png';
import image05 from 'styles/images/pages/dashboard/dashboard-04.png';
import image06 from 'styles/images/pages/dashboard/dashboard-05.png';
import { formatNumber } from 'tera-dls';
import Card from '../Card';

export interface InvestmentRefs {
  refetchData?: () => void;
}

interface InvestmentProps {
  params?: any;
}

function Investment({ params }, ref) {
  const { data, isLoading, isRefetching, refetch } = useQuery(
    ['get-investment-situation-dashboard', params],
    () => DashboardApi.getInvestmentSituation({ params }),
    {
      cacheTime: 300000,
      staleTime: 300000,
    },
  );

  const dataInvest = useMemo(() => {
    return [
      {
        title: 'Số lượng đã đầu tư',
        content: (
          <span className="text-xl">{formatNumber(data?.investor_count)}</span>
        ),
        image: image04,
      },
      {
        title: 'Doanh thu đầu tư',
        content: (
          <p>
            <span className="text-green-500 text-2xl font-semibold">
              {formatNumber(data?.invest_revenue)}
            </span>{' '}
            <span className="text-xs">vnd</span>
          </p>
        ),
        image: image05,
      },
      {
        title: 'Lãi suất nhà đầu tư',
        content: (
          <p>
            <span className="text-green-500 text-2xl font-semibold">
              {formatNumber(data?.investor_revenue)}
            </span>{' '}
            <span className="text-xs">vnd</span>
          </p>
        ),
        image: image06,
        imageClassName: '!w-[50px]',
      },
    ];
  }, [data]);

  useEffect(() => {
    refetch();
  }, []);

  useImperativeHandle(
    ref,
    () => {
      return {
        refetchData() {
          refetch();
        },
      };
    },
    [],
  );

  return (
    <div className="w-full p-[15px] rounded border">
      <Header
        title="Tình hình đầu tư"
        isZoom={false}
        isRefetch={false}
        isSetting={false}
      />
      <div className="mt-[15px] grid grid-cols-3 gap-[15px]">
        {dataInvest.map((data, index) => (
          <Card
            key={index}
            title={data.title}
            content={data.content}
            image={data.image}
            imageClassName={data.imageClassName}
            loading={isLoading || isRefetching}
          />
        ))}
      </div>
    </div>
  );
}

export default forwardRef<InvestmentRefs, InvestmentProps>(Investment);
