import { useTeraForm } from '_common/dof/FormTera/TeraFormContext';
import { useTeraFormItem } from '_common/dof/FormTera/TeraItemContext';
import React, { useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { TimePickerProps, TimePicker as TimePickerTera } from 'tera-dls';

const TimePicker = React.memo(
  ({ className, placeholder, onChange, ...props }: TimePickerProps) => {
    const { form } = useTeraForm();
    const { item, config, rules } = useTeraFormItem();
    const { control } = form;
    const inputProps = useMemo(
      () => ({
        placeholder: config?.place_holder || placeholder || 'Vui lòng chọn',
        className: config?.class_name || className || 'w-full',
      }),
      [config],
    );
    return (
      <Controller
        control={control}
        {...item}
        rules={rules}
        render={({ field }) => (
          <TimePickerTera
            data-object_type={item?.object_type}
            data-object_id={item?.object_id}
            {...field}
            {...props}
            className={inputProps.className}
            placeholder={inputProps.placeholder}
            onChange={(date, dateString) => {
              field.onChange(date);
              onChange?.(date, dateString);
            }}
          />
        )}
      />
    );
  },
);
export default TimePicker;
