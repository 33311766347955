import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyCytmOAGGBYNXD-eYEmzKEVtyoF42Xmsvk',
  authDomain: 'sumi-5a53f.firebaseapp.com',
  projectId: 'sumi-5a53f',
  storageBucket: 'sumi-5a53f.appspot.com',
  messagingSenderId: '99534270380',
  appId: '1:99534270380:web:9ec1882b928463fbd45409',
  measurementId: 'G-HZP75J0M6N',
};

const app = initializeApp(firebaseConfig);

const messaging = getMessaging(app);

export const requestForToken = async () => {
  try {
    const currentToken = await getToken(messaging, {
      vapidKey:
        'BB72LLjqyczOEb20s3Frdw_FpqZjuWyTgMKDzy_PvVSmJoEJrXIb-of_G1YkTy9jtITgHx5PRgy226higdT7uSA',
    });
    console.log('currentToken', currentToken);
    return currentToken;
  } catch (error) {
    console.error('An error occurred while retrieving token:', error);
    return null;
  }
};

export const onMessageListener = (callback) => {
  return onMessage(messaging, (payload) => {
    if (callback) {
      callback(payload);
    }
  });
};
