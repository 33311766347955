import { ftpEndpoint } from '_common/constants/common';
import api from 'states/drivers';

const CameraEndpoint = `${ftpEndpoint}/camera`;
const CamEndpoint = `${ftpEndpoint}/cam`;

const CameraApi = {
  getList: async ({ params }) =>
    await api.get(`${CameraEndpoint}/list`, params).then((res) => res.data),
  getDetail: async ({ id }) =>
    await api
      .get(`${CameraEndpoint}/detail/${id}`)
      .then((res) => res.data?.data),
  create: async ({ params }) =>
    await api.post(`${CameraEndpoint}/create`, params).then((res) => res.data),
  update: async ({ id, params }) =>
    await api
      .put(`${CameraEndpoint}/update/${id}`, params)
      .then((res) => res.data),
  checkStatus: async ({ params }) =>
    await api
      .post(`${CameraEndpoint}/check-status`, params)
      .then((res) => res.data),
  syncHistory: async ({ id, params }) =>
    await api
      .put(`${CameraEndpoint}/sync-history/${id}`, params)
      .then((res) => res.data),
  history: async ({ params }) =>
    await api
      .get(`${CamEndpoint}/histories`, params)
      .then((res) => res.data?.data),
  delete: async ({ id }) =>
    await api.delete(`${CameraEndpoint}/delete/${id}`).then((res) => res.data),
};

export default CameraApi;
