import {
  ArrowPathOutlined,
  ArrowsPointingInOutlined,
  ArrowsPointingOuOutlined,
  Cog6ToothOutlined,
  Tooltip,
} from 'tera-dls';

interface HeaderProps {
  isSmallType?: boolean;
  title?: string;
  isZoom?: boolean;
  isRefetch?: boolean;
  isSetting?: boolean;
  onClickRefresh?: () => void;
  onZoomIn?: () => void;
  onZoomOut?: () => void;
  onClickSetting?: () => void;
}

const Header = ({
  title,
  isSmallType,
  isZoom = true,
  isRefetch = true,
  isSetting = true,
  onClickRefresh,
  onZoomIn,
  onZoomOut,
  onClickSetting,
}: HeaderProps) => {
  return (
    <div className="flex item-center justify-between">
      <div className="font-medium text-[#1F2937] uppercase">{title}</div>
      <div className="flex gap-2.5">
        {isRefetch && (
          <Tooltip title="Làm mới" className="z-[99]">
            <span className="flex items-center">
              <ArrowPathOutlined
                className="cursor-pointer w-4 h-4"
                onClick={onClickRefresh}
              />
            </span>
          </Tooltip>
        )}

        {isSetting && (
          <Tooltip title="Tham số báo cáo" className="z-[99]">
            <span className="flex items-center">
              <Cog6ToothOutlined
                className="cursor-pointer w-4 h-4"
                onClick={onClickSetting}
              />
            </span>
          </Tooltip>
        )}

        {isZoom && (
          <>
            {isSmallType ? (
              <Tooltip title="Mở rộng" className="z-[99]">
                <span className="flex items-center">
                  <ArrowsPointingOuOutlined
                    className="w-4 h-4 cursor-pointer"
                    onClick={onZoomOut}
                  />
                </span>
              </Tooltip>
            ) : (
              <Tooltip title="Thu gọn" className="z-[99]">
                <span className="flex items-center">
                  <ArrowsPointingInOutlined
                    className="w-4 h-4 cursor-pointer"
                    onClick={onZoomIn}
                  />
                </span>
              </Tooltip>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Header;
