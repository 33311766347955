import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import NoData from '_common/component/NoData';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import {
  DATE_BACKEND_FORMAT,
  DATE_FORMAT,
  REGEX,
} from '_common/constants/common';
import {
  messageValidate,
  messageValidateLength,
  messageWarning,
} from '_common/constants/message';
import CheckBox from '_common/dof/Control/CheckBox';
import DatePicker from '_common/dof/Control/DatePicker';
import Image from '_common/dof/Control/Image';
import Input from '_common/dof/Control/Input';
import InputPassword from '_common/dof/Control/InputPassword';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectRole from '_common/dof/Select/SelectRole';
import useConfirm from '_common/hooks/useConfirm';
import { pick } from 'lodash';
import moment from 'moment';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { DatePickerProps, Modal, notification, Spin } from 'tera-dls';
import UserApi from '../../api';
import { GENDER } from '../../constants';

interface UserFormProps {
  id: string | number;
  open: boolean;
  onClose?: () => void;
  onSuccess?: () => void;
}

function UserForm(props: UserFormProps) {
  const { id, open, onClose, onSuccess } = props;
  const form = useForm({
    mode: 'onChange',
    defaultValues: { status: true, role_id: null },
  });
  const confirm = useConfirm();
  const queryClient = useQueryClient();
  const { isDirty } = form.formState;
  const role_id = form.watch('role_id');

  const {
    data: dataDetails,
    refetch,
    isLoading,
    isError,
  } = useQuery(['get-user-detail', id], () => UserApi.getDetail({ id }), {
    enabled: !!id,
    staleTime: 300000,
    cacheTime: 300000,
  });

  const { mutate: mutateUser, isLoading: isLoadingMutate } = useMutation(
    (params: any) => {
      if (id) return UserApi.update({ id, params });
      return UserApi.create({ params });
    },
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          onClose && onClose();
          onSuccess && onSuccess();
          queryClient.invalidateQueries(['get-user-list']);
          queryClient.invalidateQueries(['get-summary-user-list']);
        }
      },
      onError: (error: any) => {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  const handleCloseConfirm = () => {
    if (isDirty) {
      confirm.warning({
        title: 'Thoát bản ghi',
        content: (
          <>
            <p>{messageWarning.WARNING_EXIT_1}</p>
            <p>{messageWarning.WARNING_EXIT_2}</p>
          </>
        ),
        onOk: () => {
          onClose();
        },
      });
    } else onClose();
  };

  const handleSubmitForm = (value) => {
    if ((isLoading && !!id) || isLoadingMutate) return;
    const data = {
      ...value,
      status: !!value?.status ? 1 : 0,
      birthday:
        value?.birthday && moment(value?.birthday).format(DATE_BACKEND_FORMAT),
    };
    mutateUser(data);
  };

  const optionsGender = Object.entries(GENDER).map(([value, label]) => ({
    label,
    value,
  }));

  const disabledDate: DatePickerProps['disabledDate'] = (current) => {
    const today = moment();
    return current && current > today;
  };

  useEffect(() => {
    if (dataDetails) {
      const value = pick(dataDetails, [
        'avatar',
        'username',
        'code',
        'phone',
        'gender',
        'email',
        'address',
        'role_id',
      ]);
      const data = {
        ...value,
        name: dataDetails?.full_name,
        status: !!dataDetails?.status ? true : false,
        birthday: dataDetails?.birthday && moment(dataDetails?.birthday),
      };
      form.reset(data);
    }
  }, [dataDetails]);

  useEffect(() => {
    if (id) refetch();
  }, [id]);

  return (
    <Modal
      title={id ? 'Sửa người dùng' : 'Thêm người dùng'}
      destroyOnClose
      closeIcon={false}
      cancelText="Hủy"
      okText="Đồng ý"
      onOk={() => form.handleSubmit(handleSubmitForm)()}
      onCancel={handleCloseConfirm}
      open={open}
      centered={true}
      className="sm:w-[1000px]"
      confirmLoading={(isLoading && !!id) || isLoadingMutate}
    >
      <Spin spinning={(isLoading && !!id) || isLoadingMutate}>
        {isError && !!id ? (
          <NoData />
        ) : (
          <FormTera form={form} className="grid grid-cols-2 gap-x-2.5">
            <div className="flex flex-col">
              <p className="text-blue-500 font-medium mb-2.5">
                Thông tin đăng nhập
              </p>
              <FormTeraItem
                label="Ảnh đại diện"
                className="flex flex-col row-span-2"
                name="avatar"
              >
                <Image folder="user" object_key="user" />
              </FormTeraItem>
              <FormTeraItem
                label="Tên đăng nhập"
                name="username"
                rules={[
                  {
                    required: messageValidate.emptyText,
                    pattern: {
                      value: new RegExp(REGEX.USERNAME),
                      message: messageValidate.username,
                    },
                    maxLength: {
                      value: 15,
                      message: 'Không nhập quá 15 kí tự',
                    },
                  },
                ]}
              >
                <Input disabled={!!id} type="confirm-password" />
              </FormTeraItem>
              <FormTeraItem
                label="Phân quyền"
                name="role_id"
                rules={[
                  {
                    required: messageValidate.emptySelect,
                  },
                ]}
              >
                <SelectRole paramsApi={{ include_id: role_id }} />
              </FormTeraItem>
              {!id && (
                <FormTeraItem
                  label="Mật khẩu"
                  name="password"
                  rules={[
                    {
                      required: messageValidate.emptyText,
                      pattern: {
                        value: new RegExp(REGEX.PASSWORD),
                        message: messageValidate.password,
                      },
                      minLength: {
                        value: 8,
                        message: messageValidate.min_length_password,
                      },
                      maxLength: {
                        value: 16,
                        message: messageValidate.max_length_password,
                      },
                    },
                  ]}
                >
                  <InputPassword />
                </FormTeraItem>
              )}
              <FormTeraItem name="status">
                <CheckBox labelClassName="font-normal">Hoạt động</CheckBox>
              </FormTeraItem>
            </div>
            <div className="flex flex-col">
              <p className="text-blue-500 font-medium mb-2.5">
                Thông tin người dùng
              </p>
              <FormTeraItem
                label="Họ và tên"
                name="name"
                rules={[
                  {
                    required: messageValidate.emptyText,
                    maxLength: {
                      value: 100,
                      message: messageValidateLength.text,
                    },
                  },
                ]}
              >
                <Input />
              </FormTeraItem>
              <FormTeraItem label="Ngày sinh" name="birthday">
                <DatePicker format={DATE_FORMAT} disabledDate={disabledDate} />
              </FormTeraItem>
              <FormTeraItem label="Giới tính" name="gender">
                <Select options={optionsGender} />
              </FormTeraItem>
              <FormTeraItem
                label="Số điện thoại"
                name="phone"
                rules={[
                  {
                    // required: messageValidate.emptyText,
                    pattern: {
                      value: new RegExp(REGEX.PHONE_NUMBER),
                      message: messageValidate.phone,
                    },
                  },
                ]}
              >
                <Input />
              </FormTeraItem>
              <FormTeraItem
                label="Email"
                name="email"
                rules={[
                  {
                    pattern: {
                      value: new RegExp(REGEX.EMAIL),
                      message: messageValidate.email,
                    },
                    maxLength: {
                      value: 100,
                      message: messageValidateLength.text,
                    },
                  },
                ]}
              >
                <Input type="confirm-password" />
              </FormTeraItem>

              <FormTeraItem
                label="Địa chỉ"
                name="address"
                rules={[
                  {
                    maxLength: {
                      value: 255,
                      message: messageValidateLength.textLong,
                    },
                  },
                ]}
              >
                <Input />
              </FormTeraItem>
            </div>
          </FormTera>
        )}
      </Spin>
    </Modal>
  );
}

export default UserForm;
