import Filter from '_common/component/Filter';
import { DATE_BACKEND_FORMAT } from '_common/constants/common';
import DatePicker from '_common/dof/Control/DatePicker';
import Select from '_common/dof/Control/Select';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import _ from 'lodash';
import moment from 'moment';
import { PAYMENT_TYPE } from 'pages/Finance/constants';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { TRANSACTION_HISTORY_METHOD } from '../../constants';

interface TransactionHistoryFilterProps {
  open: boolean;
  onClose: () => void;
  onFilter: (value) => void;
  initialValue: any;
}

function TransactionHistoryFilter({
  open,
  onClose,
  onFilter,
  initialValue,
}: TransactionHistoryFilterProps) {
  const form = useForm({ mode: 'onChange' });

  const handleSubmitForm = (values) => {
    const data = {
      ...values,
      created_at:
        values?.created_at &&
        moment(values.created_at).format(DATE_BACKEND_FORMAT),
      updated_at:
        values?.updated_at &&
        moment(values.updated_at).format(DATE_BACKEND_FORMAT),
    };
    onFilter(data);
    onClose();
  };

  const handleReset = () => {
    const values = {
      transaction_type: null,
      methods: null,
      created_at: null,
      updated_at: null,
    };
    onFilter(values);
    onClose();
  };

  useEffect(() => {
    const values = _.pick(initialValue, [
      'transaction_type',
      'methods',
      'created_at',
      'updated_at',
    ]);
    const data = {
      ...values,
      created_at:
        values?.created_at && moment(values?.created_at, DATE_BACKEND_FORMAT),
      updated_at:
        values?.updated_at && moment(values?.updated_at, DATE_BACKEND_FORMAT),
    };
    form.reset(data);
  }, [initialValue]);

  const optionsPaymentType = Object.entries(PAYMENT_TYPE).map(
    ([value, label]) => ({
      value,
      label,
    }),
  );
  const optionsPaymentMethod = Object.entries(TRANSACTION_HISTORY_METHOD).map(
    ([value, label]) => ({
      value,
      label,
    }),
  );

  return (
    <Filter
      open={open}
      onCancel={onClose}
      onClose={onClose}
      onFilter={() => form.handleSubmit(handleSubmitForm)()}
    >
      <FormTera form={form} onSubmit={form.handleSubmit(handleSubmitForm)}>
        <FormTeraItem label="Loại giao dịch" name="transaction_type">
          <Select options={optionsPaymentType} allowClear />
        </FormTeraItem>
        <FormTeraItem label="Phương thức" name="methods">
          <Select options={optionsPaymentMethod} allowClear />
        </FormTeraItem>
        <FormTeraItem label="Thời gian tạo" name="created_at">
          <DatePicker />
        </FormTeraItem>
        <FormTeraItem label="Thời gian cập nhật" name="updated_at">
          <DatePicker />
        </FormTeraItem>

        <a
          className="text-red-500 text-sm font-normal cursor-pointer"
          onClick={() => handleReset()}
        >
          Hủy bộ lọc
        </a>
      </FormTera>
    </Filter>
  );
}

export default TransactionHistoryFilter;
