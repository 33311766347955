import Select from '_common/dof/Control/Select';
import { SelectProps } from 'tera-dls';

export const TICKET_TYPE = {
  order: 'Đặt hàng',
  request: 'Bánh yêu cầu',
};

function SelectTicketType(props: SelectProps) {
  const optionsTicketType = Object.entries(TICKET_TYPE).map(
    ([value, label]) => ({
      value,
      label,
    }),
  );

  return <Select options={optionsTicketType} allowClear {...props} />;
}

export default SelectTicketType;
