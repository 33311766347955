import { useQuery } from '@tanstack/react-query';
import { TParamsApiDof } from '_common/dof/interfaces';
import { useDebounce } from '_common/hooks/useDebounce';
import { forwardRef, useMemo, useState } from 'react';
import { OptionProps } from 'tera-dls';
import { SelectProps } from 'tera-dls/lib/components/Select';
import SelectEntity from '../SelectEntity';
import SumiApi from '_common/dof/_api';

interface SelectInvestorProps extends SelectProps {
  optionCustom?: OptionProps[];
  paramsApi?: TParamsApiDof;
}

const SelectInvestor = forwardRef<HTMLInputElement, SelectInvestorProps>(
  (
    { placeholder = 'Vui lòng chọn', paramsApi, optionCustom = [], ...props },
    ref,
  ) => {
    const [search, setSearch] = useState<string>('');
    const searchDebounce = useDebounce(search, 300);

    const { data: investorList, isLoading } = useQuery(
      ['get-investor-list', searchDebounce, paramsApi],
      () => {
        const params = {
          page: 1,
          limit: 15,
          keyword: searchDebounce,
          ...paramsApi,
        };
        return SumiApi.getInvestorList({ params });
      },
      {
        staleTime: 300000,
        cacheTime: 300000,
      },
    );

    const options: OptionProps[] = useMemo(() => {
      return (
        investorList?.data.map((investor) => ({
          label: investor?.investor_name,
          value: investor?.id,
        })) || []
      );
    }, [investorList]);

    return (
      <SelectEntity
        ref={ref}
        allowClear
        showSearch
        labelInValue
        loading={isLoading}
        placeholder={placeholder}
        options={[...optionCustom, ...options]}
        searchValue={search}
        onSearch={setSearch}
        {...props}
      />
    );
  },
);

export default SelectInvestor;
