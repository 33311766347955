import Select from '_common/dof/Control/Select';
import { SelectProps } from 'tera-dls';

export const PAYMENT_METHOD = {
  1: 'Ngân hàng',
  2: 'Ví điện tử',
};

function SelectPaymentMethodType(props: SelectProps) {
  const optionsPaymentMethod = Object.entries(PAYMENT_METHOD).map(
    ([value, label]) => ({
      value,
      label,
    }),
  );

  return <Select options={optionsPaymentMethod} allowClear {...props} />;
}

export default SelectPaymentMethodType;
