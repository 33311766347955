import CardFormV2 from '_common/component/CardForm/CardFormV2';
import DefaultImage from '_common/component/DefaultImage';
import HoverQuickView from '_common/component/HoverQuickView';
import {
  DATE_TIME_FORMAT,
  SUMI_SHADOW,
  TAB_ITEMS_STATUS,
  TAB_ITEMS_STATUS_COLOR,
} from '_common/constants/common';
import { INVESTMENT_CATEGORY } from '_common/dof/Select/SelectInvestmentCategory';
import TableTera from '_common/dof/TableTera';
import Row from '_common/dof/TableTera/TeraNormalTable/Row';
import classNames from 'classnames';
import { INVESTMENT_TYPE } from 'pages/Invest/InvestmentType/constants';
import { useMemo } from 'react';
import emptyInvestment from 'styles/images/pages/investment/empty-investment.png';
import {
  ColumnsType,
  formatCurrency,
  formatDate,
  formatNumber,
  Image,
  Tag,
} from 'tera-dls';
import { TRIAL_TIME } from '../../constants';

function Information({ dataDetails }) {
  const detail = [
    {
      label: 'Ảnh đại diện',
      value: (
        <DefaultImage
          zoom
          src={dataDetails?.file_upload}
          alt={dataDetails?.file_upload}
          className="w-[100px] h-[100px] rounded-[10px] overflow-hidden"
          imageClassName="object-cover"
        />
      ),
      className: 'row-span-7',
    },
    {
      label: 'Mã đầu tư',
      value: dataDetails?.code,
    },
    {
      label: 'Địa điểm',
      value: dataDetails?.address,
    },
    {
      label: 'Tên đầu tư',
      value: dataDetails?.name,
    },
    {
      label: 'Thời gian dùng thử',
      value: TRIAL_TIME[dataDetails?.trial_time],
    },

    {
      label: 'Danh mục đầu tư',
      value: INVESTMENT_CATEGORY?.[dataDetails?.type],
    },
    {
      label: 'Người tạo',
      value: (
        <HoverQuickView
          avatarUrl={dataDetails?.created_by?.avatar}
          name={dataDetails?.created_by?.full_name}
          code={dataDetails?.created_by?.code}
          email={dataDetails?.created_by?.email}
          phone={dataDetails?.created_by?.phone}
        >
          {dataDetails?.created_by?.full_name}
        </HoverQuickView>
      ),
    },

    {
      label: 'Giá',
      value: dataDetails?.amount && formatCurrency(dataDetails?.amount),
    },
    {
      label: 'Ngày tạo',
      value:
        dataDetails?.created_at &&
        formatDate(dataDetails?.created_at, DATE_TIME_FORMAT),
    },

    {
      label: 'Tên thiết bị',
      value: dataDetails?.device?.full_name,
    },
    {
      label: 'Người cập nhật',
      value: (
        <HoverQuickView
          avatarUrl={dataDetails?.updated_by?.avatar}
          name={dataDetails?.updated_by?.full_name}
          code={dataDetails?.updated_by?.code}
          email={dataDetails?.updated_by?.email}
          phone={dataDetails?.updated_by?.phone}
        >
          {dataDetails?.updated_by?.full_name}
        </HoverQuickView>
      ),
    },
    {
      label: 'ID camera',
      value: dataDetails?.camera_id,
    },
    {
      label: 'Ngày cập nhật',
      value:
        dataDetails?.updated_by &&
        dataDetails?.updated_at &&
        formatDate(dataDetails?.updated_at, DATE_TIME_FORMAT),
    },
    {
      label: 'Trạng thái',
      value: (
        <Tag color={TAB_ITEMS_STATUS_COLOR[dataDetails?.status]}>
          {TAB_ITEMS_STATUS[dataDetails?.status]}
        </Tag>
      ),
    },
  ];

  const location = [
    {
      label: 'Loại hình đầu tư',
      value: dataDetails?.invest_model?.name,
    },
    {
      label: 'Loại đầu tư',
      value: INVESTMENT_TYPE[dataDetails?.invest_model?.type],
    },
    {
      label: 'Giá',
      value: formatCurrency(dataDetails?.invest_model?.price),
    },
  ];

  const investor = [
    {
      label: 'Ảnh đại diện',
      value: dataDetails?.investor?.file_upload && (
        <Image
          src={dataDetails?.investor?.file_upload}
          alt={dataDetails?.investor?.file_upload}
          containerClassName="w-[100px] h-[100px] rounded-[10px] overflow-hidden"
        />
      ),
      className: 'row-span-3',
    },
    {
      label: 'Tên nhà đầu tư',
      value: (
        <HoverQuickView
          avatarUrl={dataDetails?.investor?.file_upload}
          name={dataDetails?.investor?.investor_name}
          code={dataDetails?.investor?.code}
          email={dataDetails?.investor?.email}
          phone={dataDetails?.investor?.phone}
        >
          {dataDetails?.investor?.investor_name}
        </HoverQuickView>
      ),
    },
    {
      label: 'Tổng SL đơn bán hàng',
      value: formatNumber(dataDetails?.investor?.count_sell_order?.count_total),
    },
    {
      label: 'Trạng thái',
      value: (
        <Tag color={TAB_ITEMS_STATUS_COLOR[dataDetails?.investor?.status]}>
          {TAB_ITEMS_STATUS[dataDetails?.investor?.status]}
        </Tag>
      ),
    },
    {
      label: 'Tổng SL đơn đặt hàng',
      value: formatNumber(dataDetails?.investor?.count_order?.count_total),
    },
    {
      label: '',
      value: '',
    },
    {
      label: 'Tổng doanh thu',
      value: formatCurrency(dataDetails?.investor?.total_revenue?.total_price),
    },
  ];

  const columns: ColumnsType<any> = [
    {
      title: 'Tên sản phẩm',
      dataIndex: 'name',
      width: 200,
      render: (name, record) => (
        <div className="flex gap-x-2.5">
          <Image
            src={record?.file_upload}
            alt={record?.file_upload}
            containerClassName="w-[30px] h-[30px] rounded overflow-hidden border shrink-0"
          />
          <p className="line-clamp-2 break-word">{name}</p>
        </div>
      ),
    },
    {
      title: 'Tên viết tắt',
      dataIndex: 'abbreviation',
      width: 200,
    },
    {
      title: 'Danh mục',
      dataIndex: 'warehouse_cata_log',
      width: 200,
      render: (warehouse_cata_log) => (
        <p className="line-clamp-2">{warehouse_cata_log?.name}</p>
      ),
    },
    {
      title: 'Đơn vị',
      dataIndex: 'product_unit_id',
      width: 100,
      render: (product_unit_id) => product_unit_id?.name,
    },
    {
      title: 'Giá bán',
      dataIndex: 'amount',
      width: 200,
      render: (amount) => amount && formatCurrency(amount),
    },
  ];

  const products = useMemo(() => {
    if (!dataDetails?.investment) return;
    const data = dataDetails?.investment?.map((prod) => ({
      name: prod?.product_warehouse?.name,
      warehouse_cata_log: prod?.product_warehouse?.warehouse_cata_log,
      product_unit_id: prod?.product_warehouse?.product_unit_id,
      amount: prod?.amount,
      file_upload: prod?.product_warehouse?.file_upload,
      abbreviation: prod?.abbreviation,
    }));
    return data;
  }, [dataDetails]);

  return (
    <div className="flex flex-col gap-4">
      <div
        className={classNames(
          'px-2.5 py-5 rounded-md border border-blue-400',
          SUMI_SHADOW,
        )}
      >
        <CardFormV2 title="Thông tin chung">
          <Row className="grid grid-cols-3 gap-2.5">
            {detail.map((item, index) => (
              <div
                className={classNames('flex items-start', item.className)}
                key={index}
              >
                <div className="detail-key">{item.label}</div>
                <div className="detail-value">{item.value}</div>
              </div>
            ))}
          </Row>
        </CardFormV2>
      </div>
      {dataDetails?.type === 'location' && (
        <div
          className={classNames(
            'px-2.5 py-5 rounded-md border border-blue-400',
            SUMI_SHADOW,
          )}
        >
          <CardFormV2 title="Thông tin đầu tư địa điểm">
            <Row className="grid grid-cols-3 gap-2.5">
              {location.map((item, index) => (
                <div className="flex items-start" key={index}>
                  <div className="detail-key">{item.label}</div>
                  <div className="detail-value">{item.value}</div>
                </div>
              ))}
            </Row>
          </CardFormV2>
        </div>
      )}
      <div
        className={classNames(
          'px-2.5 py-5 rounded-md border border-blue-400',
          SUMI_SHADOW,
        )}
      >
        <CardFormV2 title="Thông tin chủ đầu tư">
          {dataDetails?.investor_id ? (
            <Row className="grid grid-cols-3 gap-2.5">
              {investor.map((item, index) => (
                <div
                  className={classNames('flex items-start', item.className)}
                  key={index}
                >
                  <div className="detail-key">{item.label}</div>
                  <div className="detail-value">{item.value}</div>
                </div>
              ))}
            </Row>
          ) : (
            <div className="flex items-center flex-col gap-y-2.5">
              <img src={emptyInvestment} alt="empty-investment" />
              <p>Chưa có chủ đầu tư</p>
            </div>
          )}
        </CardFormV2>
      </div>
      <div
        className={classNames(
          'px-2.5 py-5 rounded-md border border-blue-400',
          SUMI_SHADOW,
        )}
      >
        <CardFormV2 title="Sản phẩm">
          <TableTera data={products} columns={columns} pagination={{}} />
        </CardFormV2>
      </div>
    </div>
  );
}

export default Information;
