export const ORDER_STATUS = {
  all: 'Tất cả',
  pending: 'Chờ duyệt',
  approved: 'Đã duyệt',
  reject: 'Từ chối',
};

export const ORDER_STATUS_COLOR = {
  pending: 'yellow03',
  approved: 'green03',
  reject: 'red03',
};
