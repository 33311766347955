import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import CardFormV2 from '_common/component/CardForm/CardFormV2';
import ErrorToast from '_common/component/ToastCustom/ErrorsToast';
import { messageValidate } from '_common/constants/message';
import Input from '_common/dof/Control/Input';
import TextArea from '_common/dof/Control/TextArea';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectInvestment from '_common/dof/Select/SelectInvestment';
import InvestmentApi from 'pages/Invest/Invesment/api';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { notification } from 'tera-dls';
import SalesExpansesApi from '../../api';
import SalesExpensesFormTable, {
  ISalesExpensesFormTableRef,
} from './SalesExpensesFormTable';

export interface ISalesExpensesFormContentRef {
  submit: () => void;
  getIsDirty: () => boolean;
  checkError: () => boolean;
}

interface IProps {
  onSuccess?: () => void;
  id?: number | string;
}

const SalesExpensesFormContent = (props, ref) => {
  const { onSuccess, id } = props;
  const salesExpensesRef = useRef<ISalesExpensesFormTableRef>(null);
  const form = useForm({ mode: 'onChange' });
  const queryClient = useQueryClient();
  const [expenses, setExpenses] = useState<any>();
  const invest_id = form.watch('invest_id');

  const { data: detail, refetch } = useQuery(
    ['get-sales-expenses-detail', id],
    () => SalesExpansesApi.getDetail({ id }),
    {
      enabled: !!id,
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const { data: detailInvestment } = useQuery(
    ['get-investment-detail', invest_id],
    () => InvestmentApi.getDetail({ id: invest_id }),
    {
      enabled: !!invest_id,
      staleTime: 300000,
      cacheTime: 300000,
    },
  );

  const { mutate: mutateAction } = useMutation(
    (params: any) =>
      id
        ? SalesExpansesApi.update({ params, id })
        : SalesExpansesApi.create({ params }),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          onSuccess && onSuccess();
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries(['get-investment-list']);
        }
      },
      onError(error: any) {
        ErrorToast({ errorProp: error?.data });
      },
    },
  );

  useEffect(() => {
    if (!detail) return;
    const data = {
      investor_id: detail?.investor_id?.id,
      invest_id: detail?.invest_id?.id,
      device_id: detail?.invest_id?.device_id,
      note: detail?.note,
    };

    form.reset(data);
  }, [detail]);

  useEffect(() => {
    if (detailInvestment) {
      form.setValue(
        'investor',
        detailInvestment?.investor_id
          ? detailInvestment?.investor?.investor_name
          : 'Hệ thống',
      );
      form.setValue('investor_id', detailInvestment?.investor_id);
      form.setValue('device', detailInvestment?.device?.full_name || '');
    }
  }, [detailInvestment]);

  const handleSubmit = (values: any): void => {
    if (!salesExpensesRef.current?.getLengthData()) {
      notification.error({
        message: 'Vui lòng nhập ít nhất 1 chi phí bán hàng',
      });
      return;
    }

    const data = {
      investor_id: values?.investor_id,
      invest_id: values?.invest_id,
      note: values?.note,
      details: expenses,
    };

    mutateAction(data);
  };

  useEffect(() => {
    if (id) {
      refetch();
    }
  }, [id]);

  useImperativeHandle(
    ref,
    () => ({
      checkError() {
        return salesExpensesRef?.current?.checkError();
      },
      async submit() {
        const isNotEditing = await salesExpensesRef?.current?.checkError();
        isNotEditing && form.handleSubmit(handleSubmit)();
      },
      async getIsDirty() {
        const isNotErrors = await salesExpensesRef?.current?.checkError();
        if (detail) {
          return (
            form.formState.isDirty ||
            JSON.stringify(expenses) !== JSON.stringify(detail?.explain) ||
            !isNotErrors
          );
        }
        return form.formState.isDirty || expenses?.length > 0 || !isNotErrors;
      },
    }),
    [form, form.formState.isDirty, handleSubmit, salesExpensesRef, detail],
  );

  return (
    <FormTera form={form} onSubmit={handleSubmit}>
      <CardFormV2 title="Thông tin chi phí bán hàng">
        <div className="grid grid-cols-3 gap-x-4">
          <FormTeraItem
            label="Đầu tư"
            name="invest_id"
            rules={[
              {
                required: messageValidate.emptySelect,
              },
            ]}
          >
            <SelectInvestment
              paramsApi={{
                isActive: true,
                isHaveSaleCost: false,
                include_id: detail?.invest_id?.id,
                include_id_new: invest_id,
              }}
              allowClear={false}
            />
          </FormTeraItem>
          <FormTeraItem label="Nhà đầu tư" name="investor">
            <Input disabled />
          </FormTeraItem>
          <FormTeraItem label="Thiết bị" name="device">
            <Input disabled />
          </FormTeraItem>
          <FormTeraItem label="Ghi chú" name="note" className="col-span-3">
            <TextArea rows={5} />
          </FormTeraItem>
        </div>
      </CardFormV2>
      <CardFormV2 title="Chi phí bán hàng">
        <SalesExpensesFormTable
          detail={detail}
          mode={id ? 'default' : 'soft'}
          id={id}
          investId={detail?.invest_id?.id}
          objectType="sales-expenses"
          onChange={setExpenses}
          ref={salesExpensesRef}
          onSuccess={() => {
            queryClient.invalidateQueries(['get-sales-expenses-list']);
          }}
        />
      </CardFormV2>
    </FormTera>
  );
};

export default forwardRef<ISalesExpensesFormContentRef, IProps>(
  SalesExpensesFormContent,
);
