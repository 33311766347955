import React from 'react';

function Menu1(props) {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.87341 0.461311C7.19125 0.201199 7.5893 0.059082 8 0.059082C8.4107 0.059082 8.80875 0.201199 9.12659 0.461311L15.3266 5.51637C15.5375 5.68933 15.7074 5.90703 15.8239 6.15373C15.9403 6.40042 16.0005 6.66992 16 6.94272V16.1121C16 17.1225 15.1943 17.9413 14.2 17.9413H11.6824C10.688 17.9413 9.88235 17.1225 9.88235 16.1121V11.7705C9.88235 11.4335 9.61412 11.1606 9.28235 11.1606H6.71765C6.38635 11.1606 6.11765 11.4335 6.11765 11.7705V16.1121C6.11765 17.1225 5.312 17.9413 4.31765 17.9413H1.8C0.80612 17.9413 3.21733e-06 17.1225 3.21733e-06 16.1121V6.94272C-0.000506901 6.66992 0.0596496 6.40042 0.176118 6.15373C0.292586 5.90703 0.46245 5.68933 0.673415 5.51637L6.87341 0.461311ZM8.37553 1.41237C8.26958 1.32567 8.1369 1.27829 8 1.27829C7.8631 1.27829 7.73042 1.32567 7.62447 1.41237L1.42447 6.46696C1.35403 6.52457 1.29732 6.59717 1.25849 6.67948C1.21966 6.76178 1.19968 6.85171 1.2 6.94272V16.1121C1.2 16.4491 1.46824 16.722 1.8 16.722H4.31765C4.64894 16.722 4.91765 16.4491 4.91765 16.1121V11.7705C4.91765 10.7601 5.72377 9.94131 6.71765 9.94131H9.28235C10.2762 9.94131 11.0824 10.7601 11.0824 11.7705V16.1121C11.0824 16.4491 11.3506 16.722 11.6824 16.722H14.2C14.5313 16.722 14.8 16.4491 14.8 16.1121V6.94272C14.8003 6.85179 14.7802 6.76195 14.7414 6.67974C14.7026 6.59752 14.6459 6.52499 14.5755 6.46743L8.37553 1.41237Z"
        fill="#6B7280"
      />
    </svg>
  );
}

export default Menu1;
