import Filter from '_common/component/Filter';
import FormTera, { FormTeraItem } from '_common/dof/FormTera';
import SelectBank from '_common/dof/Select/SelectBank';
import SelectPaymentMethodType from '_common/dof/Select/SelectPaymentMethodType';
import _ from 'lodash';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

interface TransactionHistoryFilterProps {
  open: boolean;
  onClose: () => void;
  onFilter: (value) => void;
  initialValue: any;
}

function TransactionHistoryFilter({
  open,
  onClose,
  onFilter,
  initialValue,
}: TransactionHistoryFilterProps) {
  const form = useForm({ mode: 'onChange' });
  const object_type = form.watch('object_type');

  const handleSubmitForm = (values) => {
    onFilter(values);
    onClose();
  };

  const handleReset = () => {
    const values = {
      object_type: null,
      card_type_id: null,
    };
    onFilter(values);
    onClose();
  };

  useEffect(() => {
    const values = _.pick(initialValue, ['object_type', 'card_type_id']);

    form.reset(values);
  }, [initialValue]);

  return (
    <Filter
      open={open}
      onCancel={onClose}
      onClose={onClose}
      onFilter={() => form.handleSubmit(handleSubmitForm)()}
    >
      <FormTera form={form} onSubmit={form.handleSubmit(handleSubmitForm)}>
        <FormTeraItem label="Phương thức thanh toán" name="object_type">
          <SelectPaymentMethodType
            onChangeCustom={() => form.setValue('card_type_id', null)}
          />
        </FormTeraItem>
        <FormTeraItem label="Ngân hàng / Ví điện tử" name="card_type_id">
          <SelectBank paramsApi={{ object_type }} disabled={!object_type} />
        </FormTeraItem>

        <a
          className="text-red-500 text-sm font-normal cursor-pointer"
          onClick={() => handleReset()}
        >
          Hủy bộ lọc
        </a>
      </FormTera>
    </Filter>
  );
}

export default TransactionHistoryFilter;
