import { IRouteProps } from '_common/interface/router';
import { INVESTOR_URL } from './url';
import Investor from '.';
import InvestorDetail from './containers/Detail';

export const InvestorRouter: IRouteProps[] = [
  {
    key: INVESTOR_URL.list.key,
    path: INVESTOR_URL.list.shortenUrl,
    component: <Investor />,
  },
  {
    key: INVESTOR_URL.detail.key,
    path: INVESTOR_URL.detail.shortenUrl,
    component: <InvestorDetail />,
  },
];
