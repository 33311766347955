import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const UnitEndpoint = `${endpoint}/portal/product-unit`;

const UnitApi = {
  getList: async ({ params }) =>
    await api
      .get(`${UnitEndpoint}/list`, params)
      .then((res) => res?.data?.data),
  getDetail: async ({ id }) =>
    await api
      .get(`${UnitEndpoint}/detail/${id}`)
      .then((res) => res?.data?.data),
  create: async ({ params }) =>
    await api.post(`${UnitEndpoint}/create`, params).then((res) => res?.data),
  update: async ({ id, params }) =>
    await api
      .put(`${UnitEndpoint}/update/${id}`, params)
      .then((res) => res?.data),
  delete: async ({ id }) =>
    await api.delete(`${UnitEndpoint}/delete/${id}`).then((res) => res?.data),
};

export default UnitApi;
