import { useQuery } from '@tanstack/react-query';
import { TParamsApiDof } from '_common/dof/interfaces';
import { useDebounce } from '_common/hooks/useDebounce';
import { forwardRef, useMemo, useState } from 'react';
import { OptionProps } from 'tera-dls';
import { SelectProps } from 'tera-dls/lib/components/Select';
import SelectEntity from '../SelectEntity';
import SumiApi from '_common/dof/_api';

interface SelectInvestmentProps extends SelectProps {
  paramsApi?: TParamsApiDof;
}

const SelectInvestment = forwardRef<HTMLInputElement, SelectInvestmentProps>(
  ({ placeholder = 'Vui lòng chọn', paramsApi, ...props }, ref) => {
    const [search, setSearch] = useState<string>('');
    const searchDebounce = useDebounce(search, 300);

    const { data: investmentList, isLoading } = useQuery(
      ['get-investment-list', searchDebounce, paramsApi],
      () => {
        const params = {
          page: 1,
          limit: 15,
          keyword: searchDebounce,
          ...paramsApi,
        };
        return SumiApi.getInvestmentList({ params });
      },
      {
        staleTime: 300000,
        cacheTime: 300000,
      },
    );

    const options: OptionProps[] = useMemo(() => {
      return (
        investmentList?.data
          ?.filter((i) => i?.id)
          .map((investment) => ({
            label: investment?.name,
            value: investment?.id,
            labelDisplay: (
              <p>
                <span className="text-green-500">[{investment?.code}]</span> -{' '}
                <span>{investment?.name}</span>
              </p>
            ),
          })) || []
      );
    }, [investmentList]);

    return (
      <SelectEntity
        ref={ref}
        allowClear
        showSearch
        labelInValue
        loading={isLoading}
        placeholder={placeholder}
        options={options}
        searchValue={search}
        onSearch={setSearch}
        {...props}
      />
    );
  },
);

export default SelectInvestment;
