import { SALE_ORDER_PERMISSION_KEY } from '_common/constants/permission';

export const SALE_ORDER_URL = {
  list: {
    key: SALE_ORDER_PERMISSION_KEY.VIEW_LIST,
    path: '/sale/sale-order/list',
    shortenUrl: 'sale-order/list',
  },
  detail: {
    key: SALE_ORDER_PERMISSION_KEY.VIEW_DETAIL,
    path: '/sale/sale-order/detail',
    shortenUrl: 'sale-order/detail/:id',
  },
};
