import { useQuery } from '@tanstack/react-query';
import SumiApi from '_common/dof/_api';
import { TParamsApiDof } from '_common/dof/interfaces';
import { forwardRef, useMemo, useState } from 'react';
import { OptionProps } from 'tera-dls';
import { SelectProps } from 'tera-dls/lib/components/Select';
import SelectEntity from '../SelectEntity';

interface SelectCameraProps extends SelectProps {
  paramsApi?: TParamsApiDof;
}

const SelectCamera = forwardRef<HTMLInputElement, SelectCameraProps>(
  ({ placeholder = 'Vui lòng chọn', paramsApi, ...props }, ref) => {
    const [search, setSearch] = useState<string>('');
    // const searchDebounce = useDebounce(search, 300);

    const { data: cameraList, isLoading } = useQuery(
      ['get-camera-list', paramsApi],
      () => {
        const params = {
          page: 1,
          limit: 100,
          // keyword: searchDebounce,
          ...paramsApi,
        };
        return SumiApi.getCameraList({ params });
      },
      {
        staleTime: 300000,
        cacheTime: 300000,
      },
    );

    const options: OptionProps[] = useMemo(() => {
      return (
        cameraList?.data.map((cam) => ({
          label: cam?.name,
          value: cam?.camID,
          labelDisplay: (
            <p>
              <span className="text-green-500">[{cam?.camID}]</span> -{' '}
              <span>{cam?.name}</span>
            </p>
          ),
        })) || []
      );
    }, [cameraList]);

    return (
      <SelectEntity
        ref={ref}
        allowClear
        labelInValue
        loading={isLoading}
        placeholder={placeholder}
        options={options}
        searchValue={search}
        onSearch={setSearch}
        {...props}
      />
    );
  },
);

export default SelectCamera;
