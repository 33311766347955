import Search from '_common/dof/Control/Search';
import FormTera, { FormTeraItem, useFormTera } from '_common/dof/FormTera';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { MagnifyingGlassOutlined, getQueryParams } from 'tera-dls';
interface OrderSearchProps {
  onSearch: (value) => void;
}
function OrderSearch({ onSearch }: OrderSearchProps) {
  const [formRef] = useFormTera();

  const { search } = useLocation();
  const queryParams = getQueryParams(search) as any;

  const handleSearch = (value) => {
    if (formRef?.current?.isDirty) {
      onSearch(value);
      formRef?.current?.reset({ ...value }, { keepValues: true });
    }
  };

  useEffect(() => {
    if (queryParams) {
      const values = {
        keyword: queryParams?.keyword,
        ticket_type: queryParams?.ticket_type === 'request',
      };

      formRef?.current?.reset({
        keyword: values?.keyword,
        ticket_type: values?.ticket_type,
      });
    }
  }, [queryParams]);

  return (
    <FormTera
      ref={formRef}
      onSubmit={handleSearch}
      isLoading={false}
      className="flex gap-x-2.5"
    >
      <FormTeraItem className="!mb-0" name="keyword">
        <Search
          placeholder="Tìm kiếm theo mã đơn hàng, tên đầu tư, tên thiết bị"
          icon={<MagnifyingGlassOutlined />}
          className="!py-[6px]"
        />
      </FormTeraItem>
    </FormTera>
  );
}

export default OrderSearch;
