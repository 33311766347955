import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import HeaderViewList from '_common/component/HeaderViewList';
import TableTera from '_common/dof/TableTera';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  DropdownItem,
  getQueryParams,
  notification,
  PaginationProps,
  Tag,
  updateQueryParams,
} from 'tera-dls';

import ActionDropdown from '_common/component/TableColumnCustom/ActionDropdown';
import useConfirm from '_common/hooks/useConfirm';
import CameraApi from './api';
import { CAMERA_STATUS, CAMERA_STATUS_COLOR } from './constants';
import CameraDetail from './containers/Detail';
import CameraForm from './containers/Form';
import { CAMERA_URL } from './url';

function Camera() {
  const [openForm, setOpenForm] = useState({ open: false, id: null });
  const [openDetail, setOpenDetail] = useState({ open: false, id: null });

  const navigate = useNavigate();
  const confirm = useConfirm();
  const { search } = useLocation();
  const queryClient = useQueryClient();
  const queryParams = getQueryParams(search) as any;

  const handleUpdateQueryParams = (data) => {
    const paramString = updateQueryParams({
      ...queryParams,
      ...data,
    });
    navigate(`${CAMERA_URL.list.path}${paramString}`);
  };

  const { data: cameraList, isLoading } = useQuery(
    ['get-camera-list', queryParams],
    () => {
      const params = {
        ...queryParams,
        page: queryParams?.page || 1,
        limit: queryParams?.limit || 10,
      };
      return CameraApi.getList({ params });
    },
    {
      cacheTime: 300000,
      staleTime: 300000,
      onSuccess: (data) => {
        if (Number(data?.last_page) < (Number(queryParams?.page) || 1)) {
          handleUpdateQueryParams({ page: data?.last_page });
        }
      },
    },
  );

  const { mutate: mutateDelete, isLoading: loadingDelete } = useMutation(
    (id: any) => CameraApi.delete({ id }),
    {
      onSuccess: (res) => {
        if (res?.code === 200) {
          notification.success({
            message: res?.msg,
          });
          queryClient.invalidateQueries(['get-camera-list']);
        }
      },
      onError: (error: any) => {
        notification.error({
          message: error?.message,
        });
      },
    },
  );

  const handleChangePage: PaginationProps['onChange'] = (page, pageSize) => {
    const isDiffPageSize = Number(pageSize) !== Number(cameraList?.limit || 10);

    handleUpdateQueryParams({
      page: isDiffPageSize ? 1 : page,
      limit: pageSize,
    });
  };

  const handleDelete = (record) => {
    confirm.warning({
      title: 'Xác nhận xóa camera',
      content: (
        <div className="break-word">
          <p>Bạn có chắc muốn xóa camera</p>
          <p>
            <b>{record?.name}</b> này không?
          </p>
        </div>
      ),
      onOk: () => {
        mutateDelete(record?.id);
      },
    });
  };

  const renderItemActions = (record) => {
    const items: DropdownItem[] = [];

    if (true)
      items.push({
        key: '1',
        label: 'Xem',
        onClick: () => setOpenDetail({ open: true, id: record?.id }),
      });
    if (true)
      items.push({
        key: '3',
        label: 'Sửa',
        onClick: () => setOpenForm({ open: true, id: record?.id }),
      });
    if (true)
      items.push({
        key: '4',
        label: <span className="text-red-500">Xóa</span>,
        onClick: () => handleDelete(record),
      });

    return items;
  };

  const columns: any = [
    {
      title: 'Camera ID',
      dataIndex: 'camID',
      render: (camid, record) => (
        <span
          className="text-blue-600 cursor-pointer"
          onClick={() => setOpenDetail({ open: true, id: record?.id })}
        >
          {camid}
        </span>
      ),
    },
    {
      title: 'IP',
      dataIndex: 'ip',
    },
    {
      title: 'Tên camera',
      dataIndex: 'name',
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      render: (status) => (
        <Tag color={CAMERA_STATUS_COLOR[status]}>{CAMERA_STATUS[status]}</Tag>
      ),
    },
    {
      width: 60,
      fixed: 'right',
      render: (record) => {
        const items = renderItemActions(record);
        return <ActionDropdown dropdownItems={items} trigger="click" />;
      },
    },
  ];

  return (
    <div className="tera-page">
      <HeaderViewList
        title="Danh sách camera"
        onClickButtonAdd={() => setOpenForm({ open: true, id: null })}
      >
        <TableTera
          rowKey={'id'}
          columns={columns}
          data={cameraList?.data || []}
          loading={isLoading || loadingDelete}
          pagination={{
            onChange: handleChangePage,
            total: cameraList?.total || 0,
            current: cameraList?.current_page,
            pageSize: cameraList?.per_page || 10,
            to: cameraList?.to,
            from: cameraList?.from || 10,
          }}
          zebra={false}
        />
      </HeaderViewList>
      {openDetail.open && (
        <CameraDetail
          open={openDetail.open}
          id={openDetail.id}
          onCancel={() => setOpenDetail({ open: false, id: null })}
        />
      )}
      {openForm.open && (
        <CameraForm
          open={openForm.open}
          id={openForm.id}
          onClose={() => setOpenForm({ open: false, id: null })}
          onSuccess={() => {
            queryClient.invalidateQueries(['get-camera-list']);
          }}
        />
      )}
    </div>
  );
}

export default Camera;
