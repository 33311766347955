import { endpoint } from '_common/constants/common';
import api from 'states/drivers';

const UserEndpoint = `${endpoint}/portal/config/user`;
const AuthEndpoint = `${endpoint}/auth`;

const UserApi = {
  getList: async ({ params }) =>
    await api
      .get(`${UserEndpoint}/list`, params)
      .then((res) => res?.data?.data),
  getDetail: async ({ id }) =>
    await api
      .get(`${UserEndpoint}/detail/${id}`)
      .then((res) => res?.data?.data),
  getSummary: async ({ params }) =>
    await api
      .get(`${UserEndpoint}/summary-list`, params)
      .then((res) => res.data?.data),
  create: async ({ params }) =>
    await api.post(`${UserEndpoint}/create`, params).then((res) => res?.data),
  update: async ({ id, params }) =>
    await api
      .put(`${UserEndpoint}/update/${id}`, params)
      .then((res) => res?.data),
  resetPassword: async ({ params }) =>
    await api
      .put(`${AuthEndpoint}/change-password`, params)
      .then((res) => res?.data),
  delete: async ({ id }) =>
    await api.delete(`${UserEndpoint}/delete/${id}`).then((res) => res?.data),
};

export default UserApi;
