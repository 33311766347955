import { IRouteProps } from '_common/interface/router';
import { TIME_CONFIG_URL } from './url';
import TimeConfig from '.';

export const TimeConfigRouter: IRouteProps[] = [
  {
    key: TIME_CONFIG_URL.list.key,
    path: TIME_CONFIG_URL.list.shortenUrl,
    component: <TimeConfig />,
  },
];
